import { atom } from 'jotai';

import { SafeRoleDetail } from '@/interfaces';

export const loadedSafeRolesAtom = atom<SafeRoleDetail | undefined>(undefined);

export const safeRolesAtom = atom<SafeRoleDetail | undefined>(undefined);

export const delegatedQueueAtom = atom<string | undefined>(undefined);

export const safeRolesQueueAtom = atom<string | undefined>(undefined);
