import {
  HttpMethod,
  sendRequest,
} from '@safe-global/api-kit/dist/src/utils/httpRequests';
import { SafeMultisigTransactionResponse } from '@safe-global/safe-core-sdk-types';
import Web3 from 'web3';

import Chain from '@/chains';
import { getChainIdByShortName } from '@/chains/utils';
import GnosisSafe from '@/contracts/gnosis';

const getSafeInfoFromGnosisSafe = async (
  chainId: number,
  safeAddress: string,
): Promise<{
  address: string;
  nonce: number;
  threshold: number;
  owners: string[];
  masterCopy: string;
  modules: string[];
  fallbackHandler: string;
  guard: string;
  version: string;
}> => {
  if (!safeAddress) throw new Error('Invalid safe address');
  const chain = new Chain(chainId);
  if (!chain) throw new Error(`chain ${chainId} not supported`);
  return sendRequest({
    url: `${chain.safeApiUrl()}/api/v1/safes/${safeAddress}/`,
    method: HttpMethod.Get,
  });
};

const safeTxResultCache: { [key: string]: SafeMultisigTransactionResponse } =
  {};

const getSafeTxFromSafeWallet = async (
  chainId: number,
  safeTxHash: string,
): Promise<SafeMultisigTransactionResponse> => {
  if (!chainId) throw new Error(`chain ${chainId} not supported`);
  const chain = new Chain(chainId);
  const key = `${chainId}-${safeTxHash}`;
  if (safeTxResultCache[key]) {
    return safeTxResultCache[key];
  }
  return sendRequest({
    url: `${chain.safeApiUrl()}/api/v1/multisig-transactions/${safeTxHash}/`,
    method: HttpMethod.Get,
  });
};

const getSafeTokenBalance = async (
  chainId: number,
  safeAddress: string,
): Promise<
  {
    tokenAddress: string | null;
    token: {
      name: string;
      symbol: string;
      decimals: number;
      logoUri: string;
    } | null;
    balance: string;
  }[]
> => {
  if (!safeAddress) throw new Error('Invalid safe address');
  const chain = new Chain(chainId);
  if (!chain) throw new Error(`chain ${chainId} not supported`);
  return sendRequest({
    url: `${chain.safeApiUrl()}/api/v1/safes/${safeAddress}/balances/?exclude_spam=true`,
    method: HttpMethod.Get,
  });
};

const getSafeHomeUrl = (chain: string, safeAddress: string): string => {
  const c = new Chain(getChainIdByShortName(chain));
  if (!c) throw new Error(`chain ${chain} not supported`);
  return c.safeUrl(safeAddress);
};

const getSafeTransactionUrl = (chain: string, safeAddress: string): string => {
  const c = new Chain(getChainIdByShortName(chain));
  if (!c) throw new Error(`chain ${chain} not supported`);
  return `${c.safeWebBaseUrl()}/transactions/history?safe=${chain}:${safeAddress}`;
};

const getSafeQueueUrl = (chain: string, safeAddress: string): string => {
  const c = new Chain(getChainIdByShortName(chain));
  if (!c) throw new Error(`chain ${chain} not supported`);
  return `${c.safeWebBaseUrl()}/transactions/queue?safe=${chain}:${safeAddress}`;
};

const getSafeTxUrl = (
  chain: string,
  safeAddress: string,
  hash: string,
): string => {
  const c = new Chain(getChainIdByShortName(chain));
  if (!c) throw new Error(`chain ${chain} not supported`);
  return `${c.safeWebBaseUrl()}/transactions/tx?safe=${chain}:${safeAddress}&id=multisig_${safeAddress}_${hash}`;
};

export const getGnosisUrl = (
  chain: string,
  safeAddress: string,
  path: string,
): string => {
  const c = new Chain(getChainIdByShortName(chain));
  if (!c) throw new Error(`chain ${chain} not supported`);
  return `${c.safeWebBaseUrl()}${path}?safe=${chain}:${safeAddress}`;
};

const getCoboConnectUrl = (): string => {
  const isSandbox = process.env.REACT_APP_ENV === 'sandbox';
  if (isSandbox) {
    return (
      localStorage.getItem('COBO-CONNECT-URL') ||
      'chrome-extension://imnmigiomjbgehplnfpajkgeaibljnik'
    );
  }
  return 'chrome-extension://imnmigiomjbgehplnfpajkgeaibljnik';
};

const getGnosisModules = async (
  gnosis: GnosisSafe,
  start?: string,
  pageSize?: number,
): Promise<string[]> => {
  start = start || Web3.utils.padLeft('0x1', 40);
  pageSize = pageSize || 20;
  const [modules, next] = await gnosis.getModulesPaginated(start, pageSize);
  if (next && next !== Web3.utils.padLeft('0x1', 40)) {
    const nextModules = await getGnosisModules(gnosis, next, pageSize);
    modules.push(...nextModules);
  }
  return modules;
};

export {
  getGnosisModules,
  getSafeTransactionUrl,
  getSafeQueueUrl,
  getSafeInfoFromGnosisSafe,
  getSafeTokenBalance,
  getCoboConnectUrl,
  getSafeTxUrl,
  getSafeHomeUrl,
  getSafeTxFromSafeWallet,
};
