import { coboApi } from '@/api';
import { TokenInfo } from '@/interfaces';

const tokenResp: {
  [key: string]: TokenInfo;
} = {};

export const getTokenInfo = async (
  chain_id: string,
  token_address: string,
): Promise<TokenInfo> => {
  const key = `${chain_id}-${token_address}`;
  if (tokenResp[key]) {
    return tokenResp[`${chain_id}-${token_address}`];
  }
  const info: TokenInfo = await coboApi.post('/argus/token/get_token/', {
    chain_id: chain_id,
    token_address,
  });
  if (info) {
    tokenResp[key] = info;
  }
  return info;
};

export const getTokenAllowance = async (
  chain_id: string,
  info: {
    token_address: string;
    owner_address: string;
    spender_address: string;
  }[],
): Promise<
  {
    token_address: string;
    owner_address: string;
    spender_address: string;
    allowance: string;
  }[]
> => {
  return await coboApi.post('/argus/token/get_token_allowance/', {
    chain_id: chain_id,
    info,
  });
};

export const getDexSupportedTokens = async (
  dex: string,
  chain: string,
  sell_token: string,
  buy_token: string,
): Promise<void> => {
  await coboApi.post(
    '/argus/token/get_dex_supported_tokens/',
    {
      chain,
      dex,
      sell_token,
      buy_token,
    },
    {
      params: { hideError: true },
    },
  );
};
