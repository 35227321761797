import { BaseChain } from './base';

export default class Xdai extends BaseChain {
  name = 'Gnosis Chain';
  shortName = 'gno';

  private providerUrl = 'https://rpc.gnosischain.com/';
  private xdaiScanBaseUrl = 'https://gnosisscan.io';
  public safeWebBaseUrl = 'https://app.safe.global';
  private safeApiBaseUrl = 'https://safe-transaction-gnosis-chain.safe.global';
  subsafeFactory = '0x9d32d826E5Ef81Bf3d5054B1035afaD8570d69cd';
  baseToken = 'XDAI';
  baseTokenName = 'xDai';

  public safeUrl(address: string): string {
    return `${this.safeWebBaseUrl}/home?safe=${this.shortName}:${address}`;
  }

  public addressUrl(address: string): string {
    return `${this.xdaiScanBaseUrl}/address/${address}`;
  }

  public tokenUrl(address: string): string {
    return `${this.xdaiScanBaseUrl}/token/${address}`;
  }

  public rpcUrl(): string {
    return this.providerUrl;
  }

  public safeApiUrl(): string {
    return this.safeApiBaseUrl;
  }

  public txDetailUrl(hash: string): string {
    return `${this.xdaiScanBaseUrl}/tx/${hash}`;
  }
}
