import { atom } from 'jotai';

import { ShowAuthPageEnum } from '@/interfaces';

export const authPageEnumAtom = atom<ShowAuthPageEnum>(ShowAuthPageEnum.Home);
export const currentAuthInfoAtom = atom<{
  curPassword: string;
  curEmail: string;
}>({
  curPassword: '',
  curEmail: '',
});
export const currentResetEmailAtom = atom<string>('');
export const inviteModalVisibleAtom = atom<boolean>(false);
