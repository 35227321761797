import { useMemo } from 'react';
import { SyncOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import styles from './index.module.less';
import { useImage } from '@/hooks';
import { ChainShortNameType } from '@/interfaces';

interface ChainIconProps {
  url?: string;
  shortName?: ChainShortNameType;
  chainName?: string;
  className?: string;
  isRadiusIcon?: boolean;
  isRegIcon?: boolean;
}

export function ChainIcon(props: ChainIconProps) {
  const { url, className, shortName, chainName, isRadiusIcon, isRegIcon } =
    props;
  const reqSvgs = require.context('@/assets/images/chains', true, /\.png$/);

  const allSvgFilepaths = reqSvgs.keys();

  const imgSrc = useMemo(() => {
    if (!shortName) {
      if (!url) {
        return isRadiusIcon
          ? reqSvgs('./network_unknown.png')
          : reqSvgs('./link_unknown.png');
      }
      return url;
    }

    const path = isRegIcon
      ? `./network_${shortName.toLowerCase()}_reg.png`
      : isRadiusIcon
      ? `./network_${shortName.toLowerCase()}.png`
      : `./link_${shortName.toLowerCase()}.png`;

    const index = allSvgFilepaths.findIndex(val => val === path);
    const imagePath = allSvgFilepaths[index];
    if (!imagePath) return reqSvgs('./link_unknown.png');
    return reqSvgs(imagePath);
  }, [shortName, isRadiusIcon, isRegIcon, allSvgFilepaths, reqSvgs, url]);

  const { hasLoaded, hasError } = useImage(imgSrc);

  return (
    <>
      {!hasLoaded && !hasError && (
        <SyncOutlined
          className={classNames(styles['chain-icon'], className)}
          spin
        />
      )}
      {hasLoaded && (
        <img
          src={imgSrc}
          alt={chainName || shortName || url}
          className={classNames(styles['chain-icon'], className)}
        />
      )}
      {hasError && (
        <img
          src={require('@/assets/images/chains/link_unknown.png')}
          alt={chainName || shortName || url}
          className={classNames(styles['chain-icon'], className)}
        />
      )}
    </>
  );
}
