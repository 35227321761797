import React, { useCallback, useEffect, useMemo } from 'react';
import { Dropdown, Spin } from 'antd';
import type { MenuProps } from 'antd';
import classNames from 'classnames/bind';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import styles from './index.module.less';
import { ChainIcon, ImportSafe, SvgIcon } from '@/components';
import { LoadingIcon } from '@/components/Common/LoadingIcon';
import { importSafeModalVisibleAtom } from '@/components/Safe/ImportSafe/ImportSafeAtoms';
import { globalLoadingAtom, safeSelectOpenAtom } from '@/globalAtom';
import { useQuery } from '@/hooks';
import { useGlobalOrgs } from '@/hooks/useGlobalOrg';
import { useGlobalSafe } from '@/hooks/useGlobalSafe';
import { ChainShortNameType } from '@/interfaces';
import {
  modalVisibleAtom,
  moduleMultisigTypeAtom,
} from '@/routes/SafeDetail/atoms';
import { MultisigModuleModal } from '@/routes/SafeDetail/components/MultisigModuleModal';
import { toFixedWithCommas } from '@/utils/format';
import {
  copyStr,
  hideConfirmModal,
  showConfirmModal,
  truncateAddress,
} from '@/utils/utils';

export function SafeSelector() {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const {
    safeId,
    safeInfo,
    safeList,
    safeBalanceMap,
    loadBalance,
    loadSafes,
    loadSafeInfo,
    clearSafe,
  } = useGlobalSafe();
  const { setDefaultSafeId, orgId } = useGlobalOrgs();
  const { pathname } = useLocation();
  const setImportSafeModalVisible = useSetAtom(importSafeModalVisibleAtom);
  const multisigType = useAtomValue(moduleMultisigTypeAtom);
  const [moduleVisible, setModuleVisible] = useAtom(modalVisibleAtom);
  const [loading] = useAtom(globalLoadingAtom);
  const [selectOpen, setSelectOpen] = useAtom(safeSelectOpenAtom);
  const query = useQuery();
  const loadingIcon = <LoadingIcon dark={true} />;
  const spin = (
    <Spin indicator={loadingIcon} className="w-[16px] h-[16px] ml-2" />
  );
  const onChangeSelectedSafe = useCallback(
    async (safeId: string) => {
      const doChangeSafe = async () => {
        if (!orgId) return;
        clearSafe(true);
        await setDefaultSafeId(safeId, orgId);
        setSelectOpen(false);
        if (needConfirm) {
          navigate(pathname, { replace: true });
        }
      };
      if (!safeId || safeId === safeInfo?.id) return;
      const pages = [
        '/service-config',
        '/service-config/farming/:id',
        '/service-config/trading/:id',
        '/farming/generic-bot/:bot_definition_id/new',
      ];
      const needConfirm = pages.some(path => {
        return matchPath(path, pathname);
      });
      if (!needConfirm) {
        await doChangeSafe();
        if (matchPath('/home', pathname)) {
          navigate('/home', { replace: true });
        }
        return;
      }
      setSelectOpen(false);
      showConfirmModal({
        confirmModalVisible: true,
        confirmModalTitle: t('authorization.reminder'),
        confirmModalElement: t('safe.changeSafeTip'),
        cancelText: t('safe.stayOnThisPage'),
        onCancel: () => {
          hideConfirmModal();
        },
        onConfirm: async () => {
          await doChangeSafe();
        },
        okText: t('safe.switchSafe'),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId, setSelectOpen, setDefaultSafeId, safeInfo?.id, pathname],
  );

  const items: MenuProps['items'] = useMemo(() => {
    const _menus: MenuProps['items'] = safeList?.map(safe => {
      return {
        key: safe.id,
        label: (
          <div
            className={cx('safe')}
            onClick={() => {
              onChangeSelectedSafe(safe.id);
            }}
          >
            <ChainIcon
              shortName={safe?.chain_id as ChainShortNameType}
              className={cx('safe-chain-icon')}
            />
            <div className={cx('safe-info')}>
              <div className={cx('safe-name-box')}>
                <div className={cx('name')}>{safe.name}</div>
                &nbsp;
                <span className={cx('safe-address')}>
                  ({truncateAddress(safe.address)})
                </span>
              </div>
              <div className={cx('safe-balance')}>
                {`$${toFixedWithCommas(
                  safeBalanceMap.get(`${safe.chain_id}:${safe.address}`),
                )}`}
              </div>
            </div>
            {safe.id === safeId && <div className={cx('icon-selected')} />}
          </div>
        ),
      };
    });
    _menus?.unshift({
      key: 'title',
      label: (
        <div className={cx('drop-down-title')}>{t('safe.chooseSafe')}</div>
      ),
    });
    return _menus || [];
  }, [safeList, safeBalanceMap, safeId, cx, t, onChangeSelectedSafe]);

  useEffect(() => {
    const reload = async () => {
      const _safeList = await loadSafes();
      if (!_safeList) return;
      await loadBalance(_safeList);
    };
    reload();
  }, [loadSafes, loadBalance]);

  useEffect(() => {
    loadSafeInfo();
  }, [loadSafeInfo]);

  useEffect(() => {
    return () => {
      setSelectOpen(false);
    };
  }, [setSelectOpen]);

  useEffect(() => {
    const switchSafeByQuery = async () => {
      if (
        !query.get('safeAddress') ||
        !query.get('chain') ||
        !safeList ||
        safeList.length === 0
      )
        return;
      const _safe_id = safeList.find(safe => {
        return (
          safe.address === query.get('safeAddress') &&
          safe.chain_id === query.get('chain')
        );
      })?.id;
      if (!_safe_id) return;
      await onChangeSelectedSafe(_safe_id);
      query.delete('safeAddress');
      query.delete('chain');
    };
    switchSafeByQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, safeList]);

  return (
    <div className={cx('safe-selector')} id="safe-selector">
      {safeList && safeList.length > 0 ? (
        <div className={cx('selector')}>
          {items && (
            <Dropdown
              menu={{ items }}
              trigger={['click']}
              open={selectOpen}
              onOpenChange={setSelectOpen}
              placement="bottomLeft"
              dropdownRender={menu => (
                <div className={cx('content')}>
                  {menu}
                  <div className={cx('divider')}></div>
                  <div className={cx('footer')}>
                    <div
                      className={cx('import-box')}
                      onClick={() => {
                        setSelectOpen(false);
                        setImportSafeModalVisible(true);
                      }}
                    >
                      <div className={cx('import-icon')}></div>
                      {t('safe.importSafe')}
                    </div>
                    <div
                      className={cx('view-all')}
                      onClick={() => {
                        setSelectOpen(false);
                        window.open(
                          `${window.location.origin}/#/safe-management`,
                          '_blank',
                        );
                      }}
                    >
                      <div className={cx('view-all-icon')}></div>
                      {t('bots.viewAll')}
                    </div>
                  </div>
                </div>
              )}
            >
              <div className={cx('current-safe')}>
                <ChainIcon
                  shortName={safeInfo?.chain_id as ChainShortNameType}
                  className={cx('chain-icon')}
                />

                <div className={cx('safe-info')}>
                  <div className={cx('safe-name')}>
                    {loading ? spin : safeInfo?.name}
                  </div>
                  <div className={cx('safe-address-box')}>
                    {loading ? spin : truncateAddress(safeInfo?.address || '')}
                    <SvgIcon
                      name="copy"
                      className={cx('copy-btn')}
                      onClick={() => copyStr(safeInfo?.address || '')}
                    />
                  </div>
                </div>
                <SvgIcon
                  name="dropdown_arrow"
                  color="#4D84FF"
                  className={`${styles['selector-icon']} ${
                    selectOpen ? styles['open'] : ''
                  }`}
                  onClick={() => {
                    setSelectOpen(!selectOpen);
                  }}
                />
              </div>
            </Dropdown>
          )}
        </div>
      ) : (
        <div className={cx('empty-safe')}>
          <div className="flex items-center mb-2">
            <div className={cx('empty-icon')}></div>
            <div className={cx('empty-text')}>{t('safe.noSafe')}</div>
          </div>
          <div
            className={cx('import-box', 'ml-1')}
            onClick={() => {
              setImportSafeModalVisible(true);
            }}
          >
            <div className={cx('import-icon')}></div>
            {t('safe.importSafe')}
          </div>
        </div>
      )}
      <ImportSafe
        refresh={async (safe: {
          chain: number;
          name: string;
          address: string;
          id: string;
        }) => {
          if (!safe.id) return;
          await onChangeSelectedSafe(safe.id);
        }}
      />
      {safeInfo && orgId && (
        <MultisigModuleModal
          type={multisigType}
          safeInfo={{
            ...safeInfo,
            org_id: orgId,
          }}
          visible={moduleVisible}
          onClose={() => setModuleVisible(false)}
        />
      )}
    </div>
  );
}
