export const transaction = {
  title: 'Transactions',
  transactionBundle: 'Transaction Bundle',
  goToTxBundle: 'Go to Transaction Bundle',
  addToBundleText1: 'You have successfully added the ',
  addToBundleText2: ' to your "Transaction Bundle".',
  transactionBundleTooltip:
    'Transactions in the bundle can be submitted to blockchain as a batch to improve efficiency as well as save gas fee.',
  queue: 'Queue',
  history: 'History',
  farming: 'Farming',
  trading: 'Trading',
  collapse: 'Collapse',
  nonce: 'Nonce',
  removeAll: 'Remove All',
  submitAll: 'Submit All',
  initiator: 'Initiator',
  strategy: 'Strategy',
  service: 'Service',
  protocol: 'Protocol',
  checkTransaction: 'Check Transaction',
  threshold: 'Threshold',
  content: 'Content',
  time: 'Time (UTC+8)',
  type: 'Type',
  txEmpty: `It's empty here.`,
  actions: 'Actions',
  action: 'Action',
  operation: 'Operation',
  operations: 'Operations',
  singleClearConfirm:
    'Are you sure you want to remove the action in the bundle? ',
  clearConfirm: 'Are you sure you want to remove all the ',
  clearConfirm2: ' actions in the bundle?',
  sendEmailBtn: `Send email notifications`,
  sendEmailTitle: `Send notifications to Admin(s) & Operator(s)`,
  sendEmailTip: `An email will be sent to Admin(s) & Operator(s) you select.`,
  selectedEmail: `You've selected`,
  email: `Email`,
  emails: `Emails`,
  send: `Send`,
  notificationsSent: `Notifications sent`,
  notificationsSentTip: `The email notifications have been sent to the selected Admin(s) & Operator(s) .`,
  safeNonce: `Safe Nonce`,
  nonceTooLow: `There is already a transaction with the same nonce. The Safe owner could decide which transaction is ultimately executed. Do you want to continue submitting?`,
  noGoBack: `No,go back`,
  yesRemoveAll: `Yes,remove all`,
  yesRemove: `Yes,remove it`,
};
