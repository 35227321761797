import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { cloneDeep } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { StrategyList } from '../FarmingList';
import { StrategySearch } from '../StrategySearch';
import { TradingList } from '../TradingList';
import styles from './index.module.less';
import { getPortfolioForSafe } from '@/api/logic';
import { Button } from '@/components';
import { globalSafeInfoAtom, orgIdAtom } from '@/globalAtom';
import { StrategySearchCondition } from '@/interfaces';

interface Props {
  strategyType: string;
}
export function Strategies({ strategyType }: Props) {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const [orgId] = useAtom(orgIdAtom);
  const [safeInfo] = useAtom(globalSafeInfoAtom);

  const initSearchCondition: StrategySearchCondition = {
    page: 1,
    chain: '',
    protocol: '',
    token: '',
    service_type: '',
    strategy_type: strategyType as '2' | '5',
  };

  const defaultPageSize: number = 20;
  const [searchCondition, setSearchCondition] =
    useState<StrategySearchCondition>(initSearchCondition);
  const [strategies, setStrategies] = useState<Record<string, any>[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [disabled, setDisabled] = useState<boolean>(false);

  const showLoadMore = totalCount > strategies.length;

  const handleSearch = (
    chain: string,
    protocol: string,
    token: string,
    service_type: string,
  ) => {
    setSearchCondition({
      ...searchCondition,
      chain,
      protocol,
      token,
      service_type,
      page: 1,
    });
  };

  const handleNextPage = () => {
    setSearchCondition({
      ...searchCondition,
      page: searchCondition.page + 1,
    });
  };

  useEffect(() => {
    const getStrategyList = async (nextPage = false) => {
      if (!safeInfo?.id) return;
      setDisabled(true);
      const result = await getPortfolioForSafe({
        safe_id: safeInfo?.id ?? '',
        current_page: searchCondition.page,
        page_size: defaultPageSize,
        protocol_id: searchCondition.protocol,
        invest_coin: searchCondition.token,
        service_type: searchCondition.service_type,
        strategy_type: strategyType,
      });

      const { total_count, portfolios } = result;

      if (!nextPage) {
        setStrategies(portfolios);
      } else {
        const cloneList = cloneDeep(strategies);
        setStrategies([...cloneList, ...portfolios]);
      }
      setTotalCount(total_count);
      setDisabled(false);
    };
    getStrategyList(searchCondition.page > 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCondition, orgId, safeInfo?.id]);

  return (
    <div className={styles['strategies']}>
      <StrategySearch
        chain={searchCondition.chain}
        protocol={searchCondition.protocol}
        token={searchCondition.token}
        service_type={searchCondition.service_type}
        onSearch={handleSearch}
        strategy_type={searchCondition.strategy_type}
      />

      {Number(strategyType) === 2 ? (
        <StrategyList strategyList={strategies} />
      ) : (
        <TradingList strategyList={strategies} />
      )}

      {showLoadMore && (
        <Button
          loading={disabled}
          disabled={disabled}
          className={cx('load-more-btn')}
          onClick={handleNextPage}
        >
          {t('investment.strategyMart.loadMore')}
        </Button>
      )}
    </div>
  );
}
