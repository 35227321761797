import { BaseChain } from './base';

export default class Mantle extends BaseChain {
  name = 'Mantle';
  shortName = 'mantle';

  private providerUrl = 'https://rpc.mantle.xyz/';
  private scanBaseUrl = 'https://explorer.mantle.xyz';
  public safeWebBaseUrl = 'https://multisig.mantle.xyz';
  private safeApiBaseUrl = 'https://transaction.multisig.mantle.xyz';
  subsafeFactory = '';
  baseToken = 'MNT';
  baseTokenName = 'Mantle';
  multiSendCallOnlyAddress = '0x998739BFdAAdde7C933B942a68053933098f9EDa';

  public safeUrl(address: string): string {
    return `${this.safeWebBaseUrl}/home?safe=${this.shortName}:${address}`;
  }

  public addressUrl(address: string): string {
    return `${this.scanBaseUrl}/address/${address}`;
  }

  public tokenUrl(address: string): string {
    return `${this.scanBaseUrl}/token/${address}`;
  }

  public rpcUrl(): string {
    return this.providerUrl;
  }

  public safeApiUrl(): string {
    return this.safeApiBaseUrl;
  }

  public txDetailUrl(hash: string): string {
    return `${this.scanBaseUrl}/tx/${hash}`;
  }
}
