import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrganizationItem } from '@/interfaces/pages/organization';

const initialState: {
  inviteRecords: OrganizationItem[] | null;
  orgSizes: string[];
  orgTypes: string[];
} = {
  inviteRecords: null,
  orgSizes: [],
  orgTypes: [],
};

const slice = createSlice({
  name: 'orgPage',
  initialState,
  reducers: {
    updateInviteRecords: (state, action: PayloadAction<OrganizationItem[]>) => {
      state.inviteRecords = action.payload;
    },
    updateOrgSizes: (state, action: PayloadAction<string[]>) => {
      state.orgSizes = action.payload;
    },
    updateOrgTypes: (state, action: PayloadAction<string[]>) => {
      state.orgTypes = action.payload;
    },
    clearOrg: state => {
      // state.orgId = null;
      // state.organizations = null;
      state.inviteRecords = null;
      state.orgSizes = [];
      state.orgTypes = [];
    },
  },
});
export const orgPageReducer = slice.reducer;
export const updateInviteRecords = slice.actions.updateInviteRecords;
export const updateOrgSizes = slice.actions.updateOrgSizes;
export const updateOrgTypes = slice.actions.updateOrgTypes;
export const clearOrg = slice.actions.clearOrg;
