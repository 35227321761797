export const teamManagement = {
  username: 'Username',
  account: 'Account',
  title: 'Team Management',
  inviteBtn: 'Invite',
  member: 'Members',
  roleTab: 'Roles',
  email: 'Emails',
  team: 'Organization',
  address: 'Address',
  roleAdmin: 'Admin',
  roleOperator: 'Operator',
  roleViewer: 'Viewer',
  errorTips: {
    email: 'Please enter a valid email address',
  },
  settings: 'Settings',
  successTips: {
    invite: 'The invitation email has been sent to the user. ',
    inviteAddress: `The link is copied. Please send it to your member.`,
    edit: 'Modified successfully',
    remove: 'User removed successfully',
  },
  emptyTips: {
    text1: "It's empty here.",
    text2: 'Click here to',
    links: 'invite your member.',
  },
  table: {
    username: 'Username',
    email: 'Email Address',
    role: 'Role',
    address: 'Address',
    status: 'Status',
    update: 'Updated Time',
    name: 'Name',
    description: 'Description',
    auctionEdit: 'Edit',
    auctionRemove: 'Remove',
    auctionReInvite: 'Re-invite',
    statusWait: 'Invitation Sent',
    statusExpired: 'Invitation Expired',
    statusAccept: 'Joined',
    statusReject: 'Rejected',
  },
  role: {
    admin: {
      name: 'Admin',
      title: 'Organization owner',
      description:
        'Manage the team, Safe{Wallet}, and Cobo Safe; buy, cancel or upgrade payment plan; asset dashboard & report; access value-added DeFi tools',
    },
    operator: {
      name: 'Operator',
      title: 'Organization member',
      description:
        'Manage Safe{Wallet} and Cobo Safe; asset dashboard & report; access value-added DeFi tools',
    },
    viewer: {
      name: 'Viewer',
      title: 'Organization member',
      description: 'Service and data read-only',
    },
  },
  modalInvite: {
    title: 'Invite Member',
    email: 'Email address',
    role: 'Role',
    button: 'Send Invitation',
  },
  modalEdit: {
    title: 'Edit',
    email: 'Email Address',
    web3Address: 'Wallet Address',
    role: 'Role',
    address: 'Address',
    maxAddressCount: 'Add up to 50 addresses',
    addressExists: 'Address already exists',
    button: 'Submit',
    authorized: 'Authorized',
    authorizedHint: `You won't revoke the authorization by unlinking this authorized account. To revoke it, please head to Cobo Safe.`,
  },
  modalRemove: {
    title: 'Remove Member',
    tips: 'Are you sure you want to remove the member?',
    remind:
      'The addresses linked by the member have been authorized in Cobo Safe. To revoke the authorization, please head to <color>Safe-Delegated Members</color> instead.',
    okButton: 'OK',
    cancelButton: 'Cancel',
  },
};
