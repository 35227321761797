import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames/bind';
import { Decimal } from 'decimal.js';
import { useAtom } from 'jotai';
import { cloneDeep, isNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { getContractAbi } from '@/api';
import Chain from '@/chains';
import {
  getChainIdByCoboName,
  getNumberFormDecimal,
  getShortNameByCoboName,
  getTokenAndAllowanceNumber,
} from '@/chains/utils';
import { ChainIcon, CoboTable } from '@/components';
import {
  GenericBotTableTokenItem,
  GenericBotTokenItem,
  unlimitedTokenValue,
  unlimitedTokenValueNumber,
} from '@/interfaces';
import { tokenApprovalAtom } from '@/routes/Bot/atoms/tokenApproval';
import { isZeroAddress } from '@/utils/utils';

interface Props {
  dataSource: GenericBotTableTokenItem[];
  receiverOptions?: {
    [key: string]: string;
  };
  chain?: string;
  wrapperCls?: string;
  emptyLabel?: string;
  emptyLabelStyle?: any;
  emptyImage?: string;
  emptyImageStyle?: any;
  disabled?: boolean;
  onEdit: (token: GenericBotTokenItem) => void;
  onDelete: (token: GenericBotTokenItem) => void;
}

export function TokenTable({
  chain,
  dataSource,
  receiverOptions,
  wrapperCls,
  emptyLabel,
  emptyLabelStyle,
  emptyImage,
  emptyImageStyle,
  disabled,
  onEdit,
  onDelete,
}: Props) {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const [tokenApprovals] = useAtom(tokenApprovalAtom);

  const [contractInfoMap, setContractInfoMap] = useState<
    Map<
      string,
      {
        contract_name: string;
        abi: any;
      }
    >
  >(new Map());

  const getNumberEle = (text: string | number, decimals: number) => {
    if (!isNumber(text) && isNaN(parseInt(text))) {
      return text;
    }
    let num;
    num = new Decimal(text).div(10 ** (decimals || 18)).toFixed();
    num = isNumber(num) ? num : parseFloat(num);
    const showTooltip = Math.abs(num) >= 1000000;
    if (!showTooltip) return getTokenAndAllowanceNumber(num);
    return (
      <Tooltip placement="right" title={getNumberFormDecimal(num)}>
        {getTokenAndAllowanceNumber(num)}
      </Tooltip>
    );
  };

  useEffect(() => {
    const loadSpenderInfo = async () => {
      if (!chain) return;
      const spenderList = Array.from(
        new Set(dataSource.map(item => item.spender_address)),
      );
      if (!spenderList.length) return;
      const chainName = getShortNameByCoboName(chain);
      const newMap = new Map();
      const promises: Promise<{
        contract_name: string;
        abi: any;
      }>[] = [];
      const fetchedSpenders: string[] = [];
      spenderList.forEach(spender => {
        if (!isZeroAddress(spender)) {
          const label = receiverOptions ? receiverOptions[spender] : '';
          if (!label) {
            fetchedSpenders.push(spender);
            const spenderInfo = contractInfoMap.get(spender);
            if (spenderInfo) {
              promises.push(new Promise(resolve => resolve(spenderInfo)));
            } else {
              promises.push(getContractAbi(chainName, spender));
            }
          }
        }
      });
      const result = await Promise.all(promises);
      fetchedSpenders.forEach((spender, index) => {
        newMap.set(spender, result[index]);
      });
      setContractInfoMap(newMap);
    };
    loadSpenderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain, JSON.stringify(dataSource), JSON.stringify(receiverOptions)]);
  let columns: any[] = [
    {
      title: t('investment.strategyMart.token'),
      dataIndex: 'token_info',
      key: 'token_info',
      render: (text: string, record: GenericBotTableTokenItem) => (
        <div className={cx('token-box')}>
          <ChainIcon
            className={cx('token-img')}
            url={record.token_info.icon_url}
          />
          {record.token_info.symbol}
        </div>
      ),
    },
    {
      title: t('authorization.spender'),
      dataIndex: 'spender_address',
      key: 'spender_address',
      render: (text: string, record: GenericBotTableTokenItem) => {
        if (!record.spender_address) return '';
        if (isZeroAddress(record.spender_address)) return '';
        const name =
          (receiverOptions && receiverOptions[record.spender_address]) ||
          contractInfoMap.get(record.spender_address)?.contract_name ||
          '';
        return (
          <div
            className={cx('spender-name')}
            onClick={() => {
              if (!chain) return;
              const c = new Chain(getChainIdByCoboName(chain));
              window.open(c.addressUrl(record.spender_address), '_blank');
            }}
          >
            {name}
            {contractInfoMap.get(record.spender_address)?.contract_name ===
              'TransferHelper' && (
              <Tooltip
                title={t('authorization.tokenApprovalTableTooltip')}
                placement="bottom"
              >
                <div className={cx('tip-icon')} />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: t('authorization.allowance'),
      dataIndex: 'allowance',
      key: 'allowance',
      render: (text: string, record: GenericBotTableTokenItem) => (
        <div className={cx('allowance')}>
          {[unlimitedTokenValueNumber, unlimitedTokenValue].includes(
            record.allowance,
          )
            ? 'Unlimited'
            : getNumberEle(text, record.token_info.decimals)}
        </div>
      ),
    },
  ];
  if (!disabled) {
    columns.push({
      title: t('common.actions'),
      dataIndex: 'address',
      key: 'address',
      width: 100,
      render: (text: string, record: GenericBotTableTokenItem) => {
        const currentToken = tokenApprovals?.find(
          item => record.token_info.address === item.address,
        );
        return (
          <div className={cx('actions')}>
            {record.canEdit && !currentToken?.disable_remove ? (
              <span
                onClick={() => {
                  if (!currentToken) return;
                  onDelete(currentToken);
                }}
                className={cx('action-button', 'delete-button')}
              >
                {t('common.delete')}
              </span>
            ) : null}
            {record.canEdit ? (
              <span
                onClick={() => {
                  const selectedToken = tokenApprovals?.find(
                    item => item.address === record.token_info.address,
                  );
                  const target = cloneDeep(selectedToken);
                  if (!target) return;
                  onEdit(target);
                }}
                className={cx('action-button')}
              >
                {t('common.edit')}
              </span>
            ) : null}
          </div>
        );
      },
    });
  }

  return (
    <CoboTable
      className={cx('token-approval-table', wrapperCls)}
      pagination={false}
      rowKey={record => record.token_info.address + record.spender_address}
      dataSource={dataSource || []}
      columns={columns}
      locale={{
        emptyText: (
          <div style={{ padding: '40px 0' }}>
            <img
              alt=""
              style={emptyImageStyle || { width: '160px' }}
              src={emptyImage || require('@/assets/images/empty.png')}
            />
            <div style={emptyLabelStyle || { padding: '20px 0 0 0' }}>
              {emptyLabel || t('common.emptyTable')}
            </div>
          </div>
        ),
      }}
    />
  );
}
