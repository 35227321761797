import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrganizationItem } from '@/interfaces';

const initialState: {
  orgList: OrganizationItem[];
} = {
  orgList: [],
};

const slice = createSlice({
  name: 'authInfo',
  initialState,
  reducers: {
    updateOrgList: (state, action: PayloadAction<OrganizationItem[]>) => {
      state.orgList = action.payload;
    },
  },
});
export const organizationReducer = slice.reducer;
export const updateOrgList = slice.actions.updateOrgList;
