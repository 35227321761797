export enum ShowAuthPageEnum {
  Home,
  BindHome,
  BindEmail,
  Web2Auth,
}

export interface MeAddressInfo {
  id: string;
  delegated: boolean;
  address_status: number;
  address: string;
  label: string;
  update_time: string | number;
}

export interface MySafeRoleInfo {
  delegate_address: string;
  delegate_label: string;
  delegate_safe_roles: string[];
  safe_name: string;
  safe_chain: string;
  safe_address: string;
  safe_id: string;
  safe_module_id: string;
  safe_module_address: string;
  update_time: string | number;
}
