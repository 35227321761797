import { BaseChain } from './base';

export default class Optimism extends BaseChain {
  name = 'Optimism';
  shortName = 'oeth';

  private providerUrl = 'https://mainnet.optimism.io/';
  private scanBaseUrl = 'https://optimistic.etherscan.io';
  public safeWebBaseUrl = 'https://app.safe.global';
  private safeApiBaseUrl = 'https://safe-transaction-optimism.safe.global';
  subsafeFactory = '0x9d32d826E5Ef81Bf3d5054B1035afaD8570d69cd';
  baseToken = 'OETH';
  baseTokenName = 'Ether';
  multiSendCallOnlyAddress = '0x998739BFdAAdde7C933B942a68053933098f9EDa';

  public safeUrl(address: string): string {
    return `${this.safeWebBaseUrl}/home?safe=${this.shortName}:${address}`;
  }

  public addressUrl(address: string): string {
    return `${this.scanBaseUrl}/address/${address}`;
  }

  public tokenUrl(address: string): string {
    return `${this.scanBaseUrl}/token/${address}`;
  }

  public rpcUrl(): string {
    return this.providerUrl;
  }

  public safeApiUrl(): string {
    return this.safeApiBaseUrl;
  }

  public txDetailUrl(hash: string): string {
    return `${this.scanBaseUrl}/tx/${hash}`;
  }
}
