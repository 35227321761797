import { useEffect, useState } from 'react';
import { Input, message } from 'antd';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { ChainSelect, Modal } from '@/components';
import { useGlobalSafe } from '@/hooks';

interface Props {
  visible: boolean;
  onSuccess: (username: string) => void;
  closeModal: () => void;
}
export function EditProfileModal({ visible, onSuccess, closeModal }: Props) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const { safeInfo } = useGlobalSafe();
  const [username, setUsername] = useState<string>(safeInfo?.name || '');

  useEffect(() => {
    if (!visible) {
      setUsername(safeInfo?.name || '');
    }
  }, [visible, safeInfo?.name]);

  return (
    <Modal
      visible={visible}
      title={t('safe.editSafe')}
      className={styles['edit-safe-modal']}
      closable={true}
      destroyOnClose={true}
      btnProps={[
        {
          text: t('common.confirm'),
          primary: true,
          onClick: () => {
            if (username === '') {
              message.error(t('safe.usernameNotEmpty'));
              return;
            }
            if (username === safeInfo?.name) {
              message.error(t('safe.usernameNotChange'));
              return;
            }
            onSuccess(username);
          },
        },
      ]}
      onCloseBtnClick={closeModal}
    >
      <div className={cx(styles['form-item'], styles['username-item'])}>
        <span className={styles['settings-item-label']}>
          {t('safe.username')}
        </span>
        <Input
          className={styles['settings-item-input']}
          onChange={e => {
            setUsername(e.target.value);
          }}
          maxLength={50}
          showCount={true}
          disabled={false}
          value={username.substring(0, 50)}
        ></Input>
      </div>
      <div className={cx(styles['form-item'], styles['network-item'])}>
        <span className={styles['settings-item-label']}>
          {t('safe.network')}
        </span>
        <ChainSelect chain={safeInfo?.chain_id || ''} disabled={true} />
      </div>
      <div className={cx(styles['form-item'], styles['address-item'])}>
        <span className={styles['settings-item-label']}>
          {t('safe.address')}
        </span>
        <Input
          className={styles['settings-item-input-address']}
          disabled={true}
          value={safeInfo?.address}
        ></Input>
      </div>
    </Modal>
  );
}
