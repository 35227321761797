import { InputNumber as AntdInputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import classNames from 'classnames/bind';

import styles from './index.module.less';
import { ErrorTip } from '@/components';

interface coboInputProps extends InputNumberProps {
  disabled?: boolean;
  suffix?: any;
  error?: string;
  containerCls?: string;
  min?: number;
  max?: number;
  controls?: boolean | { upIcon?: React.ReactNode; downIcon?: React.ReactNode };
}

const cx = classNames.bind(styles);
export const InputNumber: React.FunctionComponent<coboInputProps> = props => {
  const {
    disabled,
    className,
    error,
    containerCls,
    min,
    max,
    suffix,
    controls,
    ...restProps
  } = props;
  return (
    <div
      className={cx(
        'cobo-input-container',
        {
          'cobo-input-container-disabled': disabled,
        },
        containerCls,
      )}
    >
      <AntdInputNumber
        {...restProps}
        min={min}
        max={max}
        controls={controls}
        className={cx(
          'input-number',
          {
            'error-input': !!error,
          },
          className,
        )}
        addonAfter={suffix || <></>}
      />
      {error && <ErrorTip error={error} />}
    </div>
  );
};
InputNumber.defaultProps = {
  size: 'large',
};
