interface StorageData<T> {
  val: T;
  expires: number;
}

// CoboStorage 默认 ignoreCase = true，默认保证 key 为小写，所以建议定义 localStorage 的 key 时使用 max-length 这种形式
// 默认 key 为小写的原因为：
// 我们存储了一些链上 address 在 localStorage 中，而我们存储在 subgraph 中的链上 address 为 小写
// refs: https://eips.ethereum.org/EIPS/eip-55
class Storage {
  /**
   * @param {string} key
   * @param {any} val
   * @param {number} maxAge 存储时间：ms
   * @param ignoreCase
   */
  public static set<T>(
    key: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    val: any,
    maxAge = 0,
    ignoreCase = true,
  ): void {
    const data: StorageData<T> = {
      val,
      expires: maxAge === 0 ? 0 : Date.now() + maxAge,
    };
    localStorage.setItem(
      ignoreCase ? key.toLowerCase() : key,
      JSON.stringify(data),
    );
  }

  public static get<T>(key: string, ignoreCase = true): T | undefined {
    const data: string | null = localStorage.getItem(
      ignoreCase ? key.toLowerCase() : key,
    );
    if (!data) {
      return undefined;
    }
    try {
      const storageData: StorageData<T> | string = JSON.parse(data.toString());
      if (typeof storageData === 'string') {
        this.set(key, storageData, 0);
        return this.get<T>(key);
      }
      if (storageData.expires === 0) {
        return storageData.val as T;
      }
      if (Date.now() < storageData.expires) {
        return storageData.val as T;
      }
      this.remove(key);
      return undefined;
    } catch (e) {
      this.set(key, data, 0);
      return this.get<T>(key);
    }
  }

  public static getRaw<T>(
    key: string,
    ignoreCase = true,
  ): StorageData<T> | string | undefined {
    const data: string | null = localStorage.getItem(
      ignoreCase ? key.toLowerCase() : key,
    );
    if (!data) {
      return undefined;
    }
    const storageData: StorageData<T> | string = JSON.parse(data.toString());
    if (
      typeof storageData === 'string' ||
      storageData.expires === 0 ||
      Date.now() < storageData.expires
    ) {
      return storageData;
    }
    this.remove(key);
    return undefined;
  }

  public static remove(key: string, ignoreCase = true): void {
    localStorage.removeItem(ignoreCase ? key.toLowerCase() : key);
  }
}

export const CoboStorage = Storage;
