import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SafeAppInfo } from '@/interfaces';

const initialState: {
  safeAppInfo: SafeAppInfo | null;
} = {
  safeAppInfo: null,
};

const slice = createSlice({
  name: 'safeApp',
  initialState,
  reducers: {
    updateSafeAppInfo: (state, action: PayloadAction<SafeAppInfo | null>) => {
      state.safeAppInfo = action.payload;
    },
  },
});
export const safeAppReducer = slice.reducer;
export const updateSafeAppInfo = slice.actions.updateSafeAppInfo;
