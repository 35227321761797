import { useEffect, useMemo, useState } from 'react';
import { Checkbox, Collapse } from 'antd';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  ImportSafeModalSafesAtom,
  selectedSafesAtom,
} from '../ImportSafeAtoms';
import styles from './index.module.less';
import { getGnosisSafeInfo } from '@/api';
import { getShortNameByCoboName } from '@/chains/utils';
import { ChainIcon, LoadingIcon } from '@/components';
import { orgIdAtom } from '@/globalAtom';
import { ChainShortNameType, GnosisSafeInfo } from '@/interfaces';

interface SafeItemProps {
  reload: () => Promise<void>;
}
export function SelectSafe({ reload }: SafeItemProps) {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const [importSafeModalSafes] = useAtom(ImportSafeModalSafesAtom);

  const [selectedSafes, setSelectedSafes] = useAtom(selectedSafesAtom);

  const [safeInfoMap, setSafeInfoMap] = useState(
    new Map<string, GnosisSafeInfo>(),
  );

  const [orgId] = useAtom(orgIdAtom);

  useEffect(() => {
    const load = async () => {
      for (const safe of importSafeModalSafes) {
        const safeInfo = await getSafeInfo(safe.safe_address, safe.chain_id);
        safeInfo && safeInfoMap.set(safeInfo.address, safeInfo);
        setSafeInfoMap(new Map(safeInfoMap));
      }
    };
    if (importSafeModalSafes.length) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importSafeModalSafes]);

  const getSafeInfo = async (safe_address: string, chain_id: string) => {
    if (!orgId) return;
    const shortName = getShortNameByCoboName(chain_id);
    if (!shortName) return;
    return await getGnosisSafeInfo(safe_address, shortName, true);
  };

  const checkBoxOptions = useMemo(() => {
    return importSafeModalSafes.map(safe => {
      return {
        label: (
          <div
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Collapse
              ghost
              className={cx('safe-item')}
              expandIcon={({ isActive }) => (
                <div
                  className={cx('arrow', {
                    'arrow-active': isActive,
                  })}
                />
              )}
              expandIconPosition="end"
            >
              <Panel
                header={
                  <div className={cx('safe-item-header')}>
                    <ChainIcon
                      shortName={
                        (getShortNameByCoboName(
                          safe.chain_id,
                        ) as ChainShortNameType) || ''
                      }
                      className={cx('chain-icon')}
                    />
                    {safe.safe_address}
                  </div>
                }
                key="1"
              >
                <div className={cx('safe-item-content')}>
                  {safeInfoMap.get(safe.safe_address)?.address ? (
                    <>
                      <div className={cx('safe-item-title')}>
                        {t('safeManagement.safeOwners')}
                        {safeInfoMap.get(safe.safe_address)?.owners.length || 0}
                      </div>
                      <div className={cx('safe-item-content-blue')}>
                        {safeInfoMap
                          .get(safe.safe_address)
                          ?.owners.map(owner => (
                            <div
                              className={cx('safe-owner')}
                              key={owner.address}
                            >
                              {owner.address}
                            </div>
                          ))}
                      </div>
                      <div className={cx('safe-item-title')}>
                        {t('safeManagement.threshold')}
                      </div>
                      <div className={cx('safe-item-content-blue')}>
                        <div className={cx('safe-owner')}>
                          {t('safeManagement.ownersText')}
                          {safeInfoMap.get(safe.safe_address)?.threshold || 0}
                          &nbsp;of&nbsp;
                          {safeInfoMap.get(safe.safe_address)?.owners.length ||
                            0}
                          &nbsp;
                          {t('safeManagement.owners')}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={cx('safe-item-content-loading')}>
                      <LoadingIcon size="32px" />
                    </div>
                  )}
                </div>
              </Panel>
            </Collapse>
          </div>
        ),
        value: `${safe.chain_id}-${safe.safe_address}`,
      };
    });
  }, [importSafeModalSafes, safeInfoMap, t, Panel, cx]);

  return (
    <div className={cx('select-safe')}>
      <div className={cx('select-safe-content')}>
        <Checkbox.Group
          options={checkBoxOptions}
          defaultValue={
            selectedSafes.map(
              safe => `${safe.chain_id}-${safe.safe_address}`,
            ) || []
          }
          onChange={values => {
            let safes = [];
            for (const value of values) {
              const [chainId, address] = (value as string).split('-');
              const safe = importSafeModalSafes.find(
                safe =>
                  safe.safe_address === address && chainId === safe.chain_id,
              );
              safe &&
                safes.push({
                  ...safe,
                  safe_name: '',
                });
            }
            setSelectedSafes(safes);
          }}
        />
      </div>
      <div className={cx('select-safe-not-loaded')} onClick={reload}>
        {t('safeManagement.safeNotLoaded')}
        <span className={cx('select-safe-not-loaded-icon')} />
      </div>
    </div>
  );
}
