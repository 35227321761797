import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { Modal } from '@/components';

interface NotReceivedCodeModalProps {
  show: boolean;
  onOk: () => void;
  onCancel: () => void;
}
export function NotReceivedCodeModal({
  show,
  onOk,
  onCancel,
}: NotReceivedCodeModalProps) {
  const { t } = useTranslation();
  return (
    <Modal
      className={styles['code-modal']}
      visible={show}
      onCloseBtnClick={onCancel}
      title={t('auth.codeNotReceived')}
      onPrimaryClick={onOk}
      primaryText={t('common.ok')}
    >
      <div className={styles['code-modal-body']}>
        <p>{t('auth.noCodeContent.title')}</p>
        <p>{t('auth.noCodeContent.step1')}</p>
        <p>{t('auth.noCodeContent.step2')}</p>
        <p>{t('auth.noCodeContent.step3')}</p>
        <br />
        <p>{t('auth.noCodeContent.footer')}</p>
      </div>
    </Modal>
  );
}
