import { useEffect, useMemo, useState } from 'react';
import { Collapse } from 'antd';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  ImportSafeModalStep,
  importSafeModalStepAtom,
  selectedSafesAtom,
} from '../ImportSafeAtoms';
import styles from './index.module.less';
import { batchGetModuleInfoV2 } from '@/api';
import { getShortNameByCoboName } from '@/chains/utils';
import { ChainIcon, Input, LoadingIcon } from '@/components';
import { ChainShortNameType, Module } from '@/interfaces';
import { showSafeListAtom } from '@/routes/Safe/SafeAtoms';

export function VerifySafe() {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const [selectedSafes, setSelectedSafes] = useAtom(selectedSafesAtom);
  const [importSafeModalStep] = useAtom(importSafeModalStepAtom);
  const [safeModulesMap, setSafeModulesMap] = useState(
    new Map<string, Module[]>(),
  );
  const [showSafeList] = useAtom(showSafeListAtom);
  const safeNames = useMemo(() => {
    return showSafeList?.map(item => item.name) || [];
  }, [showSafeList]);
  // 获取modules接口
  useEffect(() => {
    if (!selectedSafes) return;
    const _coboModuleMap = new Map<string, Module[]>();
    selectedSafes.forEach(item =>
      _coboModuleMap.set(`${item.chain_id}-${item.safe_address}`, []),
    );
    batchGetModuleInfoV2(
      selectedSafes.map(item => {
        return {
          chain: item.chain_id || '',
          address: item.safe_address,
        };
      }),
    ).then(infos => {
      infos.forEach(item => {
        _coboModuleMap.set(`${item.chain}-${item.address}`, item.modules);
      });

      setSafeModulesMap(_coboModuleMap);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importSafeModalStep]);

  return (
    <div className={cx('verify-safe')}>
      <div className={cx('verify-safe-content')}>
        {selectedSafes.map(safe => {
          const modules = safeModulesMap.get(
            `${safe.chain_id}-${safe.safe_address}`,
          );
          return (
            <Collapse
              ghost
              className={cx('safe-item')}
              expandIcon={({ isActive }) => (
                <div
                  className={cx('arrow', {
                    'arrow-active': isActive,
                  })}
                />
              )}
              key={`${safe.chain_id}:${safe.safe_address}`}
              expandIconPosition="end"
            >
              <Panel
                header={
                  <div className={cx('safe-config')}>
                    <div className={cx('safe-item-header')}>
                      <ChainIcon
                        shortName={
                          (getShortNameByCoboName(
                            safe.chain_id,
                          ) as ChainShortNameType) || ''
                        }
                        className={cx('chain-icon')}
                      />
                      {safe.safe_address}
                    </div>
                    <div className={cx('safe-name')}>
                      <div className={cx('safe-title')}>
                        {t('safeManagement.safeName')}
                      </div>
                      <Input
                        onClick={e => e.stopPropagation()}
                        className={cx('safe-name-input')}
                        value={safe.safe_name}
                        disabled={
                          importSafeModalStep ===
                          ImportSafeModalStep.ImportingSafe
                        }
                        maxLength={50}
                        onChange={e => {
                          const name = e.target.value;
                          const _selectedSafes = selectedSafes.map(item => {
                            if (
                              item.safe_address === safe.safe_address &&
                              item.chain_id === safe.chain_id
                            ) {
                              return {
                                ...item,
                                safe_name: name,
                              };
                            }
                            return item;
                          });
                          setSelectedSafes(_selectedSafes);
                        }}
                      />
                      {safe.safe_name &&
                        (safeNames.includes(safe.safe_name) ||
                          selectedSafes.find(
                            item =>
                              item.safe_name === safe.safe_name &&
                              (item.chain_id !== safe.chain_id ||
                                item.safe_address !== safe.safe_address),
                          )) && (
                          <span className={cx('error-message')}>
                            {t('safeManagement.safeNameRepeat')}
                          </span>
                        )}
                      <div className={cx('safe-title')}>
                        {t('safeManagement.coboSafeModules')}
                      </div>
                    </div>
                  </div>
                }
                key="1"
              >
                <div className={cx('safe-item-content')}>
                  {modules ? (
                    <div className={cx('safe-item-content-blue')}>
                      {modules.length > 0 &&
                        modules?.map(module => {
                          return (
                            <div
                              className={cx('safe-module')}
                              key={module.address || module.factory}
                            >
                              <div className={cx('module-address')}>
                                {module.address || module.factory}
                              </div>
                              {module.is_default && (
                                <div className={cx('module-default')}>
                                  Default
                                </div>
                              )}
                              <div className={cx('module-version')}>
                                Version {module.version}
                              </div>
                            </div>
                          );
                        })}
                      {modules.length === 0 && (
                        <div className={cx('safe-item-content-empty')}>
                          {t('safeManagement.noModule')}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className={cx('safe-item-content-loading')}>
                      <LoadingIcon size="32px" />
                    </div>
                  )}
                </div>
              </Panel>
            </Collapse>
          );
        })}
      </div>
    </div>
  );
}
