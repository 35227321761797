import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { useAtom } from 'jotai';
import { useAccount, useDisconnect, useNetwork } from 'wagmi';

import { submitSafeTx } from '@/api';
import { getCoboNameByChainId, getShortNameByChainId } from '@/chains/utils';
import { orgIdAtom } from '@/globalAtom';
import { useConnectWallet } from '@/hooks/useConnectWallet';
import { useSafeSdk } from '@/hooks/useSafeSdk';
import { useSwitchChain } from '@/hooks/useSwitchChain';
import { SafeTx } from '@/interfaces';
import { getOwners } from '@/utils/multisign';

const useSignSafeTx = (safeAddress: string, chainId: number) => {
  const safeSdk = useSafeSdk(safeAddress, chainId);
  const [start, setStart] = useState(false);
  const [trigger, setTrigger] = useState<{
    reject: (error: any) => void;
    resolve: (value: string) => void;
  }>();
  const [safeTx, setSafeTx] = useState<SafeTx>();
  const [signing, setSigning] = useState(false);

  const reset = useCallback(() => {
    setSafeTx(undefined);
    setStart(false);
    setTrigger(undefined);
    setSigning(false);
  }, []);

  useEffect(() => {
    const sign = async () => {
      if (!start || !safeTx || signing) return;
      if (!safeSdk) {
        console.warn('waiting for safe sdk init');
        return;
      }
      try {
        setSigning(true);
        const safeTransactionData = {
          data: safeTx.data,
          to: safeTx.to,
          value: safeTx.value,
          operation: safeTx.operation,
          safeTxGas: safeTx.safe_tx_gas,
          baseGas: safeTx.base_gas,
          gasPrice: safeTx.gas_price,
          gasToken: safeTx.gas_token,
          refundReceiver: safeTx.refund_receiver,
          nonce: safeTx.safe_nonce,
        };
        const safeTransaction = await safeSdk.createTransaction({
          safeTransactionData,
        });
        const signedSafeTransaction = await safeSdk.signTransaction(
          safeTransaction,
          'eth_signTypedData',
        );
        trigger?.resolve(signedSafeTransaction.encodedSignatures());
      } catch (e: any) {
        const content = e.reason || e.message || e.data?.message;
        message.error(content);
        trigger?.reject(new Error(content));
      } finally {
        reset();
      }
    };
    sign();
  }, [reset, safeSdk, safeTx, signing, start, trigger]);

  return useCallback(async (safeTxData: SafeTx) => {
    return new Promise<string>((resolve, reject) => {
      setStart(true);
      setTrigger({
        resolve,
        reject,
      });
      setSafeTx(safeTxData);
    });
  }, []);
};

const useSwitchToOwnerWithoutChain = () => {
  const [orgId] = useAtom(orgIdAtom);
  const { address, connect } = useConnectWallet();
  const [owners, setOwners] = useState<string[]>();
  const [start, setStart] = useState(false);
  const [trigger, setTrigger] = useState<{
    reject: (error: any) => void;
    resolve: (value: boolean) => void;
  }>();
  const [chainId, setChainId] = useState<number>();
  const [safeAddress, setSafeAddress] = useState<string>();
  const [throwError, setThrowError] = useState(false);
  const { disconnectAsync } = useDisconnect();
  const reset = useCallback(() => {
    setStart(false);
    setTrigger(undefined);
    setChainId(undefined);
    setSafeAddress(undefined);
    setOwners(undefined);
    setThrowError(false);
  }, []);
  useEffect(() => {
    return reset;
  }, [reset]);
  useEffect(() => {
    if (
      !start ||
      !orgId ||
      !safeAddress ||
      !address ||
      !chainId ||
      !trigger ||
      !throwError ||
      !owners
    )
      return;
    if (owners.includes(address)) {
      trigger?.resolve(true);
    } else {
      message.error('Please connect owner address in your wallet');
      trigger?.reject(new Error('Please connect owner address'));
    }
    reset();
  }, [
    reset,
    address,
    chainId,
    orgId,
    owners,
    safeAddress,
    start,
    throwError,
    trigger,
  ]);
  useEffect(() => {
    const loadOwners = async () => {
      if (
        !start ||
        !orgId ||
        !safeAddress ||
        !chainId ||
        !trigger ||
        throwError
      )
        return;
      const owners = await getOwners(
        safeAddress,
        getShortNameByChainId(chainId),
      );
      if (address) {
        if (owners.includes(address)) {
          trigger?.resolve(true);
          reset();
        } else {
          await disconnectAsync();
          setThrowError(true);
          setOwners(owners);
        }
        return;
      }
      setOwners(owners);
    };
    loadOwners();
  }, [
    address,
    chainId,
    orgId,
    safeAddress,
    start,
    trigger,
    reset,
    throwError,
    disconnectAsync,
  ]);
  useEffect(() => {
    const _connect = async () => {
      if (!start || address || !safeAddress || !chainId || !orgId || !owners)
        return;
      try {
        const currentAddress = await connect(owners);
        if (!currentAddress) {
          trigger?.reject(new Error('Please connect wallet first'));
        } else {
          trigger?.resolve(true);
        }
        reset();
      } catch (e) {
        message.error('Please connect owner address in your wallet');
        trigger?.reject(new Error('Please connect owner address'));
        reset();
      }
    };
    _connect();
  }, [
    owners,
    address,
    connect,
    start,
    trigger,
    reset,
    safeAddress,
    chainId,
    orgId,
  ]);
  return useCallback(async (chainId: number, safeAddress: string) => {
    return new Promise<boolean>((resolve, reject) => {
      setStart(true);
      setChainId(chainId);
      setSafeAddress(safeAddress);
      setTrigger({
        resolve,
        reject,
      });
    });
  }, []);
};

const useSwitchToOwner = (chainId: number, safeAddress: string) => {
  const switchToOwner = useSwitchToOwnerWithoutChain();
  const { switchChain } = useSwitchChain();
  const [isOwner, setIsOwner] = useState(false);
  const [start, setStart] = useState(false);
  const [trigger, setTrigger] = useState<{
    reject: (error: any) => void;
    resolve: (value: boolean) => void;
  }>();

  const reset = useCallback(() => {
    setStart(false);
    setIsOwner(false);
    setTrigger(undefined);
  }, []);

  useEffect(() => {
    const loadIsOwner = async () => {
      if (!start || !trigger || !safeAddress) return;
      try {
        await switchToOwner(chainId, safeAddress);
        setIsOwner(true);
      } catch (e) {
        trigger.reject(e);
        reset();
      }
    };
    loadIsOwner();
  }, [chainId, reset, safeAddress, start, switchToOwner, trigger]);

  useEffect(() => {
    const _switchChain = async () => {
      if (!start || !trigger || !isOwner) return;
      try {
        await switchChain(chainId);
        trigger.resolve(true);
      } catch (e) {
        trigger.reject(e);
      } finally {
        reset();
      }
    };
    _switchChain();
  }, [chainId, isOwner, reset, start, switchChain, trigger]);

  return useCallback(async () => {
    return new Promise((resolve, reject) => {
      setStart(true);
      setIsOwner(false);
      setTrigger({
        resolve,
        reject,
      });
    });
  }, []);
};

const useSubmitSafeTx = (safeAddress: string, chainId: number) => {
  const { address } = useAccount();
  const [orgId] = useAtom(orgIdAtom);
  const sign = useSignSafeTx(safeAddress, chainId);

  return useCallback(
    async (safeTx: SafeTx) => {
      if (!orgId || !address || !sign) return;
      const signature = await sign(safeTx);
      await submitSafeTx(
        getCoboNameByChainId(chainId),
        safeTx.safe_tx_hash,
        signature,
        address,
        orgId,
      );
    },
    [address, chainId, orgId, sign],
  );
};

export const useSignAndSubmit = (safeAddress: string, chainId: number) => {
  const { chain } = useNetwork();
  const switchToOwner = useSwitchToOwner(chainId, safeAddress);
  const submit = useSubmitSafeTx(safeAddress, chainId);
  const [isOwner, setIsOwner] = useState(false);
  const [start, setStart] = useState(false);
  const [trigger, setTrigger] = useState<{
    resolve: () => void;
    reject: (error: any) => void;
  }>();
  const [safeTx, setSafeTx] = useState<SafeTx>();

  const reset = useCallback(() => {
    setStart(false);
    setTrigger(undefined);
  }, []);

  useEffect(() => {
    const _switchToOwner = async () => {
      if (!start) return;
      try {
        await switchToOwner();
        setIsOwner(true);
      } catch (e) {
        trigger?.reject(e);
        reset();
      }
    };
    _switchToOwner();
  }, [reset, start, switchToOwner, trigger]);

  useEffect(() => {
    const _submit = async () => {
      if (
        !start ||
        !isOwner ||
        !chain ||
        chainId !== chain.id ||
        !safeTx ||
        !trigger
      )
        return;
      try {
        await submit(safeTx);
        trigger?.resolve();
      } catch (e) {
        trigger?.reject(e);
      } finally {
        reset();
      }
    };
    _submit();
  }, [chain, chainId, isOwner, reset, safeTx, start, submit, trigger]);

  return useCallback((safeTx: SafeTx) => {
    return new Promise<void>((resolve, reject) => {
      setStart(true);
      setSafeTx(safeTx);
      setTrigger({
        resolve,
        reject,
      });
    });
  }, []);
};
