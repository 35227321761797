import { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { useAtomValue } from 'jotai';
import { groupBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { getShortNameByCoboName } from '@/chains/utils';
import { ChainIcon, CustomizeBotItem } from '@/components';
import {
  AvailableGeneralBotsListItem,
  BotStrategySearchCondition,
  ChainShortNameType,
  ProtocolInfo,
  TokenInfo,
} from '@/interfaces';
import { customBotsAtom } from '@/routes/MyPortfolio/atoms';
import { uniqueArrayByKey } from '@/utils/utils';

interface Props {
  onRefresh: () => Promise<AvailableGeneralBotsListItem[] | undefined>;
  canEdit: boolean;
  onViewDetail: (
    instance: AvailableGeneralBotsListItem,
    executeRecord: boolean,
  ) => void;
}

interface StrategyBotGroupItem {
  chain: string;
  botsGroup: AvailableGeneralBotsListItem[];
  tokens: TokenInfo[];
  protocols: ProtocolInfo[];
  botName: string;
}

export function StrategyBotsList({ canEdit, onViewDetail, onRefresh }: Props) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const bots: AvailableGeneralBotsListItem[] = useAtomValue(customBotsAtom);
  const strategyBots = useMemo(() => {
    return bots.filter(bot => bot.bot_type !== 3);
  }, [bots]);
  const [search] = useState<BotStrategySearchCondition>({
    type: -1,
    chain: -1,
    protocol: -1,
    token: -1,
  });
  const EmptyEle = useMemo(() => {
    return (
      <div className={cx('auth-empty')}>
        <img
          alt="empty"
          style={{ width: '160px' }}
          src={require('@/assets/images/empty_bot.png')}
        />
        <div className={cx('empty-text')}>
          <p>
            {t('common.emptyTable')}
            {` ${t('common.goto')} `}
            <a
              href={`${window.location.origin}/#/investment`}
              target="_blank"
              rel="noreferrer"
              className="text-blue"
            >
              {t('investment.strategyMartTitle')}
            </a>
            {` ${t('bots.emptyBotText2')}`}
          </p>
        </div>
      </div>
    );
  }, [cx, t]);

  const filteredBots = useMemo(() => {
    if (!strategyBots || strategyBots.length === 0) {
      return [];
    }
    let result = strategyBots;
    const { protocol, chain, type, token } = search;
    if (protocol !== -1) {
      result = result.filter(
        bot =>
          bot.protocols
            .map(protocol => protocol.project_id)
            .indexOf(protocol) !== -1,
      );
    }
    if (chain !== -1) {
      result = result.filter(bot => bot.chain === chain);
    }
    if (type !== -1) {
      result = result.filter(bot => bot.bot_type === type);
    }
    if (token !== -1) {
      result = result.filter(
        bot => bot.tokens.map(token => token.address)?.indexOf(token) !== -1,
      );
    }
    return result;
  }, [search, strategyBots]);

  const groupedBots: StrategyBotGroupItem[] = useMemo(() => {
    const groupedBySafeBots = groupBy(filteredBots, (bot: any) => {
      return `${bot.protocols[0].name}+${bot.chain}`;
    });
    let result = [];
    for (const key in groupedBySafeBots) {
      if (Object.prototype.hasOwnProperty.call(groupedBySafeBots, key)) {
        const bots = groupedBySafeBots[key];
        const botName = bots[0].bot_name;
        let tokens: TokenInfo[] = [];
        let protocols: ProtocolInfo[] = [];
        for (const safe of bots) {
          if (safe.tokens) {
            tokens = [...tokens, ...safe.tokens];
          }
          if (safe.protocols) {
            protocols = [...protocols, ...safe.protocols];
          }
        }
        tokens = uniqueArrayByKey(tokens, 'address');
        protocols = uniqueArrayByKey(protocols, 'address');
        result.push({
          chain: bots[0].chain,
          botsGroup: bots,
          tokens,
          protocols,
          botName,
        });
      }
    }
    return result;
  }, [filteredBots]);

  return (
    <div className={cx('bot-list')}>
      {strategyBots.length === 0
        ? EmptyEle
        : groupedBots.map((group: StrategyBotGroupItem, index) => {
            return (
              <div
                className={cx('strategy-card')}
                key={group.botName + group.chain}
              >
                <div className={cx('card-header')}>
                  <div className={cx('group-title-chain-icon')}>
                    <ChainIcon
                      shortName={
                        getShortNameByCoboName(
                          group.chain,
                        ) as ChainShortNameType
                      }
                      isRadiusIcon
                      chainName={group.chain}
                      className={cx('group-title-chain-icon')}
                    />
                  </div>
                  <div className={cx('card-header-content')}>
                    <div className={styles['protocols-logos']}>
                      <div className={styles['protocols-logo']}>
                        {group.protocols
                          ?.map((protocol: ProtocolInfo) => protocol.name)
                          .join('-')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cx('card-body')}>
                  {group.botsGroup.map((bot: AvailableGeneralBotsListItem) => {
                    return (
                      <CustomizeBotItem
                        bot={bot}
                        safeId={bot.safe_id}
                        canEdit={canEdit}
                        onRefresh={onRefresh}
                        key={bot.bot_instance_id}
                        safeAddress={bot.safe_address}
                        safeName={bot.safe_name}
                        onViewDetail={executeRecord =>
                          onViewDetail(bot, executeRecord || false)
                        }
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
    </div>
  );
}
