import { useMemo } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { ChainIcon, CoboSelect } from '@/components';
import { NameLogo } from '@/components/Layout/NameLogo';
import { BindSafeInfo, ChainShortNameType } from '@/interfaces';
import { copyStr, truncateAddress } from '@/utils/utils';

interface Props {
  name?: string;
  selectedSafe: BindSafeInfo | null;
  safes: BindSafeInfo[] | null;
  onChange: (safe: BindSafeInfo) => void;
  disabled?: boolean;
  optionFormat?: (safe: BindSafeInfo) => string;
  truncate?: boolean;
  copyable?: boolean;
  showChainIcon?: boolean;
  maxNameLength?: number;
}

export function SafeSelector({
  name,
  selectedSafe,
  safes,
  disabled,
  onChange,
  optionFormat,
  truncate,
  copyable,
  showChainIcon,
  maxNameLength = 10,
}: Props) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const showChain = useMemo(() => {
    if (!safes || safes.length <= 1) return false;
    const chain = safes[0].chain_id;
    return !!safes.find(safe => safe.chain_id !== chain);
  }, [safes]);
  return (
    <div className={cx('safe-selector-wrapper')} id="safe-select">
      {name && <div className={cx('name')}>{name.trim()}</div>}
      <div className={cx('selector')}>
        <CoboSelect
          value={
            safes && safes.find(safe => safe.id === selectedSafe?.id)
              ? selectedSafe?.id
              : undefined
          }
          disabled={disabled}
          onChange={safe => {
            if (!safes) return;
            const currentSafe = safes.find(s => s.id === safe);
            if (!currentSafe) return;
            onChange(currentSafe);
          }}
          className={styles['safe-selector']}
          getPopupContainer={() =>
            document.getElementById('safe-select') as HTMLElement
          }
          // dropdownStyle={{ top: '50px' }}
          placeholder={t('safe.chooseSafePlaceholder')}
          options={
            safes?.map(config => ({
              label:
                (optionFormat && optionFormat(config)) || config.name ? (
                  <div
                    className={cx('safe-label-wrapper')}
                    key={`${config.id}-icon`}
                  >
                    <div className={cx('name-logo')}>
                      {showChain || showChainIcon ? (
                        <ChainIcon
                          shortName={config?.chain_id as ChainShortNameType}
                          className={cx('safe-chain-icon')}
                        />
                      ) : (
                        <NameLogo label={config.name || ''} radius={'6px'} />
                      )}
                    </div>
                    {truncate
                      ? config.name.length > maxNameLength
                        ? config.name.slice(0, maxNameLength) + '...'
                        : config.name
                      : config.name}
                    :
                    {truncate
                      ? truncateAddress(config.address)
                      : config.address}
                  </div>
                ) : truncate ? (
                  truncateAddress(config.address)
                ) : (
                  config.address
                ),
              value: config.id,
            })) || []
          }
          optionLabelProp="label"
          popupMatchSelectWidth={true}
          renderOption={value => {
            if (!safes) return '-';
            const currentSafe = safes.find(s => s.id === value);
            const name = currentSafe?.name || '';
            const address = currentSafe?.address || '';
            const selected =
              currentSafe && selectedSafe && currentSafe.id === selectedSafe.id;
            if (!address) return '-';
            return (
              <div className={cx('safe-option-wrapper')}>
                <div className={cx('safe-option-content')}>
                  <div className={cx('safe-name', 'flex', 'items-center')}>
                    {(showChain || showChainIcon) && (
                      <ChainIcon
                        shortName={currentSafe?.chain_id as ChainShortNameType}
                        className={cx('safe-chain-icon')}
                      />
                    )}
                    {name}
                  </div>
                  <div className={cx('safe-address')}>{address}</div>
                </div>
                {selected ? <div className={cx('icon-selected')} /> : null}
              </div>
            );
          }}
        />
        {copyable && selectedSafe ? (
          <div className={cx('copy-btn')}>
            <span onClick={() => copyStr(selectedSafe.address)}>
              {t('common.copyToClipboard')}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
