import { useEffect } from 'react';
import classNames from 'classnames/bind';

import styles from './index.module.less';
import { getSupportedChains } from '@/api';
import { Strategies } from '@/components';
import { useAppDispatch } from '@/hooks';
import { updateChains } from '@/store';

export function StrategyMart() {
  const cx = classNames.bind(styles);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const loadChains = async () => {
      const chains = await getSupportedChains('2');
      dispatch(updateChains(chains));
    };
    loadChains();
  }, [dispatch]);

  return (
    <div className={styles['strategy-mart']}>
      <div className={cx('strategy-tabs')}>
        <Strategies strategyType="2" />
      </div>
    </div>
  );
}
