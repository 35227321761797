const SAFE_ADDRESS_CACHE_KEY = 'cobo-safe-address-key';
const MODULE_ADDRESS_CACHE_KEY = 'cobo-module-address-key';

export enum InviteRecordStatus {
  WAITING,
  EXPIRED,
  ACCEPTED,
  REJECTED,
}

export const getLabelByInviteRecordStatus = (status: number): string => {
  const statusLabelMap = new Map([
    [InviteRecordStatus.WAITING, 'Invitation Sent'],
    [InviteRecordStatus.EXPIRED, 'Expired'],
    [InviteRecordStatus.ACCEPTED, 'Joined'],
    [InviteRecordStatus.REJECTED, 'Rejected'],
  ]);
  return statusLabelMap.get(status) || '';
};

export enum MyOrganizationStatus {
  JOINED = 0,
  GOT_INVITED = 1,
  CREATED = 2,
}

export { SAFE_ADDRESS_CACHE_KEY, MODULE_ADDRESS_CACHE_KEY };
