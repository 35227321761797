import classNames from 'classnames/bind';

import styles from './index.module.less';

interface Props {
  title: string;
  subtitle?: string;
}

export function EmptyView({ title, subtitle }: Props) {
  const cx = classNames.bind(styles);
  return (
    <div className={cx('empty-view-wrapper')}>
      <div className={cx('image')} />
      <div className={cx('title')}>{title}</div>
      {subtitle && <div className={cx('subtitle')}>{subtitle}</div>}
    </div>
  );
}
