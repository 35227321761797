import { FC } from 'react';
import { Modal as AntModal, ModalProps } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { Button, SvgIcon } from '@/components';

interface Props extends ModalProps {
  width?: number | string;
  title: string | JSX.Element;
  className?: string;
  wrapperCls?: string;
  visible: boolean;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  primaryText?: string;
  secondaryText?: string;
  primaryDisabled?: boolean;
  primaryLoading?: boolean;
  secondaryDisabled?: boolean;
  secondaryLoading?: boolean;
  btnProps?: ModalBtnProps[];
  onCloseBtnClick?: () => void;
  afterClose?: () => void;
  zIndex?: number;
}

export const Modal: FC<Props> = props => {
  const {
    btnProps,
    width,
    title,
    className,
    wrapperCls,
    visible,
    onPrimaryClick,
    onSecondaryClick,
    primaryText,
    secondaryText,
    onCloseBtnClick,
    afterClose,
    zIndex,
    secondaryDisabled,
    secondaryLoading,
    primaryDisabled,
    primaryLoading,
    children,
    ...restProps
  } = props;
  const { t } = useTranslation();
  const closeIcon = (
    <SvgIcon name="ic_closed" className={styles['modal-close-icon']} />
  );
  let buttons = [];
  if (btnProps) {
    buttons = btnProps;
  } else {
    if (onSecondaryClick && secondaryText) {
      buttons.push({
        text: secondaryText,
        onClick: onSecondaryClick,
        disabled: secondaryDisabled,
        loading: secondaryLoading,
        primary: false,
      });
    }
    if (onPrimaryClick && primaryText) {
      buttons.push({
        text: primaryText,
        onClick: onPrimaryClick,
        disabled: primaryDisabled,
        loading: primaryLoading,
        primary: true,
      });
    }
  }
  if (buttons.length === 1) {
    if (!buttons[0].style) {
      buttons[0].style = {};
    }
    buttons[0].style = { ...buttons[0].style, width: '135px' };
  }
  return (
    <AntModal
      wrapClassName={classNames(styles['modal-wrapper'], wrapperCls || '')}
      closeIcon={closeIcon}
      className={
        className
          ? classNames(styles['modal-body'], className)
          : styles['modal-body']
      }
      open={visible}
      afterClose={afterClose}
      maskClosable={false}
      keyboard={false}
      closable={!!onCloseBtnClick}
      title={title}
      centered={true}
      okText={t('common.cancel')}
      onCancel={onCloseBtnClick}
      width={width || 680}
      zIndex={zIndex || 1000}
      footer={
        buttons.length > 0 && (
          <div className={styles['button-section']}>
            {buttons.map(item => (
              <Button
                key={item.text}
                onClick={item.onClick}
                disabled={item.disabled}
                loading={item.loading}
                type={
                  item.primary ? 'primary' : item.white ? 'white' : 'default'
                }
                className={
                  buttons.length === 1
                    ? classNames(
                        styles['modal-btn-wrapper'],
                        styles['modal-btn-single-wrapper'],
                      )
                    : styles['modal-btn-wrapper']
                }
                btnClassName={styles['modal-btn']}
              >
                {item.text}
              </Button>
            ))}
          </div>
        )
      }
      {...restProps}
    >
      {children}
    </AntModal>
  );
};

interface ModalBtnProps {
  text: string;
  primary?: boolean;
  white?: boolean;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  style?: object;
}
