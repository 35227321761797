const ICONS = [
  {
    name: 'ic_support',
    link: '',
    alt: 'Support',
  },
  {
    name: 'ic_techDoc',
    link: 'https://docs.cobo.com/cobo-argus/v/cobo-argus-v2-en-documentation/',
    alt: 'Guide',
  },
  {
    name: 'ic_manual',
    link: 'https://www.cobo.com/developers/overview/smart-contract-wallet/coboargus',
    alt: 'Tech Doc',
  },
  {
    name: 'ic_discord',
    link: 'https://discord.com/invite/FaZwQ9WYpj',
    alt: 'Discord',
  },
  {
    name: 'ic_twitter',
    link: 'https://twitter.com/Cobo_Global',
    alt: 'X',
  },
  {
    name: 'ic_youTube',
    link: 'https://www.youtube.com/@coboglobal',
    alt: 'YouTube',
  },
];

export default ICONS;
