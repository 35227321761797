import { coboApi } from '@/api/axios';
import { BotConfig, BotDetails, BotInfo, GasData, SafeTx } from '@/interfaces';

export const getGasAccount = async (
  org_id: string,
  silence = true,
): Promise<GasData[]> => {
  const data: GasData[] = await coboApi.post(
    '/argus/generic_bot/get_gas_account/',
    {
      org_id,
    },
    {
      params: { silence },
    },
  );
  const gasData = data.map(gas => {
    return {
      ...gas,
      recommended_min_balance:
        typeof gas.recommended_min_balance === 'number'
          ? gas.recommended_min_balance
          : parseInt(gas.recommended_min_balance),
      balance:
        typeof gas.balance === 'number' ? gas.balance : parseInt(gas.balance),
    };
  });
  return gasData;
};

export const executeBot = async (safe_id: string, bot_instance_id: string) => {
  await coboApi.post('/argus/generic_bot/execute_bot/', {
    safe_id,
    bot_instance_id,
  });
};

export const getBotExecutingRecord = async (
  safe_id: string,
  bot_instance_id: string,
): Promise<{
  status?: string;
}> => {
  return coboApi.post('/argus/generic_bot/get_bot_executing_record/', {
    safe_id,
    bot_instance_id,
  });
};

export const withdrawGas = async (safe_id: string) => {
  await coboApi.post('/argus/generic_bot/withdraw_gas/', { safe_id });
};

export const estimateWithdrawGas = async (
  safe_id: string,
): Promise<{ gas_fee: string }> => {
  return coboApi.post('/argus/generic_bot/estimate_withdraw_gas/', {
    safe_id,
  });
};

export const getWithdrawGasStatus = async (
  org_id: string,
  chain: string,
): Promise<{ status?: string }> => {
  return coboApi.post('/argus/generic_bot/get_withdraw_gas_status/', {
    org_id,
    chain,
  });
};

export const getBotsDetails = async (
  bot_definition_id: string,
  org_id: string,
  silence = true,
): Promise<BotDetails> => {
  return await coboApi.post(
    '/argus/generic_bot/get_bot_details/',
    {
      org_id,
      bot_definition_id,
    },
    {
      params: { silence },
    },
  );
};

export const getStrategyBotInfo = async (
  strategy_id: string,
  safe_id: string,
  safe_module_id: string,
  bot_definition_id: string,
): Promise<BotInfo> => {
  return await coboApi.post('/argus/strategy_bot/get_strategy_bot_info/', {
    strategy_id,
    safe_id,
    safe_module_id,
    bot_definition_id,
  });
};

export const editBot = async (
  strategy_id: string,
  safe_id: string,
  safe_module_id: string,
  bot_definition_id: string,
  config: BotConfig,
): Promise<SafeTx | null> => {
  return await coboApi.post('/argus/strategy_bot/edit_bot/', {
    strategy_id,
    safe_id,
    safe_module_id,
    bot_definition_id,
    config: JSON.stringify(config),
  });
};

export const activeBot = async (
  strategy_id: string,
  safe_id: string,
  safe_module_id: string,
  bot_definition_id: string,
  config: BotConfig,
): Promise<SafeTx | null> => {
  return await coboApi.post('/argus/strategy_bot/active_bot/', {
    strategy_id,
    safe_id,
    safe_module_id,
    bot_definition_id,
    config: JSON.stringify(config),
  });
};

export const getStrategyBotActivateStatus = async (
  strategy_id: string,
  safe_id: string,
  safe_module_id: string,
  bot_definition_id: string,
): Promise<{ pending_bot_activate_tx_hash: string }> => {
  return await coboApi.post(
    '/argus/strategy_bot/get_strategy_bot_activate_status/',
    {
      strategy_id,
      safe_id,
      safe_module_id,
      bot_definition_id,
    },
  );
};
