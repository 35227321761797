import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Web3 from 'web3';

import Chain from '@/chains';
import {
  Delegate,
  GnosisSafeInfo,
  Module,
  PermissionPage,
  ProtectorTableItem,
  SafeRoleTableItem,
} from '@/interfaces';

const initialState: {
  web3: Web3 | null;
  chain: Chain | null;
  safeInfo: {
    id: string;
    name: string;
    chain_id: string;
    address: string;
    default_cobo_module: string;
  } | null;
  coboModules: Module[];
  selectedModule: Module | null;
  roleList: SafeRoleTableItem[];
  protectorList: ProtectorTableItem[];
  delegatedList: Delegate[];
  delegateToUser: any;
  showPermissionPage: PermissionPage;
  gnosisSafeInfo: GnosisSafeInfo | null;
} = {
  web3: null,
  chain: null,
  safeInfo: null,
  coboModules: [],
  selectedModule: null,
  roleList: [],
  protectorList: [],
  delegatedList: [],
  delegateToUser: {},
  showPermissionPage: PermissionPage.Role,
  gnosisSafeInfo: null,
};

const slice = createSlice({
  name: 'safeDetail',
  initialState,
  reducers: {
    updateShowPermissionPage: (
      state,
      action: PayloadAction<PermissionPage>,
    ) => {
      state.showPermissionPage = action.payload;
    },
    updateSafeInfo: (
      state,
      action: PayloadAction<{
        id: string;
        name: string;
        chain_id: string;
        address: string;
        default_cobo_module: string;
      } | null>,
    ) => {
      state.safeInfo = action.payload;
    },
    updateSettingsGnosisSafeInfo: (
      state,
      action: PayloadAction<GnosisSafeInfo | null>,
    ) => {
      state.gnosisSafeInfo = action.payload;
    },
    updateCoboModules: (state, action: PayloadAction<Module[]>) => {
      state.coboModules = action.payload;
    },
    updateSelectedModule: (state, action: PayloadAction<Module | null>) => {
      state.selectedModule = action.payload;
    },
    updateWeb3: (state, action: PayloadAction<Web3 | null>) => {
      state.web3 = action.payload;
    },
    updateChain: (state, action: PayloadAction<Chain | null>) => {
      state.chain = action.payload;
    },
    updateRoleList: (state, action: PayloadAction<SafeRoleTableItem[]>) => {
      state.roleList = action.payload;
    },
    updateProtectorList: (
      state,
      action: PayloadAction<ProtectorTableItem[]>,
    ) => {
      state.protectorList = action.payload;
    },
    updateDelegatedList: (state, action: PayloadAction<Delegate[]>) => {
      state.delegatedList = action.payload;
    },
    updateDelegateToUser: (state, action: PayloadAction<any>) => {
      state.delegateToUser = action.payload;
    },
    clear: state => {
      const { safeInfo, web3, chain, showPermissionPage } = state;
      if (
        safeInfo === null &&
        web3 === null &&
        chain === null &&
        showPermissionPage === PermissionPage.Role
      )
        return;
      return {
        ...state,
        safeInfo: null,
        web3: null,
        chain: null,
        coboModules: [],
        roleList: [],
        protectorList: [],
        showPermissionPage: PermissionPage.Role,
      };
    },
  },
});

export const safeDetailReducer = slice.reducer;
export const updateShowPermissionPage = slice.actions.updateShowPermissionPage;
export const updateSafeDetailWeb3 = slice.actions.updateWeb3;
export const updateSafeInfo = slice.actions.updateSafeInfo;
export const updateSettingsGnosisSafeInfo =
  slice.actions.updateSettingsGnosisSafeInfo;
export const updateChain = slice.actions.updateChain;
export const updateCoboModules = slice.actions.updateCoboModules;
export const updateSafeSelectedModule = slice.actions.updateSelectedModule;
export const updateRoleList = slice.actions.updateRoleList;
export const updateProtectorList = slice.actions.updateProtectorList;
export const updateDelegatedList = slice.actions.updateDelegatedList;
export const updateDelegateToUser = slice.actions.updateDelegateToUser;
export const clearSafeDetail = slice.actions.clear;
