import { useMemo } from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { ChainsFilter, getShortNameByCoboName } from '@/chains/utils';
import { ChainIcon, CoboImage } from '@/components';
import { useLoadServices } from '@/components/Console/hooks';
import { ChainShortNameType } from '@/interfaces';
import { strategyInfoAtom } from '@/routes/StrategyAuthorize/strategyAuthorizeAtom';

export function StrategyBoard() {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();

  const [strategyInfo] = useAtom(strategyInfoAtom);
  const { configuredServiceIds } = useLoadServices(strategyInfo?.services);
  // const apyValue = useMemo(() => {
  //   if (!strategyInfo || strategyInfo?.apy === undefined) {
  //     return undefined;
  //   }
  //   return strategyInfo.apy
  //     ? `${(strategyInfo.apy * 100).toFixed(2)}%`
  //     : strategyInfo.apy;
  // }, [strategyInfo]);
  //
  // const tvlValue = useMemo(() => {
  //   if (!strategyInfo || strategyInfo.tvl === undefined) {
  //     return undefined;
  //   }
  //   if (strategyInfo.tvl) {
  //     return `$${toFixedWithCommas(strategyInfo.tvl)}`;
  //   }
  //   return strategyInfo.tvl;
  // }, [strategyInfo]);

  const rewardTokens = useMemo(() => {
    if (
      !strategyInfo ||
      strategyInfo.desc.reward_tokens === undefined ||
      strategyInfo.desc.reward_tokens.length === 0
    ) {
      return undefined;
    }
    const symbols = strategyInfo.desc.reward_tokens.map(item => item.symbol);
    return symbols.join('/');
  }, [strategyInfo]);

  return (
    <div className={cx('strategy-board')}>
      <div className={cx('strategy-board-header')}>
        {strategyInfo?.strategy}
        {configuredServiceIds && configuredServiceIds.length > 0 && (
          <div className={cx('configured')}>
            <span className={cx('configured-icon')}></span>
            {t('bots.configured')}
          </div>
        )}
      </div>
      <div className={cx('strategy-board-network')}>
        <div className={cx('strategy-board-network-item')}>
          <span className={cx('strategy-board-network-label')}>
            {t('investment.strategyDetail.network')}
            {strategyInfo?.desc.chain_id && (
              <ChainIcon
                shortName={
                  getShortNameByCoboName(
                    strategyInfo.desc.chain_id,
                  ) as ChainShortNameType
                }
                className={cx('chain-icon')}
              />
            )}
            <span className={cx('chain-name')}>
              {
                ChainsFilter.find(
                  item => item.coboName === strategyInfo?.desc.chain_id,
                )?.chainName
              }
            </span>
          </span>
        </div>
        <div className={cx('strategy-board-network-item')}>
          <span className={cx('strategy-board-network-label')}>
            {t('investment.strategyDetail.token')}
          </span>
          <div className={cx('strategy-tokens')}>
            {strategyInfo?.desc.invest_tokens?.map((token, index) => {
              return (
                <CoboImage
                  className={cx(
                    'strategy-token-item',
                    `strategy-token-item-${token.chain_id}`,
                  )}
                  url={token.icon_url}
                  key={index}
                />
              );
            })}
          </div>
        </div>
        {strategyInfo &&
          strategyInfo?.desc.projects &&
          strategyInfo?.desc.projects.length > 0 && (
            <div className={cx('strategy-board-network-item')}>
              <span className={cx('strategy-board-network-label')}>
                {t('authorization.website')}:
              </span>
              <div
                className={cx('strategy-website')}
                onClick={() => {
                  if (
                    strategyInfo &&
                    strategyInfo?.desc.projects &&
                    strategyInfo?.desc.projects.length > 0
                  )
                    window.open(strategyInfo.desc.projects[0].protocol_url);
                }}
              >
                {strategyInfo.desc.projects[0].protocol_url?.split('?')[0]}
              </div>
            </div>
          )}
      </div>

      {(rewardTokens || strategyInfo?.desc.pool) && (
        <div className={cx('strategy-board-portfolio')}>
          {/*<div className={styles['portfolio-item']}>*/}
          {/*  <span className={cx('portfolio-title')}>{apyValue || '--'}</span>*/}
          {/*  <span className={cx('portfolio-text')}>*/}
          {/*    {t('investment.strategyMart.apy')}*/}
          {/*  </span>*/}
          {/*</div>*/}
          {/*<div className={styles['portfolio-item']}>*/}
          {/*  <span className={cx('portfolio-title')}>{tvlValue || '--'}</span>*/}
          {/*  <span className={cx('portfolio-text')}>*/}
          {/*    {t('investment.strategyMart.tvl')}*/}
          {/*  </span>*/}
          {/*</div>*/}
          <div className={styles['portfolio-item']}>
            <span className={cx('portfolio-title')}>{rewardTokens || '-'}</span>
            <span className={cx('portfolio-text')}>
              {t('investment.strategyDetail.earn')}
              <Tooltip
                placement="bottom"
                title={<div>{t('authorization.earnTip')}</div>}
              >
                <div className={cx('tip-icon')} />
              </Tooltip>
            </span>
          </div>
          <div className={styles['portfolio-item']}>
            {strategyInfo?.desc.pool ? (
              <span className={cx('portfolio-title')}>
                {strategyInfo?.desc.pool}
              </span>
            ) : null}
            {strategyInfo?.desc.pool ? (
              <span className={cx('portfolio-text')}>
                {t('investment.strategyDetail.curvePool')}
              </span>
            ) : null}
          </div>
          <div className={styles['portfolio-item']} />
          <div className={styles['portfolio-item']} />
        </div>
      )}
    </div>
  );
}
