import 'swiper/css';
import 'swiper/css/navigation';

import React, { Suspense, useEffect, useMemo, useState } from 'react';
import {
  defaultTheme, // 默认主题
} from '@ant-design/compatible';
import { Global } from '@emotion/react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { ConfigProvider, Spin } from 'antd';
import { useAtom } from 'jotai';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';

import styles from './app.module.less';
import GlobalStyles from './globalStyle';
import colors from './utils/colors';
import { getMyProfile, ssoCallback } from '@/api/logic/auth';
import { CodeModal, Layout, LoadingIcon, Modal } from '@/components';
import coboAtomStore, {
  globalLoadingAtom,
  globalSignPromise,
  globalUserInfoAtom,
  pathHistoryAtom,
} from '@/globalAtom';
import { useAppDispatch, useAppSelector, useGlobalOrgs } from '@/hooks';
import { useDataScript } from '@/hooks/useDataScript';
import { useSign } from '@/hooks/useSign';
import i18n from '@/i18n';
// import Guidance from '@/routes/Guidance';
// import Auth from '@/routes/Auth';
import Login from '@/routes/Login';
import MyPortfolio from '@/routes/MyPortfolio';
import Organization from '@/routes/Organization';
import HomeSetting from '@/routes/SafeDetail/homeSetting';
import { clearRole, updateLoadingColor, updateLoadingProgress } from '@/store';
import { CoboStorage } from '@/utils/storage';
import {
  filterRoutes,
  getQueryVariable,
  hideConfirmModal,
} from '@/utils/utils';

// 懒加载
// 打包后体积小于5k的路由没必要懒加载, 使用魔法注释指定chunkName
const Safe = React.lazy(
  () => import(/* webpackChunkName: 'Safe' */ '@/routes/Safe'),
);
const Team = React.lazy(
  () => import(/* webpackChunkName: 'Team' */ '@/routes/Team'),
);
const Investment = React.lazy(
  () => import(/* webpackChunkName: 'Investment' */ '@/routes/Investment'),
);
const Trading = React.lazy(
  () => import(/* webpackChunkName: 'Trading' */ '@/routes/Trading'),
);
const StrategyAuthorize = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'StrategyAuthorize' */ '@/routes/StrategyAuthorize'
    ),
);
const StrategyDetail = React.lazy(
  () =>
    import(/* webpackChunkName: 'StrategyDetail' */ '@/routes/StrategyDetail'),
);

const Bot = React.lazy(
  () => import(/* webpackChunkName: 'GeneralBot' */ '@/routes/Bot'),
);

// const BotsDetail = React.lazy(
//   () => import(/* webpackChunkName: 'BotsDetail' */ '@/routes/BotsDetail'),
// );

const BotDetail = React.lazy(
  () => import(/* webpackChunkName: 'BotDetail' */ '@/routes/BotDetail'),
);

const SafeDetail = React.lazy(
  () => import(/* webpackChunkName: 'SafeDetail' */ '@/routes/SafeDetail'),
);

const Me = React.lazy(
  () => import(/* webpackChunkName: 'SafeDetail' */ '@/routes/Me'),
);

const Account = React.lazy(
  () => import(/* webpackChunkName: 'SafeDetail' */ '@/routes/Account'),
);

const InviteNew = React.lazy(
  () => import(/* webpackChunkName: 'SafeDetail' */ '@/routes/InviteNew'),
);

const Transaction = React.lazy(
  () => import(/* webpackChunkName: 'SafeDetail' */ '@/routes/Transaction'),
);

const ServiceConfig = React.lazy(() => import('@/routes/ServiceConfig'));

const RoutesWithSidebar = [
  {
    path: '/safe-management',
    element: <Safe />,
    title: i18n.t('safeManagement.home'),
  },
  {
    path: '/home',
    element: <SafeDetail />,
    title: i18n.t('safeManagement.home'),
  },
  {
    path: '/home-setting',
    element: <HomeSetting />,
    title: i18n.t('safeManagement.home'),
  },
  {
    path: '/team-management',
    element: <Team />,
    title: i18n.t('teamManagement.team'),
    permRequired: [0],
  },

  {
    path: '/organization',
    element: <Organization />,
    title: i18n.t('orgSettings.organization'),
  },
  // {
  //   path: '/guidance',
  //   element: <Guidance />,
  //   title: i18n.t('guidance.title'),
  // },
  {
    path: '/investment',
    element: <Investment />,
    title: i18n.t('investment.farming'),
  },
  {
    path: '/trading',
    element: <Trading />,
    title: i18n.t('investment.trading'),
  },
  {
    path: '/strategy-info/farming/:id',
    element: <StrategyDetail />,
    title: i18n.t('investment.strategy'),
  },
  {
    path: '/strategy-info/trading/:id',
    element: <StrategyDetail />,
    title: i18n.t('investment.strategy'),
  },
  {
    path: '/strategy-authorization/:id',
    element: <StrategyAuthorize />,
    title: i18n.t('investment.strategy'),
  },
  {
    path: '/my-portfolio/farming',
    element: <MyPortfolio />,
    title: i18n.t('investment.farming'),
  },
  {
    path: '/my-portfolio/trading',
    element: <MyPortfolio />,
    title: i18n.t('investment.trading'),
  },
  {
    path: '/farming-detail/:id',
    element: <BotDetail />,
    title: i18n.t('investment.farming'),
  },
  {
    path: '/bot-detail/:id',
    element: <BotDetail />,
    title: i18n.t('bots.bots'),
  },
  {
    path: '/farming/generic-bot/:bot_definition_id/new',
    element: <Bot />,
    title: i18n.t('investment.farming'),
  },
  {
    path: '/service-config/farming/:id',
    element: <ServiceConfig />,
    title: i18n.t('investment.farming'),
  },
  {
    path: '/service-config/trading/:id',
    element: <ServiceConfig />,
    title: i18n.t('investment.trading'),
  },
  {
    path: '/generic-bot/:bot_instance_id',
    // element: <GeneralBot />,
    element: <Bot />,
    title: i18n.t('investment.genericBot.editTitle'),
  },
  {
    path: '/me',
    element: <Me />,
    title: i18n.t('me.title'),
  },
  {
    path: '/transaction',
    element: <Transaction />,
    title: i18n.t('transaction.title'),
  },
  {
    path: '/account',
    element: <Account />,
    title: i18n.t('account.title'),
  },
];

const RoutesWithoutSidebar = [
  // {
  //   path: '/auth',
  //   element: <Auth />,
  //   title: i18n.t('auth.title'),
  // },
  {
    path: '/invite-new',
    element: <InviteNew />,
    title: i18n.t('me.inviteNew'),
  },
  {
    path: '/login',
    element: <Login />,
    title: i18n.t('auth.title'),
  },
];

export const allRoutes = [...RoutesWithSidebar, ...RoutesWithoutSidebar];

function App() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [pathHistory] = useAtom(pathHistoryAtom);
  const [userInfo] = useAtom(globalUserInfoAtom);
  const [signPromise, setSignPromise] = useAtom(globalSignPromise);
  const signResolve = useMemo(
    () => (signPromise ? signPromise['resolve'] : undefined),
    [signPromise],
  );
  const signReject = useMemo(
    () => (signPromise ? signPromise['reject'] : undefined),
    [signPromise],
  );
  const signMsg = useSign();
  const { organizations, orgId } = useGlobalOrgs();
  const [redirectTo, setRedirectTo] = useState('');
  const requestingColor = useAppSelector(state => state.global.loadingColor);
  const requestingProgress = useAppSelector(
    state => state.global.loadingProgress,
  );
  const pageLoading = useAppSelector(state => state.global.pageLoading);

  const confirmModalVisible = useAppSelector(
    state => state.global.confirmModalVisible,
  );
  const confirmModalTitle = useAppSelector(
    state => state.global.confirmModalTitle,
  );
  const confirmModalElement = useAppSelector(
    state => state.global.confirmModalElement,
  );
  const confirmModalOkText = useAppSelector(state => state.global.okText);
  const confirmModalCancelText = useAppSelector(
    state => state.global.cancelText,
  );
  const showConfirmModalClose = useAppSelector(
    state => state.global.showConfirmModalClose,
  );
  const onConfirm = useAppSelector(state => state.global.onConfirm);
  const onCancel = useAppSelector(state => state.global.onCancel);
  const orgInfo = organizations?.find(item => item.id === orgId);

  const isProd = process.env.REACT_APP_ENV === 'prod';
  if (!ReactGA.isInitialized && isProd) {
    ReactGA.initialize('G-JR4SY9HQW4');
  }

  useEffect(() => {
    if (!signResolve || !signReject) {
      return;
    }
    const sign = async () => {
      try {
        const { message, signature } = await signMsg(t('common.signToConfirm'));
        signResolve({ message, signature });
      } catch (e: any) {
        signReject(e);
      } finally {
        setSignPromise(undefined);
      }
    };
    sign();
  }, [signResolve, signReject, signMsg, t, setSignPromise]);

  useEffect(() => {
    if (!isProd) return;
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [isProd, navigate, redirectTo, location.pathname]);

  useDataScript();

  useEffect(() => {
    coboAtomStore.set(pathHistoryAtom, [...pathHistory, location.pathname]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (redirectTo) {
      navigate(redirectTo, { replace: true });
      setRedirectTo('');
    }
  }, [navigate, redirectTo]);

  useEffect(() => {
    const getSessionCodByCallback = async () => {
      const code = getQueryVariable('code');
      const state = getQueryVariable('state');
      if (
        !code ||
        !state ||
        CoboStorage.get('SESSION-CODE') ||
        location.pathname === '/login'
      ) {
        return;
      }
      try {
        const callbackInfo = await ssoCallback(code, state);
        const sessionCode = callbackInfo?.session_code;
        CoboStorage.set('SESSION-CODE', sessionCode);
        if (location.pathname !== '/home') {
          window.location.href = `${window.location.origin}/#/home`;
        } else {
          const newUrl = `${window.location.origin}/#/home`;
          window.history.replaceState(null, '', newUrl);
        }
      } catch (e: any) {
        if (e.response?.status === 405) {
          setRedirectTo('/login?openModal=true');
          return true;
        } else {
          navigate('/login');
        }
      }
    };
    const setLocale = async () => {
      if (location.pathname === '/invite-new') {
        return;
      }
      if (!CoboStorage.get('SESSION-CODE') && location.pathname !== '/login') {
        window.location.href = `${window.location.origin}/#/login`;
        return;
      }
      if (userInfo) {
        location.pathname === '/login' && setRedirectTo('/home');
        return;
      }
      try {
        const userInfo_ = await getMyProfile();
        userInfo_ && coboAtomStore.set(globalUserInfoAtom, userInfo_);
      } catch {
        if (location.pathname !== '/login') setRedirectTo('/login');
      }
    };
    const getSession = async () => {
      const skipSetLocale = await getSessionCodByCallback();
      if (skipSetLocale) return;
      await setLocale();
    };
    getSession();
  }, [location.pathname, userInfo, navigate]);

  useEffect(() => {
    dispatch(clearRole());
  }, [dispatch, orgId]);

  const confirmButtons = [];
  if (confirmModalCancelText) {
    confirmButtons.push({
      text: confirmModalCancelText,
      onClick: () => {
        hideConfirmModal();
        onCancel();
      },
    });
  }
  if (confirmModalOkText) {
    confirmButtons.push({
      text: confirmModalOkText,
      primary: true,
      onClick: () => {
        onConfirm();
        hideConfirmModal();
      },
    });
  }

  const suspenseFallback = (
    <Spin
      spinning={true}
      wrapperClassName={styles['spin-container']}
      size="large"
      indicator={<LoadingIcon size={'64px'} />}
    />
  );

  return (
    <div className={styles['app-container']}>
      <Global styles={GlobalStyles} />
      <div style={{ display: 'none' }}>
        <ConnectButton />
      </div>
      <LoadingBar
        color={requestingColor}
        progress={requestingProgress}
        onLoaderFinished={() => {
          dispatch(updateLoadingProgress(0));
          dispatch(updateLoadingColor(colors.primary));
        }}
      />
      <ConfigProvider theme={defaultTheme}>
        <Spin
          size="large"
          spinning={
            pageLoading ||
            coboAtomStore.get(globalLoadingAtom) ||
            !!signResolve ||
            !!signReject
          }
          wrapperClassName={styles['spin-container']}
          indicator={<LoadingIcon size={'64px'} />}
        >
          <Suspense fallback={suspenseFallback}>
            <Routes>
              <Route path="/" element={<Layout />}>
                {filterRoutes(RoutesWithSidebar, orgInfo).map(
                  ({ path, element }) => (
                    <Route path={path} element={element} key={path} />
                  ),
                )}
              </Route>
              {RoutesWithoutSidebar.map(({ path, element }) => (
                <Route path={path} element={element} key={path} />
              ))}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
          <CodeModal />
          <Modal
            title={confirmModalTitle}
            visible={confirmModalVisible}
            onCloseBtnClick={showConfirmModalClose}
            btnProps={confirmButtons}
            zIndex={1010}
            key={confirmModalTitle}
          >
            {confirmModalElement}
          </Modal>
        </Spin>
      </ConfigProvider>
    </div>
  );
}

export default App;
