import { BaseChain } from './base';

export default class Goerli extends BaseChain {
  name = 'Goerli';
  shortName = 'gor';

  private etherscanWebBaseUrl = 'https://goerli.etherscan.io/';
  private infraBaseUrl = 'https://goerli.infura.io';
  public safeWebBaseUrl = 'https://app.safe.global';
  private safeApiBaseUrl = 'https://safe-transaction-goerli.safe.global';
  baseToken = 'GTH';
  subsafeFactory = '0x93014BF55996dB950CD099dB3e89EBa87eE95A43';
  baseTokenName = 'Görli Ether';

  public safeUrl(address: string): string {
    return `${this.safeWebBaseUrl}/home?safe=${this.shortName}:${address}`;
  }

  public addressUrl(address: string): string {
    return `${this.etherscanWebBaseUrl}/address/${address}`;
  }
  public tokenUrl(address: string): string {
    return `${this.etherscanWebBaseUrl}/token/${address}`;
  }

  public rpcUrl(): string {
    const token = process.env.REACT_APP_INFRA_TOKEN;
    return `${this.infraBaseUrl}/v3/${token}`;
  }

  public safeApiUrl(): string {
    return this.safeApiBaseUrl;
  }

  public txDetailUrl(hash: string): string {
    return `${this.etherscanWebBaseUrl}/tx/${hash}`;
  }
}
