import classNames from 'classnames/bind';

import styles from './index.module.less';
import { Chains } from '@/chains/utils';
import { ChainIcon, CoboSelect } from '@/components';
import { ChainShortNameType } from '@/interfaces';

interface Props {
  disabled?: boolean;
  chain: string;
  onChange?: (chain: string) => void;
}

export function ChainSelect({ chain, onChange, disabled = false }: Props) {
  const chains = Array.from(Chains.entries());
  const cx = classNames.bind(styles);
  return (
    <CoboSelect
      value={chain}
      disabled={disabled}
      onChange={onChange}
      className={cx('chain-select')}
      options={
        chains.map(([_, config]) => ({
          label: config.chainName,
          value: config.shortName,
          icon: config.shortName ? (
            <ChainIcon
              shortName={config.shortName as ChainShortNameType}
              chainName={config.chainName}
            />
          ) : null,
        })) || []
      }
    />
  );
}
