import { useMemo } from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import classNames from 'classnames';

import styles from './index.module.less';
import { getServiceLabel } from '@/components/Console/utils';
import { useImage } from '@/hooks';
import { ServiceDetailType, ServiceType } from '@/interfaces';

interface Props {
  className?: string;
  name?: ServiceType | ServiceDetailType;
  inactive?: boolean;
  configured?: boolean;
  size?: 'normal' | 'large';
  parseServiceTooltip?: (tooltip: string) => string;
  showTooltip?: boolean;
}

export function ServiceIcon(props: Props) {
  const {
    className,
    name,
    inactive,
    configured,
    parseServiceTooltip,
    showTooltip,
  } = props;
  const size = props.size || 'normal';
  const reqSvgs = require.context('@/assets/icons/service', true, /\.png$/);
  const iconNameMap = {
    [ServiceType.BOT]: 'default',
    [ServiceType.SINGLE_SIGN]: 'authorization',
    [ServiceDetailType.FARMING]: 'authorization',
    [ServiceDetailType.CLAIM]: 'claim',
    [ServiceDetailType.WITHDRAW]: 'withdrawal',
    [ServiceDetailType.LEVERAGE]: 'leverage',
    [ServiceDetailType.CUSTOMIZED_BOT]: 'customized',
    [ServiceDetailType.TRADING]: 'authorization',
  };
  const allSvgFilepaths = reqSvgs.keys();
  const imgSrc = useMemo(() => {
    const iconName = name ? iconNameMap[name] : 'default';
    const path = inactive
      ? `./ic_${iconName}_blue.png`
      : `./ic_${iconName}.png`;
    const index = allSvgFilepaths.findIndex(val => val === path);
    const imagePath = allSvgFilepaths[index];
    if (!imagePath) return reqSvgs('./ic_default.png');
    return reqSvgs(imagePath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSvgFilepaths, reqSvgs, name]);
  const { hasLoaded, hasError } = useImage(imgSrc);

  const tooltipName = useMemo(() => {
    const service_type =
      name === ServiceType.SINGLE_SIGN
        ? ServiceType.SINGLE_SIGN
        : ServiceType.BOT;
    const detail_type =
      service_type === ServiceType.BOT
        ? (name as ServiceDetailType)
        : ServiceDetailType.FARMING;
    return getServiceLabel({
      service_type,
      detail_type,
      service_id: '',
      service_description: '',
      service_status: null,
    });
  }, [name]);

  const width = size === 'normal' ? 'w-[32px]' : 'w-[40px]';
  const height = size === 'normal' ? 'h-[32px]' : 'h-[40px]';
  const configuredWidth = size === 'normal' ? 'w-[16px]' : 'w-[20px]';
  const configuredHeight = size === 'normal' ? 'h-[16px]' : 'h-[20px]';
  const configuredRight = size === 'normal' ? 'right-[-8px]' : 'right-[-10px]';
  const configuredBottom =
    size === 'normal' ? 'bottom-[-6px]' : 'bottom-[-8px]';

  const service = (
    <div className="relative">
      <img
        src={imgSrc}
        alt={tooltipName}
        className={`relative top-[-1px] ${width} ${height} ${className}`}
      />
      {configured ? (
        <div
          className={`bg-[url('~@/assets/icons/service/ic_service_configured.png')] ${configuredWidth} ${configuredHeight} bg-cover bg-no-repeat absolute ${configuredBottom} ${configuredRight}`}
        />
      ) : null}
    </div>
  );
  return (
    <>
      {!hasLoaded && !hasError && (
        <SyncOutlined
          className={classNames(styles['service-icon'], className)}
          spin
        />
      )}
      {hasLoaded &&
        (showTooltip ? (
          <Tooltip
            title={
              parseServiceTooltip
                ? parseServiceTooltip(tooltipName)
                : tooltipName
            }
            placement="top"
          >
            {service}
          </Tooltip>
        ) : (
          service
        ))}
      {hasError && (
        <img
          src={require('@/assets/icons/service/ic_default.png')}
          alt={tooltipName}
          className={classNames(styles['service-icon'], className)}
        />
      )}
    </>
  );
}
