import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './index.module.less';
import { ssoLogin, ssoLogout } from '@/api';
import { Modal, SvgIcon } from '@/components';
import { globalUserInfoAtom } from '@/globalAtom';
import { useGlobalOrgs, useGlobalSafe } from '@/hooks';
import { CoboStorage } from '@/utils/storage';
import { truncateAddress } from '@/utils/utils';

interface Props {
  beforeMenuClick: (func: () => void) => void;
}

export function UserInfo({ beforeMenuClick }: Props) {
  const { t } = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [userInfo, setUserInfo] = useAtom(globalUserInfoAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const { clearSafe } = useGlobalSafe();
  const { clearOrg } = useGlobalOrgs();

  const onSwitchAndLogin = useCallback(async () => {
    const urlParams = await ssoLogin();
    const url = urlParams?.redirect_url + `&prompt=login`;
    CoboStorage.remove('SESSION-CODE');
    url && window.location.replace(url);
  }, []);

  const userInfoActions = useMemo(() => {
    let _menu = [
      {
        label: t('auth.switchAccount'),
        class: styles['icon-switch'],
        action: onSwitchAndLogin,
      },
      {
        label: t('auth.logout'),
        class: styles['icon-logout'],
        action: () => {
          setDropdownVisible(false);
          setConfirmModalVisible(true);
        },
      },
    ];
    if (userInfo?.org_count && userInfo?.org_count > 0) {
      _menu = [
        ...[
          {
            label: t('auth.account'),
            class: styles['icon-account'],
            action: () => {
              try {
                beforeMenuClick(() =>
                  navigate('/account', {
                    replace: location.pathname === '/guidance',
                  }),
                );
              } finally {
                setDropdownVisible(false);
              }
            },
          },
          {
            label: t('auth.organization'),
            class: styles['icon-org'],
            action: () => {
              try {
                beforeMenuClick(() => {
                  if (!userInfo || userInfo.org_count === 0) return;
                  navigate('/organization', {
                    replace: location.pathname === '/guidance',
                  });
                });
              } finally {
                setDropdownVisible(false);
              }
            },
          },
        ],
        ..._menu,
      ];
    }
    return _menu;
  }, [
    beforeMenuClick,
    location.pathname,
    navigate,
    t,
    userInfo,
    onSwitchAndLogin,
  ]);

  useEffect(() => {
    if (location.pathname === '/organization') {
      if (userInfoActions.length > 1) {
        setSelectedKeys([userInfoActions[1].label]);
      } else {
        setSelectedKeys([]);
      }
    } else if (location.pathname === '/account') {
      if (userInfoActions.length > 0) {
        setSelectedKeys([userInfoActions[0].label]);
      } else {
        setSelectedKeys([]);
      }
    } else {
      setSelectedKeys([]);
    }
  }, [location.pathname, userInfoActions]);

  const items: MenuProps['items'] = useMemo(() => {
    return userInfoActions.map(item => {
      let itemStyle = styles['user-info-action-item'];
      if (selectedKeys.includes(item.label)) {
        itemStyle = `${itemStyle} ${styles['user-info-action-item-selected']}`;
      }
      return {
        key: item.label,
        label: (
          <div
            onClick={() => {
              item.action();
            }}
            key={item.label}
            className={itemStyle}
          >
            <div className={styles['user-info-action-inner']}>
              <div
                className={`${styles['user-info-action-inner-icon']} ${item.class}`}
              />
              <div className={styles['user-info-action-inner-label']}>
                {item.label}
              </div>
            </div>
          </div>
        ),
      };
    });
  }, [userInfoActions, selectedKeys]);

  return (
    <div className={styles['userinfo-container']}>
      <Dropdown
        open={dropdownVisible}
        onOpenChange={setDropdownVisible}
        menu={{
          items,
          onSelect: ({ key }) => {
            beforeMenuClick(() => setSelectedKeys([key]));
          },
        }}
        trigger={['click']}
        overlayClassName={styles['user-info-dropdown']}
        placement="top"
      >
        <div className={styles['user-info-wrapper']}>
          <div className={styles['user-avatar']} />
          <div className={styles['user-info-content']}>
            <div className={styles['user-info-name']}>
              <div className={styles['user-info-name-label']}>
                {userInfo?.name || '--'}
              </div>
            </div>
            <div className={styles['user-info-email']}>
              {(userInfo?.login_address
                ? truncateAddress(userInfo?.login_address)
                : undefined) ||
                userInfo?.email ||
                userInfo?.google_one_tap_email ||
                '-'}
            </div>
          </div>
          <SvgIcon
            name="dropdown_arrow"
            color="rgba(137, 148, 171, 1)"
            className={`${styles['arrow']} ${
              dropdownVisible ? styles['arrow-down'] : styles['blue']
            }`}
          />
        </div>
      </Dropdown>
      <Modal
        title="Log Out"
        visible={confirmModalVisible}
        btnProps={[
          {
            text: t('common.cancel'),
            onClick: () => {
              setConfirmModalVisible(false);
            },
          },
          {
            text: t('common.ok'),
            primary: true,
            onClick: async () => {
              await ssoLogout();
              setUserInfo(null);
              clearSafe(true);
              clearOrg();
              CoboStorage.remove('SESSION-CODE');
              navigate('/login');
            },
          },
        ]}
        onCloseBtnClick={() => {
          setConfirmModalVisible(false);
        }}
      >
        Are you sure you want to quit?
      </Modal>
    </div>
  );
}
