import { useEffect, useState } from 'react';
import { Select } from 'antd';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { ServiceSearch } from '../ServiceSearch/index';
import styles from './index.module.less';
import { getSupportedProtocols, getSupportedToken } from '@/api/logic';
import { ChainSearch, SvgIcon } from '@/components';
import { useAppSelector } from '@/hooks';
import { ProtocolItem } from '@/interfaces';
import { uniqueArrayByKey } from '@/utils/utils';

interface StrategySearchProps {
  onSearch: (
    chain: string,
    protocol: string,
    token: string,
    service_type: string,
  ) => void;
  chain: string;
  protocol: string;
  token: string;
  service_type: string;
  strategy_type: '2' | '5';
}
export function StrategySearch({
  chain,
  protocol,
  token,
  service_type,
  strategy_type,
  onSearch,
}: StrategySearchProps) {
  const { t } = useTranslation();
  const { Option } = Select;
  const cx = classNames.bind(styles);
  const chains = useAppSelector(state => state.strategies.chains);
  const handleProtocolChange = (value: string) => {
    onSearch(chain, value, token, service_type);
  };
  const handleTokenChange = (value: string) => {
    onSearch(chain, protocol, value, service_type);
  };
  const handleChainChange = (value: string) => {
    onSearch(value, protocol, token, service_type);
  };
  const handleServiceChange = (service_type: string) => {
    onSearch(chain, protocol, token, service_type);
  };

  const [protocols, setProtocols] = useState<ProtocolItem[]>([]);

  const [tokens, setTokens] = useState<{ name: string; value: string }[]>([]);

  useEffect(() => {
    const loadData = async () => {
      const asyncProtocols = await getSupportedProtocols(strategy_type);
      const protocols = uniqueArrayByKey(asyncProtocols, 'project_id');
      protocols.sort((a, b) => a.name.localeCompare(b.name));
      protocols.unshift({
        chain_id: '',
        icon_url: '',
        name: 'All',
        project_id: 'all',
      });
      setProtocols(protocols);
      let asyncTokens = await getSupportedToken(strategy_type);
      let tokens = asyncTokens.map((item: string) => {
        return {
          name: item,
          value: item,
        };
      });
      tokens.sort((a, b) => a.name.localeCompare(b.name));
      tokens.unshift({ name: 'All', value: 'all' });
      setTokens(tokens);
    };
    loadData();
  }, [strategy_type]);

  const placeholder = (
    <div className="flex flex-row items-center h-full">
      <div className="w-[12px] h-[12px] bg-[url('~@/assets/icons/ic_search.png')] bg-cover bg-no-repeat" />
      <div className="ml-[8px] text-greyFont text-[14px] leading-[20px]">
        Filter by name
      </div>
    </div>
  );

  return (
    <div className={styles['strategy-search']}>
      <div className={cx('strategy-networks')}>
        <ChainSearch onChange={handleChainChange} chains={chains} />
        <ServiceSearch onChange={handleServiceChange} />
      </div>
      <div className={cx('strategy-select-warp')}>
        <div className={cx('strategy-select-item')}>
          <label htmlFor="token" className={cx('strategy-select-label')}>
            {t('investment.strategyMart.token')}
          </label>
          <Select
            getPopupContainer={triggerNode => triggerNode.parentElement}
            style={{ width: 200 }}
            showSearch
            onChange={handleTokenChange}
            className={cx('strategy-select')}
            value={token || undefined}
            suffixIcon={
              <SvgIcon name="arrow_down" className={styles['arrow_icon']} />
            }
            placeholder={placeholder}
          >
            {tokens.map(item => (
              <Option value={item.value} key={item.value}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className={cx('strategy-select-item')}>
          <label htmlFor="token" className={cx('strategy-select-label')}>
            {t('investment.strategyMart.protocol')}
          </label>
          <Select
            getPopupContainer={triggerNode => triggerNode.parentElement}
            style={{ width: 200 }}
            value={protocol || undefined}
            showSearch
            onChange={handleProtocolChange}
            className={cx('strategy-select')}
            suffixIcon={
              <SvgIcon name="arrow_down" className={styles['arrow_icon']} />
            }
            placeholder={placeholder}
          >
            {protocols.map(item => (
              <Option value={item.project_id} key={item.project_id}>
                {item.chain_id ? `${item.name}(${item.chain_id})` : item.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}
