import { useCallback, useEffect, useState } from 'react';
import { SiweMessage } from 'siwe';
import { useDisconnect, useNetwork, useSignMessage } from 'wagmi';

import { useConnectWallet } from '@/hooks/useConnectWallet';
import { SignMessage } from '@/interfaces';

interface SignResp {
  message: SignMessage;
  signature: string;
  siwe_message: string;
  address: string;
}

export const useSign = () => {
  const { chain } = useNetwork();
  const { signMessageAsync } = useSignMessage();
  const { address, connect } = useConnectWallet();
  const { disconnectAsync } = useDisconnect();
  const [start, setStart] = useState(false);
  const [trigger, setTrigger] = useState<{
    reject: (error: any) => void;
    resolve: (value: SignResp) => void;
  }>();
  const [disableAddress, setDisableAddress] = useState<string>();
  const [message, setMessage] = useState<string>();

  const reset = useCallback(() => {
    setStart(false);
    setTrigger(undefined);
    setDisableAddress(undefined);
    setMessage(undefined);
  }, []);

  const sign = useCallback(async (): Promise<SignResp | undefined> => {
    if (!chain || !address) return;
    if (disableAddress === address) {
      throw new Error('Please select another address');
    }
    const domain = window.location.host;
    const origin = window.location.origin;
    const chainId = chain.id;
    const date = new Date();
    const nonce = `${date.getTime()}`;
    const expirationDate = new Date(date.getTime() + 5 * 60000);
    const issuedAt = date.toISOString();
    const expirationTime = expirationDate.toISOString();
    const siweMessage = new SiweMessage({
      domain,
      address,
      statement: message,
      uri: origin,
      version: '1',
      chainId: chainId,
      nonce,
      issuedAt,
      expirationTime,
    });
    const siwe_message = siweMessage.prepareMessage();
    const siwe_signature = await signMessageAsync({
      message: siwe_message,
    });
    return {
      message: {
        domain: siweMessage.domain,
        address: siweMessage.address,
        statement: siweMessage.statement || null,
        uri: siweMessage.uri,
        version: siweMessage.version,
        chain_id: siweMessage.chainId,
        nonce: parseInt(siweMessage.nonce),
        not_before: siweMessage.notBefore || null,
        expiration_time: siweMessage.expirationTime || null,
        issued_at: siweMessage.issuedAt || new Date().toISOString(),
      },
      siwe_message,
      signature: siwe_signature,
      address,
    };
  }, [address, chain, disableAddress, message, signMessageAsync]);

  useEffect(() => {
    const _connect = async () => {
      if (!start || !trigger) return;
      if (!address || (disableAddress && address === disableAddress)) {
        await disconnectAsync();
        try {
          const newAddress = await connect();
          if (newAddress === disableAddress) {
            trigger.reject(new Error('Please switch to another wallet'));
            reset();
          }
        } catch (e) {
          trigger.reject(e);
          reset();
        }
      }
    };
    _connect();
  }, [
    address,
    connect,
    disableAddress,
    disconnectAsync,
    start,
    trigger,
    reset,
  ]);

  useEffect(() => {
    const _sign = async () => {
      if (!start || !trigger || !address || !chain) return;
      try {
        if (address && disableAddress && address === disableAddress) return;
        const data = await sign();
        if (data) {
          trigger.resolve(data);
        }
      } catch (e) {
        trigger.reject(e);
      } finally {
        reset();
      }
    };
    _sign();
  }, [address, chain, disableAddress, reset, sign, start, trigger]);

  useEffect(() => {
    return reset;
  }, [reset]);

  return useCallback((message: string, disableAddress?: string) => {
    return new Promise<SignResp>((resolve, reject) => {
      setStart(true);
      setMessage(message);
      setDisableAddress(disableAddress);
      setTrigger({
        resolve,
        reject,
      });
    });
  }, []);
};
