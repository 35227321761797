import { useCallback, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { Support } from './Support';
import { ssoLogin, ssoSignUp } from '@/api';
import { Button, LoginHeader } from '@/components';
import NotSupportAccountModal from '@/components/Layout/NotSupportAccountModal';
import { globalAccountModalVisibleAtom } from '@/globalAtom';
import { useQuery } from '@/hooks';
import { useHeartbeat } from '@/hooks/useHeartbeat';

export default function Auth() {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const query = useQuery();
  const [accountModalVisible, setAccountModalVisible] = useAtom(
    globalAccountModalVisibleAtom,
  );

  useHeartbeat('View_Page_Register', true);

  const onLogin = useCallback(async (needSwitch: boolean = false) => {
    const urlParams = await ssoLogin();
    let url = urlParams?.redirect_url;
    if (needSwitch) {
      url = url + '&prompt=login';
    }
    url && window.location.replace(url);
  }, []);

  useEffect(() => {
    if (query.get('openModal')) {
      setAccountModalVisible(true);
    }
  }, [query, setAccountModalVisible]);

  return (
    <div className={styles['auth-container']}>
      <div className={cx('auth-home-container')}>
        <LoginHeader />
        <div className={cx('auth-home-body')}>
          <div className={cx('auth-home-header')}>
            {t('auth.welcomeToCobo')}
          </div>
          <div className={cx('auth-home-unleashDefi')}>
            {t('auth.unleashDefi')}
          </div>
          <div className={cx('login')}>
            <Button
              type="primary"
              btnClassName={styles['auth-home-btn']}
              onClick={() => {
                onLogin();
              }}
            >
              {t('auth.loginWithSSO')}
            </Button>
            <div className="mt-6">
              <Button
                type="white"
                btnClassName={styles['auth-home-btn']}
                onClick={async () => {
                  const urlParams = await ssoSignUp();
                  const url = urlParams?.redirect_url;
                  url && window.location.replace(url + '&prompt=login');
                }}
              >
                {t('auth.signUpWithSSO')}
              </Button>
            </div>
            <div className="flex items-center mt-5 justify-center">
              <span className="text-[#8590A6] font-ub text-[14px] font-normal leading-5">
                {t('common.support')}:
              </span>
              <img
                src={require('@/assets/images/png-email2.png')}
                alt="email"
                className="ml-3 cursor-pointer w-5 h-5"
                onClick={() => {
                  onLogin();
                }}
              />
              <img
                src={require('@/assets/icons/png_metamask.png')}
                alt="metamask"
                className="ml-4 cursor-pointer w-5 h-5"
                onClick={() => {
                  onLogin();
                }}
              />
              {/* <img
                src={require('@/assets/images/png-google.png')}
                alt="google"
                className="ml-4 cursor-pointer w-5 h-5"
                onClick={onLogin}
              /> */}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['auth-footer']}>
        <Support />
      </div>
      <NotSupportAccountModal
        visible={accountModalVisible}
        handleGotoAccount={() => {
          const isSandbox = process.env.REACT_APP_ENV === 'sandbox';
          const url = isSandbox
            ? `https://accounts.sandbox.cobo.com/`
            : `https://accounts.cobo.com/`;
          window.open(url, '_blank');
        }}
        handleLogin={onLogin}
        handleClose={() => setAccountModalVisible(false)}
      />
    </div>
  );
}
