import classNames from 'classnames/bind';

import styles from './index.module.less';

interface Props {
  css?: object;
  width?: number;
  height?: number;
  // HARVESTER 1 WITHDRAWER 2 CUSTOMIZED_BOT 3
  type: number;
  scene?: 'default' | 'info' | 'portfolio';
  className?: string;
}

export function BotIcon({ css, width, height, type, scene, className }: Props) {
  const cx = classNames.bind(styles);
  if (!css) {
    css = {};
  }
  const defaultWidth = 32;
  const defaultHeight = 32;
  scene = scene || 'default';
  let icCls = '';
  switch (type) {
    case 1:
    case 4:
      icCls = 'ic-claim';
      break;
    case 2:
      icCls = 'ic-withdraw';
      break;
    case 3:
      icCls = 'ic-customized';
      break;
    case 6:
    case 7:
      icCls = 'ic-leverage';
      break;
    default:
      break;
  }
  if (!icCls) return <div />;
  switch (scene) {
    case 'info':
      icCls = `${icCls}-green`;
      break;
    case 'portfolio':
      icCls = `${icCls}-white`;
      break;
    default:
      break;
  }
  return (
    <div
      className={cx('bot-icon', icCls, className)}
      style={{
        width: `${width || defaultWidth}px`,
        height: `${height || defaultHeight}px`,
        minWidth: `${width || defaultWidth}px`,
        minHeight: `${height || defaultHeight}px`,
        backgroundSize: `${width || defaultWidth}px ${
          height || defaultHeight
        }px`,
        ...css,
      }}
    />
  );
}
