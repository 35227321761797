import { useCallback } from 'react';
import { IClientMeta } from '@walletconnect/legacy-types';
import { CoreTypes } from '@walletconnect/types';

import useWalletConnectV1 from './useWalletConnectV1';
import useWalletConnectV2 from './useWalletConnectV2';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type wcConnectType = (uri: string) => Promise<void>;
// eslint-disable-next-line @typescript-eslint/naming-convention
export type wcDisconnectType = () => Promise<void>;
export type MetadataType = CoreTypes.Metadata | IClientMeta | null;

// eslint-disable-next-line @typescript-eslint/naming-convention
export type useWalletConnectType = {
  wcConnect: wcConnectType;
  wcDisconnect: wcDisconnectType;
  wcClientData: CoreTypes.Metadata | undefined;
  isWalletConnectInitialized: boolean;
  version: undefined | 1 | 2;
};

const getWalletConnectVersion = (uri: string): string => {
  const encodedURI = encodeURI(uri);
  return encodedURI?.split('@')?.[1]?.[0];
};

const useWalletConnect = (
  chainId: number,
  safeAddress: string,
): useWalletConnectType => {
  // wallet-connect v1
  const {
    wcConnect: wcConnectV1,
    wcClientData: wcSessionV1,
    wcDisconnect: wcDisconnectV1,
  } = useWalletConnectV1(chainId, safeAddress);

  // wallet-connect v2
  const {
    wcConnect: wcConnectV2,
    wcClientData: wcSessionV2,
    wcDisconnect: wcDisconnectV2,
    isWalletConnectInitialized,
  } = useWalletConnectV2(chainId, safeAddress);

  const wcConnect = useCallback<wcConnectType>(
    async (uri: string) => {
      // walletconnect URI follows eip-1328 standard
      // see https://github.com/ethereum/EIPs/blob/master/EIPS/eip-1328.md
      const walletConnectVersion = getWalletConnectVersion(uri);
      const isWalletConnectV1 = walletConnectVersion === '1';

      // we need to keep both v1 & v2 versions, see https://docs.walletconnect.com/2.0/javascript/sign/wallet-usage#migrating-from-v1x
      if (isWalletConnectV1) {
        wcConnectV1({ uri });
      } else {
        wcConnectV2(uri);
      }
    },
    [wcConnectV1, wcConnectV2],
  );

  const wcDisconnect = useCallback<wcDisconnectType>(async () => {
    wcDisconnectV1();
    wcDisconnectV2();
  }, [wcDisconnectV1, wcDisconnectV2]);

  const wcClientData = wcSessionV1 || wcSessionV2;

  let version: undefined | 1 | 2;
  if (wcClientData) {
    version = wcSessionV1 ? 1 : 2;
  }

  return {
    version,
    wcConnect,
    wcClientData,
    wcDisconnect,
    isWalletConnectInitialized,
  };
};

export default useWalletConnect;
