import Web3 from 'web3';

import { coboApi } from '@/api/axios';
import { getCoboNameByShortName } from '@/chains/utils';

const contractAbiResp: {
  [key: string]: {
    contract_name: string;
    abi: any;
  };
} = {};

export const getContractAbi = async (
  chain_id: string,
  address: string,
  hideError?: boolean,
): Promise<{
  contract_name: string;
  abi: any;
}> => {
  const key = `${chain_id}-${address}`;
  if (contractAbiResp[key]) {
    return contractAbiResp[key];
  }
  if (!Web3.utils.isAddress(address))
    return {
      contract_name: '',
      abi: [],
    };
  const resp: {
    contract_name: string;
    abi: any;
  } = await coboApi.post(
    '/argus/contracts/get_contract_info/',
    {
      chain: getCoboNameByShortName(chain_id),
      address,
    },
    hideError ? { params: { hideError } } : {},
  );
  contractAbiResp[key] = resp;
  return resp;
};
