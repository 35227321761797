import { coboApi } from '@/api/axios';
import { OrganizationItem } from '@/interfaces/pages/organization';

export const listOrganizations = async (): Promise<OrganizationItem[]> => {
  return await coboApi.post('/argus/orgs/list_organizations/');
};

export const listInviteRecords = async (): Promise<OrganizationItem[]> => {
  return await coboApi.post('/argus/orgs/list_invite_records/');
};

export const preCreateOrganization = async (): Promise<{
  size_choice: string[];
  type_choice: string[];
}> => {
  return await coboApi.post('/argus/orgs/pre_create_organization/');
};

export const createOrganization = async (
  name: string,
  org_type: string,
  org_size: string,
): Promise<{
  id: string;
  name: string;
  display_id: string;
  size: string;
  type: string;
}> => {
  return await coboApi.post('/argus/orgs/create_organization/', {
    name,
    org_type,
    org_size,
  });
};

export const getOrganizationInfo = async (
  org_id: string,
): Promise<OrganizationItem> => {
  return await coboApi.post('/argus/orgs/get_org_info/', { org_id });
};

export const updateOrgInfo = async (
  org_id: string,
  name: string,
  org_size: string,
  org_type: string,
): Promise<void> => {
  await coboApi.post('/argus/orgs/update_org_info/', {
    org_id,
    name,
    org_size,
    org_type,
  });
};

export const switchOrg = async (org_id: string): Promise<void> => {
  await coboApi.post('/argus/orgs/switch_org/', {
    org_id,
  });
};
