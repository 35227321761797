export const safe = {
  member: 'Member',
  email: 'Email',
  acl: 'ACL',
  coboSafe: 'Cobo Safe',
  chooseSafePlaceholder: 'Please choose Safe{Wallet}',
  chooseModulePlaceholder: 'Please choose Module',
  disabled: 'Disabled',
  createRoleButton: '+ Create Role',
  goSafeAppTitle: 'Head to Safe{Wallet} DApp',
  goSafeAppText:
    'You’re about to leave Argus and head to the Safe{Wallet} DApp on a third-party website.',
  notInOrg: 'Not in org',
  onChainRole: 'On-Chain Role',
  memberTitle: 'Member',
  targetName: 'Target Name',
  targetContract: 'Target Contract',
  aclContract: 'ACL Contract',
  contracts: 'Contracts',
  editSafe: 'Edit Safe{Wallet}',
  address: 'Address',
  network: 'Network',
  username: 'Name',
  nickname: 'Nickname',
  memberEmail: 'Email',
  DelegatedButton: 'Delegate Addresses',
  replaceButton: 'Replace',
  installButton: 'Install',
  removeButton: 'Remove',
  revokeButton: 'Revoke',
  viewButton: 'View',
  editButton: 'Edit',
  thresholdMsg: 'Every transaction requires the confirmation of:',
  default: 'Default',
  switchToDefault: 'Switch to default',
  emptyRoleBtn: 'Create on-chain role',
  emptyRoleContent: 'It’s empty here. Go to',
  emptyDelegateBtn: 'Delegate role to member',
  emptyDelegateContent: 'It’s empty here. Go to',
  usernameNotChange: 'Name cannot be same as previous one',
  usernameNotEmpty: 'Name cannot be empty',
  switchDefaultModuleText: 'Switch default Cobo Safe',
  switchDefaultModuleContent: 'You’re switching the default Cobo Safe.',
  AclHint:
    'ACL is short for Access Control List, which is used for on-chain risk control in parameter granularity.',
  onChainRoleHint:
    'On-chain role is used to define the on-chain permission set for protocols and functions.',
  memberTitleHint:
    'If “--” is shown, it indicates that the owner of the delegated address is not a team member in your Argus organization. The address, which may be authorized by the Safe{Wallet} owner via other organization, remains effective.',
  targetRoleHint:
    'Target Contract represents the contract that authorized users are able to interact with.',
  delegatedMemberHint:
    'Delegated Members are externally owned account (EOA) addresses, which are usually generated by your wallet.',
  safeModuleTip:
    'Cobo Safe is the core component of Cobo Argus. It is a smart contract that supports role-based access control for on-chain activity.',
  enable: 'Enable',
  switchDefaultModuleSuccess:
    'You’ve switched the default Cobo Safe successfully.',
  noSafe: `No Safe{Wallet}`,
  importSafe: 'Import Safe{Wallet}',
  chooseSafe: 'Choose Safe{Wallet}',
  selected: `Selected`,
  changeSafeTip: `You have unsaved changes. Switching to a different Safe{Wallet} will cause your changes to be lost.`,
  changeOrgTip: `You have unsaved changes. Switching to a different organization will cause your changes to be lost.`,
  switchSafe: `Switch Safe{Wallet}`,
  switchOrg: `Switch Organization`,
  stayOnThisPage: `Stay on this page`,
  chooseOrg: `Choose Organization`,
};
