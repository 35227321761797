import React, { useEffect, useMemo, useState } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useAccount, useDisconnect } from 'wagmi';

import styles from './index.module.less';
import { Button } from '@/components';

const useWalletIcon = () => {
  const { connector } = useAccount();
  const walletIcon = useMemo(() => {
    switch (connector?.name) {
      case 'MetaMask':
        return styles['metamask'];
      case 'Rabby Wallet':
        return styles['rabby'];
    }
    return styles['metamask'];
  }, [connector?.name]);
  return {
    walletName: connector?.name || 'Injected',
    walletIcon,
  };
};

export function Wallet() {
  const { t } = useTranslation();
  const { disconnectAsync } = useDisconnect();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const cx = classNames.bind(styles);

  const { walletName, walletIcon } = useWalletIcon();

  useEffect(() => {
    return () => setDropdownVisible(false);
  }, []);
  const menuItems: MenuProps['items'] = [
    {
      key: 'disconnect',
      label: (
        <div
          onClick={async () => {
            await disconnectAsync();
            setDropdownVisible(false);
          }}
          key="Disconnect"
        >
          <div className={styles['wallet-disconnect']}>
            {t('walletconnect.disconnect')}
          </div>
        </div>
      ),
    },
  ];
  return (
    <ConnectButton.Custom>
      {({ account, chain, openConnectModal, mounted }) => {
        const connected = mounted && account && chain;
        let content;
        if (!connected) {
          content = (
            <Button
              type="primary"
              btnClassName={styles['wallet-connect-button']}
              onClick={async () => {
                openConnectModal();
                setDropdownVisible(false);
              }}
            >
              {t('walletconnect.connectWallet')}
            </Button>
          );
        } else {
          content = (
            <div
              className={styles['wallet-wrapper']}
              onClick={() => {
                setDropdownVisible(!dropdownVisible);
              }}
            >
              <div className={cx('wallet-icon', walletIcon)}>
                <div className={styles['active']} />
              </div>
              <div className={styles['wallet-content']}>
                <div className={styles['wallet-name']}>{walletName}</div>
                <div className={styles['wallet-address']}>
                  {account.displayName}
                </div>
              </div>
            </div>
          );
        }
        return (
          <div className={styles['wallet-container']}>
            <Dropdown
              open={dropdownVisible}
              onOpenChange={visible => {
                if (!connected) {
                  return;
                }
                setDropdownVisible(visible);
              }}
              menu={{ items: menuItems }}
              trigger={['click']}
              overlayClassName={styles['wallet-dropdown']}
            >
              {content}
            </Dropdown>
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}
