import React from 'react';

import { ModuleSelect } from '@/components';
import { Module } from '@/interfaces';

interface Props {
  name?: string;
  selectedModule: Module | null;
  modules: Module[] | null;
  disabled?: boolean;
  defaultModule: string;
  defaultInLabel?: boolean;
  defaultInOption?: boolean;
  truncate?: boolean;
  onChange: (module: Module) => void;
  optionFormat?: (module: Module) => string;
  nameStyle?: object;
  selectorStyle?: object;
  copyable?: boolean;
}

export function ModuleSelectorV2({
  name,
  selectedModule,
  modules,
  disabled,
  defaultModule,
  defaultInLabel,
  defaultInOption,
  truncate,
  onChange,
  optionFormat,
  nameStyle,
  selectorStyle,
  copyable,
}: Props) {
  return (
    <ModuleSelect
      name={name}
      selectedModule={selectedModule}
      modules={modules || null}
      disabled={disabled}
      defaultModule={defaultModule}
      defaultInLabel={defaultInLabel}
      defaultInOption={defaultInOption}
      truncate={truncate}
      onChange={m => {
        onChange(m);
      }}
      optionFormat={optionFormat ? m => optionFormat(m) : undefined}
      nameStyle={nameStyle}
      selectorStyle={selectorStyle}
      copyable={copyable}
    />
  );
}
