import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BotInfo, GasData } from '@/interfaces';

const initialState: {
  balanceData: GasData | null;
  currentBotInfo: BotInfo | null;
  selectedTriggers: string[];
  triggerRewardAmountToken: string;
  triggerRewardAmount: string;
  triggerTimeNumber: string;
  triggerTimeRange: number | null;
  triggerPoolWeightAddress: string;
  triggerPoolWeightOp: string;
  triggerPoolWeightNumber: string;
  rewardReceivingAddress: { [key: string]: string };
  withdrawTokens: string[];
  slippage?: string;
  inputSlippage: string;
  terminateButtonLoading: boolean;
} = {
  balanceData: null,
  currentBotInfo: null,
  selectedTriggers: [],
  triggerRewardAmountToken: '',
  triggerRewardAmount: '',
  triggerTimeNumber: '',
  triggerTimeRange: null,
  triggerPoolWeightAddress: '',
  triggerPoolWeightOp: '',
  triggerPoolWeightNumber: '',
  rewardReceivingAddress: {},
  slippage: undefined,
  inputSlippage: '',
  withdrawTokens: [],
  terminateButtonLoading: false,
};

const slice = createSlice({
  name: 'bots',
  initialState,
  reducers: {
    updateTerminateButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.terminateButtonLoading = action.payload;
    },
    updateCurrentBotInfo: (state, action: PayloadAction<BotInfo | null>) => {
      state.currentBotInfo = action.payload;
    },
    updateSelectedTriggers: (state, action: PayloadAction<string[]>) => {
      state.selectedTriggers = action.payload;
    },
    updateTriggerRewardAmountToken: (state, action: PayloadAction<string>) => {
      state.triggerRewardAmountToken = action.payload;
    },
    updateTriggerRewardAmount: (state, action: PayloadAction<string>) => {
      state.triggerRewardAmount = action.payload;
    },
    updateTriggerTimeNumber: (state, action: PayloadAction<string>) => {
      state.triggerTimeNumber = action.payload;
    },
    updateTriggerTimeRange: (state, action: PayloadAction<number | null>) => {
      state.triggerTimeRange = action.payload;
    },
    updateTriggerPoolWeightAddress: (state, action: PayloadAction<string>) => {
      state.triggerPoolWeightAddress = action.payload;
    },
    updateTriggerPoolWeightOp: (state, action: PayloadAction<string>) => {
      state.triggerPoolWeightOp = action.payload;
    },
    updateTriggerPoolWeightNumber: (state, action: PayloadAction<string>) => {
      state.triggerPoolWeightNumber = action.payload;
    },
    updateRewardReceivingAddress: (
      state,
      action: PayloadAction<{ [key: string]: string }>,
    ) => {
      state.rewardReceivingAddress = action.payload;
    },
    updateSlippage: (state, action: PayloadAction<string | undefined>) => {
      state.slippage = action.payload;
    },
    updateInputSlippage: (state, action: PayloadAction<string>) => {
      state.inputSlippage = action.payload;
    },
    updateWithdrawTokens: (state, action: PayloadAction<string[]>) => {
      state.withdrawTokens = action.payload;
    },
  },
});
export const botsReducer = slice.reducer;
export const updateCurrentBotInfo = slice.actions.updateCurrentBotInfo;
export const updateSelectedTriggers = slice.actions.updateSelectedTriggers;
export const updateTriggerRewardAmountToken =
  slice.actions.updateTriggerRewardAmountToken;
export const updateTriggerRewardAmount =
  slice.actions.updateTriggerRewardAmount;
export const updateTriggerTimeNumber = slice.actions.updateTriggerTimeNumber;
export const updateTriggerTimeRange = slice.actions.updateTriggerTimeRange;
export const updateTriggerPoolWeightAddress =
  slice.actions.updateTriggerPoolWeightAddress;
export const updateTriggerPoolWeightOp =
  slice.actions.updateTriggerPoolWeightOp;
export const updateTriggerPoolWeightNumber =
  slice.actions.updateTriggerPoolWeightNumber;
export const updateSlippage = slice.actions.updateSlippage;
export const updateInputSlippage = slice.actions.updateInputSlippage;
export const updateWithdrawTokens = slice.actions.updateWithdrawTokens;
export const updateBotRewardReceivingAddress =
  slice.actions.updateRewardReceivingAddress;
export const updateTerminateButtonLoading =
  slice.actions.updateTerminateButtonLoading;
