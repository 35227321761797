import { message } from 'antd';

import { coboApi } from '@/api/axios';
import { MeAddressInfo, MySafeRoleInfo } from '@/interfaces';
import { CoboStorage } from '@/utils/storage';
import { encodePassword } from '@/utils/utils';

export const sendRegisterVerificationCode = async (
  email: string,
): Promise<void> => {
  try {
    await coboApi.post('/argus/send_register_verification_code/', { email });
  } catch (e: any) {
    if (e?.response?.status === 429) {
      message.error(e?.response?.data?.error_message || e?.message);
    }
    throw e;
  }
};

export const register = async (
  email: string,
  password: string,
  verificationCode: string,
): Promise<void> => {
  password = encodePassword(password);
  await coboApi.post('/argus/register/', {
    email,
    password,
    verification_code: verificationCode,
  });
};

export const login = async (
  email: string,
  password: string,
): Promise<{
  session_code: string;
  otp_enabled: boolean;
}> => {
  password = encodePassword(password);
  const data = {
    email,
    password,
  };
  const resp: {
    session_code: string;
    otp_enabled: boolean;
  } = await coboApi.post('/argus/login/', data);
  CoboStorage.set('SESSION-CODE', resp.session_code);
  return resp;
};

export const logout = async () => {
  await coboApi.post('/argus/logout/');
  CoboStorage.remove('SESSION-CODE');
};

export const acceptTerms = async (): Promise<{
  result: string;
  success: boolean;
}> => {
  return await coboApi.post('/argus/users/accept_terms/');
};

export const getMyProfile = async (): Promise<{
  accept_terms: boolean;
  can_login_by_email: boolean;
  name: string;
  email: string;
  has_2fa: boolean;
  invite_record_count: number;
  org_count: number;
  is_v1_user: boolean;
  user_id: string | null;
  login_address: string;
  web3_login_addresses: string[];
}> => {
  return await coboApi.post('/argus/users/my_profile/');
};

export const updateMyProfile = async (nickname: string): Promise<void> => {
  await coboApi.post('/argus/users/update_my_profile/', { nickname });
};

export const sendAddEmailCode = async (email: string): Promise<void> => {
  try {
    await coboApi.post('/argus/users/send_add_email_code/', { email });
  } catch (e: any) {
    if (e?.response?.status === 429) {
      message.error(e?.response?.data?.error_message || e?.message);
    }
    throw e;
  }
};

export const addEmail = async (email: string, code: string): Promise<void> => {
  await coboApi.post('/argus/users/add_email/', { email, code });
};

export const sendResetPasswordCode = async (email: string): Promise<void> => {
  await coboApi.post('/argus/users/send_reset_password_code/', { email });
};

export const resetPassword = async (
  email: string,
  code: string,
  password: string,
  confirm_password: string,
): Promise<void> => {
  if (password === confirm_password) {
    password = encodePassword(password);
  } else {
    throw new Error('Password is different from Confirm Password');
  }
  await coboApi.post('/argus/users/reset_password/', {
    email,
    code,
    password,
  });
};

export const web3Login = async (
  message: {
    domain: string;
    address: string;
    statement: string | null;
    uri: string;
    version: string;
    chain_id: number;
    nonce: number;
    not_before: string | null;
    expiration_time: string | null;
    issued_at: string;
  },
  signature: string,
): Promise<{
  login_address: string;
  session_code: string;
  user_id: string;
}> => {
  const resp: { login_address: string; session_code: string; user_id: string } =
    await coboApi.post('/argus/web3_login/', { message, signature });
  CoboStorage.set(
    resp.user_id ? 'SESSION-CODE' : 'PREPARE-SESSION-CODE',
    resp.session_code,
  );
  return resp;
};

export const createWeb3Account = async (
  user_name: string,
): Promise<{ id: string }> => {
  return await coboApi.post('/argus/create_web3_account/', { user_name });
};

export const bindToWeb2Account = async (
  email: string,
  password: string,
): Promise<void> => {
  await coboApi.post('/argus/bind_to_web2_account/', { email, password });
};

export const bindToWeb3Account = async (
  message: {
    domain: string;
    address: string;
    statement: string | null;
    uri: string;
    version: string;
    chain_id: number;
    nonce: number;
    not_before: string | null;
    expiration_time: string | null;
    issued_at: string;
  },
  signature: string,
): Promise<void> => {
  await coboApi.post('/argus/bind_to_web3_account/', { message, signature });
};

export const addWeb3LoginAddress = async (
  message: {
    domain: string;
    address: string;
    statement: string | null;
    uri: string;
    version: string;
    chain_id: number;
    nonce: number;
    not_before: string | null;
    expiration_time: string | null;
    issued_at: string;
  },
  signature: string,
): Promise<void> => {
  await coboApi.post(
    '/argus/add_web3_login_address/',
    { message, signature },
    {
      params: { hideError: true },
    },
  );
};
export const getMyDelegateAddress = async (
  safe_id: string,
): Promise<MeAddressInfo[]> => {
  return await coboApi.post('/argus/users/my_delegate_address/', {
    safe_id,
  });
};

export const editMyAddress = async (
  address_id: string,
  address?: string,
  label?: string,
  enabled?: boolean,
): Promise<void> => {
  await coboApi.post('/argus/users/edit_my_address/', {
    address_id,
    address,
    enabled,
    label,
  });
};

export const addDelegateAddress = async (
  address: string,
  label?: string,
): Promise<void> => {
  await coboApi.post('/argus/users/add_delegate_address/', {
    address,
    label,
  });
};

export const getMySafeRoleForOrg = async (
  org_id: string,
): Promise<MySafeRoleInfo[]> => {
  return await coboApi.post('/argus/users/my_safe_role_for_org/', {
    org_id,
  });
};

export const getMyOrgRole = async (org_id: string): Promise<any> => {
  return await coboApi.post('/argus/users/my_org_role/', {
    org_id,
  });
};

export const ssoLogin = async (): Promise<{
  redirect_url: string;
}> => {
  return await coboApi.post('/argus/oauth/login/');
};

export const ssoSignUp = async (): Promise<{
  redirect_url: string;
}> => {
  return await coboApi.post('/argus/oauth/signup/');
};

export const ssoLogout = async (): Promise<{
  redirect_url: string;
}> => {
  return await coboApi.post('/argus/oauth/logout/');
};

export const ssoCallback = async (
  code: string,
  state: string,
): Promise<{
  session_code: string;
  user_id: string;
  login_address?: string;
}> => {
  return await coboApi.get(
    `/argus/oauth/callback/?code=${code}&state=${state}`,
  );
};

export const ssoDisableOtp = async (
  otp_code?: string,
): Promise<{
  link: string;
}> => {
  return await coboApi.post('/argus/oauth/disable_otp/', {
    otp_code,
  });
};

export const ssoEnableOtp = async (
  otp_code?: string,
): Promise<{
  link: string;
}> => {
  return await coboApi.post('/argus/oauth/enable_otp/', {
    otp_code,
  });
};
