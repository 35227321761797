import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { useAtomValue } from 'jotai';
import { groupBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { getGenericBotsSummary } from '@/api';
import { getShortNameByCoboName } from '@/chains/utils';
import { ChainIcon, CustomizeBotItem } from '@/components';
import { NameLogo } from '@/components/Layout/NameLogo';
import { orgIdAtom } from '@/globalAtom';
import {
  AvailableGeneralBotItem,
  AvailableGeneralBotsListItem,
  ChainShortNameType,
} from '@/interfaces';
import { customBotsAtom } from '@/routes/MyPortfolio/atoms';
import { truncateAddress } from '@/utils/utils';

interface Props {
  canEdit: boolean;
  onRefresh: () => Promise<AvailableGeneralBotsListItem[] | undefined>;
  onViewDetail: (
    instance: AvailableGeneralBotsListItem,
    executeRecord: boolean,
  ) => void;
}

export function CustomizeBotsList({ canEdit, onViewDetail, onRefresh }: Props) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const bots: AvailableGeneralBotsListItem[] = useAtomValue(customBotsAtom);
  const customBots = useMemo(() => {
    return bots.filter(bot => bot.bot_type === 3);
  }, [bots]);

  const orgId = useAtomValue(orgIdAtom);

  const [data, setData] = useState<AvailableGeneralBotItem[]>();

  const createBotData = useMemo(() => {
    if (!data) return;
    const customizedBots = data.filter(bot => bot.bot_type === 3);
    if (customizedBots.length > 0) return customizedBots[0];
    return undefined;
  }, [data]);

  useEffect(() => {
    const load = async () => {
      if (!orgId) return;
      const res = await getGenericBotsSummary(orgId, false);
      setData(res.bots);
    };
    if (data !== undefined) return;
    load();
  }, [data, orgId]);

  const EmptyEle = useMemo(() => {
    return (
      <div className={cx('auth-empty')}>
        <img
          alt="empty"
          style={{ width: '160px' }}
          src={require('@/assets/images/empty_bot.png')}
        />
        <div className={cx('empty-text')}>
          <p>
            {t('common.emptyTable')}
            {createBotData ? ` ${t('common.goto')} ` : ''}
            {createBotData ? (
              <a
                className="text-blue"
                href={`${window.location.origin}/#/farming-detail/${createBotData.bot_definition_id}`}
                target="_blank"
                rel="noreferrer"
              >
                {t('bots.createCustomizeBot')}
              </a>
            ) : null}
          </p>
        </div>
      </div>
    );
  }, [createBotData, cx, t]);

  const groupedBots = useMemo(() => {
    const groupedBySafeBots = groupBy(customBots, (bot: any) => {
      return `${bot.safe_address}+${bot.chain}`;
    });
    let result: any[] = [];
    for (const safe_address in groupedBySafeBots) {
      if (
        Object.prototype.hasOwnProperty.call(groupedBySafeBots, safe_address)
      ) {
        const safes = groupedBySafeBots[safe_address];
        const safeName = safes[0].safe_name;
        result.push({
          safeName,
          chian: safes[0].chain,
          safe_address: safes[0].safe_address,
          safesGroup: safes,
        });
      }
    }
    return result;
  }, [customBots]);

  return (
    <div className={cx('bot-list')}>
      {customBots.length === 0
        ? EmptyEle
        : groupedBots.map(
            (group: {
              safeName: string;
              chian: string;
              safe_address: string;
              safesGroup: AvailableGeneralBotsListItem[];
            }) => {
              return (
                <div
                  className={cx('strategy-card')}
                  key={group.safe_address + group.chian}
                >
                  <div className={cx('card-header')}>
                    <div className={cx('group-title-chain-icon')}>
                      <ChainIcon
                        shortName={
                          getShortNameByCoboName(
                            group.chian,
                          ) as ChainShortNameType
                        }
                        isRadiusIcon
                        chainName={group.chian}
                        className={cx('group-title-chain-icon')}
                      />
                    </div>
                    <div className={cx('card-header-content')}>
                      <div className={styles['name-logo']}>
                        <NameLogo label={group.safeName || ''} radius={'6px'} />
                      </div>
                      {group.safeName}:&nbsp;
                      {truncateAddress(group.safe_address)}
                    </div>
                  </div>
                  <div className={cx('card-body')}>
                    {group.safesGroup.map(
                      (bot: AvailableGeneralBotsListItem) => {
                        return (
                          <CustomizeBotItem
                            canEdit={canEdit}
                            onRefresh={onRefresh}
                            bot={bot}
                            key={bot.bot_instance_id}
                            onViewDetail={executeRecord =>
                              onViewDetail(bot, executeRecord || false)
                            }
                          />
                        );
                      },
                    )}
                  </div>
                </div>
              );
            },
          )}
    </div>
  );
}
