import { useMemo } from 'react';
import { Pagination as BasePagination } from 'antd';
import { PaginationProps } from 'antd/lib/pagination/Pagination';
import classNames from 'classnames/bind';

import styles from './index.module.less';

interface Props extends PaginationProps {
  itemRender?: (
    page: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    element: React.ReactNode,
  ) => React.ReactNode;
  current: number;
  total: number;
  pageSize: number;
}

export function Pagination(props: Props) {
  const cx = classNames.bind(styles);
  const { current, total, pageSize } = props;
  const customizedItemRender = useMemo(() => {
    return (
      page: number,
      type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
      element: React.ReactNode,
    ): React.ReactNode => {
      let ele: React.ReactNode;
      switch (type) {
        case 'prev':
          ele = (
            <div className={cx('prev-btn', current === 1 ? 'disabled' : '')} />
          );
          break;
        case 'next':
          ele = (
            <div
              className={cx(
                'next-btn',
                current * pageSize >= total ? 'disabled' : '',
              )}
            />
          );
          break;
        case 'page':
          return element;
        case 'jump-next':
        case 'jump-prev':
          ele = <div className={cx('jump')}>...</div>;
          break;
        default:
          ele = element;
      }
      return <div className={cx('pag-item')}>{ele}</div>;
    };
  }, [current, cx, pageSize, total]);
  const itemRender = props.itemRender || customizedItemRender;
  return (
    <BasePagination
      className={cx('pag-wrapper')}
      {...props}
      itemRender={itemRender}
    />
  );
}
