import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SettingItem } from '../components/SettingItem';
import styles from './index.module.less';
import { useGlobalOrgs, useGlobalSafe } from '@/hooks';
import { getGnosisUrl } from '@/sdk/safe';

export function Threshold() {
  const { t } = useTranslation();
  const { gnosisSafeInfo, safeInfo } = useGlobalSafe();
  const { canEdit } = useGlobalOrgs();

  const policyUrl = useMemo(() => {
    if (!safeInfo || !safeInfo.chain_id) return '';
    return getGnosisUrl(safeInfo.chain_id, safeInfo.address, '/settings/setup');
  }, [safeInfo]);

  const ownerList = useMemo(() => {
    return gnosisSafeInfo?.owners.map(item => {
      return {
        address: item,
      };
    });
  }, [gnosisSafeInfo]);

  return (
    <>
      <SettingItem
        contentClassName={styles['threshold']}
        title={'Threshold'}
        buttons={
          canEdit
            ? [
                {
                  name: t('safe.editButton'),
                  url: policyUrl,
                  urlType: 'normal',
                  goSafeApp: true,
                },
              ]
            : []
        }
      >
        <div className={styles['settings-threshold-content']}>
          {t('safe.thresholdMsg')}
          <span style={{ fontWeight: 'bold', color: '#000', marginLeft: 4 }}>
            {gnosisSafeInfo?.threshold || 0} of {ownerList?.length || 0}
          </span>
        </div>
      </SettingItem>
    </>
  );
}
