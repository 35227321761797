import { configureStore } from '@reduxjs/toolkit';

import {
  authorizationReducer,
  botsReducer,
  genericBotsReducer,
  globalReducer,
  orgPageReducer,
  portfolioReducer,
  rolePageReducer,
  safeAppReducer,
  safeDetailReducer,
  safeReducer,
  strategiesReducer,
  strategyDetailReducer,
} from './slice';

export * from './slice';

const store = configureStore({
  reducer: {
    bots: botsReducer,
    genericBots: genericBotsReducer,
    global: globalReducer,
    safe: safeReducer,
    orgPage: orgPageReducer,
    rolePage: rolePageReducer,
    safeDetail: safeDetailReducer,
    safeApp: safeAppReducer,
    strategies: strategiesReducer,
    strategyDetail: strategyDetailReducer,
    authorization: authorizationReducer,
    portfolio: portfolioReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
