const zhHans = {
  common: {
    ok: '好的',
    cancel: '取消',
    confirm: '确认',
    view: '查看',
    next: '下一步',
    continue: '继续',
    prev: '上一步',
    edit: '修改',
    delete: '删除',
    login: '登录',
    logout: '登出',
    copy: '复制',
    copySuccess: '已复制到剪切板',
    copyFailed: '复制失败。',
    paste: '粘贴',
    submit: '提交',
    congratulations: '恭喜！',
    finish: '完成',
    accept: '接受',
    slogan: 'Powering your crypto ambitions.',
    en: 'EN',
    cn: 'CN',
    back: '返回',
    unnamed: '未设置',
    goForward: '下一步',
    support: 'Support',
    guide: 'Guides',
    contactUsAt: 'Contact us at ',
    helpText: 'If you need any help, subject line: Cobo Argus',
    yes: 'Yes',
    no: 'No',
    previousStep: 'Previous',
    emptyTable: '暂无数据',
    network: 'Network',
    expand: 'Expand',
    collapse: 'Collapse',
    optional: 'Optional',
  },
  bots: {
    noSafeHint: `To create customized bots, please import Safe{Wallet} first.`,
    noModuleHint: `To create customized bots, please import Cobo Safes first.`,
    noEnabledHint: `To create customized bots, please enable Cobo Safes first.`,
    type: 'Type',
    customized: 'Customized',
    claim: 'Claim',
    myBots: 'Bots',
    viewAll: 'View All',
    generalBots: 'General Bots',
    customizedBots: 'Customized Bots',
    gasAccountTooltip:
      'Gas fees will be incurred when the strategy bot executes a task. Please top up your Gas Account first.',
    authorizationDetail: 'Authorization Detail',
    gnosisSafe: 'Safe{Wallet}',
    depositGas: 'Deposit {{token}} Gas',
    gasAccount: 'Gas Accounts',
    action: 'Action',
    deposit: 'Deposit',
    balance: 'Balance',
    strategyBots: 'Strategy Bots',
    investmentDetail: 'Investment Detail',
    strategyBotsList: 'Strategy Bots List',
    setUp: 'Set Up',
    view: 'View',
    running: 'Running',
    paused: 'Paused',
    lowGasBtn: 'top up',
    lowGasText1: 'We recommend that you ',
    lowGasText2: ' at least ',
    lowGasText3: ' first. ',
    lowGasText4:
      'The bot may fail if the balance in your Gas Account is insufficient. It will stop running if your balance is zero.',
    time: 'Updated Time',
    times: 'Times',
    total: 'Total',
    success: 'Success',
    failure: 'Failure',
    pause: 'Pause',
    resume: 'Resume',
    confirmPauseTitle: 'Do you want to pause the strategy bot?',
    confirmPauseContent:
      'The bot will no longer automatically execute the assigned task.',
    confirmResumeTitle: 'Do you want to resume the strategy bot?',
    confirmResumeContent:
      'The strategy bot will start to automatically execute the assigned task. Please note that gas fees will be incurred.',
    note: 'Note:',
    emptyText1: `You don't have any strategy bot yet. `,
    emptyText2: 'You can head to the ',
    emptyBtn: 'Strategy Marketplace',
    emptyText3: ' to make an investment first.',
    coboSafe: 'Cobo Safe',
    bot: 'Bot',
    needsConfirmationTitle: `Multisig Confirmation Required`,
    needsConfirmationContent1: `A multi-sig confirmation is required to terminate the bot.`,
    needsConfirmationContent2: `Please inform Safe owners to complete the transaction on `,
  },
  investmentDetail: {
    safe: 'Safe',
    depositedAmount: 'Deposited Amount',
    unclaimedRewards: 'Unclaimed Rewards',
    depositedAmountTooltip: 'The token amount deposited into the strategy.',
    unclaimedRewardsTooltip: 'The token amount of unclaimed rewards.',
    status: 'Status',
    type: 'Type',
    time: 'Time',
    operator: 'Operator',
  },
  authorization: {
    createSubSafeTitle: 'Create SubSafe',
    delegateAccessTitle: 'Delegate Access',
    editAuthorizationTitle: 'Edit Authorization',
    tokenPermissionTitle: 'Set Token Permission',
    tokenReceiverTitle: 'Token Permission',
    previewAndSubmitTitle: 'Preview & Submit',
    multiSignTitle: 'Multi-sign',
    finishedTitle: 'Finished',
    subSafe: 'SubSafe',
    subSafeHint: 'What is SubSafe?',
    step1Title: 'Delegate Access',
    step2Title: 'Set Token Permission',
    step3Title: 'Preview & Submit',
    step4Title: 'Multi-sign',
    step5Title: 'Finished',
    abortText:
      'You’re authorizing permissions to team members. Are you sure that you want to cancel?',
    tokenApproval: 'Token Approval',
    tokenTransferWhitelist: 'Set Whitelist Address',
    tokenReceiver: 'Rewards Receiving Address',
    transferWhitelistName: 'Set Whitelist Address',
    tokenApprovalTip:
      'The types and amounts of token in your Safe{Wallet} that the protocol can use when executing a strategy.',
    spender: 'Spender',
    allowance: 'Allowance',
    tokenTransferWhitelistText:
      "You can add an address to the Transfer Whitelist. Team members who have been assigned the role of claimer can send the token rewards they've claimed from Cobo Safe to this whitelisted address with a single-sig transaction.",
    tokenReceiverHint:
      'All claimed rewards will be automatically transferred to this address.',
    tokenReceiverHintWithEditing:
      'All claimed rewards will be automatically transferred to this address.',
    checkAddressText: 'Check existing address(es) on the ',
    checkAddress: 'Token Whitelist.',
    addAddressText: 'Need to add a new address? ',
    addAddress: 'Add it now.',
    addAddressTitle: 'Add address',
    reminder: 'Reminder',
    coboSafe: 'Cobo Safe',
    coboModule: 'Cobo Safe',
    safeModule: 'Cobo Safe',
    roleAndMember: 'Strategy Roles and Members',
    roleAndMemberTip:
      'Strategy roles refer to the set of functions or permissions related to the corresponding strategy. When a member is authorized with a strategy role, he or she can initiate and sign transactions via a single-sign process instead of a multi-sign process.',
    roleAndMemberWithSubSafeTip:
      'You can authorize roles to your team members. A delegated member can execute his assigned roles independently without multi-sig. ',
    roleAndMemberText:
      'Select members that need to be authorized with strategy roles',
    roleAndMemberWarning:
      'Modifications to member settings will be applied to all strategies under the selected Safe. ',
    depositor: 'Depositor',
    withdrawer: 'Withdrawer',
    claimer: 'Claimer',
    depositorDesc:
      'The Depositor role gives user the permission of depositing tokens into the strategy.',
    withdrawerDesc:
      'The Withdrawer role gives user the permission of withdrawing tokens from the strategy.',
    claimerDesc:
      'The Claimer role gives user the permission of claiming reward tokens from the strategy.',
    confirmTitle: 'Multi-Sign Confirmation Required',
    confirmContent: 'Your request has been submitted.',
    confirmText1:
      'Please inform all Safe owners to complete the multi-sign on ',
    checkTransactionBtn: 'Check the transaction',
    backToStrategy: 'Back to the strategy',
    safeBtn: 'Safe{Wallet}',
    confirmText2: ' to complete the multi-sign process. ',
    step4Btn: 'Transaction in progress...',
    step4BtnWithsubsafe: 'Check the transaction',
    successText: 'Your Cobo SubCobo Safe has been successfully activated. ',
    failTitle: 'Transaction Failed',
    failText: 'Oops, your transaction has failed. ',
    goBack: 'Retry',
    checkBtn: 'Check Transaction',
    advanced: 'Advanced',
    targetContract: 'Target Contract',
    function: 'Function',
    earnTip: `The types of rewards you'll earn after depositing token(s) into the strategy.`,
    chooseMember: 'Choose Member',
    switchToSafeOwner: 'Switch to Safe Owner Address',
    switchToSafeOwnerText: "Please connect with Safe Owner's Address",
    subSafeDesc1: `SubSafe is created and owned by your Safe{Wallet}. It is a smart contract and acts as an independent channel to isolate the funds you've invested into this strategy. In the event that the DeFi protocol(s) are compromised, your funds in Gnosis Safe will not be affected. Please make a transfer from Gnosis Safe to SubSafe before depositing into this strategy. `,
    subSafeDesc2: `When you make a deposit into this strategy, your tokens will be deposited via SubSafe. This process also applies when you withdraw tokens or claim rewards. `,
    transferOperatorName: 'Transfer Operator',
    transferOperatorDesc:
      'The role of a "transfer operator" indicates that the individual is eligible to access the associated target contract. The claimer must be delegated the role of a "transfer operator" in order to claim rewards from the Transfer Whitelist.',
    tokenApprovalTableTooltip: `This smart contract is associated with Cobo Safe. It is used to set a whitelist address for token transfer.`,
  },
  portfolio: {
    safe: 'Safe',
    strategy: 'Strategy',
    noSafe: 'Import Cobo Safe',
    noModule: 'Create Cobo Safe',
    noEnabled: 'Enable Cobo Safe',
    noModuleModalText:
      'Please select a Safe and then follow the path to create a Cobo Safe:',
    noEnabledModalText:
      'Please select the Safe and then follow the path to enable the Cobo Safe:',
    path: '-Apps-Cobo Safe',
    withdraw: 'Withdraw',
    withdrawAll: 'Withdraw All',
    claimAll: 'Claim All',
    withdrawAndClaim: 'Claim & Withdraw All',
    deposits: 'Deposits',
    rewards: 'Rewards',
    deposit: 'Deposit',
    all: 'All',
    holding: 'Holding',
    connectWallet: 'Connect Wallet',
    deposited: 'Deposited',
    unClaimedRewards: 'Unclaimed Rewards',
    unClaimed: 'Unclaimed',
    totalValue: 'Total Value',
    comingSoon: 'Coming Soon',
    strategyDetail: 'Strategy Detail',
    investmentDetail: 'Investment Detail',
    position: 'Position',
    transactions: 'Transactions',
    pleaseConnectWallet1: 'Please',
    pleaseConnectWallet2: 'Connect Wallet',
    pleaseConnectWallet3: 'first.',
    pleaseConnectWallet: 'Please connect your wallet.',
    walletLoadingTitle: 'Waiting wallet connection',
    upgradeSafeTitle: 'Upgrade Cobo Safe',
    upgradeSafeText1:
      'Please upgrade your Cobo Safe to version {{version}} or above in order to activate this strategy. ',
    upgradeSafeText3: 'Your current Cobo Safe version is too low.Please ',
    upgradeSafeBtn: 'Upgrade to the latest version.',
    switchAddress: 'Switch Address',
    switchAddressText1: `Sorry, only Safe Owners or Delegated Members can continue operations. Your current address doesn’t have the required permission to continue.      `,
    switchAddressText2: `You can take either action below:`,
    switchAddressTextMethods1: `1. Switch your wallet address;`,
    switchAddressTextMethods2: `2. Switch the Safe;`,
    switchAddressTextMethods3: `3. Contact your Admin to authorize;`,
    switchAddressTextMethods4: `4. Switch the Organization.`,
    transactionSubmitted: 'Transaction Submitted',
    congratulations: 'Congratulations!',
    submitText: 'Your transaction has been submitted.',
  },
  investment: {
    title: 'Investment',
    strategy: 'Strategy',
    myPortfolio: 'My Strategies',
    myAuthorization: 'My Authorization',
    myAuthorizations: 'My Authorizations',
    myInvestment: 'My Investments',
    myPortfolioBoardTitle: 'My Strategies',
    myPortfolioBoardButton: 'View >',
    strategyMartTitle: 'Strategy Mart',
    importText:
      'Please import Cobo safe on the corresponding network before proceeding.',
    myPortfolioBoard: {
      totalValue: 'Total Value',
      deposited: 'Deposited',
      unclaimedRewards: 'Unclaimed Rewards',
      avgApy: 'Avg. APY',
    },
    strategyMart: {
      allStrategies: 'All Strategies',
      newStrategies: 'New Strategies',
      strategyName: 'Strategy Name',
      token: 'Token',
      tokenTip: 'Supported tokens can be deposited into strategy.',
      apy: 'APY',
      apyTip: 'Annual Percentage Yield of strategy.',
      tvl: 'TVL',
      tvlTip: 'Total Locked Value in the strategy.',
      protocol: 'Protocol',
      loadMore: 'Load More',
      network: 'Network',
    },
    strategyDetail: {
      invested: 'Total Invested',
      network: 'Network:',
      token: 'Token:',
      curvePool: 'Curve Pool',
      earn: 'Earn',
      description: 'Description',
      introduction: 'Introduction',
      strategy: 'Strategy',
      investmentConsole: 'Investment Console',
      safe: 'Safe',
      safeModule: 'Cobo Safe',
      authorization: 'Authorization',
      authorizationTip:
        'Authorization means delegating investment permissions (i.e. deposit and withdraw tokens) to team members. This feature ensures higher efficiency as selected team members can initiate transactions without undergoing the multi-sign process in Safe{Wallet}.',
      noAuthorizationText:
        'You can delegate DeFi permissions (e.g. deposit and withdraw) to a specific role and then assign the role to a member. ',
      authorize: 'Authorize',
      tradeInCoboSafeApp: 'Head to Safe{Wallet} DApp',
      me: 'Me',
      viewAll: 'View All',
      AuthorizedRoles: 'Authorized Roles',
      hasNoPermTitle: 'No Authorization',
      hasNoPermText: 'You have no permission to authorize',
      deposit: {
        title: 'Deposit',
        maxBtn: 'MAX',
        balance: 'Balance',
        activateBtn: 'Activate',
        depositBtn: 'Deposit',
      },
    },
  },
  userInfo: {
    account: 'Account',
    organization: 'Organization',
    logout: 'Logout',
  },
  safeManagement: {
    title: 'Cobo Safe',
    addBtn: '导入 Safe',
    version: '版本',
    path: '所有 Safes',
    copy: '复制成功',
    removeModal: {
      title: '移除 Cobo Safe',
      tips: '确定要移除下列Safe吗？',
      cancelBtn: '取消',
      okBtn: '确定',
    },
    emptyTips: {
      text1: '暂无数据',
      text2: '点击这里，',
      links: '导入Cobo Safe',
    },
    toolTips: {
      copy: 'Copy to Clipboard',
      es: 'View on Blockchain Explorer',
      gs: 'View on Safe{Wallet}',
      remove: 'Remove Safe',
      topBanner:
        'Cobo Safe 是基于链上多重签名权限控制的智能钱包，例如：Safe{Wallet}',
      address: 'Safe{Wallet} 地址',
      asset: '总资产 = Safe{Wallet} 总余额 + Defi protocol 总资产',
      safeModule:
        'Cobo Safe 是支持基于角色的链上活动访问控制的智能合约，是 Cobo Safe 的核心组件。',
    },
    bind: {
      succ: '导入成功',
      fail: '导入失败',
    },
    importModal: {
      title: '导入 Cobo Safe',
      exit: {
        title: '提醒',
        text: '当前正在导入新的 Cobo Safe. 确定退出并停止导入吗？',
      },
      step0: {
        chain: 'Network',
        emptyChainError: '请选择 chain',
        name: '名称',
        namePlaceholder: '请输入名称',
        emptyNameError: '请输入 Safe 名称',
        gnosisSafeAddress: 'Safe{Wallet} 地址',
        emptyAddressError: '请输入您的 Safe{Wallet} 地址',
        addressPlaceholder: '请输入您的 Safe{Wallet} 地址',
        invalidAddressError: '非法的 Safe{Wallet} 地址',
        tips: '提示： 只有创建者才能导入Cobo Safe。',
        backBtn: '返回',
        continueBtn: '继续',
      },
      step1: {
        title:
          '所有启用的 Cobo Safes 将会被导入进 Argus。 选择默认的 Cobo Safe 版本进行展示:',
        roles: 'Roles',
        delegates: 'Delegates',
        contracts: 'Target Contracts',
        acl: 'Acl',
        backBtn: '返回',
        continueBtn: '继续',
        createModule: {
          title: '没有发现 Cobo Safe！请先创建 Cobo Safe。',
          note: '注意: Cobo Safe 目前只能在 Safe App 创建。',
          backBtn: '返回',
          continueBtn: '创建 Cobo Safe',
        },
        enableModule: {
          title: '没有发现启用的 Cobo Safe！请先启用 Cobo Safe。',
          note: '注意: Cobo Safe 目前只能在 Safe App 创建。',
          backBtn: '返回',
          continueBtn: '启用 Cobo Safe',
        },
      },
      step2: {
        transaction: 'Any transaction requires the confrmation of:',
        tips: 'Make sure you have installed Metamask before proceeding.',
        address: 'Safe Owner 地址',
        backBtn: '返回',
        continueBtn: '验证并导入',
      },
    },
  },
  safeInfo: {
    spender: 'Spender',
    all: 'All',
    allSafe: 'All Safes',
    safeModule: 'Cobo Safe',
    coboModule: 'Cobo Safe',
    permissions: 'Permissions',
    delegatedMembers: 'Delegated Members',
    delegatedMembersHint:
      'Delegated Members are externally owned account (EOA) addresses, which are usually generated by your wallet.',
    settings: 'Settings',
    tokenApproval: 'Token Approval',
    batchRevokeButton: 'Batch Revoke',
    token: 'Token',
    tokenExposure: 'Token Exposure',
    approvedSpender: 'Approved Spender',
    allowance: 'Allowance',
    revoke: 'Revoke',
    update: 'Update',
    revokeHint:
      'This Token Approval has been applied into the Argus strategy. Revoke this approval may cause your strategy failure.',
    needsConfirmationTitle: 'Multi-Sig Confirmation Required',
    needsConfirmationContent1: 'Your transaction has been submitted.',
    needsConfirmationContent2:
      'Please inform Safe Owner(s) to complete a multi-sig transaction on ',
    gnosisSafe: 'Safe{Wallet}',
    needsConfirmationContent3: ' platform to complete the Multi-Sign process.',
    backToTheStrategy: 'Back',
    checkTransaction: 'Check the transaction',
    batchRevoke: 'Batch Revoke',
    selectTokens: 'Select token(s) need to be revoked below:',
    batchRevokeHint: 'The approval for the token{{s}} below will be revoked. ',
    batchRevokeHint2:
      'Please note that some strategies may not work properly thereafter.',
    unlimited: 'Unlimited',
    customized: 'Customized',
    allowancePlaceholder: 'Amount > 0',
  },
  teamManagement: {
    title: 'Team Management',
    inviteBtn: '邀请',
    tab1: '成员',
    tab2: '角色',
    roleAdmin: '管理员',
    roleOperator: '操作员',
    roleViewer: '观察员',
    errorTips: {
      email: '请输入有效的Email地址！',
    },
    successTips: {
      invite: '邀请已经发送',
      edit: '修改成功',
      remove: '移出用户成功',
    },

    emptyTips: {
      text1: '暂无数据',
      text2: '点击这里，',
      links: '邀请你的成员',
    },
    table: {
      username: '用户名',
      email: '邮件地址',
      role: '角色',
      address: '地址',
      status: '状态',
      update: '更新时间',
      action: '操作',
      name: '名称',
      description: '描述',
      auctionEdit: '修改',
      auctionRemove: '移除',
      auctionReInvite: '重新邀请',
      statusWait: '邀请中',
      statusExpired: '已过期',
      statusAccept: '已加入',
      statusReject: '已拒绝',
    },
    role: {
      admin: {
        name: '管理员',
        title: '组织拥有者',
        description:
          '管理团队，管理 Safe{Wallet} 和 Cobo Safe，购买/取消/升级付款计划，资产仪表板和报告，增值 defi 工具的访问',
      },
      operator: {
        name: '操作员',
        title: '组织成员',
        description:
          '管理 Safe{Wallet} 和 Cobo Safe、资产仪表板和报告、访问增值的 defi 工具',
      },
      viewer: {
        name: '观察者',
        title: '组织成员',
        description: '仅查看相关服务和数据',
      },
    },
    modalInvite: {
      title: '邀请成员',
      email: '邮件地址',
      role: '角色',
      button: '发送邀请',
    },
    modalEdit: {
      title: '编辑成员',
      email: '邮件地址',
      role: '角色',
      address: '地址',
      maxAddressCount: '最多添加50个地址',
      addressExists: '地址已经存在，请重新输入',
      button: '提交',
      authorized: 'Authorized',
      authorizedHint:
        'Unlink the authorized account will not revoke the authorization. Go to Cobo Safe to finish the operation.',
    },
    modalRemove: {
      title: '移出成员',
      tips: 'Remove action will delete the member (include role and linked address) from your organization. This can’t be undone. Are you sure you want to remove this member from your organization?',
      remind:
        'We have detected the address(es) linked by the member has been authorized in the Cobo Safe. Revoking the authorization needs to be done in the menu of <color>Safe-Delegated Members</color> separately.',
      okButton: '确定',
      cancelButton: '取消',
    },
  },
  orgSettings: {
    title: 'Settings',
    saveChange: 'Save Change',
    editConfirmTitle: 'Edit organization profile',
    editConfirmContent: 'Are you sure you want to save the change(s)?',
    updateSuccess:
      'Congratulations! The change(s) of organization profile has been saved!',
    profile: 'Profile',
    name: 'Name',
    size: 'Size',
    type: 'Type',
  },
  account: {
    title: 'Account',
    updateSuccess: 'Edit successfully! Your profile is updated.',
    saveChange: 'Save Change',
    basicInfo: 'Basic Information',
    username: 'Username',
    emailAddress: 'Email address',
    twoFa: '2FA',
    googleAuthenticator: 'Google Authenticator',
    enabled: 'Enabled',
    addAddressBtn: 'Add Address',
    tabs: {
      profile: 'Profile',
      security: 'Security',
      address: 'Address',
    },
    addressTable: {
      col: {
        address: 'Address',
        label: 'Label',
        link: 'Linked Organization',
        update: 'Updated Time',
        action: 'Action',
        actionBtn: {
          edit: 'Edit',
          delete: 'Delete',
        },
      },
      emptyTips: {
        text1: "It's empty here.",
        text2: 'Click here to',
        links: 'Add Address',
      },
      editModal: {
        addTitle: 'Add Address',
        editTitle: 'Edit Address',
        address: 'Address',
        label: 'Label',
        link: 'Link to organization',
        tips: ' Note: Only linked Web3 Address can be authorized by organization Admin for on-chain trading.',
        btnCancel: 'Cancel',
        btnContinue: 'Continue',
      },
      removeModal: {
        title: 'Remove Address',
        subTitle: 'Are you sure you want to delete the following address?',
        tips: 'Note: Only linked Web3 Address can be authorized by organization Admin for on-chain trading.',
        btnCancel: 'Cancel',
        btnContinue: 'Confirm',
      },
      linkOrgModal: {
        title: 'Linked Organization',
      },
      placeHolder: {
        address: 'Enter an address',
        label: 'Please input label',
      },
      error: {
        address: 'can not be empty',
        invalidAddress: 'invalid address',
      },
      actionTips: {
        unChoose: 'please choose org first',
        exists: 'this org is already added, please choose another one',
        delete: 'Delete Successful',
        add: 'Add Successful',
      },
    },
  },
  orgManagement: {
    title: 'Organization',
  },
  guidance: {
    title: 'Guidance',
    welcomeTitle: '欢迎来到 Cobo Argus！',
    welcomeSubtitle:
      'It’s great to have you here! You’re almost ready to start your DeFi journey. To get started, please complete this checklist to confirm the basic settings.',
    step1Title: 'Enable Google Authenticator',
    step2Title: 'Create/Join Organization',
    gaStep1: 'Step1.',
    gaStep2: 'Step2.',
    gaStep3: 'Step3.',
    gaStep1Title: 'Download and install the Google Authenticator App',
    gaStep2Title: 'Scan QR code & Back up Key',
    gaStep2Subtitle:
      'Scan this QR code in the Google Authenticator app or manually enter the key below into the app.',
    gaStep3Title: 'Enter Google verification code',
    gaStep3Subtitle: 'Enter the 6 digit code from Google Authenticator.',
    joinOrgDesc:
      'To unlock the full power of Cobo Argus, you need to Create an Organization or Invited by an Organization',
    createAnOrganization: 'Create an organization',
    checkTheInvitation: 'Check the invitation',
    createOrgTitle: 'Create Organization',
    orgNameError: 'Please input name of organization',
    orgSizeError: 'Please select organization size',
    orgTypeError: 'Please select organization type',
    createOrgSuccess: 'The "{{orgName}}" has been created successfully!',
    joinOrgSuccess: "You've joined {{orgs}} successfully!",
    chooseJoinOrgs: 'Choose the organization(s) you want to join:',
  },
  auth: {
    title: 'Auth',
    verifyGaCode: 'Verify GA Code',
    pleaseEnter2fa: 'Please enter the Google 2FA authentication code.',
    gaHint:
      'If you are unable to access your Google 2FA Authentication, please contact Cobo customer service argussupport@cobo.com to reset.',
    unavailableHint: 'Security verification unavailable?',
    congratulations: 'Congratulations',
    login: 'Log in',
    loginBtn: 'Login',
    signup: 'Sign up',
    signupBtn: 'Sign Up',
    emailAddress: 'Email address',
    inputEmail: 'Input Email',
    inputPassword: 'Input Password',
    emailEmptyError: 'Please input your e-mail address!',
    emailError: 'Invalid E-mail!',
    password: 'Password',
    passwordEmptyError: 'Please input your password!',
    passwordError: 'Invalid Password!',
    emailNotExistError: 'Please sign up first',
    codeNotReceived: 'Haven’t received the code?',
    requireLowercase: 'One lowercase character',
    requireUppercase: 'One uppercase character',
    requireNumber: 'One number',
    require8Char: '8~30 characters',
    agreeTo: 'By clicking, you agree to ',
    agreeToAnd: ' and ',
    termsOfService: 'Terms of Service',
    alreadyHaveAccount: 'Already have an account?',
    signUpSuccessTitle: 'Welcome to Cobo Argus! Your new account is ready.',
    resend: 'Resend',
    verifyCode: 'Verify Code',
    verifyCodePlaceholder: 'Verification Code',
    hasSendVerifyCode: 'A email with the verification code has been sent to ',
    haveNotReceiveCode: "Haven't received the code?",
    noCodeContent: {
      title:
        'If you have not received the email verification code, we suggest you do the following:',
      step1: '1. Please confirm whether the entered email address is correct',
      step2:
        '2. If the verification code cannot be received in the mailbox, please check whether it is in the "trash" first.',
      step3:
        '3. If you can\'t find the verification code in the mailbox, and you can\'t receive it in the "trash", it may be that your mailbox firewall blocks our mail. It is recommended that you use a mailbox with better compatibility.',
      footer:
        'If you still cannot receive the verification code after trying the above methods, please contact our customer service and provide the email of your registered account and the specific time of occurrence.',
    },
    protocol: {
      privacyPolicy: 'Privacy Policy',
      lastUpdateAt: 'Last updated: 9 May 2022',
      policyDetail1:
        'This Privacy Notice describes how Cobo collects and processes your personal information through the Cobo websites and applications that reference this Privacy Notice. Cobo refers to an ecosystem comprising Cobo websites(whose domain names include but are not limited to www.cobo.com, mobile applications, clients, applets and other applications that are developed to offer Cobo Services,and includes independently-operated platforms, websites and clients within the ecosystem(e.g Cobo\'s Custody, DeFi Yield Fund, DaaS, MaaS, StaaS, WaaS, Ventures). "Cobo Operators" refer to all parties that run Cobo, including but not limited to legal persons, unincorporated organizations and teams that provide Cobo Services and are responsible for such services. "Cobo"as used in this Policy includes Cobo Operators.',
      policyDetail2:
        'This Privacy Policy applies to all platforms,websites,and departments of Cobo and Cobo Operators.By using Cobo Services,you are consenting to the collection,storage,processing and transfer of your personal information as described in this Privacy Policy.',
    },
    resetYourPassword: 'Reset Your Password',
    next: 'Next',
    verifyEmailSubTitle:
      'To secure your account, please complete the following verification.',
    securityVerification: 'Security Verification',
    emailVerificationCode: 'Email Verification Code',
    forgotPassword: 'Forgot Password',
    confirmPasswordLabel: 'Confirm Password',
    confirmPasswordEmptyError: 'Confirm Password is required',
    confirmPasswordNotMatchError: 'Passwords do not match',
    inputConfirmPassword: 'Please input confirm password',
    resetSuccessButton: 'Log In',
    PasswordChanged: 'Password Changed',
    ResetSuccessTip: 'Please log in with the new password.',
  },
  safe: {
    member: 'Member',
    email: 'Email',
    ACL: 'ACL',
    disabled: 'Disabled',
    goSafeAppTitle: 'Head to Safe{Wallet} DApp',
    createRoleButton: '+ Create Role',
    goSafeAppText:
      'You’re about to leave Argus and head to the Safe{Wallet} Dapp on the third-party website to complete the operation.',
    onChainRole: 'On Chain Role',
    memberTitle: 'Member',
    targetName: 'Target Name',
    targetContract: 'Target Contract',
    aclContract: 'ACL Contract',
    action: 'Action',
    contracts: 'Contracts',
    editSafe: 'Edit Safe{Wallet}',
    address: 'Address',
    network: 'Network',
    username: 'Name',
    nickname: 'Nickname',
    memberEmail: 'Email',
    DelegatedButton: 'Delegate',
    replaceButton: 'Replace',
    installButton: 'Install',
    removeButton: 'Remove',
    revokeButton: 'Revoke',
    viewButton: 'View',
    editButton: 'Edit',
    thresholdMsg: 'Every transaction requires the confirmation of:',
    default: 'Default',
    switchToDefault: 'Switch to default',
    usernameNotChange: 'Name cannot be same as previous one!',
    usernameNotEmpty: 'Name cannot be empty!',
    switchDefaultModuleText: 'Switch default Cobo Safe',
    switchDefaultModuleContent: 'You’re switching the default Cobo Safe.',
    switchDefaultModuleSuccess:
      'You’ve switched the default Cobo Safe successfully.',
    AclHint:
      'ACL is short for Access Control List, which is used for on-chain risk control in parameter granularity',
    onChainRoleHint:
      'On-chain Role is used to define the on-chain permission set for protocol and function.',
    memberTitleHint:
      'If “--” shows in this column, it means the owner of the delegated address is not a team member in your Argus organization. But it is still an effective on-chain member of the Cobo Safe authorized by the Safe owner.',
    targetRoleHint:
      'Target Contract stands for the contract which authorized users are able to interact with.',
    delegatedMemberHint:
      'Delegated Members are EOA(Externally Owned Account) Addresses, usually generated by your wallet.',
    safeModuleTip:
      'Cobo Safe, the core component of Cobo Safe, is a smart contract supporting role-based access control for on-chain activity.',
    enable: 'Enable',
  },
};

export default zhHans;
