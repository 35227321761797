import { createSlice } from '@reduxjs/toolkit';

let initialState = {};

const slice = createSlice({
  name: 'safeInfo',
  initialState,
  reducers: {},
});
export const safeReducer = slice.reducer;
