import { useMemo, useRef, useState } from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ChainIconGrey from '../ChainIconGrey';
import styles from './index.module.less';
import { ChainsFilter } from '@/chains/utils';
import { Button, ChainIcon } from '@/components';
import { ChainShortNameType } from '@/interfaces';

interface Props {
  chains: string[];
  onChange?: (chain: string) => void;
}
export function ChainSearch({ onChange, chains }: Props) {
  const ref = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const chainList = useMemo(() => {
    let list = ChainsFilter.filter(item => {
      return chains.includes(item.coboName);
    });
    list.unshift({
      chainName: 'All',
      shortName: '',
      coboName: '',
    });
    return list;
  }, [chains]);

  return (
    <div>
      <div ref={ref} className={cx('chain-search')}>
        <p> {t('common.network')}:</p>

        {chainList.map((config, index) => {
          if (index === 0) {
            const selected = index === activeIndex;
            const selectedBtn = '!text-blue !font-[500] !font-ubMd';
            const btn =
              '!text-[12px] !leading-[20px] !py-0 !px-[10px] !rounded-[8px] !bg-transparent';
            const unselectedBtn =
              '!text-greyFont !font-[400] !font-ub !border-[#E7EBF2] !border-[1px] !bg-transparent hover:!border-blue hover:!bg-transparent';
            return (
              <Button
                key="all"
                type="white"
                className="mr-[12px]"
                btnClassName={`${
                  selected ? selectedBtn : unselectedBtn
                } ${btn}`}
                onClick={() => {
                  setActiveIndex(index);
                  onChange && onChange('');
                }}
              >
                All
              </Button>
            );
          }
          return (
            <Tooltip
              key={config.chainName}
              placement="top"
              title={<div>{config.chainName}</div>}
              destroyTooltipOnHide
              open={hoverIndex === index}
            >
              <div
                className={cx('chain-box')}
                onClick={() => {
                  setActiveIndex(index);
                  onChange && onChange(config.coboName);
                }}
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(null)}
              >
                {config.shortName &&
                  (activeIndex === index || hoverIndex === index ? (
                    <ChainIcon
                      shortName={config.shortName as ChainShortNameType}
                      chainName={config.chainName}
                    />
                  ) : (
                    <ChainIconGrey
                      shortName={config.shortName as ChainShortNameType}
                      chainName={config.chainName}
                    />
                  ))}
              </div>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
}
