import LegacySignClient from '@walletconnect/client';
import {
  IClientMeta,
  IJsonRpcRequest,
  IWalletConnectSession,
} from '@walletconnect/legacy-types';

import { EIP155_SIGNING_METHODS } from '../data/EIP155Data';
import { MultiCallFunc } from '@/walletconnect/utils/interface';

export class LegacySignClientFactory {
  clientMap = new Map<string, LegacySignClient>();

  generateClient(
    safe: string,
    onSessionRequest: (legacyProposal: {
      id: number;
      params: [{ chainId: number; peerId: string; peerMeta: IClientMeta }];
    }) => void,
    onLegacySign: (legacyCallRequestEvent: any) => void,
    onLegacySignTypedData: (legacyCallRequestEvent: any) => void,
    onLegacySignTransaction: (legacyCallRequestEvent: IJsonRpcRequest) => void,
    uri?: string,
    onDisconnect?: () => void,
  ): LegacySignClient | undefined {
    let client: LegacySignClient | undefined = this.clientMap.get(safe);
    if (uri) {
      deleteCachedLegacySession(safe);
      client = new LegacySignClient({
        uri,
        storageId: `${safe}-walletconnect`,
      });
    } else if (!client && getCachedLegacySession(safe)) {
      const session = getCachedLegacySession(safe);
      client = new LegacySignClient({ session });
    } else {
      return client;
    }

    client.on('session_request', (error, payload) => {
      console.log('legacySignClient > session_request', payload);
      if (error) {
        throw new Error(`legacySignClient > session_request failed: ${error}`);
      }
      onSessionRequest(payload);
    });

    client.on('connect', () => {
      console.log('legacySignClient > connect');
    });

    client.on('error', error => {
      throw new Error(`legacySignClient > on error: ${error}`);
    });

    client.on('call_request', (error, payload) => {
      console.log('legacySignClient > call_request', payload);
      if (error) {
        throw new Error(`legacySignClient > call_request failed: ${error}`);
      }
      onCallRequest(
        this.clientMap.get(safe),
        payload,
        onLegacySign,
        onLegacySignTypedData,
        onLegacySignTransaction,
      );
    });

    client.on('disconnect', async () => {
      try {
        await this.killClient(safe);
      } finally {
        onDisconnect && onDisconnect();
      }
    });
    this.clientMap.set(safe, client);
    return client;
  }

  async killClient(safe: string): Promise<Map<string, LegacySignClient>> {
    const client = this.clientMap.get(safe);
    if (client) {
      try {
        await client.killSession();
      } finally {
        deleteCachedLegacySession(safe);
        this.clientMap.delete(safe);
      }
    }
    return this.clientMap;
  }

  static approveSession(
    safe: string,
    chainId: number,
    client?: LegacySignClient,
  ) {
    if (!client) return;
    client.approveSession({
      accounts: [safe],
      chainId: chainId,
    });
    const session = client.session;
    if (session) {
      window.localStorage.setItem(
        `${safe}-walletconnect`,
        JSON.stringify(session),
      );
    }
  }
}

export const legacySignClientFactory = new LegacySignClientFactory();

const onCallRequest = (
  client: LegacySignClient | undefined,
  payload: IJsonRpcRequest,
  onLegacySign: (legacyCallRequestEvent: any) => void,
  onLegacySignTypedData: (legacyCallRequestEvent: any) => void,
  onLegacySignTransaction: (legacyCallRequestEvent: any) => void,
) => {
  if (!client) return;
  switch (payload.method) {
    case EIP155_SIGNING_METHODS.ETH_SIGN:
    case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
      onLegacySign(payload);
      return;

    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4:
      onLegacySignTypedData(payload);
      return;

    case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
      onLegacySignTransaction(payload);
      return;

    default:
      alert(`${payload.method} is not supported for WalletConnect v1`);
  }
};

function getCachedLegacySession(
  prefix: string,
): IWalletConnectSession | undefined {
  if (typeof window === 'undefined') return;

  const local = window.localStorage
    ? window.localStorage.getItem(`${prefix ? prefix + '-' : ''}walletconnect`)
    : null;

  let session = null;
  if (local) {
    try {
      session = JSON.parse(local);
    } catch (error) {
      throw error;
    }
  }
  return session;
}

function deleteCachedLegacySession(prefix: string): void {
  if (typeof window === 'undefined') return;
  window.localStorage.removeItem(`${prefix ? prefix + '-' : ''}walletconnect`);
}

export const getApproveRequestData = async (
  payload: {
    method: string;
    params: any[];
  },
  chainId: number,
  module: string,
  multiCallFuncs: MultiCallFunc[],
): Promise<
  | {
      to: string | string[];
      value: string | string[];
      data: string | string[];
    }
  | undefined
> => {
  if (!payload) throw new Error('payload can not be empty');
  if (!module) throw new Error('module can not be empty');
  const params = Array.isArray(payload.params)
    ? payload.params[0]
    : // @ts-ignore
      payload.params.request.params[0];
  if (multiCallFuncs.length) {
    if (multiCallFuncs.length === 1) {
      return {
        to: params.to,
        value: params.value || '0',
        data: multiCallFuncs[0].data,
      };
    }
    const toList: string[] = [];
    const valueList: string[] = [];
    const dataList: string[] = [];
    let hasSetValue = false;
    multiCallFuncs.forEach(item => {
      toList.push(params.to);
      if (!hasSetValue && item.abi.abiItem.stateMutability === 'payable') {
        hasSetValue = true;
        valueList.push(params.value || '0');
      } else {
        valueList.push('0');
      }
      dataList.push(item.data);
    });
    return {
      to: toList,
      value: valueList,
      data: dataList,
    };
  }
  return {
    to: params.to,
    value: params.value || '0',
    data: params.data,
  };
};
