import classNames from 'classnames';

import styles from './index.module.less';

// 导入icons下的所有图标，避免手动引入
const importAll = (requireContext: __WebpackModuleApi.RequireContext) =>
  requireContext.keys().forEach(requireContext);

try {
  importAll(require.context('@/assets/icons', true, /\.svg$/));
} catch (error) {
  console.log(error);
}

interface SvgIconProps {
  name: string;
}

export function SvgIcon(props: SvgIconProps & React.SVGAttributes<SVGElement>) {
  const { className, ...otherProps } = props;
  return (
    <svg className={classNames(styles.icon, className)} {...otherProps}>
      <use xlinkHref={'#' + props.name} />
    </svg>
  );
}
