import { useMemo } from 'react';
import { SyncOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import styles from './index.module.less';
import { useImage } from '@/hooks';
import { ChainShortNameType } from '@/interfaces';

interface BrowserIconProps {
  shortName: ChainShortNameType;
  chainName?: string;
  className?: string;
}

export function BrowserIcon(props: BrowserIconProps) {
  const { className, shortName, chainName } = props;
  const reqSvgs = require.context('@/assets/icons/browser', true, /\.png$/);
  const allSvgFilepaths = reqSvgs.keys();
  const imgSrc = useMemo(() => {
    const index =
      allSvgFilepaths.findIndex(
        val => val === `./link_${shortName.toLowerCase()}.png`,
      ) || 0;
    const imagePath = allSvgFilepaths[index];
    if (!imagePath) return reqSvgs('./link_unknown.png');
    return reqSvgs(imagePath);
  }, [shortName, allSvgFilepaths, reqSvgs]);

  const { hasLoaded } = useImage(imgSrc);
  return (
    <>
      {!hasLoaded && (
        <SyncOutlined
          className={classNames(styles['browser-icon'], className)}
          spin
        />
      )}
      {hasLoaded && (
        <img
          src={imgSrc}
          alt={chainName || shortName}
          className={classNames(styles['browser-icon'], className)}
        />
      )}
    </>
  );
}
