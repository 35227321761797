import { useImage } from '@/hooks';

interface Props {
  url: string;
  alt?: string;
  className?: string;
}

export function CoboImage({ url, alt, className }: Props) {
  const { hasLoaded } = useImage(url);
  if (hasLoaded) {
    return <img className={className} src={url} alt={alt || url} />;
  }
  return (
    <img
      className={className}
      src={require('@/assets/images/chains/link_unknown.png')}
      alt={alt || url}
    />
  );
}
