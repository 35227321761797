import { useEffect, useMemo } from 'react';
import { useInterval } from 'ahooks';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  modalVisibleAtom,
  moduleTxAtom,
  moduleTxStatusAtom,
} from '../../atoms';
import commonStyles from '../common.module.less';
import styles from './index.module.less';
import { getCreateSafeModuleStatus, getSafeTxRecord } from '@/api';
import { getCoboNameByShortName } from '@/chains/utils';
import { Button } from '@/components';
import { Collapse } from '@/components/Common/Collapse';
import { useQuery } from '@/hooks';

interface Props {
  safeInfo: {
    id: string;
    chain_id: string;
    name: string;
    address: string;
    org_id: string;
    default_cobo_module: string;
  };
  onVisibleChange: (visible: boolean, status?: string) => void;
}

export function CreateModuleTab({ safeInfo, onVisibleChange }: Props) {
  const query = useQuery();
  const commonCx = classNames.bind(commonStyles);
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const [createModuleTx, setCreateModuleTx] = useAtom(moduleTxAtom);
  const [createTxStatus, setCreateTxStatus] = useAtom(moduleTxStatusAtom);
  const [visible] = useAtom(modalVisibleAtom);

  useEffect(() => {
    if (!query.has('status')) {
      return;
    }
    const status = query.get('status');
    if (status === 'noModule' && !txPending) {
      onVisibleChange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const loadTx = async () => {
      const coboName = getCoboNameByShortName(safeInfo.chain_id);
      if (!coboName) return;
      const { pending_create_safe_module_tx_hash } =
        await getCreateSafeModuleStatus(coboName, safeInfo.id);
      setCreateModuleTx(pending_create_safe_module_tx_hash);
    };
    loadTx();
  }, [safeInfo.chain_id, safeInfo.id, setCreateModuleTx]);
  useEffect(() => {
    if (createTxStatus === 'pending' || !createModuleTx || visible) return;
    onVisibleChange(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createModuleTx, createTxStatus, visible]);
  useInterval(async () => {
    if (!safeInfo.chain_id || !createModuleTx) return;
    const coboName = getCoboNameByShortName(safeInfo.chain_id);
    if (!coboName) return;
    const record = await getSafeTxRecord(coboName, createModuleTx);
    const { status } = record;
    if (status === 50) {
      setCreateTxStatus('success');
      onVisibleChange(false, 'success');
      // failed; invalid; cancel;
    } else if ([40, 60, 70].includes(status)) {
      setCreateTxStatus('failed');
    }
  }, 5000);
  const txPending = useMemo(() => {
    return !!createModuleTx;
  }, [createModuleTx]);
  return (
    <div className={commonCx('tab-content-container')}>
      <div className={cx('module-img')} />
      <div className={cx('label')}>
        {txPending
          ? t('safeInfo.createModulePendingTip')
          : t('safeInfo.createModuleTip')}
      </div>
      <Button onClick={() => onVisibleChange(true)}>
        {txPending
          ? t('safeInfo.createPendingBtn')
          : t('safeInfo.createModuleBtn')}
      </Button>
      <div className={commonCx('collapse-wrapper')}>
        <Collapse
          showArrow={false}
          defaultExpand
          showExpendIcon
          header={t('safeInfo.moduleDescTitle')}
          className={commonCx('module-desc-collapse')}
        >
          {t('safeInfo.moduleDesc')}
        </Collapse>
      </div>
    </div>
  );
}
