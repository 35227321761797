export const account = {
  title: 'Account',
  security: 'Security',
  updateSuccess: 'Edited successfully! Your profile has been updated.',
  basicInfo: 'Basic Information',
  twoFaAuth: 'Two-Factor Authentication',
  enableTwoFa: 'Enable Google Authenticator',
  accountSettingsDesc:
    'You can modify your account settings on the Cobo Account website.  ',
  twoFaTip:
    '2FA will be applied during address management, gas account withdrawals, and other sensitive operations to ensure the security of your account.',
  googleAuth: 'Google Authenticator',
  googleAuthDesc: `2FA will be applied during address management, gas account withdrawals, and other sensitive operations to ensure the security of your account.`,
  wallet: 'Wallet',
  walletSubtitle: 'The wallet address will be used to login.',
  emailSubtitle:
    'The Email address will be used to login and receive notifications.',
  emailSubtitleWithoutLogin:
    'The Email address will be used receive notifications.',
  addWallet: 'Add Wallet',
  addEmail: 'Add Email',
  saveChange: 'Save Changes',
  username: 'Username',
  emailAddress: 'Email Address',
  twoFa: '2FA',
  googleAuthenticator: 'Google Authenticator',
  enabled: 'Enabled',
  addAddressBtn: 'Add Address',
  migrateNotice:
    'We have discontinued the organization address book and will now only maintain individual address books.',
  tabs: {
    profile: 'Profile',
    security: 'Security',
    address: 'Address',
  },
  addressTable: {
    col: {
      address: 'Address',
      label: 'Label',
      link: 'Linked Organization',
      update: 'Update Time',
      action: 'Action',
      actionBtn: {
        edit: 'Edit',
        delete: 'Delete',
      },
    },
    emptyTips: {
      text1: "It's empty here.",
      text2: 'Click here to',
      links: 'add the address.',
    },
    editModal: {
      addTitle: 'Add Address',
      editTitle: 'Edit Address',
      address: 'Address',
      label: 'Label',
      link: 'Link to organization',
      tips: ' Note: Only a linked address can be authorized by the organization admin for on-chain trading.',
      btnCancel: 'Cancel',
      btnContinue: 'Continue',
    },
    removeModal: {
      title: 'Remove Address',
      subTitle: 'Are you sure that you want to delete the following address?',
      tips: 'Note: Only a linked address can be authorized by the organization admin for on-chain trading.',
      btnCancel: 'Cancel',
      btnContinue: 'Confirm',
    },
    linkOrgModal: {
      title: 'Linked Organization',
    },
    placeHolder: {
      address: 'Enter an address',
      label:
        'We suggest specifying the network name and address source (e.g. ETH-Exchange)',
      name: 'Enter a name',
    },
    error: {
      address: 'cannot be empty',
      invalidAddress: 'invalid address',
      member: 'Please select member',
    },
    actionTips: {
      unChoose: 'Please choose an org first.',
      exists: 'This org has already been added. Please choose another one.',
      delete: 'Deleted successfully!',
      add: 'Added successfully!',
    },
  },
  connectedAccounts: `Connected Accounts`,
  email: `Email`,
  googleOneTapLogin: `Google One-Tap Login`,
  web3Address: `Web3 Address`,
  addMFATip: `To add MFA methods, please go to the Cobo Account website.`,
  emailTip: `This email address will be used to receive notifications.`,
  termsOfConditions: `Terms of Conditions`,
  privacyPolicy: `Privacy Policy`,
  coboAccountModal: {
    note: `Cobo Argus does not support Cobo Accounts that have not added an email or a Web3 address yet. Follow the steps below to add or `,
    switch: `switch`,
    note2: ` to a different Cobo Account.`,
    step1: `Step 1:`,
    step2: `Step 2:`,
    step1Text: `Navigate to the Cobo Account homepage to add an email or a Web3 address.`,
    step2Text: `Please log in to your Cobo Argus account again.`,
    step1Btn: `Go to Cobo Account`,
    step2Btn: `Log in`,
  },
};
