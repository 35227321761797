import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useWalletClient,
} from 'wagmi';

import moduleAbi from '@/contracts/cobo/module.abi';
import { useEstimateGas } from '@/hooks/useEstimateGas';
import { useConnectWithChain } from '@/hooks/useSwitchChain';

const ERROR_MAP: { [key: string]: string } = {
  E2: 'E2: Call/Static-call failed',
  E3: "E3: Argument's type not supported in View Variant",
  E4: 'E4: Invalid length for variant raw data',
  E6: 'E6: Invalid variant type',
  E7: 'E7: Rule not exists',
  E8: 'E8: Variant name not found',
  E9: 'E9: Rule: v1/v2 solType mismatch',
  E11: 'E11: Invalid rule OP',
  E21: 'E21: checkCmpOp: OP not support',
  E22: 'E22: checkBySolType: Invalid op for bool',
  E23: 'E23: checkBySolType: Invalid op',
  E24: 'E24: Invalid solidity type',
  E25: 'E25: computeBySolType: invalid vm op',
  E26: 'E26: computeBySolType: invalid vm arith op',
  E27: 'E27: onlyCaller: Invalid caller',
  E29: 'E29: Side-effect is not allowed here',
  E30: 'E30: Invalid variant count for the rule op',
  E31: 'E31: extractCallData: Invalid op',
  E32: 'E32: extractCallData: Invalid array index',
  E33: 'E33: extractCallData: No extract op',
  E34: 'E34: extractCallData: No extract path',
  E35: 'E35: BaseOwnable: caller is not owner',
  E36: 'E36: BaseOwnable: Already initialized',
  E39: 'E39: BaseACL: ACL check method should not return anything',
  E41: 'E41: Invalid delegate',
  E42: 'E42: RootAuthorizer: delegateCallAuthorizer not set',
  E43: 'E43: RootAuthorizer: callAuthorizer not set',
  E44: 'E44: BaseAccount: Authorizer not set',
  E46: 'E46: BaseAuthorizer: Authorizer paused',
  E47: 'E47: Authorizer set: Invalid hint',
  E48: 'E48: Authorizer set: All auth deny',
  E49: 'E49: BaseACL: Method not allow',
  E50: 'E50: AuthorizerUnionSet: Invalid hint collected',
  E51: 'E51: AuthorizerSet: Empty auth set',
  E52: 'E52: AuthorizerSet: hint not implement',
  E53: 'E53: RoleAuthorizer: Empty role set',
  E54: 'E54: RoleAuthorizer: No auth for the role',
  E55: 'E55: BaseACL: No in contract white list',
  E56: 'E56: BaseACL: Same process not allowed to install twice',
  E57: 'E57: BaseAuthorizer: Account not set (then can not find roleManger)',
  E58: 'E58: BaseAuthorizer: roleManger not set',
};

export default function useSingleSign(
  moduleAddress: `0x${string}` | undefined,
  version: string,
  to: string | string[],
  value: string | string[],
  data: string | string[],
  chainId: number,
) {
  const [start, setStart] = useState(false);
  const [trigger, setTrigger] = useState<{
    reject: (error: any) => void;
    resolve: (txHash: string) => void;
  }>();
  const [writing, setWriting] = useState(false);
  const [txHash, setTxHash] = useState<`0x${string}`>();
  const connect = useConnectWithChain(chainId);
  const isMultiCall = useMemo(() => (to ? Array.isArray(to) : false), [to]);
  const { data: walletClient } = useWalletClient();

  const txResult = useWaitForTransaction({
    chainId,
    hash: txHash,
  });
  const { error: txError, isSuccess: txSuccess } = txResult;

  const handleError = useCallback(
    (error: any) => {
      console.error(error);
      if (!error) return;
      let errorMessage =
        error.cause?.reason ||
        error.shortMessage ||
        error.reason ||
        error.message ||
        'Unknown error';
      if (Object.keys(ERROR_MAP).includes(errorMessage)) {
        errorMessage = ERROR_MAP[errorMessage];
      } else if (errorMessage.length > 135) {
        errorMessage = 'Execute failed. Please check your params';
      }
      trigger?.reject(new Error(errorMessage));
    },
    [trigger],
  );

  const readEnabled = useMemo(() => {
    return !!(start && to && data);
  }, [data, start, to]);

  const readArgs = useMemo(() => {
    if (!to || value === undefined || !data) return [];
    return isMultiCall
      ? (to as string[]).map((item, index) => [
          0,
          item,
          value[index],
          data[index],
          '0x',
          '0x',
        ])
      : [[0, to, value, data, '0x', '0x']];
  }, [data, isMultiCall, to, value]);

  const functionName = useMemo(() => {
    return isMultiCall ? 'execTransactions' : 'execTransaction';
  }, [isMultiCall]);

  const { data: hintData, error: readError } = useContractRead({
    address: moduleAddress,
    abi: moduleAbi,
    functionName,
    chainId,
    args: readArgs,
    enabled: readEnabled,
    account: walletClient?.account,
  });

  const writeEnabled = useMemo(() => {
    // 如果 read 报错那么直接用空的 hint
    return readEnabled && (!!hintData || !!readError);
  }, [hintData, readEnabled, readError]);

  const writeArgs = useMemo(() => {
    if (isMultiCall) {
      return (to as string[]).map((item, index) => [
        0,
        item,
        value[index],
        data[index],
        // @ts-ignore
        hintData ? hintData[index]?.hint || '0x' : '0x',
        '0x',
      ]);
    } else {
      // @ts-ignore
      const hint = hintData?.hint;
      return [[0, to, value, data, hint || '0x', '0x']];
    }
  }, [data, hintData, isMultiCall, to, value]);

  const gasLimit = useEstimateGas(chainId, {
    address: moduleAddress,
    abi: moduleAbi,
    functionName: isMultiCall ? 'execTransactions' : 'execTransaction',
    chainId,
    args: writeArgs,
    account: walletClient?.account,
  });

  const gas = useMemo(() => {
    if (!gasLimit) return;
    return `${version}` === '1' ? gasLimit * 3n : (gasLimit * 13n) / 10n;
  }, [gasLimit, version]);

  const {
    config,
    error: prepareWriteError,
    isLoading: isPrepareWriteLoading,
    isFetching: isPrepareWriteFetching,
    isRefetching: isPrepareWriteRefetching,
  } = usePrepareContractWrite({
    address: moduleAddress,
    abi: moduleAbi,
    functionName: isMultiCall ? 'execTransactions' : 'execTransaction',
    chainId,
    args: writeArgs,
    account: walletClient?.account,
    enabled: writeEnabled,
    gas,
  });

  const {
    writeAsync,
    isLoading: isWriteLoading,
    error: writeError,
    reset: resetWrite,
  } = useContractWrite(config);

  const reset = useCallback(() => {
    setStart(false);
    setTrigger(undefined);
    if (resetWrite) {
      resetWrite();
    }
    setWriting(false);
    setTxHash(undefined);
  }, [resetWrite]);

  useEffect(() => {
    return reset;
  }, [reset]);

  useEffect(() => {
    const _write = async () => {
      if (!start || !writeEnabled || !writeAsync || writing) return;
      setWriting(true);
      const { hash } = await writeAsync();
      setTxHash(hash);
    };
    _write();
  }, [reset, start, trigger, writeAsync, writeEnabled, writing]);

  useEffect(() => {
    if (
      !start ||
      !(writeError || prepareWriteError || txError) ||
      isWriteLoading ||
      isPrepareWriteRefetching ||
      isPrepareWriteLoading ||
      isPrepareWriteFetching
    )
      return;
    handleError(writeError || prepareWriteError || txError);
    reset();
  }, [
    handleError,
    isPrepareWriteFetching,
    isPrepareWriteLoading,
    isPrepareWriteRefetching,
    isWriteLoading,
    prepareWriteError,
    txError,
    reset,
    start,
    writeError,
  ]);

  useEffect(() => {
    if (!start || !txSuccess || !txHash) return;
    trigger?.resolve(txHash);
    reset();
  }, [reset, start, trigger, txHash, txSuccess]);

  return useCallback(async () => {
    return new Promise<string>(async (resolve, reject) => {
      try {
        await connect();
      } catch (e) {
        reject(e);
        return;
      }
      setTrigger({
        resolve,
        reject,
      });
      setStart(true);
    });
  }, [connect]);
}
