import { Provider as JotaiProvider } from 'jotai';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import '@rainbow-me/rainbowkit/styles.css';

import ReactGA from 'react-ga4';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from '@/store';

import './index.less';
import './i18n';

import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { createRoot } from 'react-dom/client';
import { WagmiConfig } from 'wagmi';

import coboAtomStore from '@/globalAtom';
import { init, useInitWallet } from '@/prepare';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

init();

const Index = () => {
  const { chains, config } = useInitWallet();
  return (
    <WagmiConfig config={config}>
      <RainbowKitProvider chains={chains}>
        <HashRouter>
          <JotaiProvider store={coboAtomStore}>
            <App />
          </JotaiProvider>
        </HashRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

const isProd = process.env.REACT_APP_ENV === 'prod';

// Sentry.init({
//   dsn: isSandbox
//     ? 'https://331b9d1227194a8081e3caf100f38487@sentry.sandbox.cobo.com/25'
//     : 'https://ed0dcf5ffbd44296a7775f9722c96d4a@o117179.ingest.sentry.io/4504353329709056',
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

root.render(
  <Provider store={store}>
    <Index />
  </Provider>,
);

if (isProd) {
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
  ReactGA.initialize('G-JR4SY9HQW4', { testMode: true });
  ReactGA.send('pageview');
}
