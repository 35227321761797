import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layout as BaseLayout, Menu, MenuProps } from 'antd';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';

import AgreementsModal from './AgreementsModal';
import styles from './index.module.less';
import { acceptTerms, getMyProfile } from '@/api';
import { allRoutes } from '@/App';
import { Support, SvgIcon } from '@/components';
import { GasSelector } from '@/components/Layout/GasSelector';
import { OrgSelector } from '@/components/Layout/OrgSelector';
import { RabbyImport } from '@/components/Layout/RabbyImport';
import { SafeSelector } from '@/components/Layout/SafeSelector';
import { TransactionBundle } from '@/components/Layout/TransactionBundle';
import { UpgradeNotice } from '@/components/Layout/UpgradeNotice';
import { UserInfo } from '@/components/Layout/UserInfo';
import { Wallet } from '@/components/Layout/Wallet';
import WalletConnectModal from '@/components/Layout/WalletConnectModal';
import {
  globalFocusMenuAtom,
  globalSafeInfoAtom,
  globalTitleAtom,
  globalUserInfoAtom,
  orgIdAtom,
  orgsAtom,
  safeModuleAtom,
} from '@/globalAtom';
import i18n from '@/i18n';
import { asyncDebounce, filterRoutes } from '@/utils/utils';

export function Layout() {
  const items = [
    {
      path: '/home',
      text: i18n.t('safeManagement.home'),
      icon: (
        <div className={`${styles['menu-icon']} ${styles['menu-safe-icon']}`} />
      ),
      sub_paths: ['/safe-management', '/home-setting'],
      selectedIcon: (
        <div
          className={`${styles['menu-icon']} ${styles['menu-safe-on-icon']}`}
        />
      ),
    },
    {
      path: '/investment',
      text: i18n.t('investment.farming'),
      icon: (
        <div
          className={`${styles['menu-icon']} ${styles['menu-investment-icon']}`}
        />
      ),
      sub_paths: [
        '/strategy-info/farming/:id',
        '/service-config/farming/:id',
        '/strategy-authorization/:id',
        '/farming/generic-bot/:bot_definition_id/new',
        '/generic-bot/:bot_instance_id',
        '/my-portfolio/farming',
        '/strategy-bot/:id',
        '/bot-detail/:id',
        'farming-detail/:id',
      ],
      selectedIcon: (
        <div
          className={`${styles['menu-icon']} ${styles['menu-investment-on-icon']}`}
        />
      ),
    },
    {
      path: '/trading',
      text: i18n.t('investment.trading'),
      icon: (
        <div
          className={`${styles['menu-icon']} ${styles['menu-trading-icon']}`}
        />
      ),
      sub_paths: [
        '/strategy-info/trading/:id',
        '/service-config/trading/:id',
        '/strategy-authorization/:id',
        '/my-portfolio/trading',
        '/strategy-bot/:id',
      ],
      selectedIcon: (
        <div
          className={`${styles['menu-icon']} ${styles['menu-trading-on-icon']}`}
        />
      ),
    },
    // {
    //   path: '/my-bots',
    //   text: i18n.t('bots.bots'),
    //   icon: (
    //     <div className={`${styles['menu-icon']} ${styles['menu-bot-icon']}`} />
    //   ),
    //   sub_paths: ['/bot-detail/:id', '/bots-detail'],
    //   selectedIcon: (
    //     <div
    //       className={`${styles['menu-icon']} ${styles['menu-bot-on-icon']}`}
    //     />
    //   ),
    // },
    {
      path: '/transaction',
      text: i18n.t('auth.transaction'),
      icon: (
        <div
          className={`${styles['menu-icon']} ${styles['menu-transaction-icon']}`}
        />
      ),
      sub_paths: [],
      selectedIcon: (
        <div
          className={`${styles['menu-icon']} ${styles['menu-transaction-on-icon']}`}
        />
      ),
    },
    {
      path: '/me',
      text: i18n.t('me.title'),
      icon: (
        <div className={`${styles['menu-icon']} ${styles['menu-me-icon']}`} />
      ),
      sub_paths: [],
      selectedIcon: (
        <div
          className={`${styles['menu-icon']} ${styles['menu-me-on-icon']}`}
        />
      ),
    },
    {
      path: '/team-management',
      text: i18n.t('teamManagement.team'),
      icon: (
        <div className={`${styles['menu-icon']} ${styles['menu-team-icon']}`} />
      ),
      sub_paths: [],
      selectedIcon: (
        <div
          className={`${styles['menu-icon']} ${styles['menu-team-on-icon']}`}
        />
      ),
    },
    {
      path: '/org-settings',
      text: i18n.t('orgSettings.title'),
      icon: (
        <div
          className={`${styles['menu-icon']} ${styles['menu-settings-icon']}`}
        />
      ),
      sub_paths: [],
      selectedIcon: (
        <div
          className={`${styles['menu-icon']} ${styles['menu-settings-on-icon']}`}
        />
      ),
    },
  ];

  const accountItems = [
    // {
    //   path: '/user-settings',
    //   text: i18n.t('auth.account'),
    //   icon: (
    //     <div
    //       className={`${styles['menu-icon']} ${styles['menu-account-icon']}`}
    //     />
    //   ),
    //   sub_paths: [],
    //   selectedIcon: (
    //     <div
    //       className={`${styles['menu-icon']} ${styles['menu-account-on-icon']}`}
    //     />
    //   ),
    // },
    {
      path: '/account',
      text: i18n.t('auth.account'),
      icon: (
        <div
          className={`${styles['menu-icon']} ${styles['menu-account-icon']}`}
        />
      ),
      sub_paths: [],
      selectedIcon: (
        <div
          className={`${styles['menu-icon']} ${styles['menu-account-on-icon']}`}
        />
      ),
    },
    {
      path: '/organization',
      text: i18n.t('auth.organization'),
      icon: (
        <div className={`${styles['menu-icon']} ${styles['menu-org-icon']}`} />
      ),
      sub_paths: [],
      selectedIcon: (
        <div
          className={`${styles['menu-icon']} ${styles['menu-org-on-icon']}`}
        />
      ),
    },
  ];

  const { t } = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [userInfo, setUserInfo] = useAtom(globalUserInfoAtom);
  const [orgId] = useAtom(orgIdAtom);
  const [organizations] = useAtom(orgsAtom);
  const [globalTitle, setGlobalTitle] = useAtom(globalTitleAtom);
  const [globalFocusMenu, setGlobalFocusMenu] = useAtom(globalFocusMenuAtom);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [history, setHistory] = useState<string[]>([]);
  const [acceptTermsVisible, setAcceptTermsVisible] = useState<boolean>(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const safeInfo = useAtomValue(globalSafeInfoAtom);
  const moduleInfo = useAtomValue(safeModuleAtom);

  const routeTitle = useMemo(() => {
    if (globalTitle) return globalTitle;
    return allRoutes.find(item => matchPath(item.path, pathname))?.title || '';
  }, [globalTitle, pathname]);

  useEffect(() => {
    if (userInfo && !userInfo.accept_terms) {
      setAcceptTermsVisible(true);
    }
  }, [userInfo]);

  const isAccountMenu = useMemo(() => {
    return ['/organization', '/account', '/user-settings'].includes(pathname);
  }, [pathname]);

  useEffect(() => {
    if (history.length > 0 && history[history.length - 1] === pathname) return;
    setHistory([...history, pathname]);
  }, [history, pathname]);

  useEffect(() => {
    setIsNewUser(userInfo?.is_new_user || false);
  }, [userInfo]);

  useEffect(() => {
    if (!userInfo) return;
    if (
      organizations !== null &&
      organizations.length === 0 &&
      pathname !== '/organization'
      // && pathname !== '/guidance'
    ) {
      navigate('/organization', { replace: true });
      return;
    }
    if (pathname === '/') {
      navigate('/home', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, pathname, userInfo, organizations]);

  useEffect(() => {
    const menuItems = isAccountMenu ? accountItems : items;
    if (globalFocusMenu) {
      const focusIdx = menuItems.findIndex(
        item => item.path === globalFocusMenu,
      );
      if (focusIdx !== -1) {
        setSelectedKeys([`${focusIdx}`]);
        return;
      }
    }
    const findItemIndex = (item: { path: string; sub_paths: any[] }) =>
      item.path === pathname ||
      item.sub_paths.some(sub_path => matchPath(sub_path, pathname));
    let currentItemIdx = menuItems.findIndex(findItemIndex);
    setSelectedKeys(currentItemIdx !== -1 ? [`${currentItemIdx}`] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountMenu, pathname, globalFocusMenu]);

  const orgInfo = useMemo(() => {
    return organizations?.find(item => item.id === orgId);
  }, [orgId, organizations]);

  const availablePaths: string[] = useMemo(() => {
    return filterRoutes(allRoutes, orgInfo).map(item => item.path);
  }, [orgInfo]);

  type MenuItem = Required<MenuProps>['items'][number];

  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };

  const menuItems = useMemo(() => {
    return (isAccountMenu ? accountItems : items)
      .filter(item => availablePaths.includes(item.path))
      .map((item, index) => {
        let icon = item.icon;
        if (
          selectedKeys &&
          selectedKeys.length > 0 &&
          selectedKeys[0] === `${index}`
        ) {
          icon = item.selectedIcon;
        }
        return getItem(item.text, index, icon);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountMenu, availablePaths, selectedKeys]);

  const beforeMenuClick = (fun: () => void) => {
    if (!userInfo || !userInfo.user_id || userInfo.org_count === 0) return;
    fun();
  };

  const handleConfirm = asyncDebounce(
    useCallback(async () => {
      setAcceptTermsVisible(false);
      await acceptTerms();
      const info = await getMyProfile();
      setUserInfo(info);
    }, [setUserInfo]),
    1000,
  );

  useEffect(() => {
    if (pathname) {
      setGlobalFocusMenu('');
      setGlobalTitle('');
    }
  }, [pathname, setGlobalFocusMenu, setGlobalTitle]);

  const hasOrg = useMemo(() => {
    return userInfo?.org_count && userInfo?.org_count > 0;
  }, [userInfo?.org_count]);

  return (
    <BaseLayout className={styles['layout-container']}>
      <BaseLayout.Sider theme="light" className={styles['sidebar-wrapper']}>
        <div className={styles['logo-wrapper']}>
          <div
            className={styles['logo']}
            onClick={() => navigate('/', { replace: true })}
          />
        </div>
        {isAccountMenu ? (
          <div
            className={styles['back-wrapper']}
            onClick={() => {
              if (window.history.length > 2) {
                const target = history
                  .reverse()
                  .find(item => items.map(route => route.path).includes(item));
                if (!target) {
                  navigate('/', { replace: true });
                  return;
                }
                const lastRecordIdx = history
                  .reverse()
                  .findIndex(item =>
                    items.map(route => route.path).includes(item),
                  );
                if (lastRecordIdx !== -1) {
                  navigate((lastRecordIdx + 1) * -1);
                } else {
                  navigate('/', { replace: true });
                }
              } else {
                navigate('/', { replace: true });
              }
            }}
          >
            <SvgIcon name="arrow_down" className={styles['back-icon']} />
            {t('common.back')}
          </div>
        ) : (
          <>
            <OrgSelector />
            <SafeSelector />
          </>
        )}
        <div className={styles['menu-wrapper']}>
          <Menu
            onClick={e => {
              beforeMenuClick(() => {
                const _items = isAccountMenu ? accountItems : items;
                navigate(_items[parseInt(e.key)].path, {
                  replace: pathname === '/guidance',
                });
              });
            }}
            mode="inline"
            selectedKeys={selectedKeys}
            onSelect={info => {
              beforeMenuClick(() => {
                const _items = isAccountMenu ? accountItems : items;
                setSelectedKeys([_items[parseInt(info.key)].path]);
              });
            }}
            items={menuItems}
          />
        </div>
        <div className="pl-6 pr-6 mt-4 mb-4">
          <div className="bg-[#E7EBF2] h-[1px] w-[100%]"></div>
        </div>
        {hasOrg && safeInfo && moduleInfo ? <RabbyImport /> : null}
        {hasOrg ? <WalletConnectModal /> : null}
        <div className={styles['user-info-box']}>
          <UserInfo beforeMenuClick={beforeMenuClick} />
        </div>
        <div className={styles['footer-wrapper']}>
          <Support />
        </div>
      </BaseLayout.Sider>

      <BaseLayout>
        <UpgradeNotice />
        <BaseLayout.Header className={styles['menu-header']}>
          <div>{routeTitle}</div>
          <div className={styles['menu-right']}>
            {!matchPath('/transaction', pathname) && <TransactionBundle />}
            {orgId && <GasSelector />}
            <Wallet />
          </div>
        </BaseLayout.Header>
        <BaseLayout.Content className={styles['content']}>
          <Outlet />
        </BaseLayout.Content>
      </BaseLayout>
      <AgreementsModal
        isNewUser={isNewUser}
        visible={acceptTermsVisible}
        handleClose={() => setAcceptTermsVisible(false)}
        handleConfirm={handleConfirm}
      />
      {userInfo && !userInfo.accept_terms && (
        <div
          onClick={() => {
            setAcceptTermsVisible(true);
          }}
          className={styles['fixed-box']}
        ></div>
      )}
    </BaseLayout>
  );
}
