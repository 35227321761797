import { useCallback, useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import classNames from 'classnames/bind';
import { useAtom, useAtomValue } from 'jotai';
import Web3 from 'web3';

import styles from './index.module.less';
import { getChainIdByShortName } from '@/chains/utils';
import { LoadingIcon } from '@/components';
import { globalSafeInfoAtom, safeModuleAtom } from '@/globalAtom';
import Connected from '@/routes/SafeDetail/components/WalletConnectTab/Connected';
import UriInput from '@/routes/SafeDetail/components/WalletConnectTab/UriInput';
import WaitingTx from '@/routes/SafeDetail/components/WalletConnectTab/WaitingTx';
import {
  formattedParamsAtom,
  methodAtom,
  paramsAtom,
  promiseTriggerAtom,
} from '@/walletconnect/atoms/txflow';
import useSingleSign from '@/walletconnect/hooks/useSingleSign';
import useWalletConnect from '@/walletconnect/hooks/useWalletConnect';
import { getApproveRequestData } from '@/walletconnect/utils/LegacyWalletConnectUtil';
import { parseMultiCallFuncs } from '@/walletconnect/utils/parser';

export function WalletConnectTab() {
  const cx = classNames.bind(styles);
  const safeInfo = useAtomValue(globalSafeInfoAtom);
  const moduleInfo = useAtomValue(safeModuleAtom);
  const [trigger, setTrigger] = useAtom(promiseTriggerAtom);
  const [formattedParams, setFormattedParams] = useAtom(formattedParamsAtom);
  const [method, setMethod] = useAtom(methodAtom);
  const [params, setParams] = useAtom(paramsAtom);
  const safeAddress = useMemo(() => safeInfo?.address || '', [safeInfo]);
  const chainId = useMemo(
    () => getChainIdByShortName(safeInfo?.chain_id || ''),
    [safeInfo],
  );
  const [loading, setLoading] = useState(false);
  const [to, setTo] = useState<string | string[]>('');
  const [value, setValue] = useState<string | string[]>('');
  const [data, setData] = useState<string | string[]>('');
  const {
    version,
    wcConnect,
    wcClientData,
    wcDisconnect,
    isWalletConnectInitialized,
  } = useWalletConnect(chainId, safeAddress);

  const moduleAddress = useMemo(() => {
    return moduleInfo?.address
      ? Web3.utils.toChecksumAddress(moduleInfo.address)
      : '';
  }, [moduleInfo?.address]);

  const singleSign = useSingleSign(
    moduleAddress as `0x${string}`,
    moduleInfo?.version || '',
    to,
    value,
    data,
    chainId,
  );

  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const loadTxData = async () => {
      if (!method || !formattedParams || !params || params.length === 0) return;
      const multiCallFuncs = await parseMultiCallFuncs({ params }, chainId);
      const approveData = await getApproveRequestData(
        { method, params },
        getChainIdByShortName(safeInfo?.chain_id || ''),
        moduleInfo?.address || '',
        multiCallFuncs,
      );
      if (!approveData) return;
      const { to, value, data } = approveData;
      setTo(to);
      setValue(value);
      setData(data);
    };
    loadTxData();
  }, [
    chainId,
    method,
    moduleInfo?.address,
    formattedParams,
    safeInfo?.chain_id,
    params,
  ]);

  const clear = useCallback(() => {
    setTo('');
    setValue('');
    setData('');
    setFormattedParams(undefined);
    setTrigger(undefined);
    setMethod(undefined);
    setParams(undefined);
  }, [setFormattedParams, setMethod, setParams, setTrigger]);

  useEffect(() => {
    return () => {
      const rejectFunc = trigger?.reject;
      if (rejectFunc) {
        rejectFunc(new Error('Transaction rejected by user'));
      }
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  useEffect(() => {
    setConnected(!!wcClientData);
  }, [wcClientData]);

  const killSession = useCallback(() => {
    setConnected(false);
    wcDisconnect();
    clear();
  }, [clear, wcDisconnect]);

  const waitingTxView = (
    <WaitingTx
      peerMeta={wcClientData}
      connected={connected}
      killSession={killSession}
    />
  );
  if (!isWalletConnectInitialized)
    return (
      <div className={cx('loading-wrapper')}>
        <LoadingIcon size="64px" />
      </div>
    );
  if (!wcClientData || !connected) {
    return <UriInput wcClientData={wcClientData} onConnect={wcConnect} />;
  }
  if (!formattedParams || formattedParams.length === 0) return waitingTxView;
  return (
    <Connected
      peerMeta={wcClientData}
      approveRequest={async () => {
        if (version === undefined || !trigger?.resolve || !method || !params)
          return;
        try {
          setLoading(true);
          if (!to) throw new Error('no approve data');
          const txHash = await singleSign();
          trigger?.resolve(txHash);
          clear();
        } catch (e: any) {
          trigger?.reject(e);
          console.error(e);
          message.error(e.message);
        } finally {
          setLoading(false);
        }
      }}
      rejectRequest={() => {
        trigger?.reject(new Error('Transaction rejected by user'));
        clear();
      }}
      killSession={killSession}
      loading={loading}
    />
  );
}
