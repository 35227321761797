import { useCallback, useEffect, useState } from 'react';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi';

export const useConnectWallet = () => {
  const { address, isConnected } = useAccount();
  const { openConnectModal, connectModalOpen } = useConnectModal();
  const [connecting, setConnecting] = useState(false);
  const [targets, setTargets] = useState<string[]>();
  const [start, setStart] = useState(false);
  const [trigger, setTrigger] = useState<{
    reject: (error: any) => void;
    resolve: (value: `0x${string}`) => void;
  }>();

  const reset = useCallback(() => {
    setConnecting(false);
    setTargets(undefined);
    setStart(false);
    setTrigger(undefined);
  }, []);

  useEffect(() => {
    if (start && !connecting && isConnected && address && trigger?.resolve) {
      if (targets && targets.length > 0 && !targets.includes(address)) {
        trigger?.reject(new Error('Please connect correct address'));
        reset();
      } else {
        trigger.resolve(address);
        reset();
      }
    }
  }, [address, connecting, isConnected, start, targets, trigger, reset]);

  useEffect(() => {
    if (!start || !openConnectModal) return;
    if (!isConnected || !address) {
      openConnectModal();
      setConnecting(true);
    }
  }, [address, isConnected, openConnectModal, start]);

  useEffect(() => {
    if (!start) return;
    if (connecting && !connectModalOpen) {
      if (isConnected && address) {
        if (!targets || (targets.length > 0 && targets.includes(address))) {
          trigger?.resolve(address);
          reset();
        } else {
          trigger?.reject('Please connect correct address');
          reset();
        }
      } else {
        trigger?.reject(new Error('User rejected'));
        reset();
      }
    }
  }, [
    address,
    connectModalOpen,
    connecting,
    isConnected,
    trigger,
    start,
    reset,
    targets,
  ]);

  useEffect(() => {
    return reset;
  }, [reset]);

  const run = useCallback((targets?: string[]) => {
    return new Promise<`0x${string}`>((res, rej) => {
      setStart(true);
      setTargets(targets);
      setTrigger({
        resolve: res,
        reject: rej,
      });
    });
  }, []);

  return {
    address,
    isConnected,
    connect: run,
  };
};
