import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from '@/components';
import Box from '@/components/Common/Box';

interface Props {
  visible: boolean;
  handleClose: () => void;
  handleLogin: (needSwitch?: boolean) => void;
  handleGotoAccount: () => void;
}

const NotSupportAccountModal: React.FC<Props> = ({
  visible,
  handleClose: handleCloseProp,
  handleGotoAccount: handleGotoAccountProp,
  handleLogin: handleLoginProp,
}) => {
  const { t } = useTranslation();

  const handleClose = useCallback(handleCloseProp, [handleCloseProp]);
  const handleLogin = useCallback(handleLoginProp, [handleLoginProp]);
  const handleGotoAccount = useCallback(handleGotoAccountProp, [
    handleGotoAccountProp,
  ]);
  const [step2Disabled, setStep2Disabled] = useState(true);

  return (
    <Modal
      title={t('common.note')}
      visible={visible}
      onCloseBtnClick={handleClose}
    >
      <p className="text-black font-ubMd text-base mb-[24px]">
        {t('account.coboAccountModal.note')}
        <span
          className="text-blue cursor-pointer"
          onClick={() => {
            handleLogin(true);
          }}
        >
          {t('account.coboAccountModal.switch')}
        </span>
        {t('account.coboAccountModal.note2')}
      </p>
      <h3 className="text-[#E86E2C] font-ubMd text-base">
        {t('account.coboAccountModal.step1')}
      </h3>
      <p className="text-[#181C27] font-ub text-base mb-[16px]">
        {t('account.coboAccountModal.step1Text')}
      </p>
      <Button
        type="primary"
        onClick={() => {
          setStep2Disabled(false);
          handleGotoAccount();
        }}
        btnClassName="!w-[200px]"
      >
        {t('account.coboAccountModal.step1Btn')}
      </Button>
      <Box height={24} />
      <h3 className="text-[#E86E2C] font-ubMd text-base">
        {t('account.coboAccountModal.step2')}
      </h3>
      <p className="text-[#181C27] font-ub text-base mb-[16px]">
        {t('account.coboAccountModal.step2Text')}
      </p>
      <Button
        type="primary"
        onClick={() => {
          handleLogin(false);
        }}
        btnClassName="!w-[200px]"
        disabled={step2Disabled}
      >
        {t('account.coboAccountModal.step2Btn')}
      </Button>
      <Box height={32} />
    </Modal>
  );
};

export default memo(NotSupportAccountModal);
