import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { MDContent } from '@/components/Common/MDContent';

interface StrategyDescriptionProps {
  md: string;
}
export function StrategyDescription({ md }: StrategyDescriptionProps) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();

  return (
    <div className={cx('strategy-description')}>
      <div className={cx('strategy-description-title')}>
        {t('investment.strategyDetail.description')}
      </div>
      <div className={cx('strategy-description-content')}>
        <MDContent md={md} />
      </div>
    </div>
  );
}
