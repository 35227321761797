import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, SettingItem } from '../components';
import styles from './index.module.less';
import { CoboTable } from '@/components';
import { useGlobalOrgs, useGlobalSafe } from '@/hooks';
import { getGnosisUrl } from '@/sdk/safe';

export function SafeOwner() {
  const { gnosisSafeInfo, safeInfo } = useGlobalSafe();
  const { canEdit } = useGlobalOrgs();
  const { t } = useTranslation();
  const columns = [
    {
      title: t('safe.address'),
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: t('me.label'),
      dataIndex: 'label',
      key: 'label',
      width: 200,
      render: (label: string) => {
        const addressLabelCls =
          'py-[2px] px-[12px] font-ubMd font-[500] leading-[20px] bg-label text-white rounded-[12px]';
        const ellipsisCls =
          'cursor-pointer overflow-hidden inline-block text-ellipsis max-w-full whitespace-nowrap';
        return (
          <div
            onClick={e => {
              // 点击的时候展示全部，取消隐藏
              const isEllipsisActive =
                // @ts-ignore
                e.target.clientWidth < e.target.scrollWidth;
              if (!isEllipsisActive) return;
              if (label) {
                e.currentTarget.className = addressLabelCls;
              }
            }}
            className={label ? `${addressLabelCls} ${ellipsisCls}` : ''}
          >
            {label || '--'}
          </div>
        );
      },
    },
  ];

  const ownerList = useMemo(() => {
    return gnosisSafeInfo ? Array.from(gnosisSafeInfo.owners) : [];
  }, [gnosisSafeInfo]);

  const ownerUrl = useMemo(() => {
    if (!safeInfo || !safeInfo.chain_id) return '';
    return getGnosisUrl(safeInfo.chain_id, safeInfo.address, '/settings/setup');
  }, [safeInfo]);

  return (
    <div className={styles['settings-safe']}>
      <SettingItem
        title={'Safe Owners'}
        buttons={
          canEdit
            ? [
                {
                  name: 'Add',
                  url: ownerUrl,
                  urlType: 'normal',
                  goSafeApp: true,
                },
                {
                  name: 'Edit',
                  url: ownerUrl,
                  urlType: 'normal',
                  goSafeApp: true,
                },
              ]
            : []
        }
      >
        <div className={styles['settings-safe-content']}>
          <CoboTable
            className={styles['safe-owner-table']}
            pagination={false}
            dataSource={ownerList || []}
            columns={columns}
            rowKey={'address'}
            locale={{
              emptyText: (
                <div style={{ padding: '40px 0' }}>
                  <img
                    alt=""
                    style={{ width: '160px' }}
                    src={require('@/assets/images/empty.png')}
                  />
                  <div style={{ padding: '20px 0 0 0' }}>
                    {t('common.emptyTable')}
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </SettingItem>
      <Divider />
    </div>
  );
}
