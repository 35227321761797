export const auth = {
  connectExistingWallet: `Connect existing wallet`,
  loginWithExistingEmail: `Login with existing email`,
  createAccountText: 'If you are a new user:',
  hasExistingAccountText: 'If you already have an existing Argus account:',
  welcomeToCoboArgus: 'Welcome to Cobo Argus!',
  joinText1: 'You’ve invited to join ',
  joinText2: ' in Cobo Argus.',
  welcome: 'Welcome',
  connectWallet: 'Connect Wallet',
  connectWalletLabel: 'Connect your wallet to start',
  signUpOrLoginLabel: 'Sign up or Login with',
  verification: 'Verification',
  waitForSign: 'Waiting for signing message in your wallet:',
  failedVerification: 'Failed verification',
  failedVerifyNotice:
    "Sorry, we couldn't get your valid signature of the wallet below:",
  addEmailReminder:
    'Remind: Once added, the Email can’t be used to create a new account, nor can be changed or removed.',
  failRemind:
    "Remind: If you can't switch address, please try switching account in your wallet manually.",
  switchWallet: 'Switch wallet address',
  sameAddress:
    'You still connect Argus with the new address. Please switch to your existing account first.',
  successVerification: 'Successful verification',
  successVerifyNotice: 'Your wallet address has been verified successfully:',
  bindWalletSuccess:
    'The wallet below is bound with your current account and can be used as an alternative login method',
  addEmailSuccess: 'You have added Email successfully',
  createNewAccount: 'Create new account',
  bindExistAccount: 'Bind with existing Argus account',
  verifyWeb2Account: 'Verify your existing Argus account',
  newWallet: 'New Wallet',
  existWallet: 'Existing Account',
  chooseBindAddress: 'Choose the login method of existing account',
  verifyWallet: 'Verify existing wallet...',
  bindSuccess: 'Your new wallet have been bound with existing Argus account:',
  bindSuccessLabel: 'Bound Successfully',
  launchApp: 'Launch App',
  title: 'Auth',
  verifyGaCode: 'Verify GA Code',
  pleaseEnter2fa: 'Please enter the Google 2FA authentication code',
  gaHint:
    'If you are unable to access your Google 2FA Authentication, please contact Cobo customer service at argussupport@cobo.com.',
  unavailableHint: 'Security verification unavailable?',
  congratulations: 'Congratulations',
  login: 'Login',
  loginBtn: 'Log In',
  signup: 'Sign up',
  signupBtn: 'Sign Up',
  emailAddress: 'Email address',
  inputEmail: 'Input Email',
  inputPassword: 'Input Password',
  emailEmptyError: 'Please input your e-mail address!',
  emailError: 'Invalid e-mail address!',
  password: 'Password',
  passwordEmptyError: 'Please input your password!',
  passwordError: 'Invalid password',
  emailNotExistError: 'Please sign up first',
  codeNotReceived: 'Haven’t received the code?',
  requireLowercase: 'One lowercase character',
  requireUppercase: 'One uppercase character',
  requireNumber: 'One numeric character',
  require8Char: '8~30 characters',
  agreeTo: 'By clicking continue, you agree to ',
  agreeToAnd: ' and ',
  termsOfService: 'Terms of Service',
  alreadyHaveAccount: 'Already have an account?',
  signUpSuccessTitle: 'Welcome to Cobo Argus! Your new account is ready.',
  resend: 'Resend',
  verifyCode: 'Verify Code',
  verifyCodePlaceholder: 'Verification Code',
  hasSendVerifyCode: 'An email with the verification code has been sent to ',
  haveNotReceiveCode: "Haven't received the code?",
  noCodeContent: {
    title: `If you have not received the email verification code, we recommend that you do the following:`,
    step1: `1. Please confirm that the email address you've entered is correct.`,
    step2: `2. If the verification code cannot be found in the inbox, please check your email's spam folder.`,
    step3:
      '3. If you still cannot find the verification code, your mailbox firewall may have blocked the mail. We recommend that you use a mailbox with better compatibility.',
    footer: `If you still cannot receive the verification code after trying the above methods, please contact our customer service. You'll need to provide the email to your registered account and the specific time of occurrence.`,
  },
  protocol: {
    privacyPolicy: 'Privacy Policy',
    lastUpdateAt: 'Last updated: 9 May 2022',
    policyDetail1:
      'This Privacy Notice describes how Cobo collects and processes your personal information through the Cobo websites and applications that reference this Privacy Notice. Cobo refers to an ecosystem comprising Cobo websites(whose domain names include but are not limited to www.cobo.com, mobile applications, clients, applets and other applications that are developed to offer Cobo Services, and includes independently-operated platforms, websites and clients within the ecosystem(e.g Cobo\'s Custody, DeFi Yield Fund, DaaS, MaaS, StaaS, WaaS, Ventures). "Cobo Operators" refer to all parties that run Cobo, including but not limited to legal persons, unincorporated organization and teams that provide Cobo Services and are responsible for such services. "Cobo"as used in this Policy includes Cobo Operators.',
    policyDetail2:
      'This Privacy Policy applies to all platforms, websites, and departments of Cobo and Cobo Operators. By using Cobo Services, you are consenting to the collection, storage, processing and transfer of your personal information as described in this Privacy Policy.',
  },
  resetYourPassword: 'Reset Your Password',
  next: 'Next',
  verifyEmailSubTitle:
    'To secure your account, please complete the following verification.',
  securityVerification: 'Security Verification',
  emailVerificationCode: 'Email Verification Code',
  forgotPassword: 'Forgot Password',
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordEmptyError: 'Please confirm your password',
  confirmPasswordNotMatchError: 'Passwords do not match',
  inputConfirmPassword: 'Please confirm your password',
  resetSuccessButton: 'Log In',
  PasswordChanged: 'Password Changed',
  ResetSuccessTip: 'Please log in with the new password',
  account: 'Account',
  organization: 'Organization',
  logout: 'Log Out',
  disableAddress: 'Disable Address',
  disableAddressTip: `After the address is disabled, the address can't be authorized with any new role and permission.`,
  disableAddressTip2: `Are you sure to disable the address?`,
  welcomeToCobo: 'Welcome to Cobo Argus',
  unleashDefi: 'Unleash Your DeFi Potential: Automate, Delegate and Secure.',
  aboutArgus: 'About Argus',
  guide: 'Guide',
  techDoc: 'Tech Doc',
  support: 'Support',
  success: 'Success',
  creatContent: 'Create Argus Account',
  linkContent: 'Link Existing Argus Account',
  forNewUsers: 'For new users.',
  forExistingUsers: 'For existing users.',
  linkExistingAccount: 'Link existing account',
  riskWarning: 'Risk Warning',
  riskContent:
    'The products and services on this website are not intended for residents in the [following countries].',
  understand: 'I understand and continue',
  and: 'and',
  accept: 'I hereby accept the',
  transaction: 'Transaction',
  loginWithSSO: 'Login with Cobo Account',
  signUpWithSSO: 'Sign up with Cobo Account',
  switch: 'Switch',
  switchAccount: 'Switch Account',
};
