import React from 'react';
import classNames from 'classnames/bind';

import styles from './index.module.less';

interface Props {
  text: string;
  extraClass?: string;
}

export function LampText({ text, extraClass }: Props) {
  const cx = classNames.bind(styles);
  return (
    <div className={cx('lamp-text-wrapper', extraClass)}>
      <div className={cx('lamp')} />
      <div className={cx('note-text')}>{text}</div>
    </div>
  );
}
