import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StrategyListItem } from '@/interfaces';

const initialState: {
  strategyInfo: StrategyListItem | null;
} = {
  strategyInfo: null,
};

const slice = createSlice({
  name: 'strategyDetail',
  initialState,
  reducers: {
    updateStrategyInfo: (
      state,
      action: PayloadAction<StrategyListItem | null>,
    ) => {
      state.strategyInfo = action.payload;
    },
  },
});
export const strategyDetailReducer = slice.reducer;
export const updateStrategyInfo = slice.actions.updateStrategyInfo;
