import { useMemo } from 'react';
import { message } from 'antd';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  ImportSafeModalSafesAtom,
  ImportSafeModalStep,
  importSafeModalStepAtom,
  importSafeModalVisibleAtom,
  importSafeSubTitleAtom,
  importSafeTitleAtom,
  selectedSafesAtom,
} from '../ImportSafeAtoms';
import styles from './index.module.less';
import { collect } from '@/api/axios/collect';
import { batchBindSafes, getSafeByAddress } from '@/api/logic/safe';
import {
  Button,
  LoadingIcon,
  Modal,
  SelectSafe,
  SelectWallet,
  VerifySafe,
} from '@/components';
import { orgIdAtom } from '@/globalAtom';
import { useConnectWallet } from '@/hooks/useConnectWallet';
import { useHeartbeat } from '@/hooks/useHeartbeat';
import { useSign } from '@/hooks/useSign';
import { showSafeListAtom } from '@/routes/Safe/SafeAtoms';
import { hideConfirmModal, showConfirmModal } from '@/utils/utils';

interface ImportSafeProps {
  refresh: (safe: {
    chain: number;
    name: string;
    address: string;
    id: string;
  }) => Promise<void>;
}
export function ImportSafe({ refresh }: ImportSafeProps) {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);

  const signMsg = useSign();

  const [orgId] = useAtom(orgIdAtom);
  const [showSafeList] = useAtom(showSafeListAtom);
  const safeNames = useMemo(() => {
    return showSafeList?.map(item => item.name);
  }, [showSafeList]);
  const [importSafeTitle] = useAtom(importSafeTitleAtom);
  const [importSafeModalStep, setImportSafeModalStep] = useAtom(
    importSafeModalStepAtom,
  );
  const [importSafeSubTitle] = useAtom(importSafeSubTitleAtom);
  const [importSafeModalVisible, setImportSafeModalVisible] = useAtom(
    importSafeModalVisibleAtom,
  );
  const [, setImportSafeModalSafes] = useAtom(ImportSafeModalSafesAtom);

  const [selectedSafes, setSelectedSafes] = useAtom(selectedSafesAtom);

  const { address, isConnected, connect } = useConnectWallet();

  useHeartbeat(
    'View_Page_ConnectSafeWallet',
    importSafeModalVisible &&
      importSafeModalStep === ImportSafeModalStep.SelectWallet,
  );
  useHeartbeat(
    'View_Page_ImportSafe',
    importSafeModalVisible &&
      importSafeModalStep === ImportSafeModalStep.SelectSafe,
  );

  const onCancel = () => {
    setImportSafeModalVisible(false);
    setImportSafeModalStep(ImportSafeModalStep.SelectWallet);
    setImportSafeModalSafes([]);
    setSelectedSafes([]);
  };

  const cancelBtn = (
    <Button
      className={cx('cancel-btn-warp', 'modal-btn-wrapper')}
      onClick={onCancel}
      btnClassName={cx('modal-btn', 'cancel-btn')}
    >
      {t('common.cancel')}
    </Button>
  );

  const goToSafe = () => {
    collect({
      cid: 'Click_SafeCreate_CreateNow',
    });
    onCancel();
    showConfirmModal({
      confirmModalVisible: true,
      confirmModalTitle: t('safeManagement.importSafe.goToSafeTitle'),
      confirmModalElement: t('safeManagement.importSafe.goToSafeText'),
      onConfirm: () => {
        window.open('https://app.safe.global/welcome');
        hideConfirmModal();
        onCancel();
      },
      onCancel: () => {
        hideConfirmModal();
      },
      okText: t('common.yes'),
      cancelText: t('common.no'),
      showConfirmModalClose: () => {
        hideConfirmModal();
      },
    });
  };

  const onConnectMetaMask = async () => {
    collect({
      cid: 'Click_SafeCreate_ConnectSafeWallet',
    });
    let walletAddress = '';
    if (isConnected && address) {
      walletAddress = address as `0x${string}`;
    } else {
      try {
        walletAddress = await connect();
      } catch (e: any) {
        message.error(e?.message);
        throw e;
      }
    }
    await loadSafeFromAddressAndOrg(walletAddress);
  };

  const loadSafeFromAddressAndOrg = async (address: string) => {
    if (!orgId) return;
    setImportSafeModalStep(ImportSafeModalStep.LoadingSafe);
    try {
      const safes = (await getSafeByAddress(address, orgId)).filter(
        safe => !safe.imported,
      );
      if (!safes || safes.length === 0) {
        setImportSafeModalStep(ImportSafeModalStep.EmptySafe);
      } else {
        setImportSafeModalStep(ImportSafeModalStep.SelectSafe);
        setImportSafeModalSafes(safes);
      }
    } catch (e) {
      setImportSafeModalStep(ImportSafeModalStep.SelectWallet);
    }
  };

  const disableVerifyBtn = useMemo(() => {
    if (!selectedSafes || selectedSafes.length === 0) return true;
    const hasRepeatName = selectedSafes.some(safe => {
      const safeName = safe.safe_name;
      const otherSafeNames = selectedSafes
        .filter(item => item.safe_address !== safe.safe_address)
        .map(item => item.safe_name);
      if (!safeName) return false;
      return [...safeNames, ...otherSafeNames]?.includes(safeName);
    });
    if (hasRepeatName) return true;

    const allHasName = selectedSafes.every(safe => safe.safe_name);
    return !allHasName;
  }, [selectedSafes, safeNames]);

  const verifyAndImport = async () => {
    collect({
      cid: 'Click_SafeCreate_ImportSafeList',
      extra: {
        safe_list: selectedSafes.map(safe => safe.safe_address),
      },
    });
    if (!orgId) return;
    const safes = selectedSafes.map(safe => {
      return {
        name: safe.safe_name as string,
        chain: safe.chain_id,
        address: safe.safe_address,
      };
    });
    try {
      setImportSafeModalStep(ImportSafeModalStep.ImportingSafe);
      const { message: signMessage, signature } = await signMsg(
        t('common.signToConfirm'),
      );
      const importedSafes = await batchBindSafes(
        orgId,
        safes,
        signMessage,
        signature,
      );
      onCancel();
      message.success(
        t('safeManagement.importSuccess', {
          count: safes.length,
          s: safes.length > 1 ? 's' : '',
        }),
      );
      await refresh(importedSafes[0]);
    } catch {
      // 回到上一步
      setImportSafeModalStep(ImportSafeModalStep.VerifySafe);
    }
  };

  return (
    <Modal
      visible={importSafeModalVisible}
      title={importSafeTitle}
      className={cx('import-safe')}
      closable={true}
      onCloseBtnClick={onCancel}
      width="680px"
    >
      <div className={cx('import-safe-sub-title')}>{importSafeSubTitle}</div>
      {importSafeModalStep === ImportSafeModalStep.SelectWallet && (
        <SelectWallet connectWallet={onConnectMetaMask} />
      )}
      {importSafeModalStep === ImportSafeModalStep.LoadingSafe && (
        <div className={cx('loading-safe')}>
          <div className={cx('loading-box')}>
            <LoadingIcon size="64px" />
          </div>
          <div className={cx('btns-box', 'single-btn-box')}>{cancelBtn}</div>
        </div>
      )}
      {importSafeModalStep === ImportSafeModalStep.EmptySafe && (
        <div className={cx('empty-safe')}>
          <div className={cx('btns-box')}>
            {cancelBtn}
            <Button
              onClick={goToSafe}
              className={cx('modal-btn-wrapper')}
              btnClassName={styles['modal-btn']}
            >
              {t('safeManagement.importSafe.createNewSafe')}
            </Button>
          </div>
        </div>
      )}
      {importSafeModalStep === ImportSafeModalStep.SelectSafe && (
        <>
          <SelectSafe reload={onConnectMetaMask} />
          <div className={cx('btns-box')}>
            {cancelBtn}
            <Button
              onClick={() => {
                const _selectedSafes = selectedSafes.map(safe => {
                  return {
                    ...safe,
                    safe_name: '',
                  };
                });
                setSelectedSafes(_selectedSafes);
                setImportSafeModalStep(ImportSafeModalStep.VerifySafe);
              }}
              className={cx('modal-btn-wrapper')}
              btnClassName={styles['modal-btn']}
              disabled={!selectedSafes || !selectedSafes.length}
            >
              {t('common.continue')}
            </Button>
          </div>
        </>
      )}
      {(importSafeModalStep === ImportSafeModalStep.VerifySafe ||
        importSafeModalStep === ImportSafeModalStep.ImportingSafe) && (
        <>
          <VerifySafe />
          <div className={cx('btns-box')}>
            <Button
              className={cx('cancel-btn-warp', 'modal-btn-wrapper')}
              onClick={() => {
                setImportSafeModalStep(ImportSafeModalStep.SelectSafe);
              }}
              btnClassName={cx('modal-btn', 'cancel-btn')}
              disabled={
                importSafeModalStep === ImportSafeModalStep.ImportingSafe
              }
            >
              {t('common.back')}
            </Button>
            <Button
              loading={
                importSafeModalStep === ImportSafeModalStep.ImportingSafe
              }
              onClick={verifyAndImport}
              className={cx('modal-btn-wrapper')}
              btnClassName={cx('modal-btn')}
              disabled={
                importSafeModalStep === ImportSafeModalStep.ImportingSafe ||
                disableVerifyBtn
              }
            >
              {t('safeManagement.import')}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}
