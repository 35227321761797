export const common = {
  ok: 'OK',
  cancel: 'Cancel',
  confirm: 'Confirm',
  or: 'Or',
  view: 'View',
  next: 'Continue',
  continue: 'Continue',
  refresh: 'Refresh',
  prev: 'Go Back',
  edit: 'Edit',
  delete: 'Remove',
  login: 'Log In',
  logout: 'Log Out',
  copy: 'Copy',
  note: 'Note',
  copyToClipboard: 'Copy to clipboard',
  copySuccess: 'Copied to clipboard',
  copyFailed: 'Failed to copy',
  paste: 'Paste',
  submit: 'Submit',
  congratulations: 'Congratulations',
  finish: 'Finish',
  accept: 'Accept',
  acceptNow: 'Accept now',
  connect: 'Connect',
  disconnect: 'Disconnect',
  reject: 'Reject',
  approve: 'Approve',
  slogan: 'Powering your crypto ambitions.',
  en: 'EN',
  cn: 'CN',
  back: 'Back',
  unnamed: 'Unnamed',
  goForward: 'Go',
  support: 'Support',
  guide: 'Guides',
  contactUsAt: 'Contact us at ',
  helpText: 'If you need any help, subject line: Cobo Argus',
  yes: 'Yes',
  no: 'No',
  previousStep: 'Previous',
  emptyTable: "It's empty here.",
  network: 'Network',
  expand: 'Expand',
  collapse: 'Collapse',
  optional: 'Optional',
  customize: 'Customize',
  fetchingToken: 'Fetching...',
  tokenNotFound: 'Token not found',
  notFound: 'Not found',
  invalidToken: 'Invalid token address',
  create: 'Create',
  add: 'Add',
  select: 'Select',
  safe: 'Safe',
  moderate: 'Moderate',
  weak: 'Weak',
  searchByAddress: 'Search by address',
  protocol: 'Protocol',
  token: 'Token',
  type: 'Type',
  all: 'All',
  waiting: 'Waiting',
  statusText: 'Status',
  status: {
    running: 'Running',
  },
  noPermission: 'You need to be an admin/operater to continue',
  action: 'Action',
  actions: 'Actions',
  name: 'Name',
  role: 'Role',
  email: 'Email',
  save: 'Save',
  remove: 'Remove',
  revoke: 'Revoke',
  address: 'Address',
  addresses: 'Addresses',
  member: 'Member',
  upgradeNotice: 'Cobo Argus New Design is officially live!',
  upgradeBtn: 'Upgrade now 🚀',
  learnMore: 'Know more',
  dontShow: "Don't show again",
  safeWallet: 'Safe{Wallet}',
  signToConfirm: 'Please sign to confirm you are the owner of the address',
  service: 'Service',
  accountSettings: 'Account Settings',
  notice: 'Notice',
  goto: 'Go to',
  download: 'Download',
  contracts: 'Contracts',
  functions: 'Functions',
};
