import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import styles from './index.module.less';
import { getStrategyServiceTypes } from '@/api/logic';
import { Button } from '@/components';

interface Props {
  onChange?: (chain: string) => void;
}

const ServiceSearch = memo(({ onChange }: Props) => {
  const [data, setData] = useState<string[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    (async () => {
      const isFarming = ['/my-portfolio/farming', '/investment'].includes(
        pathname,
      );
      const res = await getStrategyServiceTypes(isFarming ? '2' : '5');
      if (Array.isArray(res)) {
        res.unshift('All');
      }
      setData(res as any);
    })();
  }, [pathname]);

  const handleChange = useCallback(
    (el: string, index: number) => {
      setActiveIndex(index);
      onChange && onChange(el === 'All' ? '' : el);
    },
    [onChange],
  );

  return (
    <div className={styles['container']}>
      <p> {t('common.service')} :</p>
      {data.map((el, index) => {
        const selected = index === activeIndex;
        const selectedBtn = '!text-blue !font-[500] !font-ubMd';
        const btn =
          '!text-[12px] !leading-[20px] !py-0 !px-[10px] !rounded-[8px] !bg-transparent';
        const unselectedBtn =
          '!text-greyFont !font-[400] !font-ub !border-[#E7EBF2] !border-[2px] !bg-transparent hover:!border-blue hover:!bg-transparent';
        const margin = index === data.length - 1 ? '' : 'mr-[12px]';
        return (
          <Button
            key={el}
            type="white"
            className={margin}
            btnClassName={`${selected ? selectedBtn : unselectedBtn} ${btn}`}
            onClick={() => handleChange(el, index)}
          >
            {el}
          </Button>
        );
      })}
    </div>
  );
});

export { ServiceSearch };
