import { IBaseChain } from './base';
import { ChainConfig, Chains } from './utils';

export default class Chain {
  networkInstance: IBaseChain;
  config: ChainConfig;

  constructor(chainId: number) {
    const config = Chains.get(chainId);
    if (config) {
      const instance = config.network();
      if (instance) {
        this.networkInstance = instance;
        this.config = config;
      } else {
        throw new Error(`Chain: ${config.chainName}-${chainId} not supported.`);
      }
    } else {
      throw new Error(`Chain ID: ${chainId} not supported.`);
    }
  }

  addressUrl(address: string): string {
    return this.networkInstance.addressUrl(address) || '';
  }

  tokenUrl(address: string): string {
    return this.networkInstance.tokenUrl(address) || '';
  }

  rpcUrl(): string {
    return this.networkInstance.rpcUrl() || '';
  }

  safeApiUrl(): string {
    return this.networkInstance.safeApiUrl() || '';
  }

  safeWebBaseUrl(): string {
    return this.networkInstance.safeWebBaseUrl;
  }

  safeUrl(address: string): string {
    return this.networkInstance.safeUrl(address) || '';
  }

  txDetailUrl(hash: string): string {
    return this.networkInstance.txDetailUrl(hash) || '';
  }

  getSubSafeFactory(): string {
    return this.networkInstance.subsafeFactory || '';
  }

  getBaseToken(): string {
    return this.networkInstance.baseToken;
  }

  getBaseTokenName(): string {
    return this.networkInstance.baseTokenName;
  }

  multicallAddress(): string {
    return this.networkInstance.multiSendCallOnlyAddress;
  }
}
