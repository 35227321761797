export const orgSettings = {
  title: 'Settings',
  saveChange: 'Save Changes',
  editConfirmTitle: 'Edit organization profile',
  editConfirmContent: 'Are you sure that you want to save the changes?',
  updateSuccess: `Congratulations! The changes you've made to the organization profile have been saved.`,
  profile: 'Profile',
  name: 'Name',
  size: 'Size',
  type: 'Type',
  organization: 'Organization',
};
