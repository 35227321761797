export const bots = {
  currentIndicatorValue: 'Current Value is:',
  successRate: 'Success Rate',
  averageGasCost: 'Average Gas Cost',
  execute: 'Execute',
  totalGasCost: 'Total Gas Cost',
  lastTriggered: 'Last Triggered:',
  createNewBot: 'Create New Bot',
  botManagement: 'Bot Management',
  setUpBot: 'Set Up Bot',
  console: 'Console',
  services: 'Services',
  detail: 'Detail',
  details: 'Details',
  history: 'History',
  gasFee: 'Gas Fee',
  noGasAccount: 'No Gas Account.',
  createBots: 'Create Bots',
  viewDetail: 'View Detail',
  executeNowNote:
    'Your bot will execute its task(s) immediately. Please confirm before proceeding.',
  status: 'Status',
  success: 'Success',
  fail: 'Fail',
  bots: 'Bots',
  lowGasContent:
    'Insufficient balance in Gas Account. A minimum of {{number}} {{token}} required.',
  addNewTokenApproval: 'Add New Token Approval',
  noSafeHint: `To create customized bots, please import Safe{Wallet} first.`,
  noModuleHint: `To create customized bots, please import Cobo Safes first.`,
  noEnabledHint: `To create customized bots, please enable Cobo Safes first.`,
  type: 'Type',
  customized: 'Customized',
  claim: 'Claim',
  myBots: 'Bots',
  viewAll: 'View All',
  generalBots: 'General Bots',
  customizedBots: 'Customized Bots',
  customizedBotsTip:
    'Only bots that you have configured under the current Safe{Wallet} will be displayed.',
  gasAccountTooltip:
    'Gas fees will be incurred when the strategy bot executes a task. Please top up your Gas Account first.',
  authorizationDetail: 'Authorization Detail',
  gnosisSafe: 'Safe{Wallet}',
  depositGas: 'Deposit {{token}} Gas',
  gasAccount: 'Gas Accounts',
  action: 'Action',
  deposit: 'Deposit',
  balance: 'Balance',
  strategyBots: 'Strategy Bots',
  investmentDetail: 'Investment Detail',
  strategyBotsList: 'Strategy Bots List',
  setUp: 'Set Up',
  reviewPermission: 'Review Permissions',
  addToTxBundle: '+ Add to Transaction Bundle',
  view: 'View',
  running: 'Running',
  paused: 'Paused',
  lowGasBtn: 'top up',
  lowGasText1: 'We recommend that you ',
  lowGasText2: ' at least ',
  lowGasText3: ' first. ',
  lowGasText4:
    'The bot may fail if the balance in your Gas Account is insufficient. It will stop running if your balance is zero.',
  time: 'Updated Time',
  times: 'Time(s)',
  total: 'Total',
  failure: 'Failure',
  pause: 'Pause',
  resume: 'Resume',
  confirmPauseTitle: 'Do you want to pause the strategy bot?',
  confirmPauseContent:
    'The bot will no longer automatically execute the assigned task.',
  confirmResumeTitle: 'Do you want to resume the strategy bot?',
  confirmResumeContent:
    'The strategy bot will start to automatically execute the assigned task. Please note that gas fees will be incurred.',
  confirmTerminateTitle: 'Note',
  confirmTerminateContent:
    'Are you sure that you want to terminate this bot ({{title}})?',
  note: 'Note:',
  emptyText1: `You don't have any strategy bot yet. `,
  emptyText2: 'You can head to the ',
  emptyBtn: 'Strategy Marketplace',
  emptyText3: ' to make an investment first.',
  createCustomizeBot: 'create a customized bot.',
  emptyBotText2: 'to set up strategy bot service.',
  coboSafe: 'Cobo Safe',
  bot: 'Bot',
  terminate: 'Terminate',
  edit: 'Edit',
  terminateTip: `When you terminate the bot, a multi-sig confirmation is required because bot authorization will be removed on chain.`,
  terminateFailedMsg: 'Terminate bot failed',
  terminateSuccessMsg: 'Terminate bot successfully',
  needsConfirmationTitle: `Multisig Confirmation Required`,
  needsConfirmationContent1: `A multi-sig confirmation is required to terminate the bot.`,
  needsConfirmationContent2: `Please inform Safe owners to complete the transaction on `,
  farmingBot: 'Farming Bot',
  withdrawalBot: 'Withdrawal Bot',
  leverageBot: 'Leverage Bot',
  title: 'Bots',
  customizeBot: 'Customized Bot',
  customizeBotTitle: 'Customized Bots',
  customizeBotTooltip:
    'You can customize the monitoring and trigger conditions for your bots to automatically interact with DeFi protocols and execute the corresponding tasks.',
  strategyBotTitle: 'Strategy Bots',
  gasAccountTitle: 'Gas Accounts',
  notificationText: 'Email address list is as below:',
  viewMore: 'View More',
  please: 'Please',
  addEmail: 'add email',
  or: 'or',
  inviteMember: 'invite member',
  first: 'first.',
  notificationTip: `There's no available Email in this organization currently.`,
  liquidation: `Liquidation`,
  liquidationCollateralRatio: `Liquidation Ratio`,
  collateralRatio: `Collateral Ratio`,
  currentCollateralRatio: `Current Collateral Ratio`,
  equalLiquidationError:
    'The target ratio cannot be the same as the liquidation ratio.',
  equalCurrentError: `The bot trigger ratio cannot be the same as the current collateral ratio.`,
  targetLessThanLiquidationError: `The target ratio must be greater than the liquidation ratio.`,
  triggerLessThanLiquidationError: `The bot trigger ratio must be greater than the liquidation ratio.`,
  addLeverageTargetGreaterThanTriggerError: `The target ratio must be less than the bot trigger ratio.`,
  addLeverageTriggerLessThanCurrentError: `The bot trigger ratio must be greater than the current collateral ratio.`,
  addLeverageTriggerLessThanTargetError: `The bot trigger ratio must be greater than the target ratio.`,
  deleverageTargetLessThanTriggerError: `The target ratio must be greater than the bot trigger ratio.`,
  deleverageTriggerGreaterThanCurrentError: `The bot trigger ratio must be less than the current collateral ratio.`,
  deleverageTriggerGreaterThanTargetError: `The trigger ratio must be less than the target ratio.`,
  leverageTargetMinRatio: `The target ratio must be greater than or equal to {{number}}%.`,
  addLeverage: `Add Leverage`,
  deleverage: `Deleverage`,
  botTrigger: `Bot Trigger`,
  target: `Target`,
  ratioCondition: 'If Collateral Ratio ≥',
  ratioCondition2: 'If Collateral Ratio ≤',
  boost: 'Boost It to:',
  rapay: 'Repay It to:',
  borrowingMode: 'Borrowing Mode',
  paybackMode: 'Payback Mode',
  borrow: 'Borrow',
  against: 'Against',
  withdraw: 'Withdraw',
  withdrawal: 'Withdrawal',
  withdrawSuccess: 'Withdraw Request Successful!',
  withdrawFailed: 'Withdraw Failed...',
  withdrawNote:
    "Please withdraw your tokens in full and only deposit them into the Safe{Wallet} you've imported. ",
  withdrawNote2:
    'When you submit a request to withdraw tokens from the Gas Account, all bots on this network will be automatically paused.',
  payBack: 'PayBack',
  approveToken: 'Approve Token',
  spender: 'Spender',
  Allowance: 'Allowance',
  addLeverageTableTitle: 'Borrow > Deposit',
  deleverageTableTitle: 'Withdraw > Pay Back',
  payback: 'PayBack',
  paybackTip: `Tokens that will be used for payback must be stored in the Safe{Wallet}. If the token balance is insufficient, we will still proceed ahead with the payback. However, your collateral ratio may not reach the target value.`,
  expertMode: 'Expert Mode',
  manualAuthorize: 'Single-Sig Authorization',
  myStrategy: 'My Strategy',
  myStrategyToolTip:
    'Only strategies that you have configured under the current Safe{Wallet} will be displayed',
  myBot: 'My Bot',
  setupBotSuccess: 'You have successfully set up your "{{bot}}"',
  editBotSuccess: 'You have successfully edit your "{{bot}}"',
  claimingBot: 'Claiming Bot',
  configured: 'Configured',
  refreshBasicAuthNote: `Would you like to update your Webhook? Please note that updating your Webhook will render the current credential invalid. In the event that your current credential is compromised, we strongly recommend updating your Webhook for enhanced security.`,
  close: 'Close',
  skipTest: 'Skip Test',
  tryAgain: 'Try Again',
  findNewRecord: 'Find New Record',
  noRecord: 'No request found, please try again',
  recordCount: `We will load up to {{count}} most recent records, that have not appeared previously.`,
  recordTime: `original record pulled on {{time}}`,
};
