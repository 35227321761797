import { useEffect, useState } from 'react';
import { useInterval } from 'ahooks';

export const useCountDown = (s: any) => {
  const [seconds, setSeconds] = useState(s);
  useEffect(() => {
    setSeconds(s);
  }, [s]);
  useInterval(() => {
    if (seconds > 0) {
      const newSeconds = seconds - 1;
      setSeconds(newSeconds);
    }
  }, 1000);

  return [seconds, setSeconds];
};
