import { BaseChain } from './base';

export default class Polygon extends BaseChain {
  name = 'Polygon';
  shortName = 'matic';

  private providerUrl = `https://polygon-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_POLYGON}`;
  private scanBaseUrl = 'https://polygonscan.com';
  public safeWebBaseUrl = 'https://app.safe.global';
  private safeApiBaseUrl = 'https://safe-transaction-polygon.safe.global';
  baseToken = 'MATIC';
  subsafeFactory = '0x8d765371024ae481bfB850f53265dAe8398945b1';
  baseTokenName = 'Matic';

  public safeUrl(address: string): string {
    return `${this.safeWebBaseUrl}/home?safe=${this.shortName}:${address}`;
  }

  public addressUrl(address: string): string {
    return `${this.scanBaseUrl}/address/${address}`;
  }

  public tokenUrl(address: string): string {
    return `${this.scanBaseUrl}/token/${address}`;
  }

  public rpcUrl(): string {
    return this.providerUrl;
  }

  public safeApiUrl(): string {
    return this.safeApiBaseUrl;
  }

  public txDetailUrl(hash: string): string {
    return `${this.scanBaseUrl}/tx/${hash}`;
  }
}
