import React, { useEffect, useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import styles from './index.module.less';
import {
  batchAcceptInvitations,
  getMyProfile,
  listInviteRecords,
  listRoles,
  switchOrg,
} from '@/api';
import { collect } from '@/api/axios/collect';
import { Modal } from '@/components';
import { globalUserInfoAtom, orgIdAtom } from '@/globalAtom';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { OrganizationItem } from '@/interfaces';
import { updateInviteRecords, updatePageLoading, updateRoles } from '@/store';

interface Props {
  visible: boolean;
  closeModal: () => void;
  onSuccess: (label: string) => void;
  onSetCreateModalVisible?: () => void;
}

export function InvitationModal({
  visible,
  closeModal,
  onSuccess,
  onSetCreateModalVisible,
}: Props) {
  const { t } = useTranslation();
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const inviteRecords = useAppSelector(state => state.orgPage.inviteRecords);
  const roles = useAppSelector(state => state.rolePage.roles);
  const [orgId, setOrgId] = useAtom(orgIdAtom);
  const [userInfo, setUserInfo] = useAtom(globalUserInfoAtom);

  const isNoOrgUser = useMemo(() => {
    return userInfo?.org_count === 0;
  }, [userInfo?.org_count]);

  const onOrgChange = async (newOrgId: string) => {
    if (newOrgId !== orgId) {
      await switchOrg(newOrgId);
      setOrgId(newOrgId);
      navigate('/');
    }
  };
  useEffect(() => {
    if (!visible) {
      setSelectedRecords([]);
      return;
    }
    if (inviteRecords === null) {
      listInviteRecords().then(records => {
        dispatch(updateInviteRecords(records));
      });
    }
    if (roles === null) {
      listRoles().then(data => {
        dispatch(updateRoles(data));
      });
    }
  }, [dispatch, visible, inviteRecords, roles]);

  const btnProps = useMemo(() => {
    const cancelBtn = {
      text: t('common.cancel'),
      onClick: closeModal,
    };
    const acceptBtn = {
      text: isNoOrgUser ? t('common.acceptNow') : t('common.accept'),
      primary: true,
      onClick: async () => {
        try {
          dispatch(updatePageLoading(true));
          await batchAcceptInvitations(selectedRecords);
          const recordInfos: OrganizationItem[] = [];
          inviteRecords?.forEach(item => {
            if (selectedRecords.includes(item.id)) {
              recordInfos.push(item);
            }
          });
          const orgNames = recordInfos.map(
            item => `"${item.name}#${item.display_id}"`,
          );
          onSuccess(t('guidance.joinOrgSuccess', { orgs: orgNames.join(' ') }));
          const userInfo = await getMyProfile();
          setUserInfo(userInfo);
          const newOrgId = recordInfos[0].id;
          await onOrgChange(newOrgId);
          closeModal();
          collect({
            cid: 'Click_CreateJoinOrg_Accept',
            extra: {
              org_id: newOrgId,
            },
          });
        } finally {
          dispatch(updatePageLoading(false));
        }
      },
      disabled: selectedRecords.length === 0,
    };
    const createNewOrgBtn = {
      text: t('guidance.createNewOrg'),
      white: true,
      onClick: () => {
        onSetCreateModalVisible && onSetCreateModalVisible();
      },
    };
    return isNoOrgUser ? [acceptBtn, createNewOrgBtn] : [cancelBtn, acceptBtn];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isNoOrgUser,
    selectedRecords,
    inviteRecords,
    closeModal,
    t,
    setUserInfo,
    onSetCreateModalVisible,
  ]);

  return (
    <Modal
      title={t('guidance.checkTheInvitation')}
      visible={visible}
      onCloseBtnClick={isNoOrgUser ? undefined : closeModal}
      btnProps={btnProps}
      zIndex={1010}
    >
      <div className={styles['invite-modal-wrapper']}>
        <div className={styles['invite-desc']}>
          {t('guidance.chooseJoinOrgs')}
        </div>
        {inviteRecords && (
          <div className={styles['invite-records-wrapper']}>
            {inviteRecords.map(item => {
              return (
                <div
                  key={item.id}
                  className={styles['invite-record-item']}
                  onClick={() => {
                    if (selectedRecords.includes(item.id)) {
                      setSelectedRecords(
                        Array.from(selectedRecords).filter(
                          current => current !== item.id,
                        ),
                      );
                    } else {
                      const newRecords = Array.from(selectedRecords);
                      newRecords.push(item.id);
                      setSelectedRecords(newRecords);
                    }
                  }}
                >
                  <div className={styles['invite-record-title-wrapper']}>
                    <div
                      className={
                        styles[
                          selectedRecords.includes(item.id)
                            ? 'selected-icon'
                            : 'icon'
                        ]
                      }
                    />
                    <div
                      className={styles['invite-record-title']}
                    >{`Organization: ${item.name}#${item.display_id}`}</div>
                  </div>
                  <div className={styles['invite-record-role']}>
                    {`Role: ${
                      roles?.find(role => role.value === item.role)?.name || ''
                    }`}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Modal>
  );
}
