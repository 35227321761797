import classNames from 'classnames/bind';
import Markdown from 'markdown-to-jsx';

import styles from './index.module.less';
import { CoboImage } from '@/components';

const cx = classNames.bind(styles);

interface Props {
  md: string;
}

interface IconProps {
  src: string;
  link?: string;
  name: string;
}

interface LinkProps {
  href: string;
  children: string[];
}

function MDLink({ href, children }: LinkProps) {
  return (
    <a className={cx('md-link')} href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
}

function MDIcon({ src, link, name }: IconProps) {
  return (
    <img
      className={cx('md-icon')}
      src={src}
      alt={name}
      onClick={() => {
        if (link) window.open(link, '_blank');
      }}
    />
  );
}

function ChainIcon({ src }: IconProps) {
  return <CoboImage className={cx('chain-icon')} url={src} />;
}

function SelectIcon({ src }: IconProps) {
  return <CoboImage className={cx('select-icon')} url={src} />;
}

export function MDContent({ md }: Props) {
  md = md.replaceAll('\n', '\n\n').replaceAll('\n\n\n\n', '\n\n');
  return (
    <div className={cx('md-wrapper', 'font-ub')}>
      <Markdown
        options={{
          overrides: {
            h1: { props: { className: cx('md-title') } },
            h2: { props: { className: cx('md-subtitle') } },
            p: { props: { className: cx('md-content') } },
            ol: { props: { className: cx('md-ol') } },
            img: { props: { className: cx('md-img') } },
            a: { component: MDLink },
            Link: { component: MDLink },
            Icon: { component: MDIcon },
            ChainIcon: { component: ChainIcon },
            SelectIcon: { component: SelectIcon },
          },
        }}
      >
        {md}
      </Markdown>
    </div>
  );
}
