export const safeManagement = {
  home: 'Home',
  settings: 'Settings',
  disabledTip: `The Cobo Safe is disabled. Please enable the Cobo Safe.`,
  noModuleTip: `The Cobo Safe hasn’t been created. Please create a Cobo Safe.`,
  import: 'Import',
  safeNameRepeat: 'The name already exists.',
  waitingConfirmation: 'Upgrading...',
  upgradeTitle: 'Upgrade Cobo Safe',
  upgradeContent: `You’ll upgrade to the latest Cobo Safe version for the Safe{Wallet} below:`,
  upgradeTips:
    'Note: A new Cobo Safe will be created when you upgrade the Cobo Safe. Your current module will also be valid.',
  doItLater: 'Do it later',
  importSuccess: 'You’ve imported {{count}} Safe{Wallet}',
  title: 'Cobo Safe',
  safeName: 'Safe Name',
  coboSafeModules: 'Cobo Safe',
  upgrade: 'Upgrade',
  addBtn: 'Import Safe{Wallet}',
  emptySafe: "Don't have a Safe{Wallet} yet?",
  emptyToCreateSafe: 'Click to create one.',
  allSafeWallets: 'All Safe{Wallet}s',
  safeModule: 'Cobo Safe',
  metamask: 'Metamask',
  walletConnect: 'WalletConnect',
  noModule: 'No Module',
  safeNotLoaded: 'Safe{Wallet} not loaded?',
  version: 'Version',
  path: 'All Safe{Wallet}',
  copy: 'Address copied successfully!',
  safeOwners: 'Safe Owner(s):',
  threshold: 'Threshold',
  totalAssets: 'Total Assets',
  totalBalance: 'Total Balance',
  owners: 'owners',
  ownersText: `Every transaction requires the confirmation of: `,
  verifyAndImport: 'Verify and Import',
  importSafe: {
    title: 'Import Safe{Wallet}',
    selectWallet: 'Select a Wallet',
    confirmCoboSafeModule: 'Confirm Cobo Safe',
    selectWalletText: `Please select a wallet`,
    emptySafeText: `Sorry, we haven’t detected any Safe(s) under your connected address. Please create a new Safe first.`,
    loadingSafeText: `We’re loading the safe under your connected address.`,
    selectSafeText: `Your connected address is the owner of the following Safe{Wallet}. Please select the ones you'd like to import into Argus.`,
    verifySafeText: `Please input the Safe name and check the Cobo Safes below. All the Cobo Safes will be imported into Cobo Argus.`,
    createNewSafe: 'Create a New Safe',
    goToSafeTitle: 'Head to third-party website',
    goToSafeText: `You’re about to leave Argus and head to the Safe website  to complete the operation.`,
  },
  removeModal: {
    title: 'Remove Cobo Safe',
    tips: 'Are you sure that you want to remove this Cobo Safe?',
    cancelBtn: 'No',
    okBtn: 'Yes',
  },
  emptyTips: {
    text1: "It's empty here.",
    text2: 'Try to',
    links: 'Import Your first Safe.',
  },
  toolTips: {
    debank: 'View on Debank',
    copy: 'Copy to Clipboard',
    es: 'View on Blockchain Explorer',
    gs: 'View on Safe{Wallet}',
    remove: 'Remove Safe',
    topBanner:
      'Cobo Safe is an on-chain access control management infrastructure based on the multi-signature smart contract wallet Safe{Wallet}.',
    address: 'Safe{Wallet} address',
    asset:
      'Total asset = total balance in Safe{Wallet} + total asset in the DeFi protocol',
    safeModule:
      'Cobo Safe is the core component of Cobo Safe. It is a smart contract that supports role-based access control for on-chain activity.',
  },
  bind: {
    succ: 'Import Successful',
    fail: 'Import Failed',
  },
  importModal: {
    title: 'Import Cobo Safe',
    exit: {
      title: 'Reminder',
      text: 'You’re importing a new Cobo Safe. Are you sure that you want to cancel?',
    },
    bind: {
      succ: 'Import Successful',
      fail: 'Import Failed',
    },
    step0: {
      chain: 'Network',
      emptyChainError: 'Please select a chain',
      name: 'Name',
      namePlaceholder: 'Please input name',
      emptyNameError: 'Please input your name',
      gnosisSafeAddress: 'Safe{Wallet} address',
      emptyAddressError: 'Please input your address',
      addressPlaceholder: 'Please input Safe address',
      invalidAddressError: 'Invalid Safe{Wallet} address',
      tips: 'Note: Only Safe{Wallet} owners could import Cobo Safe',
      backBtn: 'Go Back',
      continueBtn: 'Continue',
    },
    step1: {
      title:
        'All enabled Cobo Safes will be imported into Argus. Please choose the default Cobo Safe version to display.',
      roles: 'Roles',
      delegates: 'Delegates',
      contracts: 'Target Contracts',
      acl: 'ACL',
      backBtn: 'Back',
      continueBtn: 'Continue',
      createModule: {
        title:
          'Oops, no Cobo Safe has been detected! Please create a Cobo Safe first.',
        note: 'Note: Cobo Safe currently can only be created on Cobo Safe DApp.',
        backBtn: 'Back',
        continueBtn: 'Create Cobo Safe',
      },
      enableModule: {
        title:
          'Oops, no enabled Cobo Safe has been detected! Please enable a Cobo Safe first.',
        note: 'Note: Cobo Safe currently can only be created on Cobo Safe DApp.',
        backBtn: 'Back',
        continueBtn: 'Enable Cobo Safe',
      },
    },
    step2: {
      transaction: 'Any transaction requires the confirmation of:',
      tips: 'Please make sure that you have installed MetaMask before proceeding.',
      address: 'Safe Owner Address',
      backBtn: 'Back',
      continueBtn: 'Verify & Import',
    },
  },
  serviceAgreementsModal: {
    cancel: 'Cancel',
    title: 'Service Agreements',
    content:
      'We have upgraded our service agreements. Please make sure to read our latest',
    newUserContent: 'To continue using our services, please agree to our',
    first: 'first',
    and: 'and',
    privacyPolicy: 'Privacy Policy',
    termsofService: 'Terms of Service',
    understand: 'I understand and accept',
  },
};
