import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { cloneDeep } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { getStrategies } from '@/api/logic';
import { Button, StrategyList, StrategySearch } from '@/components';
import { StrategyListItem, StrategySearchCondition } from '@/interfaces';

interface Props {
  strategyType: string;
}

export const Strategies = ({ strategyType }: Props) => {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const defaultPageSize: number = 20;
  const [searchCondition, setSearchCondition] =
    useState<StrategySearchCondition>({
      page: 1,
      chain: '',
      protocol: '',
      token: '',
      service_type: '',
      strategy_type: strategyType as '2' | '5',
    });
  const [strategies, setStrategies] = useState<StrategyListItem[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [disabled, setDisabled] = useState<boolean>(false);

  const showLoadMore = useMemo(
    () => totalCount > strategies.length,
    [totalCount, strategies.length],
  );

  const handleSearch = (
    chain: string,
    protocol: string,
    token: string,
    service_type: string,
  ) => {
    setSearchCondition({
      ...searchCondition,
      chain,
      protocol,
      token,
      service_type,
      page: 1,
    });
  };

  const handleNextPage = () =>
    setSearchCondition({ ...searchCondition, page: searchCondition.page + 1 });

  useEffect(() => {
    const getStrategyList = async (nextPage = false) => {
      setDisabled(true);
      const { page, chain, protocol, token, service_type } = searchCondition;
      const result = await getStrategies({
        current_page: page,
        chain_id: chain,
        protocol_id: protocol === 'all' ? '' : protocol,
        invest_coin: token === 'all' ? '' : token,
        service_type,
        strategy_type: strategyType,
        page_size: defaultPageSize,
      });

      const { total_count, strategies: resultStrategies } = result;

      if (!nextPage) {
        setStrategies(resultStrategies);
      } else {
        setStrategies([...cloneDeep(strategies), ...resultStrategies]);
      }
      setTotalCount(total_count);
      setDisabled(false);
    };
    getStrategyList(searchCondition.page > 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCondition]);

  return (
    <div className={styles['strategies']}>
      <StrategySearch {...searchCondition} onSearch={handleSearch} />
      <StrategyList strategyList={strategies} />

      {showLoadMore && (
        <Button
          loading={disabled}
          disabled={disabled}
          className={cx('load-more-btn')}
          onClick={handleNextPage}
        >
          {t('investment.strategyMart.loadMore')}
        </Button>
      )}
    </div>
  );
};
