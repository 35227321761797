import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCoboNameByShortName } from '@/chains/utils';
import {
  BindSafeInfo,
  CollateralRatioTrigger,
  GasTrigger,
  GenericBotInfo,
  GenericBotOperation,
  GenericBotTask,
  GenericBotTokenItem,
  LpTokenRatioTrigger,
  Module,
  TimeTrigger,
  TokenAmountTrigger,
  TokenInfo,
  TokenWithBalance,
  ValueInContractTrigger,
} from '@/interfaces';

const initialState: {
  addTokenModalVisible: boolean;
  info?: GenericBotInfo;
  safes?: BindSafeInfo[];
  modules?: Module[];
  safeInfo?: BindSafeInfo;
  moduleInfo?: Module;
  botName?: string;
  triggers: string[];
  timeTrigger?: TimeTrigger;
  tokenAmountTrigger?: TokenAmountTrigger;
  lpTokenRatioTrigger?: LpTokenRatioTrigger;
  valueInContractTrigger?: ValueInContractTrigger;
  collateralRatioTrigger?: CollateralRatioTrigger;
  gasTrigger?: GasTrigger;
  selectedTasks?: string[];
  // useless
  botTasks?: GenericBotTask[];
  botOperations?: GenericBotOperation[];
  // for transfer tokens
  tokenOptions?: TokenWithBalance[];
  tradeTokenOptions?: TokenWithBalance[];
  receiverOptions?: {
    address: string;
    customizeLabel: string;
    isGnosisSafe: boolean;
  }[];
  selectedToken?: GenericBotTokenItem | undefined;
  tokenApprovals?: GenericBotTokenItem[];
  rewardsDataTokens?: TokenInfo[];
} = {
  addTokenModalVisible: false,
  safes: [],
  triggers: [],
};

const slice = createSlice({
  name: 'genericBots',
  initialState,
  reducers: {
    updateGenericBotRewardsManageTokens(
      state,
      action: PayloadAction<TokenInfo[]>,
    ) {
      state.rewardsDataTokens = action.payload;
    },
    updateGenericBotAddTokenModalVisible(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.addTokenModalVisible = action.payload;
    },
    updateGenericBotInfo(state, action: PayloadAction<GenericBotInfo>) {
      state.info = action.payload;
    },
    updateGenericBotSafes(state, action: PayloadAction<BindSafeInfo[]>) {
      state.safes = action.payload;
    },
    updateGenericBotModules(state, action: PayloadAction<Module[]>) {
      state.modules = action.payload;
    },
    updateGenericBotSafe(
      state,
      action: PayloadAction<{
        info: BindSafeInfo;
        clearData?: boolean;
      }>,
    ) {
      const { info, clearData } = action.payload;
      state.safeInfo = info;
      if (clearData) {
        state.moduleInfo = undefined;
        state.modules = undefined;
        state.valueInContractTrigger = undefined;
        if (!state.rewardsDataTokens) {
          state.botOperations = undefined;
        } else {
          state.botOperations = state.botOperations?.map(item => {
            const coboName = item.chain;
            item.chain = getCoboNameByShortName(info.chain_id) || coboName;
            if (item.type === 'token_transfer') {
              item.receiver = undefined;
            } else if (item.type === 'sell_token' && item.swap) {
              item.swap.token_to_buy = '';
            }
            return item;
          });
          state.receiverOptions = undefined;
        }
        state.tradeTokenOptions = undefined;
      }
    },
    updateGenericBotModule(state, action: PayloadAction<Module>) {
      state.moduleInfo = action.payload;
    },
    updateGenericBotName(state, action: PayloadAction<string>) {
      state.botName = action.payload;
    },
    updateGenericBotTokenOptions(
      state,
      action: PayloadAction<TokenWithBalance[]>,
    ) {
      state.tokenOptions = action.payload;
    },
    updateGenericBotTradeTokenOptions(
      state,
      action: PayloadAction<TokenWithBalance[]>,
    ) {
      state.tradeTokenOptions = action.payload;
    },
    updateGenericTriggers(state, action: PayloadAction<string[]>) {
      state.triggers = action.payload;
    },
    updateGenericTimeTrigger(state, action: PayloadAction<TimeTrigger>) {
      state.timeTrigger = action.payload;
    },
    updateGenericTokenAmountTrigger(
      state,
      action: PayloadAction<TokenAmountTrigger>,
    ) {
      state.tokenAmountTrigger = action.payload;
    },
    updateGenericLpTokenRatioTrigger(
      state,
      action: PayloadAction<LpTokenRatioTrigger>,
    ) {
      state.lpTokenRatioTrigger = action.payload;
    },
    updateGenericValueInContractTrigger(
      state,
      action: PayloadAction<ValueInContractTrigger>,
    ) {
      state.valueInContractTrigger = action.payload;
    },
    updateGenericGasTrigger(state, action: PayloadAction<GasTrigger>) {
      state.gasTrigger = action.payload;
    },
    updateCollateralRatioTrigger(
      state,
      action: PayloadAction<CollateralRatioTrigger>,
    ) {
      state.collateralRatioTrigger = action.payload;
    },
    updateGenericBotTasks(state, action: PayloadAction<GenericBotTask[]>) {
      state.botTasks = action.payload;
    },
    updateGenericBotSelectedTasks(state, action: PayloadAction<string[]>) {
      state.selectedTasks = action.payload;
    },
    updateGenericBotReceiverOptions(state, action: PayloadAction<any[]>) {
      state.receiverOptions = action.payload;
    },
    updateGenericBotTokenApprovals(
      state,
      action: PayloadAction<GenericBotTokenItem[]>,
    ) {
      state.tokenApprovals = action.payload;
    },
    updateSelectGenericBotToken(
      state,
      action: PayloadAction<GenericBotTokenItem | undefined>,
    ) {
      state.selectedToken = action.payload;
    },
    updateGenericBotOperations(
      state,
      action: PayloadAction<GenericBotOperation[]>,
    ) {
      state.botOperations = action.payload;
    },
    reset(state) {
      state.info = undefined;
      state.safes = undefined;
      state.modules = undefined;
      state.safeInfo = undefined;
      state.moduleInfo = undefined;
      state.botName = undefined;
      state.triggers = [];
      state.tradeTokenOptions = undefined;
      state.tokenOptions = undefined;
      state.timeTrigger = undefined;
      state.tokenAmountTrigger = undefined;
      state.lpTokenRatioTrigger = undefined;
      state.valueInContractTrigger = undefined;
      state.gasTrigger = undefined;
      state.selectedTasks = [];
      state.receiverOptions = undefined;
      state.botTasks = undefined;
      state.botOperations = undefined;
      state.tokenApprovals = undefined;
      state.selectedToken = undefined;
    },
  },
});

export const genericBotsReducer = slice.reducer;
export const updateGenericBotInfo = slice.actions.updateGenericBotInfo;
export const updateGenericBotSafes = slice.actions.updateGenericBotSafes;
export const updateGenericBotModules = slice.actions.updateGenericBotModules;
export const updateGenericBotSafe = slice.actions.updateGenericBotSafe;
export const updateGenericBotModule = slice.actions.updateGenericBotModule;
export const updateGenericBotName = slice.actions.updateGenericBotName;
export const updateGenericTriggers = slice.actions.updateGenericTriggers;
export const updateGenericTimeTrigger = slice.actions.updateGenericTimeTrigger;
export const updateCollateralRatioTrigger =
  slice.actions.updateCollateralRatioTrigger;
export const updateGenericTokenAmountTrigger =
  slice.actions.updateGenericTokenAmountTrigger;
export const updateGenericLpTokenRatioTrigger =
  slice.actions.updateGenericLpTokenRatioTrigger;
export const updateGenericValueInContractTrigger =
  slice.actions.updateGenericValueInContractTrigger;
export const updateGenericGasTrigger = slice.actions.updateGenericGasTrigger;
export const updateGenericBotTasks = slice.actions.updateGenericBotTasks;
export const updateGenericBotTokenApprovals =
  slice.actions.updateGenericBotTokenApprovals;
export const updateGenericBotSelectedTasks =
  slice.actions.updateGenericBotSelectedTasks;
export const updateGenericBotOperations =
  slice.actions.updateGenericBotOperations;
export const updateGenericBotTokenOptions =
  slice.actions.updateGenericBotTokenOptions;
export const updateGenericBotReceiverOptions =
  slice.actions.updateGenericBotReceiverOptions;
export const resetGenericBot = slice.actions.reset;
export const updateGenericBotAddTokenModalVisible =
  slice.actions.updateGenericBotAddTokenModalVisible;
export const updateSelectGenericBotToken =
  slice.actions.updateSelectGenericBotToken;
export const updateGenericBotTradeTokenOptions =
  slice.actions.updateGenericBotTradeTokenOptions;
export const updateGenericBotRewardsManageTokens =
  slice.actions.updateGenericBotRewardsManageTokens;
