import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Collapse as AntdCollapse } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { SvgIcon } from '@/components';

interface Props {
  header: string | ReactNode;
  children: ReactNode;
  className?: string;
  collapseCls?: string;
  panelCls?: string;
  style?: any;
  showArrow?: boolean;
  showExpendIcon?: boolean;
  defaultExpand?: boolean;
  customOpen?: boolean;
  setCustomOpen?: (open: boolean) => void;
}

export function Collapse({
  header,
  children,
  className,
  collapseCls,
  panelCls,
  style,
  showArrow,
  showExpendIcon,
  defaultExpand,
  customOpen,
  setCustomOpen,
}: Props) {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const [_collapse, _setCollapse] = useState(false);
  const collapse = useMemo(
    () => (setCustomOpen ? customOpen : _collapse),
    [_collapse, customOpen, setCustomOpen],
  );
  const setCollapse = useCallback(
    (open: boolean) =>
      setCustomOpen ? setCustomOpen(open) : _setCollapse(open),
    [setCustomOpen],
  );
  useEffect(() => {
    setCollapse(defaultExpand || false);
  }, [defaultExpand, setCollapse]);
  const expendIcon = (
    <SvgIcon
      width={12}
      height={12}
      name={collapse ? 'ic-Collapse-color' : 'ic-Expand-color'}
      color="rgba(77, 132, 255, 1)"
      className={cx('collapse-icon ml-1')}
    />
  );

  return (
    <div className={cx(styles['collapse-wrapper'], className)} style={style}>
      <AntdCollapse
        onChange={value => setCollapse(value.includes('1'))}
        activeKey={collapse ? '1' : '0'}
        className={collapseCls}
        items={[
          {
            key: '1',
            label: header,
            children: children,
            showArrow: showArrow,
            className: panelCls,
            extra: (
              <span className="flex items-center">
                {collapse ? t('common.collapse') : t('common.expand')}
                {showExpendIcon && expendIcon}
              </span>
            ),
          },
        ]}
      ></AntdCollapse>
    </div>
  );
}
