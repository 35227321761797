// styles

// react
import { useEffect } from 'react';

import styles from './index.module.less';
// Components
import { Profiles } from './Profile';
import { SafeModule } from './SafeModule';
import { SafeOwner } from './SafeOwner';
import { Threshold } from './Threshold';
// api
import { useGlobalSafe } from '@/hooks';

export function Settings() {
  const { loadGnosisSafeInfo } = useGlobalSafe();

  useEffect(() => {
    loadGnosisSafeInfo();
  }, [loadGnosisSafeInfo]);

  return (
    <div className={styles['settings']}>
      <Profiles />
      <SafeModule />
      <SafeOwner />
      <Threshold />
    </div>
  );
}
