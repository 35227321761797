export const portfolio = {
  safe: 'Safe',
  strategy: 'Authorizations',
  strategyBot: 'Strategy Bots',
  customizedBot: 'Customized Bots',
  noSafe: 'Import Cobo Safe',
  noModule: 'Create Cobo Safe',
  noEnabled: 'Enable Cobo Safe',
  noModuleModalText: 'Please select the Safe and then create the Cobo Safe.',
  noEnabledModalText: 'Please select the Safe and then enable the Cobo Safe.',
  path: '-Apps-Cobo Safe',
  withdraw: 'Withdraw',
  withdrawAll: 'Withdraw All',
  claimAll: 'Claim All',
  withdrawAndClaim: 'Claim & Withdraw All',
  deposits: 'Deposits',
  rewards: 'Rewards',
  deposit: 'Deposit',
  all: 'All',
  holding: 'Holding',
  connectWallet: 'Connect Wallet',
  deposited: 'Deposited',
  unClaimedRewards: 'Unclaimed Rewards',
  unClaimed: 'Unclaimed',
  totalValue: 'Total Value',
  comingSoon: 'Coming Soon',
  strategyDetail: 'Strategy Detail',
  investmentDetail: 'Investment Detail',
  position: 'Position',
  transactions: 'Transactions',
  pleaseConnectWallet1: 'Please',
  pleaseConnectWallet2: 'Connect Wallet',
  pleaseConnectWallet3: 'first.',
  pleaseConnectWallet: 'Please connect your wallet.',
  walletLoadingTitle: 'Waiting wallet connection',
  upgradeSafeTitle: 'Upgrade Cobo Safe',
  upgradeSafeText1:
    'Please upgrade your Cobo Safe to version {{version}} or above in order to activate this strategy. ',
  upgradeSafeText3: 'Your current Cobo Safe version is too low.Please ',
  upgradeSafeBtn: 'Upgrade to the latest version.',
  switchAddress: 'Switch Address',
  switchAddressText1: `Sorry, only Safe Owners or Delegated Members can continue operations. Your current address doesn’t have the required permission to continue.      `,
  switchAddressText2: `You can take either action below:`,
  switchAddressTextMethods1: `1. Switch your wallet address;`,
  switchAddressTextMethods2: `2. Switch the Safe;`,
  switchAddressTextMethods3: `3. Contact your Admin to authorize;`,
  switchAddressTextMethods4: `4. Switch the Organization.`,
  transactionSubmitted: 'Transaction Submitted',
  congratulations: 'Congratulations!',
  submitText: 'Your transaction has been submitted.',
};
