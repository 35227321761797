import { useTranslation } from 'react-i18next';

import { Button } from '@/components';
import { hideConfirmModal, showConfirmModal } from '@/utils/utils';

interface Props {
  url: string;
  chainId?: string;
  address?: string;
  moduleAddress?: string;
  children?: React.ReactNode;
  className?: string;
  btnType?: 'button' | 'span';
  action?: () => void;
  urlType?: 'safeApp' | 'normal';
}

export function GoSafeAppBtn({
  url,
  chainId,
  address,
  moduleAddress,
  urlType = 'safeApp',
  children,
  className,
  btnType = 'span',
  action,
}: Props) {
  const { t } = useTranslation();

  const showModal = () => {
    showConfirmModal({
      confirmModalVisible: true,
      confirmModalTitle: t('safe.goSafeAppTitle'),
      confirmModalElement: t('safe.goSafeAppText'),
      onConfirm: () => {
        if (urlType === 'safeApp') {
          if (!chainId || !address || !moduleAddress) {
            throw new Error('invalid safe info');
          }
          // gotoCoboSafeApp(chainId, address, moduleAddress, url);
        } else {
          window.open(url);
        }
        hideConfirmModal();
      },
      onCancel: () => {
        hideConfirmModal();
      },
      okText: t('common.goForward'),
      cancelText: t('common.cancel'),
      showConfirmModalClose: () => {
        hideConfirmModal();
      },
    });
  };
  const onClick = () => {
    if (urlType !== 'safeApp') {
      showModal();
      action && action();
      return;
    }
    if (!chainId || !address || !moduleAddress) {
      return;
    }
    showModal();
    action && action();
  };
  return (
    <>
      {btnType === 'span' ? (
        <span
          onClick={() => {
            onClick();
          }}
          className={className}
        >
          {children}
        </span>
      ) : (
        <Button
          onClick={() => {
            onClick();
          }}
          className={className}
          btnClassName={className}
        >
          {children}
        </Button>
      )}
    </>
  );
}
