import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import commonStyles from '../../common.module.less';
import styles from '../index.module.less';
import { Collapse } from '@/components/Common/Collapse';

interface Props {
  connected: boolean;
}

export default function Expand({ connected }: Props) {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const commonCx = classNames.bind(commonStyles);

  return (
    <div className={commonCx('collapse-wrapper')}>
      <Collapse
        header={
          connected
            ? t('walletconnect.txDescTitle')
            : t('walletconnect.descTitle')
        }
        showArrow={false}
        showExpendIcon={true}
        className={commonCx('module-desc-collapse')}
      >
        {connected ? (
          <div className={cx('expand-list')}>
            <div className={cx('expand-item')}>
              <div className={cx('expand-item-num', 'num1')} />
              <div className={cx('expand-item-label')}>
                {t('walletconnect.txDescStep1')}
              </div>
            </div>
            <div className={cx('expand-item')}>
              <div className={cx('expand-item-num', 'num2')} />
              <div className={cx('expand-item-label')}>
                {t('walletconnect.txDescStep2')}
              </div>
            </div>
            <div className={cx('expand-item')}>
              <div className={cx('expand-item-num', 'num3')} />
              <div className={cx('expand-item-label')}>
                {t('walletconnect.txDescStep3')}
              </div>
            </div>
          </div>
        ) : (
          <div className={cx('expand-list')}>
            <div className={cx('expand-item')}>
              <div className={cx('expand-item-num', 'num1')} />
              <div className={cx('expand-item-label')}>
                {t('walletconnect.descStep1')}
              </div>
            </div>
            <div className={cx('expand-item')}>
              <div className={cx('expand-item-num', 'num2')} />
              <div className={cx('expand-item-label')}>
                {t('walletconnect.descStep2')}
              </div>
            </div>
            <div className={cx('expand-item')}>
              <div className={cx('expand-item-num', 'num3')} />
              <div className={cx('expand-item-label')}>
                {t('walletconnect.descStep3')}
              </div>
            </div>
            <div className={cx('expand-item')}>
              <div className={cx('expand-item-num', 'num4')} />
              <div className={cx('expand-item-label')}>
                {t('walletconnect.descStep4')}
              </div>
            </div>
            <div className={cx('expand-item')}>
              <div className={cx('expand-item-num', 'num5')} />
              <div className={cx('expand-item-label')}>
                {t('walletconnect.descStep5')}
              </div>
            </div>
          </div>
        )}
      </Collapse>
    </div>
  );
}
