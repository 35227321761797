import { AbiFunc } from './common';
import { TokenItem } from './strategies';

export enum TxOperationType {
  Assoc = 'Assoc',
  Dissoc = 'Dissoc',
  AddRole = 'AddRole',
  GrantRole = 'GrantRole',
  RevokeRole = 'RevokeRole',
}

export interface SafeContract {
  address: string;
  contractName: string;
  contractFuncs: AbiFunc[];
  selectedFuncs: AbiFunc[];
}

export interface Contract {
  address: string;
  funcs: AbiFunc[];
}

export interface RoleXContracts {
  hexName: string;
  contracts: Contract[];
}

interface TxOperationDetail {
  role?: { name: string };
  delegate?: { address: string };
  type: TxOperationType;
}

export interface TxOperation {
  id: string;
  timestamp: string;
  operations: TxOperationDetail[];
}

export interface Delegate {
  address: string;
  roles: string[];
}

export interface TokenApprovalListItem {
  token_info: TokenItem;
  spender: {
    spender_address: string;
    spender_name: string;
  };
  allowance: string;
  exposure: number;
  strategies: {
    id: string;
    name: string;
    type: number;
  }[];
  bot_instances: {
    id: string;
    name: string;
  }[];
}

export interface Module {
  id: string;
  enabled: boolean;
  address: string;
  factory: string;
  version: string;
  is_default?: boolean;
  safe_address?: string;
  latest_version?: string;
}

export type ChainShortNameType =
  | ''
  | 'eth'
  | 'gno'
  | 'bnb'
  | 'avax'
  | 'rin'
  | 'oeth'
  | 'arb1'
  | 'matic'
  | 'gor';

export interface SafeAppInfo {
  importId: string;
  network: ChainShortNameType;
}
