export const authorization = {
  transactionBundle: 'Transaction Bundle',
  inputRoleName: 'Please input role name',
  selectRole: 'Select one or multiple Safe roles',
  permissionTooltip: `Strategy permission refer to the set of functions or permissions related to the corresponding strategy. When a safe role is authorized with a strategy permission, he or she can initiate and sign transactions via a single-sign process instead of a multi-sig process.`,
  duplicatedRole:
    'A role with the same name already exists. Please enter a different name.',
  txOnChain:
    'Your previous transaction has succeed, do you want to refresh your data?',
  editAndResubmit: 'Edit and re-submit',
  roleNameLengthError: 'Role name length exceed limit',
  unAuthorizeConfirm: `Are you sure you want to unauthorize?`,
  unAuthorizeConfirm2: `Once removed, the role will no longer be associated with the corresponding DeFi permission.`,
  dappInteraction: 'Dapp Interaction',
  dappInteractionTip: ``,
  permissionDetailText: `The permission to make a deposit into this strategy.`,
  website: 'Website',
  members: 'Members',
  continueToSetup: 'Continue to Setup',
  unAuthorize: 'Unauthorize',
  unAuthorizing: 'UnAuthorizing',
  sameAsSell: 'Same as Sell List',
  tokenToSell: 'Tokens to Sell',
  tokenToBuy: 'Tokens to Buy',
  tokens: 'Tokens',
  token: 'Token',
  contractInteraction: 'Contract Interaction',
  contractInteractionTooltip: `If a role is given this permission, its assigned members can call the following smart contract addresses and functions.`,
  tokenTransfer: 'Token Transfer',
  permissionDetail: 'Permission Detail',
  permissions: 'Permissions',
  whitelistAddress: 'Whitelist Address',
  generalSettings: 'General Settings',
  strategyPermission: 'Strategy Permissions',
  strategyPermissionTooltip: ``,
  createSafeRole: 'Create Safe Role',
  customizeAuthorization: 'Customize Authorization',
  newSafeRoles: 'New Safe Role',
  viewSafeRole: 'View Safe Roles',
  safeRoleName: 'Safe Role Name',
  safeRole: 'Safe Role',
  delegates: 'Delegated Address(es)',
  smartContract: 'Smart Contract',
  checkContract: 'Check Contract',
  address: 'Address',
  safeRoleAddressPlaceholder: 'Choose address(es) need to be delegated',
  addAddressText: 'Need to add a new address? ',
  confirm: 'Confirm',
  cancel: 'Cancel',
  advancedSettings: 'Advanced Settings',
  transferWhitelist: 'Transfer Whitelist',
  transferWhitelistAddress: 'Transfer Whitelist Address:',
  chooseAddress: 'Choose Address',
  addRewardsTransferPermission: 'Add rewards transfer permission',
  addPermissionText: 'You can add permissions to each safe role.',
  newSafeRole: 'New Safe Role(s):',
  sameAsSellList: 'Same as Sell List',
  searchByAddress: 'Search by Address',
  createFirst: 'Please create a safe role first.',
  tradeToSell: 'Trade to Sell',
  tradeToBuy: 'Trade to Buy',
  createSubSafeTitle: 'Create SubSafe',
  delegateAccessTitle: 'Delegate Access',
  ACLTitle: 'Advanced Settings',
  editAuthorizationTitle: 'Edit Authorization',
  tokenPermissionTitle: 'Set Token Permission',
  tokenReceiverTitle: 'Token Permission',
  previewAndSubmitTitle: 'Preview & Submit',
  multiSignTitle: 'Multi-sig',
  finishedTitle: 'Finished',
  subSafe: 'SubSafe',
  subSafeHint: 'What is SubSafe?',
  step1Title: 'Delegate Access',
  step2Title: 'Set Token Permission',
  step3Title: 'Preview & Submit',
  step4Title: 'Multi-sig',
  step5Title: 'Finished',
  abortText:
    'You’re authorizing permissions to team members. Are you sure that you want to cancel?',
  abortTextWithEditing:
    'You’re editing the team member authorization. Are you sure that you want to cancel?',
  tokenApproval: 'Token Approval',
  tokenTransferWhitelist: 'Set Whitelist Address',
  tokenReceiver: 'Rewards Receiving Address',
  transferWhitelistName: 'Set Whitelist Address',
  tokenApprovalTip: `The types and amounts of token in your Safe{Wallet} that the protocol can use when interacting with. Particularly, the  base token doesn't need any approval. `,
  spender: 'Spender',
  allowance: 'Allowance',
  tokenTransferWhitelistText:
    "You can add an address to the Transfer Whitelist. Team members who have been assigned the role of claimer can send the token rewards they've claimed from Cobo Safe to this whitelisted address with a single-sig transaction.",
  tokenReceiverHint:
    'All claimed rewards will be automatically transferred to this address.',
  tokenReceiverHintWithEditing:
    'All claimed rewards will be automatically transferred to this address.',
  checkAddressText: 'Check existing address(es) on the ',
  checkAddress: 'Token Whitelist.',
  addAddress: 'Add it now.',
  addAddressTitle: 'Add address',
  reminder: 'Reminder',
  coboSafe: 'Cobo Safe',
  coboModule: 'Cobo Safe',
  safeModule: 'Cobo Safe',
  roleAndMember: 'Strategy Roles and Members',
  roleAndMemberTip:
    'Strategy roles refer to the set of functions or permissions related to the corresponding strategy. When a member is authorized with a strategy role, he or she can initiate and sign transactions via a single-sign process instead of a multi-sig process.',
  roleAndMemberWithSubSafeTip:
    'You can authorize roles to your team members. A delegated member can execute his assigned roles independently without multi-sig. ',
  roleAndMemberText:
    'Select members that need to be authorized with strategy roles',
  roleAndMemberWarning:
    'Modifications to member settings will be applied to all strategies under the selected Safe. ',
  depositor: 'Depositor',
  withdrawer: 'Withdrawer',
  claimer: 'Claimer',
  depositorDesc:
    'The Depositor role gives user the permission of depositing tokens into the strategy.',
  withdrawerDesc:
    'The Withdrawer role gives user the permission of withdrawing tokens from the strategy.',
  claimerDesc:
    'The Claimer role gives user the permission of claiming reward tokens from the strategy.',
  strategyAcl: 'Strategy ACL',
  strategyAclTip:
    'The ACL is a smart contract that enables risk control at the parameter level. It may be required for authorization to work properly.',
  aclAddress: 'ACL Address',
  aclAddresses: 'ACL Addresses',
  installAclHint:
    'To ensure access control, the following strategy ACL will be installed during authorization.',
  installAclCheckbox:
    'I agree to install the strategy ACL to replace my customized ACL. I acknowledge the impact associated with this replacement.',
  aclAddressNote:
    'A customized ACL has been detected for the selected DeFi protocol(s). Unless selected, the following strategy ACL will no longer be installed. Please make sure that your customized ACL meets the strategy requirements. Otherwise, the authorization may not work properly.',
  aclSetting: 'Advanced Settings',
  replaceAclHint:
    'Please note that the strategy ACL will replace your customized ACL for the selected DeFi protocol(s).',
  replaceAclNote:
    'Based on your settings, the strategy ACL will not be installed. \nPlease make sure that your customized ACL meets the strategy requirements. Otherwise, the authorization may not work properly.',
  aclAdvancedSettingText:
    'The advanced settings will be applied to all {{role}}',
  aclEmpty:
    'To enable advanced settings, please replace your customized ACL with the above strategy ACL first.',
  riskControl: 'Risk Control',
  riskControlTip:
    'The health factor depends on the liquidation threshold of your collateral against the value of your borrowed funds. If the health factor is below 1, you will get liquidated. You can set a health factor for your borrowers to avoid liquidation risks.',
  healthFactor: 'Health Factor',
  healthFactorError: 'Health Factor must be above 1',
  trades: 'Trades',
  sellOnly: 'Sell Only',
  buyOnly: 'Buy Only',
  addNewToken: 'Add New Token',
  confirmTitle: 'Multi-Sig Confirmation Required',
  confirmContent: 'Your request has been submitted.',
  confirmText1: 'Please inform all Safe owners to complete the multi-sig on ',
  checkTransactionBtn: 'Check the transaction',
  backToStrategy: 'Back to the strategy',
  safeBtn: 'Safe{Wallet}',
  confirmText2: ' to complete the multi-sig process. ',
  step4Btn: 'Transaction in progress...',
  step4BtnWithsubsafe: 'Check the transaction',
  editAuthorizeSuccessText: 'You have successfully edited this authorization.',
  authorizeSuccessText: 'Authorization Complete.',
  activateSuccessText: 'Your strategy has been successfully activated.',
  failTitle: 'Transaction Failed',
  failText: 'Oops, your transaction has failed. ',
  goBack: 'Retry',
  checkBtn: 'Check Transaction',
  advanced: 'Advanced',
  targetContract: 'Target Contract',
  function: 'Function',
  earnTip: `The types of rewards you'll earn after depositing token(s) into the strategy.`,
  chooseMember: 'Choose Member',
  chooseMemberTip: 'Select member address',
  chooseMembersTip: 'Select member addresses',
  switchToSafeOwner: 'Switch to Safe Owner Address',
  switchToSafeOwnerText: "Please connect with Safe Owner's Address",
  subSafeDesc1:
    'A SubSafe is created by Cobo Argus and managed via your Safe{Wallet}. It is used to isolate your funds in Gnosis Safe from the DeFi protocols, and manage your investment positions independently. When you invest into a strategy, a unique SubSafe will be created. Each Gnosis Safe wallet can correspond to multiple Sub Cobo Safes. ',
  subSafeDesc2:
    'When you make a deposit into the strategy (i.e. DeFi protocols), your funds will be automatically deposited into SubSafe first and then transferred to the corresponding DeFi protocols. Similarly, when you make a withdrawal from the strategy, the request will be processed in the opposite path. ',
  subSafeDesc3:
    'If the smart contracts that govern the DeFi protocols are compromised, your maximum loss will be the principal deposited through SubSafe. Your funds in Safe{Wallet} remain secure.',
  transferOperatorName: 'Transfer Operator',
  transferOperatorDesc:
    'The role of a "transfer operator" indicates that the individual is eligible to access the associated target contract. The claimer must be delegated the role of a "transfer operator" in order to claim rewards from the Transfer Whitelist.',
  tokenApprovalTableTooltip: `This smart contract is associated with Cobo Safe. It is used to set a whitelist address for token transfer.`,
  member: 'Member',
  note: 'Note: The name cannot be modified after the multi-sig transaction is completed',
  createCoboSafeNote:
    'You haven’t created the Cobo Safe under the Safe{Wallet}. Please create a Cobo Safe in order to use this strategy.',
  importSafeNote:
    'You are currently viewing a strategy on {{network}}. Please import your Safe{Wallet} on {{network}} in order to use this strategy.',
  switchChainNote:
    'You are currently viewing a strategy on {{network}}. Please switch to your Safe{Wallet} on {{network}} in order to use this strategy.',
  enableCoboSafeNote:
    'Your Cobo Safe is disabled. Please enable the Cobo Safe in order to use this strategy.',
};
