import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.less';
import { collect } from '@/api/axios/collect';
import { getShortNameByCoboName } from '@/chains/utils';
import { ChainIcon, CoboTable, IconGroup, ServiceIcon } from '@/components';
import {
  ChainShortNameType,
  ServiceType,
  StrategyListItem,
} from '@/interfaces';
import { serviceSorts } from '@/utils/utils';

interface Props {
  strategyList: StrategyListItem[];
}
export function StrategyList({ strategyList }: Props) {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  const navigateToStrategyInfo = (strategy: StrategyListItem) => {
    collect({
      cid: 'Click_Invest_Strategy',
      extra: {
        strategy_id: strategy.desc.unique_id,
      },
    });

    navigate(
      `/service-config/${strategy.desc.type === 2 ? 'farming' : 'trading'}/${
        strategy.desc.unique_id
      }`,
    );
  };

  const strategyNameEle = (strategy: StrategyListItem) => {
    return (
      <div
        className={cx('strategy-name')}
        onClick={() => {
          navigateToStrategyInfo(strategy);
        }}
      >
        <ChainIcon
          isRegIcon
          shortName={
            getShortNameByCoboName(strategy.desc.chain_id) as ChainShortNameType
          }
          className={cx('strategy-icon-box')}
        />

        {strategy.desc.icon || strategy.desc.invest_tokens?.length ? (
          <div className={cx('iconGroup-box')}>
            <IconGroup
              icons={
                strategy.desc.icon
                  ? [{ icon_url: strategy.desc.icon }]
                  : strategy.desc.invest_tokens?.map(item => ({
                      icon_url: item.icon_url,
                      alt: item.symbol,
                    })) ?? []
              }
            />
          </div>
        ) : (
          <div className={cx('protocol-box')}>
            <img
              width="32px"
              height="32px"
              className={cx('protocol')}
              src={require('@/assets/images/chains/protocol.png')}
              alt="none"
            />
          </div>
        )}

        <div className={cx('strategy-name-title')}>
          <div>
            <div className={cx('strategy-name-box')}>
              <h2>{strategy.strategy}</h2>
              {strategy.desc.is_new && (
                <div className={cx('strategy-name-new')} />
              )}
            </div>
            <div className={cx('strategy-content-box')}>
              {strategy.desc.projects.map(el => {
                return (
                  <p
                    className={cx('strategy-name-content')}
                    key={el.project_id}
                  >
                    {el.name}
                  </p>
                );
              })}
              {strategy.desc.pool_id && (
                <p className={cx('strategy-name-content')}>
                  {t('investment.strategyDetail.poolId')}:{' '}
                  {strategy.desc.pool_id}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const serviceEle = (strategy: StrategyListItem) => {
    return (
      <div
        className={cx('strategy-service')}
        onClick={() => {
          navigateToStrategyInfo(strategy);
        }}
      >
        <div className={cx('strategy-service-content')}>
          {strategy.services.sort(serviceSorts).map(el => {
            const sameData = strategy.services.filter(
              _service =>
                _service.detail_type === el.detail_type &&
                _service.service_type === el.service_type,
            );
            const serviceIdx = sameData.findIndex(
              _service => _service.service_id === el.service_id,
            );
            const showIndex = sameData.length > 1 ? `# ${serviceIdx + 1}` : '';
            return (
              <ServiceIcon
                inactive
                key={el.service_id}
                className="mr-[12px]"
                name={
                  el.service_type === ServiceType.SINGLE_SIGN
                    ? el.service_type
                    : el.detail_type
                }
                showTooltip
                parseServiceTooltip={tooltip =>
                  showIndex ? `${tooltip} ${showIndex}` : tooltip
                }
              />
            );
          })}
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: t('investment.strategyMart.strategyName'),
      dataIndex: 'strategyName',
      key: 'strategyName',
      render: (text: Text, record: StrategyListItem) => strategyNameEle(record),
    },
    {
      title: (
        <span className={cx('table-title')}>
          {t('investment.strategyDetail.service')}
        </span>
      ),
      dataIndex: 'chain_id',
      key: 'chain_id',
      width: 520,
      render: (text: Text, record: StrategyListItem) => serviceEle(record),
    },
  ];

  return (
    <div className={styles['strategy-list']}>
      <CoboTable
        className={cx('strategy-table')}
        pagination={false}
        rowKey={(record: StrategyListItem) => `${record.desc.unique_id}`}
        showSorterTooltip={false}
        dataSource={strategyList || []}
        columns={columns}
        locale={{
          emptyText: (
            <div style={{ padding: '40px 0' }}>
              <img
                alt=""
                style={{ width: '160px' }}
                src={require('@/assets/images/empty.png')}
              />
              <div style={{ padding: '20px 0 0 0' }}>
                {t('common.emptyTable')}
              </div>
            </div>
          ),
        }}
      />
    </div>
  );
}
