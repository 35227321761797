export function validatePattern(password: string, pattern: RegExp): boolean {
  return pattern.test(password);
}

export const daasPattern: Record<string, RegExp> = {
  passwordLength: /^.{8,30}$/, // 密码长度
  isNumber: /^(.*\d.*)$/, // 密码包含一位数字
  lowerCase: /^(.*[a-z].*)$/, // 密码包含一位小写字母
  upperCase: /^(.*[A-Z].*)$/, // 密码包含一位大写字母
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,30}$/, // 密码校验
  email:
    /^([a-zA-Z0-9]+[_\-|.+])*[a-zA-Z0-9]+@[a-zA-Z0-9_-]+(?:\.[a-zA-Z0-9_-]+)*$/,
};
