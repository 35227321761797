export const investment = {
  safe: 'Safe',
  depositedAmount: 'Deposited Amount',
  unclaimedRewards: 'Unclaimed Rewards',
  depositedAmountTooltip: 'The token amount deposited into the strategy.',
  unclaimedRewardsTooltip: 'The token amount of unclaimed rewards.',
  status: 'Status',
  type: 'Type',
  time: 'Time',
  operator: 'Operator',
  title: 'Investment',
  strategy: 'Strategy',
  myStrategy: 'My Strategy',
  myPortfolio: 'My Strategies',
  myAuthorization: 'My Authorization',
  myAuthorizations: 'My Authorizations',
  myInvestment: 'My Investments',
  myPortfolioBoardTitle: 'My Strategies',
  myPortfolioBoardButton: 'View',
  viewMore: 'View More',
  strategyMartTitle: 'Marketplace',
  importText:
    'Please import Cobo safe on the corresponding network before proceeding.',
  farming: 'Farming',
  trading: 'Trading',
  myPortfolioBoard: {
    totalValue: 'Total Value',
    deposited: 'Deposited',
    unclaimedRewards: 'Unclaimed Rewards',
    avgApy: 'Avg. APR',
  },
  strategyMart: {
    allStrategies: 'All Strategies',
    strategyMarketplace: `Strategy Marketplace`,
    newStrategies: 'New Strategies',
    authorization: 'Authorization',
    investment: 'Investment',
    strategyName: 'Strategy',
    token: 'Token',
    tokenTip: 'Tokens that can be deposited into the strategy.',
    apy: 'APR',
    apyTip: 'Annual percentage rate of the strategy.',
    tvl: 'TVL',
    tvlTip: 'Total value locked in the strategy.',
    protocol: 'Protocol',
    loadMore: 'Load More',
    network: 'Network',
  },
  strategyDetail: {
    poolId: 'Pool',
    invested: 'Total Invested',
    network: 'Network:',
    token: 'Token:',
    curvePool: 'Pool',
    earn: 'Earn',
    description: 'Description',
    services: 'Services',
    introduction: 'Introduction',
    strategy: 'Strategy',
    investmentConsole: 'Investment Console',
    console: 'Console',
    createCoboSafe: 'Create Cobo Safe',
    enableCoboSafe: 'Enable Cobo Safe',
    importSafeWallet: 'Import Safe{Wallet}',
    switchSafeWallet: 'Switch Safe{Wallet}',
    connectWallet: 'Connect Wallet',
    safeOwnerWarning: 'Only the Safe{Wallet} owner can operate.',
    switchSafeWarning: 'Switch Safe{Wallet} to corresponding network',
    safe: 'Safe',
    safeModule: 'Cobo Safe',
    authorization: 'Authorization',
    authorizationTip:
      'Authorization means delegating investment permissions (i.e. deposit and withdraw tokens) to team members. This feature ensures higher efficiency as selected team members can initiate transactions without undergoing the multi-sig process in Safe{Wallet}.',
    noAuthorizationText:
      'You can delegate DeFi permissions (e.g. deposit and withdraw) to a specific role and then assign the role to a member. ',
    authorize: 'Authorize',
    tradeInCoboSafeApp: 'Head to Safe{Wallet} DApp',
    me: 'Me',
    viewAll: 'View All',
    AuthorizedRoles: 'Authorized Roles',
    hasNoPermTitle: 'No Authorization',
    hasNoPermText: 'You’re not authorized to {{action}}',
    service: 'Services',
    deposit: {
      title: 'Deposit',
      maxBtn: 'MAX',
      balance: 'Balance',
      activateBtn: 'Activate',
      depositBtn: 'Deposit',
    },
    toChooseStrategy: 'to choose a strategy.',
  },
  strategyBot: {
    listen: `Listen`,
    listening: `Listening`,
    webhook: 'Webhook',
    webhookUrl: 'Webhook URL',
    basicAuth: 'Basic Auth',
    webhookNote: `Note: Please make sure to pass in your Basic Auth as a POST request in JSON format.`,
    standardTrigger: 'Standard Trigger',
    collateralRatio: 'Collateral Ratio',
    setup: 'Setup Strategy Bot',
    editBot: 'Edit Strategy Bot',
    safeAddress: 'Safe{Wallet} Address',
    multiSign: 'Multi-sig',
    finished: 'Finished',
    trigger: 'Trigger',
    rewardReceiving: 'Rewards Receiving Address',
    withdrawal: 'Withdrawal',
    withdrawTokens: 'Withdraw Token(s):',
    withdrawTooltip:
      'The tokens that the bot has withdrawn will be automatically deposited into the Safe{Wallet} address:',
    withdrawHint:
      "Your tokens will be withdrawn into the types of tokens supported by the liquidity pool based on a floating ratio. The ratio will be similar to the pool weight you've set for the trigger.",
    withdrawHintForSingleToken:
      'To help you mitigate potential losses, your tokens can be directly withdrawn into a single type. You must set slippage control in order to prevent sandwich attacks.',
    withdrawHintForSingleToken2:
      " Do note that in the event of de-pegging, a withdrawal may fail if the maximum slippage you've set is too low, and a loss may be incurred if the maximum slippage you've set is too high.",
    slippageControl: 'Slippage Control',
    slippage: 'Slippage',
    notification: 'Notification',
    triggerTime: 'Time Interval',
    triggerGas: 'Gas Price',
    gasPriceOver: 'Gas Price <=',
    currentGasPrice: 'Current Gas Price is {{gas}} Gwei',
    triggerRewardAmount: 'Rewards Amount',
    triggerPoolWeight: 'Pool Weight',
    triggerValueInContract: 'Contract Value Monitor',
    ratioNumberPlaceholder: 'Enter Ratio',
    triggerNote:
      'Note: if you tick multiple boxes, the bot will execute its task only when those trigger conditions are met.',
    rewardReceivingHint:
      'The rewards you’ve claimed will be automatically transferred to this address. Please note that any existing token deposit address(es) cannot be deleted.',
    multiSignTitle: 'Multi-Sig Transaction Confirmation Required',
    multiSignSubtitle1: 'Please inform Safe Owners and head to ',
    gnosisSafe: 'Safe{Wallet}',
    multiSignSubtitle2: ' to complete the Multi-sig process.',
    jumpToTx: 'Check the transaction',
    setupBotSuccess: 'Your strategy bot has been successfully added.',
    editBotSuccess: 'Your strategy bot has been modified successfully.',
    setupBotFailWithTxTitle: 'Transaction Failed',
    setupBotFailWithTxContent: 'Oops, the multi-sig transaction has failed.',
    setupBotFailTitle: 'Failed',
    setupBotFailContent: 'Unknown error. Please try again later.',
    sameCategoryNote:
      "Note: When you set up the first bot (claim) for Curve-Convex Authorization, it will require a multi-sig confirmation. If you'd like to modify this bot or set up a new bot (claim) for this type of strategy later, it will no longer require a multi-sig.",
  },
  genericBot: {
    customizedBot: 'Customized Bot',
    createTitle: 'Bots',
    editTitle: 'Edit Bot',
    general: 'General',
    trigger: 'Trigger',
    myBotsMenu: 'Bots',
    editBot: 'Edit Bot',
    setup: 'Bot Setup',
    preview: 'Preview',
    tokenApproval: 'Token Approval',
    task: 'Task',
    notification: 'Notification',
    defiOperation: 'DeFi Operation',
    contractInteraction: 'Call Smart Contracts',
    parseTransaction: 'Parse Transactions',
    parseTransactionTip: `You can enter a transaction hash and the bot will automatically parse the transaction. For instance, you can manually claim rewards from a DeFi protocol, and then paste its transaction hash into the text box. The bot can then automatically parse the transaction and auto fill the parameters.`,
    sellToken: 'Swap Token',
    tokenTransfer: 'Transfer Tokens',
    tokenTransferTip:
      'The selected tokens will be auto transferred from Safe{Wallet} to your receiving address.',
    addContractInteractionBtn: '+ Call Contracts',
    addParseTransactionBtn: '+ Parse Txn',
    addSellTokenBtn: '+ Swap Tokens',
    addTokenTransferBtn: '+ Transfer Tokens',
    setAction: 'Set Task',
    valueMonitor: 'Contract Value Monitor',
    valueMonitorTip:
      'You can specify a function for the bot to use to call a smart contract and retrieve its on-chain data. For instance, the bot may use the getRewards function to retrieve the total amount of rewards you (e.g. liquidity provider) can claim from a smart contract.',
    allTokenBalance: 'All Token Balance',
    customizedNumber: 'Customized number',
    changesInTokenAmount: 'Changes in token amount',
    changesInTokenAmountTipSell: `Suppose that your bot executes a task and your total balance increases by 10 DAI. If you choose the "Changes in token amount" option, the bot will automatically swap 10 DAI for an equivalent amount of USDT. `,
    changesInTokenAmountTipTransfer: `Suppose that your bot executes a task and your total balance increases by 10 DAI. If you choose the "Changes in token amount" option, the bot will automatically transfer out 10 DAI to your  receiving address.`,
    changesInTokenAmountTipContract: `Suppose that your bot executes a task and your total balance increases by 10 DAI. If you choose the "Changes in token amount" option, the bot will automatically key in 10 (followed by the required number of decimal places) into the parameter.`,
    contractAddress: 'Contract Address',
    txHash: 'Transaction Hash',
    invalidTx: 'Invalid transaction',
    multiSendTxNotSupport:
      'The transaction is a Safe multi-send tx and is not yet supported.',
    safeTxNote:
      'This transaction is associated with Safe. Your bot will parse this transaction and identify the underlying smart contract.',
    safeTxOnchainNote:
      "You've entered a transaction hash associated with a Safe{Wallet}. To facilitate transaction parsing, however, we recommend that you head to Safe{Wallet} and copy a safeTxHash instead.",
    token: 'Token',
    receiver: 'Receiver',
    swapTo: 'Swap To',
    abortText:
      'You’re setting up your bot. Are you sure that you want to cancel?',
    abortEditText:
      'You’re editing your bot. Are you sure that you want to cancel?',
    tokenApprovalNotice:
      'The required token approvals (i.e. swap token) are listed below. If your customized contract call requires other approvals, click on "Add Token Approval".',
    addTokenApproval: 'Add Token Approval',
    botNamePlaceholder: 'Maximum 20 characters',
    addAddressText1: 'Need to add a new address? Go to',
    addAddressText: 'Add Address',
    addAddressText2: 'or',
    addAddressText3: 'Invite Member.',
    contractPlaceholder: 'Please input contract address.',
    dex: 'DEX',
    tokenToSell: 'Token',
    amount: 'Amount',
    tokenToBuy: 'Swap to',
    slippageControl: 'Slippage Control',
    amountRadioAll: 'All token balance',
    amountRadioCustomize: 'Customized num',
    amountRadioAmountChange: 'Changes in token amount',
    multipleOperationNote:
      'Note: the bot will execute tasks based on the order in the drop-down list.',
    emptyTableLabel: 'Token approval not required.',
    setupSuccess: 'The customized bot has been successfully added.',
    editSuccess: 'The customized bot has been successfully edited.',
    farmingEditSuccess: 'The strategy bot has been successfully edited.',
    farmingSetupSuccess: 'The strategy bot has been successfully added.',
    sellTokenError:
      'This pair is currently not supported on your selected DEX. Please choose a different pair or DEX.',
    sellTokenErrorInFarming: 'This token is not supported.',
    transferToken: 'Transfer Token',
    advanceSetting: 'Advanced Settings',
    rewards: 'Rewards:',
    rewardsToSell: 'Rewards (to sell):',
    receiverAddress: 'Receiving Address',
    manageRewards: 'Manage Rewards',
    claimAndVest: 'Claim & Vest',
    receiveRewards: 'Receive Rewards',
    sellRewards: 'Swap Rewards',
    receiveToken: 'Receive Token',
    collateralRatioPaybackBtn: '+ Collateral Ratio Payback',
    collateralRatioPayback: 'Payback',
    collateralRatioPaybackLabel: ' debt, increase collateral ratio to ',
    withdraw: 'Withdrawal',
    chooseOperation: 'Choose Operation',
    functionPlaceholder: 'Please choose function',
    indicatorPlaceholder: 'Please choose indicator',
    operatorPlaceholder: 'Choose operator',
    txTable: {
      status: 'Status',
      type: 'Type',
      details: 'Details',
      value: 'Value',
      time: 'Time',
    },
  },
  botTask: {
    slippageControl: 'Slippage Control',
    slippage: 'Slippage',
    withdraw: {
      title: 'Withdrawal',
      type: 'Withdrawal Type',
      singleTokenNotice:
        'Note: Avoid losses by withdrawing tokens into a single type and setting slippage control to prevent sandwich attacks. Be cautious during de-pegging as setting the maximum slippage too low may cause withdrawal failure, while setting it too high may result in losses.',
      multipleTokenNotice:
        'Note: Tokens will be withdrawn into types supported by the liquidity pool based on a floating ratio, similar to the token ratio set for the trigger.',
    },
    dexError:
      'We have discontinued support for {{dex}} in Token Swap. Please manually switch to alternative aggregators.',
  },
};
