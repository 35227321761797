import { coboApi } from '@/api/axios';
import { getCoboNameByShortName, getShortNameByCoboName } from '@/chains/utils';
import { Module, SafeTx } from '@/interfaces';
import {
  BindSafeInfo,
  GnosisSafeInfo,
  ImportSafeModalSafeItem,
  ProtectorTableItem,
  SafeBalanceItem,
  SafeRoleDetail,
  SafeRoles,
} from '@/interfaces/pages/safe';

export const loadGnosisSafe = async (
  orgId: string,
  chain_ids?: string[],
): Promise<BindSafeInfo[]> => {
  const config: {
    org_id: string;
    chain_ids?: string[];
  } = {
    org_id: orgId,
  };
  if (chain_ids && chain_ids.length > 0) {
    config.chain_ids = chain_ids;
  }
  const resp: any[] = await coboApi.post('/argus/safes/list_safes/', config);
  return resp.map((item: BindSafeInfo) => ({
    ...item,
    chain_id: getShortNameByCoboName(item.chain_id),
  }));
};

export const batchBindSafes = async (
  org_id: string,
  safe_list: {
    chain: string;
    address: string;
    name: string;
  }[],
  message: {
    domain: string;
    address: string;
    statement: string | null;
    uri: string;
    version: string;
    chain_id: number;
    nonce: number;
    not_before: string | null;
    expiration_time: string | null;
    issued_at: string;
  },
  signature: string,
): Promise<any> => {
  return await coboApi.post('/argus/safes/batch_bind_safe/', {
    org_id,
    safe_list,
    message,
    signature,
  });
};

export const updateSafe = async (
  safeId: string,
  name: string,
): Promise<void> => {
  await coboApi.post('/argus/safes/update_safe/', { safe_id: safeId, name });
};

export const unbindSafe = async (safeId: string): Promise<void> => {
  await coboApi.post('/argus/safes/unbind_safe/', {
    safe_id: safeId,
  });
};

export const getSafeInfo = async (
  safe_id: string,
): Promise<{
  id: string;
  chain_id: string;
  name: string;
  address: string;
  org_id: string;
  default_cobo_module: string;
}> => {
  const resp: any = await coboApi.post('/argus/safes/get_safe_info/', {
    safe_id,
  });
  return {
    ...resp,
    chain_id: getShortNameByCoboName(resp.chain_id),
  };
};

export const getGnosisSafeInfo = async (
  address: string,
  gnosis_chain_id: string,
  silence = false,
): Promise<GnosisSafeInfo> => {
  gnosis_chain_id = getCoboNameByShortName(gnosis_chain_id) || '';
  return coboApi.post(
    '/argus/safes/get_gnosis_safe_info_with_label/',
    {
      address,
      gnosis_chain_id,
    },
    {
      params: { silence: silence },
    },
  );
};

export const batchGetModuleInfoV2 = async (
  gnosis_safe_list: { chain: string; address: string }[],
): Promise<
  {
    chain: string;
    address: string;
    modules: Module[];
  }[]
> => {
  const resp: any[] = await coboApi.post(
    '/argus/safes/batch_get_cobo_module_v2/',
    {
      gnosis_safe_list,
    },
  );
  return resp.map(item => {
    const modules = item.modules;
    const defaultModule = modules.find(
      (module: { is_default: any }) => module.is_default,
    );
    if (!!defaultModule || modules.length === 0) return item;
    return {
      ...item,
      modules: [
        {
          ...modules[0],
          is_default: true,
        },
        ...modules.slice(1),
      ],
    };
  });
};

export const switchDefaultSafeModule = async (
  safe_id: string,
  default_cobo_module: string,
) => {
  await coboApi.post('/argus/safes/switch_default_safe_module/', {
    safe_id,
    default_cobo_module,
  });
};

export const getProtectorList = async (
  safe_id: string,
  chain_id: string,
  module_address: string,
): Promise<ProtectorTableItem[]> => {
  const result: {
    contract_name: string;
    contract: string;
    protector: string;
  }[] = await coboApi.post('/argus/safes/get_safe_protectors_by_safe/', {
    safe_id,
    chain_id,
    module_address,
  });
  return result.map(item => ({
    contractName: item.contract_name,
    contract: item.contract,
    protector: item.protector,
  }));
};

export const getBalanceList = async (
  safe_ids: string[],
  org_id: string,
): Promise<SafeBalanceItem[]> => {
  return await coboApi.post('/argus/safes/batch_get_safe_balance/', {
    safe_ids,
    org_id,
  });
};

export const getSafeByAddress = async (
  address: string,
  org_id: string,
): Promise<ImportSafeModalSafeItem[]> => {
  return await coboApi.post('/argus/safes/get_safe_address_by_owner/', {
    address,
    org_id,
  });
};

export const getCreateSafeModuleStatus = async (
  chain_id: string,
  safe_id: string,
): Promise<{ pending_create_safe_module_tx_hash: string | null }> => {
  return await coboApi.post('/argus/safes/get_create_safe_module_status/', {
    chain_id,
    safe_id,
  });
};

export const getEnableSafeModuleStatus = async (
  safe_id: string,
  module_id: string,
): Promise<{ pending_enable_safe_module_tx_hash: string | null }> => {
  return await coboApi.post('/argus/safes/get_enable_safe_module_status/', {
    module_id,
    safe_id,
  });
};

export const createSafeModule = async (
  chain_id: string,
  safe_id: string,
): Promise<SafeTx> => {
  return await coboApi.post('/argus/safes/create_safe_module/', {
    chain_id,
    safe_id,
  });
};

export const enableSafeModule = async (
  chain_id: string,
  safe_id: string,
  module_id: string,
): Promise<SafeTx> => {
  return await coboApi.post('/argus/safes/enable_safe_module/', {
    chain_id,
    safe_id,
    module_id,
  });
};

export const getSafeRoles = async (
  safe_id: string,
  safe_module_id: string,
): Promise<SafeRoles> => {
  return await coboApi.post('/argus/safes/get_safe_account_roles/', {
    safe_module_id,
    safe_id,
  });
};

export const getSafeRolesDetails = async (
  safe_id: string,
  safe_module_id: string,
): Promise<SafeRoleDetail> => {
  return await coboApi.post('/argus/safes/get_safe_roles_details/', {
    safe_id,
    safe_module_id,
  });
};

const authorizerInfoMap: {
  [key: string]: {
    tag: string;
  };
} = {};

export const getAuthorizerInfo = async (
  chain_id: string,
  address: string,
): Promise<{
  tag: string;
} | null> => {
  const key = `${chain_id}-${address}`;
  if (authorizerInfoMap[key]) return authorizerInfoMap[key];
  const {
    tag,
  }: {
    tag: string;
  } = await coboApi.post('/argus/safes/get_contract_tag/', {
    chain_id,
    address,
  });
  authorizerInfoMap[key] = { tag: tag || '-' };
  return { tag };
};

export const modifyPermissionConfig = async (
  safe_id: string,
  safe_module_id: string,
  modifications: {
    type: string;
    description: string;
    modification_params:
      | { roles: string[] } // add role
      | { role: string; delegates: string[] } // add delegate / remove delegate
      | {
          role: string;
          tag: string;
          params: { target_contract: string; signatures: string[] }[];
        } // add authorizer
      | { roles: string; authorizer_address: string } // remove authorizer
      | {
          tag: string;
          authorizer_address: string;
          params: {
            target_contract: string;
            add_signatures: string[];
            remove_signatures: string[];
          }[];
        } // edit authorizer
      | { roles: string[]; tag: string; authorizer_address: string }; // import authorizer
  }[],
): Promise<SafeTx> => {
  return coboApi.post('/argus/safes/modify_permission_config/', {
    safe_id,
    safe_module_id,
    modifications,
  });
};

export const getModifyPermissionConfigSafeTx = async (
  safe_id: string,
  safe_module_id: string,
): Promise<{ pending_modify_permission: string }> => {
  return coboApi.post('/argus/safes/get_modify_permission_config_safe_tx/', {
    safe_id,
    safe_module_id,
  });
};

export const upgradeSafeModule = async (
  safe_id: string,
  safe_module_id: string,
): Promise<SafeTx> => {
  return await coboApi.post('/argus/safes/upgrade_safe_module/', {
    safe_id,
    safe_module_id,
  });
};

export const getUpgradeSafeModuleSafeTx = async (
  safe_id: string,
  safe_module_id: string,
): Promise<{ safe_tx_hash: string }> => {
  return await coboApi.post(
    '/argus/safes/get_upgrade_safe_module_status/',
    {
      safe_id,
      safe_module_id,
    },
    {
      params: { silence: true },
    },
  );
};

export const setDefaultSafe = async (
  safe_id: string,
  org_id: string,
): Promise<{ safe_tx_hash: string }> => {
  return await coboApi.post('/argus/orgs/set_default_safe/', {
    safe_id,
    org_id,
  });
};
