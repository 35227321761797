import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { CoboSelect, SvgIcon } from '@/components';
import { Wallet } from '@/components/Layout/Wallet';

interface LoginHeaderProps {
  connectBtnVisible?: boolean;
}
export function LoginHeader({ connectBtnVisible = false }: LoginHeaderProps) {
  const { t } = useTranslation();
  return (
    <div className={styles['header-container']}>
      <div className={styles['header-logo']}>
        <div
          className={styles['header-logo-icon']}
          onClick={() => window.location.reload()}
        />
      </div>

      <div className={styles['header-content']}>
        <div style={{ visibility: connectBtnVisible ? 'visible' : 'hidden' }}>
          <Wallet />
        </div>
        <SvgIcon name="ic_language" className={styles['language-icon']} />
        <div className={styles['header-language-selector']}>
          <CoboSelect
            defaultValue="en"
            bordered={false}
            options={[{ label: t('common.en'), value: 'en' }]}
          />
        </div>
      </div>
    </div>
  );
}
