import { useState } from 'react';
import { Input } from 'antd';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Divider, SettingItem } from '../components';
import { EditProfileModal } from '../EditProfileModal';
import styles from './index.module.less';
import { updateSafe } from '@/api/logic/safe';
import { ChainSelect } from '@/components';
import { orgIdAtom, orgsAtom } from '@/globalAtom';
import { useAppDispatch, useGlobalSafe } from '@/hooks';
import { updatePageLoading } from '@/store';
import { isRole } from '@/utils/utils';

export function Profiles() {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const { safeInfo, loadSafeInfo, safeId } = useGlobalSafe();
  const [orgId] = useAtom(orgIdAtom);
  const [organizations] = useAtom(orgsAtom);
  const orgInfo = organizations?.find(item => item.id === orgId);
  const canEdit = orgInfo && !isRole(orgInfo, 2);

  const openEditProfile = () => {
    setDialogVisible(true);
  };

  const editModal = async (username: string) => {
    if (!safeInfo || !safeId) {
      return;
    }
    dispatch(updatePageLoading(true));
    try {
      await updateSafe(safeId, username);
    } finally {
      dispatch(updatePageLoading(false));
    }
    // /*  刷新数据 */
    await loadSafeInfo();
    setDialogVisible(false);
  };

  return (
    <div className={cx(styles['profile'])}>
      <SettingItem
        title={'Profile'}
        buttons={
          canEdit
            ? [
                {
                  name: t('common.edit'),
                  action: openEditProfile,
                  goSafeApp: false,
                },
              ]
            : []
        }
      >
        <div className={cx('settings-content')}>
          <div className={cx('form-item', 'username-item')}>
            <span className={styles['settings-item-label']}>
              {t('safe.username')}
            </span>
            <Input
              className={cx('settings-item-input')}
              disabled={true}
              value={safeInfo?.name}
            />
          </div>
          <div className={cx('form-item', 'network-item')}>
            <span className={styles['settings-item-label']}>
              {t('safe.network')}
            </span>
            <ChainSelect chain={safeInfo?.chain_id || ''} disabled={true} />
          </div>
          <div className={cx('form-item', 'address-item')}>
            <span className={styles['settings-item-label']}>
              {t('safe.address')}
            </span>
            <Input
              className={cx('settings-item-input')}
              disabled={true}
              value={safeInfo?.address}
            />
          </div>
        </div>
      </SettingItem>
      <EditProfileModal
        visible={dialogVisible}
        closeModal={() => {
          setDialogVisible(false);
        }}
        onSuccess={editModal}
      />
      <Divider />
    </div>
  );
}
