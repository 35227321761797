import { useMemo } from 'react';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.less';
import { Settings } from './Settings';
import { Breadcrumb } from '@/components';
import { orgIdAtom } from '@/globalAtom';
import { useGlobalSafe } from '@/hooks/useGlobalSafe';
import { modalVisibleAtom } from '@/routes/SafeDetail/atoms';
import { MultisigModuleModal } from '@/routes/SafeDetail/components/MultisigModuleModal';

export default function HomeSetting() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { safeInfo, safeId } = useGlobalSafe();
  const cx = classNames.bind(styles);
  const [orgId] = useAtom(orgIdAtom);
  const [visible, setVisible] = useAtom(modalVisibleAtom);

  const breadcrumbItems = useMemo(() => {
    return [
      {
        label: t('safeManagement.home'),
        onClick: () => navigate('/safe-management/' + safeId),
      },
      {
        label: t('safeManagement.settings'),
        onClick: () => {},
      },
    ];
  }, [safeId, t, navigate]);

  return (
    <div className={cx('detail-container')}>
      <Breadcrumb items={breadcrumbItems} />
      <div className={cx('content')}>
        <Settings />
        {safeInfo && orgId && (
          <MultisigModuleModal
            type={'enable'}
            safeInfo={{
              ...safeInfo,
              org_id: orgId,
            }}
            visible={visible}
            onClose={() => setVisible(false)}
          />
        )}
      </div>
    </div>
  );
}
