import { Table, TableProps } from 'antd';
import classNames from 'classnames/bind';

import styles from './index.module.less';

interface CoboTableProps extends TableProps<any> {
  className?: string;
  tableClassName?: string;
}

const cx = classNames.bind(styles);

export const CoboTable: React.FC<CoboTableProps> = props => {
  const { className, children, tableClassName, ...restProps } = props;
  return (
    <div
      className={
        className
          ? cx(className, styles['cobo-table-wrapper'])
          : styles['cobo-table-wrapper']
      }
    >
      <Table
        className={
          tableClassName
            ? cx(tableClassName, styles['cobo-table'])
            : styles['cobo-table']
        }
        {...restProps}
      >
        <div className={cx(['children-wrapper'])}>{children}</div>
      </Table>
    </div>
  );
};
