import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';

interface Props {
  connectWallet: () => Promise<void>;
}
export function SelectWallet({ connectWallet }: Props) {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);

  return (
    <div className={cx('select-wallet')}>
      <div className={cx('wallet')} onClick={connectWallet}>
        <div className={cx('wallet-icon', 'metamask')} />
        <div className={cx('wallet-title')}>{t('safeManagement.metamask')}</div>
      </div>
      {/* <div className={cx('wallet')}>
        <div className={cx('wallet-icon', 'walletConnect')}></div>
        <div className={cx('wallet-title')}>
          {t('safeManagement.walletConnect')}
        </div>
      </div> */}
    </div>
  );
}
