import styles from './index.module.less';

interface Props {
  onOpenCreateModal: () => void;
}
export function EmptyOrganization({ onOpenCreateModal }: Props) {
  return (
    <div className={styles['organization-body']}>
      <div className={styles['organization-empty-image']}></div>
      <p className={styles['organization-empty-text']}>
        You haven’t joined any organization.
      </p>
      <p className={styles['organization-empty-text']}>
        You can&nbsp;
        <span
          className={styles['organization-empty-link']}
          onClick={onOpenCreateModal}
        >
          Create an Organization
        </span>
        &nbsp; to start the journey
      </p>
    </div>
  );
}
