import { Checkbox as BaseCheckbox, CheckboxProps } from 'antd';
import classNames from 'classnames/bind';

import styles from './index.module.less';

interface Props extends CheckboxProps {
  round?: boolean;
}

export function Checkbox({ round, ...props }: Props) {
  const cx = classNames.bind(styles);
  return <BaseCheckbox className={cx(round ? 'round' : 'reg')} {...props} />;
}
