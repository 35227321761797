import { useState } from 'react';
import { Popover, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import ICONS from './util';
import { collect } from '@/api/axios/collect';
import { SvgIcon } from '@/components';

export function Support() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    if (newOpen) {
      collect({
        cid: 'Click_Page_Support',
      });
    }
    setOpen(newOpen);
  };

  const content = (
    <div className={styles['support-popover']}>
      <div className={styles['support-popover-title']}>
        <h2>{t('common.support')}</h2>
        <SvgIcon
          name="ic_closed"
          className={styles['support-icon']}
          onClick={() => setOpen(false)}
        />
      </div>
      <div className={styles['support-popover-content']}>
        <p>
          {t('common.contactUsAt')}
          <span className={styles['color-blue']}>argussupport@cobo.com</span>
        </p>
        <p> {t('common.helpText')}</p>
      </div>
    </div>
  );

  return (
    <div className={styles['support-container']} id="support-container">
      <div className={styles['support-item-box']}>
        {ICONS.map(({ name, link, alt }, index) => {
          if (name === 'ic_support') {
            return (
              <Tooltip title={alt} key={alt}>
                <Popover
                  key={name + link}
                  trigger={'click'}
                  content={content}
                  placement="rightBottom"
                  arrow={{ pointAtCenter: true }}
                  open={open}
                  onOpenChange={handleOpenChange}
                  getPopupContainer={() =>
                    document.getElementById('support-container') as HTMLElement
                  }
                >
                  <SvgIcon
                    name={'ic_support'}
                    className={styles['support-icon']}
                  />
                </Popover>
              </Tooltip>
            );
          }
          return (
            <Tooltip title={alt} key={alt}>
              <SvgIcon
                name={name as string}
                className={`${styles['support-icon']} ${
                  index > 2 && styles['support-icon-bg']
                }`}
                onClick={() => window.open(link)}
              />
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
}
