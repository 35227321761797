import { ReactNode } from 'react';
import classNames from 'classnames/bind';

import styles from './index.module.less';

interface Props {
  children: ReactNode;
  checked: boolean;
  onClick?: () => void;
  extraWrapperCls?: string;
}

export function Radio({ checked, children, onClick, extraWrapperCls }: Props) {
  const cx = classNames.bind(styles);
  return (
    <label
      className={cx('cobo-radio-wrapper', extraWrapperCls || '')}
      onClick={onClick}
    >
      <span
        className={cx('cobo-radio-img', checked ? 'checked' : 'unchecked')}
      />
      <span>{children}</span>
    </label>
  );
}
