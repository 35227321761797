import { useCallback, useState } from 'react';
import { useAtom } from 'jotai';

import {
  clearBundle,
  getSafeTxBundle,
  getTransactionsHistory,
  getTransactionsQueue,
  removeBundleItem,
  sendSafeTxConfirmationEmail,
  submitBundle,
} from '@/api';
import {
  SafeBundleInfoAtom,
  SafeTransactionHistoryAtom,
  SafeTransactionQueueAtom,
} from '@/globalAtom';
import { useGlobalSafe } from '@/hooks';

export const useTransactionBundle = () => {
  const { safeId, safeList, safeInfo } = useGlobalSafe();
  const [bundleInfo, setBundleInfo] = useAtom(SafeBundleInfoAtom);
  const [bundleQueue, setBundleQueue] = useAtom(SafeTransactionQueueAtom);
  const [bundleHistory, setBundleHistory] = useAtom(SafeTransactionHistoryAtom);

  const [historyTotalCount, setTotalCount] = useState(0);
  const [queueTotalCount, setQueueTotalCount] = useState(0);

  const getBundleInfo = useCallback(async () => {
    // 切换了没有Safe的Org，需要清空bundleInfo
    if (!safeList || safeList.length === 0) {
      setBundleInfo(undefined);
    }
    if (!safeId) return;
    const bundleInfo = await getSafeTxBundle(safeId);
    setBundleInfo(bundleInfo);
  }, [safeId, setBundleInfo, safeList]);

  const getBundleQueue = useCallback(
    async (page: number, page_size: number, silence: boolean) => {
      if (!safeId) return;
      const queue = await getTransactionsQueue(
        safeId,
        page,
        page_size,
        silence,
      );
      const total_count = queue.total_count;
      setQueueTotalCount(total_count);
      setBundleQueue(queue);
    },
    [safeId, setBundleQueue],
  );

  const getBundleHistory = useCallback(
    async (page: number, page_size: number, silence: boolean) => {
      if (!safeId) return;
      const history = await getTransactionsHistory(
        safeId,
        page,
        page_size,
        silence,
      );
      const total_count = history.total_count;
      setTotalCount(total_count);
      setBundleHistory(history.txs);
    },
    [safeId, setBundleHistory],
  );

  const statusColorMap: { [key: string]: string } = {
    'Waiting Confirmation': '#E86E2C',
    'Pending Confirmation': '#E86E2C',
    Success: '#65C97A',
    Failed: '#E74E5A',
    Rejected: '#E74E5A',
  };

  const sendEmail = useCallback(
    async (receivers: string[], safe_tx?: string) => {
      if (!safeId) return;
      await sendSafeTxConfirmationEmail(safeId, receivers, safe_tx);
    },
    [safeId],
  );

  const removeBundle = useCallback(
    async (id: string) => {
      if (!safeId) return;
      await removeBundleItem(safeId, id);
    },
    [safeId],
  );

  const clearBundleQueue = useCallback(async () => {
    if (!safeId) return;
    await clearBundle(safeId);
  }, [safeId]);

  const submitBundleQueue = useCallback(
    async (id: string, nonce: number, ignore_nonce_check: boolean) => {
      if (!safeId) return;
      return await submitBundle(safeId, id, nonce, ignore_nonce_check);
    },
    [safeId],
  );

  const defaultPageSize = 10;

  const resetTransactionsAfterChangeSafe = useCallback(() => {
    setBundleInfo(undefined);
    setBundleQueue(undefined);
    setBundleHistory(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBundleInfo, setBundleQueue, setBundleHistory, safeInfo?.id]);

  return {
    getBundleInfo,
    bundleInfo,
    getBundleQueue,
    getBundleHistory,
    bundleQueue,
    bundleHistory,
    historyTotalCount,
    queueTotalCount,
    statusColorMap,
    defaultPageSize,
    sendEmail,
    removeBundle,
    clearBundleQueue,
    submitBundleQueue,
    resetTransactionsAfterChangeSafe,
  };
};
