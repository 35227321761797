import Web3 from 'web3';

export function getHexByName(name: string, limit = 66): string {
  let hex = Web3.utils.utf8ToHex(name);
  if (hex.length > limit) {
    throw Error('role name length exceed limit');
  }
  hex = Web3.utils.padRight(hex, limit - 2);
  return hex;
}

export function getUtf8ByHex(hex: string): string {
  try {
    return Web3.utils.hexToUtf8(hex);
  } catch {
    return hex;
  }
}

export function toFixed(
  number: number | undefined,
  fractionDigits: number,
): number {
  if (number === undefined) {
    return 0;
  }
  return parseFloat(number.toFixed(fractionDigits));
}

export function getFlooredFixed(v: number, d: number) {
  return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
}

// 数字转千分位显示
export function numWithCommas(num: number | string) {
  if (typeof num === 'number' && isNaN(num)) return '';
  const splits = num.toString().split('.');
  if (splits.length > 0) {
    let firstStr = splits[0];
    if (firstStr) {
      if (parseFloat(firstStr) === 0) {
        firstStr = '0';
      } else {
        firstStr = firstStr.replace(/^0+/, '');
      }
    }
    const restStr = splits.slice(1);
    if (restStr.length > 0) {
      restStr.unshift('.');
    }
    return [firstStr.replace(/\B(?=(\d{3})+(?!\d))/g, ','), ...restStr].join(
      '',
    );
  } else {
    return '';
  }
}

export function toFixedWithCommas(
  number: number | undefined,
  fractionDigits = 2,
): string {
  return numWithCommas(toFixed(number, fractionDigits));
}
