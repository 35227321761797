import { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './index.module.less';

interface Props {
  size?: string;
  dark?: boolean;
}
export const LoadingIcon: FC<Props> = props => {
  const { size, dark } = props;
  const cx = classNames.bind(styles);
  return dark ? (
    <div className={cx('loadingIcon-dark-small')}></div>
  ) : size ? (
    <div
      className={cx('loadingIcon')}
      style={{
        backgroundSize: size,
        width: size,
        height: size,
        margin: `-${parseFloat(size) / 2}px`,
      }}
    ></div>
  ) : (
    <div className={cx('loadingIcon')}></div>
  );
};

LoadingIcon.defaultProps = {
  size: '64px',
};
