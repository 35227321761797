import { AbiItem } from 'web3-utils';

import {
  AuthorizeMultiSigRecord,
  BotMultiSigRecord,
} from '@/interfaces/authorize';
import { TokenInfo, TokenTransferItem } from '@/interfaces/bots';

export interface StrategySearchCondition {
  chain: string;
  protocol: string;
  token: string;
  page: number;
  service_type: string;
  strategy_type: '2' | '5';
}

export interface ProtocolItem {
  chain_id: string;
  icon_url: string;
  name: string;
  project_id: string;
  protocol_url?: string;
  project_type: string[];
}
export interface TokenItem {
  symbol: string;
  address: string;
  decimals: number;
  chain_id: string;
  icon_url: string;
}

export interface StrategyListProps {
  current_page: number;
  page_size: number;
  chain_id?: string;
  invest_coin?: string;
  strategy_type?: number | string;
  protocol_id?: string;
  order_by?: string;
  is_new?: boolean;
  service_type?: string;
}

export interface StrategyDesc {
  chain_id: string;
  intro: string;
  invest_tokens?: TokenItem[];
  is_new: boolean;
  icon: string | undefined;
  pool: string;
  pool_id: string;
  projects: ProtocolItem[];
  reward_tokens: TokenItem[];
  type: number;
  unique_id: string;
}

export interface StrategyListItem {
  strategy: string;
  desc: StrategyDesc;
  error_msg?: string | null;
  permissions: AuthorizationPermission[];
  services: StrategyService[];
}

export interface StrategyAction {
  name: string;
  required_role: string[];
}

export interface StrategyACL {
  type: 'common' | 'dex' | 'lend';
  targets?: string[] | null;
  top_tokens?: TokenInfo[];
  nonces?: number[] | null;
  acl_factory: string;
  min_ver?: { [key: string]: number };
  // dex
  traders?: {
    sell: TokenInfo[];
    buy: TokenInfo[];
  };
  // lend
  borrowers?: {
    health_factor: number;
  };
  health_factor_low?: number;
  health_factor_mid?: number;
  health_factor_high?: number;
}

export interface AuthorizationPermission {
  name: string;
  desc: {
    chain_id?: string | null;
    intro: string;
  };
  authorizers: {
    type: string;
    factory_key: string;
    desc: {
      intro: string;
      auths: {
        [key: string]: string[];
      }[];
    };
    config: {
      token_list?: string[];
      actions: {
        poolAddresses: {
          add: AbiItem;
          get: AbiItem;
          remove: AbiItem;
        };
        poolIds: {
          add: AbiItem;
          get: AbiItem;
          remove: AbiItem;
        };
      };
      init: {
        poolIds: number[];
        poolAddresses: string[];
      };
    };
  }[];
  token_approvals: {
    type: number;
    approval_list: AuthorizationBundleToken[];
    config: {
      spender_list?: string[];
    };
  };
}

export enum ServiceDetailType {
  CUSTOMIZED_BOT = 'Customized Bot',
  CLAIM = 'Claiming Bot',
  WITHDRAW = 'Withdrawal Bot',
  LEVERAGE = 'Leveraging Bot',
  TRADING = 'trading',
  FARMING = 'farming',
}

export enum ServiceType {
  BOT = 'Bot',
  SINGLE_SIGN = 'Single-Sig Authorization',
}

export interface StrategyService {
  detail_type: ServiceDetailType;
  service_id: string;
  service_name?: string;
  service_type: ServiceType;
  service_description: string;
  service_status: 'In use' | null;
}

export interface ContractsInfo {
  contract_authorizations: ContractsItem[];
  include_role: { [key: string]: ContractsItem[] };
}
export interface ContractsItem {
  contract_address: string;
  contract_name: string;
  signature2selector: any;
}
export interface Contracts {
  [key: string]: ContractsItem[];
}

export interface AuthorizationBundle {
  tokens?: AuthorizationBundleToken[];
  transfer_allowed_tokens?: TokenItem[];
  contracts: { [key: string]: ContractsInfo };
}

export interface WithdrawData {
  deposits?: WithdrawDataItem[];
  unclaimed?: WithdrawDataItem[];
  debts?: WithdrawDataItem[];
}

export interface WithdrawDataItem {
  token_info: TokenItem;
  amount: number;
  price: number;
}
export interface AuthorizationBundleToken {
  allowance: string;
  spender_address: string;
  token_info: TokenItem;
}

export interface StrategyAuthorization {
  permission_roles: { [key: string]: string[] };
  // key: swap_in_tokens | swap_out_tokens
  dex_acl_config: { [key: string]: string[] };
  // key: token address
  token_transfer_permission: { [key: string]: string[] };
  user_set_token_approval: { [key: string]: string[] };
}

export interface StrategyPermissionRole {
  // key: permission name e.g. Argus1inch-Trade
  [key: string]: {
    roles_config: string[];
    permission_config?: {
      token_transfer_permission?: { [key: string]: string[] };
      dex_acl_config?: {
        swap_in_tokens: string[];
        swap_out_tokens: string[];
      };
      user_set_token_approval?: {
        [key: string]: string[];
      };
    };

    contract_interactions?: {
      [key: string]: string[];
    } | null;
    token_transfers?: TokenTransferItem[];
    token_approvals?: AuthorizationBundleToken[];
  };
}

export interface StrategyAuthorizationInfoItem {
  permission_roles: StrategyPermissionRole;
  new_roles?: SafeRole;
}

export interface SafeTx {
  safe_address: string;
  to: string;
  value: string;
  data: string;
  operation: number;
  safe_tx_gas: number;
  base_gas: number;
  gas_price: number;
  gas_token: string;
  refund_receiver: string;
  safe_nonce: number;
  safe_version: string;
  chain_id: number;
  safe_tx_hash: string;
}

export enum MyPortfolioTab {
  MyInvestment,
  MyAuthorization,
}

export interface PortfolioListItem {
  id: string;
  name: string;
  safe_address: string;
  status?: string;
  module_address?: string;
}

export interface WhitelistItem {
  token: string;
  whitelist: string[];
}
export interface InvestmentPositionItem {
  deposit_amounts: WithdrawDataItem[];
  unclaimed_amounts: WithdrawDataItem[];
  address: string;
  name: string;
  update_time: number;
}

export interface InvestmentTransaction {
  address: string;
  is_multisig: boolean;
  name: string;
  operator: string;
  operator_address: string;
  safe_tx_hash: string;
  status: string;
  time: number;
  tx_hash: string;
  type: string;
  chain_id: string;
}

export interface InvestmentTransactions {
  transactions: any[];
  total_count: number;
}

export interface MyPortfolioTabData {
  portfolios?: any;
  total_count: number;
  total_deposit_value: number;
  total_unclaimed_value: number;
  strategy_count?: number;
  total_value: number;
  safe_count?: number;
  farming_strategy_count?: number;
  trading_strategy_count?: number;
  strategies: PortfolioListItem[];
}

export interface SafeRole {
  [key: string]: string[];
}

export interface DexACLMap {
  [key: string]: {
    swap_in_tokens: string[];
    swap_out_tokens: string[];
  };
}

export interface StrategyServiceTypes {
  [key: string]: string[];
}

export interface BundleItem {
  id: string;
  tx_type: string;
  strategy_name: string;
  strategy_icon?: string;
  bot_name?: string;
  service_name?: string;
  strategy_id: string;
  service: string;
  initiator: string;
  bot_definition_id?: string;
  strategy_pool_id?: string;
  strategy_is_new: boolean;
  strategy_protocol: ProtocolItem[];
  strategy_invest_tokens: TokenItem[];
  strategy_type: string; // 2 Farming, 5 Trading
  details?: AuthorizeMultiSigRecord | BotMultiSigRecord;
}

export interface BundleInfo {
  bundle_id: string;
  bundle_items: BundleItem[];
}

export interface portfolioItem {
  services: serviceItem[];
  strategy_chain: string;
  strategy_icon: string;
  strategy_id: string;
  strategy_invest_tokens: TokenItem[];
  strategy_is_new: boolean;
  strategy_name: string;
  strategy_pool_id: string | null;
  strategy_protocol: ProtocolItem[];
  strategy_safety_level: number;
  strategy_type: number;
}

export interface MyPortfolioSafe {
  portfolios: portfolioItem[];
  total_count: number;
  strategy_bot_count: number;
  customized_bot_count: number;
}

export interface serviceItem {
  service_type: ServiceType;
  detail_type: ServiceDetailType;
  service_name: string;
  service_description: string;
  service_id: string;
  service_status: 'In use' | null;
}
export interface TransactionsQueueInfo {
  total_count: number;
  txs: TransactionsQueueItem[];
}
export interface TransactionsQueueItem {
  nonce: number;
  safe_tx_hash: string;
  type: string;
  time: number;
  threshold: string;
  action_count: number;
  status: string;
}
