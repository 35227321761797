import { forwardRef, useImperativeHandle, useState } from 'react';
import { Popover } from 'antd';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import Chain from '@/chains';
import { getChainIdByCoboName, getShowNumber } from '@/chains/utils';
import { SvgIcon } from '@/components';
import {
  qrCodeModalDataAtom,
  qrCodeModalOpenAtom,
} from '@/components/Layout/GasSelector/atom';
import { GasData } from '@/interfaces';

interface Props {
  gas: GasData;
  handleNewOpen: () => void;
}
interface PopoverHandle {
  hide: () => void;
}
export const GasAccountPopover = forwardRef<PopoverHandle, Props>(
  (props, ref) => {
    const { gas, handleNewOpen } = props;
    useImperativeHandle(ref, () => ({
      hide: () => {
        setOpen(false);
      },
    }));
    const cx = classNames.bind(styles);
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [, setQrCodeModalData] = useAtom(qrCodeModalDataAtom);
    const [, setQrCodeModalOpen] = useAtom(qrCodeModalOpenAtom);

    const handleOpenChange = (newOpen: boolean) => {
      if (newOpen) {
        handleNewOpen();
      }
      setOpen(newOpen);
    };
    const chain = new Chain(getChainIdByCoboName(gas.chain));
    return (
      <div className={cx('gas-account-warp')}>
        <Popover
          destroyTooltipOnHide={true}
          trigger={'click'}
          ref={ref => {}}
          content={
            <div className={styles['low-gas-popover']}>
              <div className={styles['low-gas-popover-content']}>
                <p>
                  {t('bots.lowGasContent', {
                    number:
                      getShowNumber(
                        gas.recommended_min_balance,
                        gas.gas_token_decimals,
                        4,
                      ) || 0,
                    token: chain.getBaseToken(),
                  })}
                </p>
                <span
                  className={cx('deposit')}
                  onClick={() => {
                    setOpen(false);
                    setQrCodeModalData(gas);
                    setQrCodeModalOpen(true);
                  }}
                >
                  {t('bots.deposit')}
                </span>
              </div>
              <SvgIcon
                name="ic_closed"
                className={styles['close-icon']}
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
          }
          placement="bottom"
          arrow={{ pointAtCenter: true }}
          open={open}
          onOpenChange={handleOpenChange}
        >
          <div className={cx('tip-icon')} />
        </Popover>
      </div>
    );
  },
);
