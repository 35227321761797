import { coboApi } from '@/api';
import {
  AvailableGeneralBotItem,
  BotConfig,
  BotSummaryData,
  BotTxLog,
  GenericBotInfo,
  PermissionConfig,
  SafeTx,
  SubmitBotParams,
  TokenInfo,
  WebhookInfo,
} from '@/interfaces';

export const getGenericBots = async (
  orgId: string,
  safeId: string,
  silence: boolean = false,
  hide_instance: boolean = false,
): Promise<AvailableGeneralBotItem[]> => {
  return await coboApi.post(
    '/argus/generic_bot/get_generic_bots/',
    {
      org_id: orgId,
      hide_instance,
      safe_id: safeId,
    },
    {
      params: { silence: silence },
    },
  );
};

export const getGenericBotsSummary = async (
  orgId: string,
  silence: boolean = false,
  safe_id?: string,
): Promise<BotSummaryData> => {
  return await coboApi.post(
    '/argus/generic_bot/get_generic_bots_summary/',
    {
      org_id: orgId,
      safe_id,
    },
    {
      params: { silence: silence },
    },
  );
};

export const getGenericBotDetail = async (
  org_id: string,
  bot_definition_id: string,
): Promise<GenericBotInfo> => {
  return await coboApi.post('/argus/generic_bot/get_bot_details/', {
    org_id,
    bot_definition_id,
  });
};

export const getGenericBotInstanceDetail = async (
  org_id: string,
  bot_instance_id: string,
): Promise<GenericBotInfo> => {
  return await coboApi.post('/argus/generic_bot/get_bot_instance_details/', {
    org_id,
    bot_instance_id,
  });
};

export const activeAdvanceBot = async (
  safe_id: string,
  safe_module_id: string,
  bot_definition_id: string,
  generic_setup: { safe: string; safe_module: string; bot_name: string },
  triggers: { [key: string]: any },
  tasks: { [key: string]: any },
  token_approvals?: {
    spender_address: string;
    allowance: string;
    token_info?: TokenInfo;
  }[],
  add_to_txs_bundle?: boolean,
  webhook_id?: string,
): Promise<SafeTx | null> => {
  return coboApi.post('/argus/generic_bot/active_advance_bot/', {
    safe_id,
    safe_module_id,
    bot_definition_id,
    generic_setup,
    triggers,
    tasks,
    token_approvals,
    webhook_id,
    add_to_txs_bundle,
  });
};

export const editAdvanceBot = async (
  safe_id: string,
  safe_module_id: string,
  bot_instance_id: string,
  generic_setup: { safe: string; safe_module: string; bot_name: string },
  triggers: { [key: string]: any },
  tasks: { [key: string]: any },
  token_approvals?: {
    spender_address: string;
    allowance: string;
    token_info?: TokenInfo;
  }[],
  add_to_txs_bundle?: boolean,
  webhook_id?: string,
): Promise<SafeTx | { bundle_id: string } | null> => {
  return coboApi.post('/argus/generic_bot/edit_advance_bot/', {
    safe_id,
    safe_module_id,
    bot_instance_id,
    generic_setup,
    triggers,
    tasks,
    token_approvals,
    webhook_id,
    add_to_txs_bundle,
  });
};

export const activeGenericBot = async (
  safe_id: string,
  safe_module_id: string,
  bot_definition_id: string,
  config: BotConfig,
): Promise<SafeTx | null> => {
  return coboApi.post('/argus/generic_bot/active_bot/', {
    safe_id,
    safe_module_id,
    bot_definition_id,
    config: JSON.stringify(config),
  });
};

export const editGenericBot = async (
  safe_id: string,
  bot_instance_id: string,
  config: BotConfig,
): Promise<SafeTx | null> => {
  return coboApi.post('/argus/generic_bot/edit_bot/', {
    safe_id,
    bot_instance_id,
    config: JSON.stringify(config),
  });
};

export const getGenericBotActivateStatus = async (
  safe_id: string,
  safe_module_id: string,
  bot_definition_id?: string,
  bot_instance_id?: string,
): Promise<{ pending_bot_activate_tx_hash: string | null }> => {
  return coboApi.post('/argus/generic_bot/get_bot_activate_status/', {
    safe_id,
    safe_module_id,
    bot_definition_id,
    bot_instance_id,
  });
};

export const pauseGenericBot = async (
  safe_id: string,
  bot_instance_id: string,
) => {
  return coboApi.post('/argus/generic_bot/pause_bot/', {
    safe_id,
    bot_instance_id,
  });
};

export const resumeGenericBot = async (
  safe_id: string,
  bot_instance_id: string,
) => {
  return coboApi.post('/argus/generic_bot/resume_bot/', {
    safe_id,
    bot_instance_id,
  });
};

export const terminateGenericBot = async (
  safe_id: string,
  bot_instance_id: string,
): Promise<SafeTx | null> => {
  return coboApi.post('/argus/generic_bot/terminate_bot/', {
    safe_id,
    bot_instance_id,
  });
};

export const getGenericBotTerminateStatus = async (
  safe_id: string,
  safe_module_id: string,
  bot_instance_id: string,
  silence: boolean = true,
): Promise<{
  pending_bot_terminate_tx_hash: string;
  bot_status: 'to_be_active' | 'running' | 'paused';
}> => {
  return coboApi.post(
    '/argus/generic_bot/get_bot_terminate_status/',
    {
      safe_id,
      safe_module_id,
      bot_instance_id,
    },
    {
      params: { silence: silence },
    },
  );
};

export const getBotOperationLog = async (
  bot_instance_id: string,
  safe_id: string,
  page: number,
  page_size: number,
): Promise<BotTxLog> => {
  return coboApi.post('/argus/generic_bot/get_bot_operation_log/', {
    bot_instance_id,
    safe_id,
    page,
    page_size,
  });
};

export const getBotSafeDatas = async (
  org_id: string,
  bot_definition_id: string,
  safe_id: string,
): Promise<{
  current_ratio: number | string;
  min_ratio: number | string;
}> => {
  return await coboApi.post('/argus/generic_bot/get_safe_datas/', {
    org_id,
    safe_id,
    bot_definition_id,
  });
};

export const getBotPermissions = async ({
  safe_id,
  safe_module_id,
  bot_definition_id,
  generic_setup,
  triggers,
  tasks,
  token_approvals,
}: SubmitBotParams): Promise<PermissionConfig> => {
  return await coboApi.post('argus/generic_bot/get_bot_permissions/', {
    safe_id,
    safe_module_id,
    bot_definition_id,
    generic_setup,
    triggers,
    tasks,
    token_approvals,
  });
};

export const getWebhookInfo = async (
  safe_id: string,
  safe_module_id: string,
  bot_definition_id: string,
  bot_instance_id: string,
  channel = 'web',
): Promise<WebhookInfo> => {
  return await coboApi.post('/argus/webhook/get_bot_trigger/', {
    safe_id,
    safe_module_id,
    bot_definition_id,
    bot_instance_id,
    channel,
  });
};

export const refreshWebhook = async (
  safe_id: string,
  safe_module_id: string,
  bot_definition_id: string,
  bot_instance_id: string,
  channel = 'web',
): Promise<WebhookInfo> => {
  return await coboApi.post('/argus/webhook/refresh_bot_trigger/', {
    safe_id,
    safe_module_id,
    bot_definition_id,
    bot_instance_id,
    channel,
  });
};

export const getWebhookRecord = async (
  safe_id: string,
  webhook_id: string,
  limit = 100,
): Promise<any[]> => {
  return await coboApi.post('/argus/webhook/get_bot_trigger_records/', {
    safe_id,
    webhook_id,
    limit,
  });
};

export const requestWebhook = async (
  safe_id: string,
  webhook_id: string,
  trigger_data: Object,
): Promise<any> => {
  return await coboApi.post(`/hooks/bot/${safe_id}/${webhook_id}/`, {
    trigger_data,
  });
};
