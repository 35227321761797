import { BaseChain } from './base';

export default class Manta extends BaseChain {
  name = 'Manta';
  shortName = 'manta';

  private providerUrl = 'https://pacific-rpc.manta.network/http/';
  private scanBaseUrl = 'https://manta.socialscan.io';
  public safeWebBaseUrl = 'https://safe.manta.network';
  private safeApiBaseUrl = 'https://transaction.safe.manta.network';
  subsafeFactory = '';
  baseToken = 'ETH';
  baseTokenName = 'ETH';

  public safeUrl(address: string): string {
    return `${this.safeWebBaseUrl}/home?safe=${this.shortName}:${address}`;
  }

  public addressUrl(address: string): string {
    return `${this.scanBaseUrl}/address/${address}`;
  }

  public tokenUrl(address: string): string {
    return `${this.scanBaseUrl}/token/${address}`;
  }

  public rpcUrl(): string {
    return this.providerUrl;
  }

  public safeApiUrl(): string {
    return this.safeApiBaseUrl;
  }

  public txDetailUrl(hash: string): string {
    return `${this.scanBaseUrl}/tx/${hash}`;
  }
}
