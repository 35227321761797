import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Expand from '../Expand';
import styles from '../index.module.less';
import { Button, Input } from '@/components';

interface Props {
  wcClientData: any;
  onConnect: (uri: string) => Promise<void>;
}

export default function UriInput({ wcClientData, onConnect }: Props) {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const [isConnecting, setIsConnecting] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isConnecting) {
      interval = setTimeout(() => setIsConnecting(false), 2_000);
    }

    return () => clearTimeout(interval);
  }, [isConnecting]);

  const onPaste = useCallback(
    (event: React.ClipboardEvent) => {
      const connectWithUri = (data: string) => {
        if (data.startsWith('wc')) {
          setIsConnecting(true);
          onConnect(data);
          setInputValue(data);
        }
      };

      setInputValue('');

      if (wcClientData) {
        return;
      }

      const { items } = event.clipboardData;

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const index in items) {
        const item = items[index];

        if (item.kind === 'string' && item.type === 'text/plain') {
          connectWithUri(event.clipboardData.getData('Text'));
        }
      }
    },
    [wcClientData, onConnect],
  );
  return (
    <div className={cx('wallet-connect-container', 'input-container')}>
      <div className={cx('logo')} />
      <Input
        containerCls={cx('uri-input')}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onPaste={onPaste}
        disabled={isConnecting}
        placeholder={t('walletconnect.uriPlaceholder')}
      />
      <Button
        disabled={isConnecting || !inputValue}
        onClick={() => {
          if (!inputValue) return;
          try {
            if (inputValue.startsWith('wc')) {
              setIsConnecting(true);
              onConnect(inputValue);
            } else {
              throw new Error('incorrect uri');
            }
          } catch (e: any) {
            message.error(e.message);
          }
        }}
        type="primary"
      >
        {t('common.connect')}
      </Button>
      <Expand connected={false} />
    </div>
  );
}
