import React, { memo, useCallback } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { Button, Modal } from '@/components';
import Box from '@/components/Common/Box';

interface Props {
  visible: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  isNewUser: boolean;
}

const TERMS_OF_SERVICE_URL =
  'https://www.cobo.com/files/CoboArgusTermsandConditions.pdf';

const AgreementsModal: React.FC<Props> = ({
  visible,
  handleClose: handleCloseProp,
  handleConfirm: handleConfirmProp,
  isNewUser,
}) => {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);

  const handleClose = useCallback(handleCloseProp, [handleCloseProp]);
  const handleConfirm = useCallback(handleConfirmProp, [handleConfirmProp]);

  const renderLink = () => (
    <a href={TERMS_OF_SERVICE_URL} target="_blank" rel="noreferrer">
      {t('safeManagement.serviceAgreementsModal.termsofService')}
    </a>
  );

  return (
    <Modal
      title={t('safeManagement.serviceAgreementsModal.title')}
      visible={visible}
      onCloseBtnClick={handleClose}
    >
      <div className={cx('risk-content')}>
        {isNewUser ? (
          <>
            {t('safeManagement.serviceAgreementsModal.newUserContent')}
            &nbsp;
            {renderLink()}
            &nbsp;
            {t('safeManagement.serviceAgreementsModal.first')}.
          </>
        ) : (
          <>
            {t('safeManagement.serviceAgreementsModal.content')}
            &nbsp;
            {renderLink()}
          </>
        )}
      </div>

      <Box height={32} />

      <div className={cx('btn-box')}>
        <Button
          className={cx('cancel-btn-box')}
          onClick={handleClose}
          btnClassName="!bg-greyBtn !text-greyFont"
        >
          {t('safeManagement.serviceAgreementsModal.cancel')}
        </Button>
        <Button
          className={cx('confirm-btn-box')}
          onClick={handleConfirm}
          type="primary"
        >
          {t('safeManagement.serviceAgreementsModal.understand')}
        </Button>
      </div>
      <Box height={32} />
    </Modal>
  );
};

export default memo(AgreementsModal);
