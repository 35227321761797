import { BaseChain } from './base';

export default class Mainnet extends BaseChain {
  name = 'Ethereum Mainnet';
  shortName = 'eth';

  private etherscanWebBaseUrl = 'https://etherscan.io';
  private infraBaseUrl = 'https://mainnet.infura.io';
  public safeWebBaseUrl = 'https://app.safe.global';
  private safeApiBaseUrl = 'https://safe-transaction-mainnet.safe.global';
  baseToken = 'ETH';
  baseTokenName = 'Ether';
  subsafeFactory = '0x4FdFF384F51Bd5e128e53B09EFFED79a39fB654e';

  public safeUrl(address: string): string {
    return `${this.safeWebBaseUrl}/home?safe=${this.shortName}:${address}`;
  }

  public addressUrl(address: string): string {
    return `${this.etherscanWebBaseUrl}/address/${address}`;
  }

  public tokenUrl(address: string): string {
    return `${this.etherscanWebBaseUrl}/token/${address}`;
  }

  public rpcUrl(): string {
    const token = process.env.REACT_APP_INFRA_TOKEN;
    return `${this.infraBaseUrl}/v3/${token}`;
  }

  public safeApiUrl(): string {
    return this.safeApiBaseUrl;
  }

  public txDetailUrl(hash: string): string {
    return `${this.etherscanWebBaseUrl}/tx/${hash}`;
  }
}
