export const safeInfo = {
  emptySafeRole: 'You have not created any safe roles yet.',
  emptyDelegatedAddress: 'You have not delegated safe role to any address yet.',
  leavePageTip1: `Are you sure you want to leave this tab?`,
  leavePageTip2: `You have unsaved changes that will be lost if you decide to continue.`,
  batchRevokeButtonText: 'Complete Multi-Sig Confirmation',
  spender: 'Spender',
  all: 'All',
  allSafe: 'All Safes',
  coboSafeModule: 'Cobo Safe',
  safeModule: 'Cobo Safe',
  coboModule: 'Cobo Safe',
  totalBalance: 'Total Assets',
  totalAssets: 'Total Assets',
  walletConnect: 'WalletConnect',
  rabbyNotInstall: 'Rabby Wallet not yet installed',
  downloadRabbyNotice:
    'You can download the Rabby Wallet browser extension to seamlessly connect Cobo Argus with dApps, akin to WalletConnect.',
  downloadRabby: 'Download Rabby Wallet',
  importRabbyAddress:
    "Import private keys of delegate addresses and Cobo Safe's address",
  importToRabby: 'Import Cobo Safe’s address into Rabby',
  importToRabbyNotice:
    'After importing Cobo Safe’s address into Rabby, you can use your Rabby Wallet to seamlessly connect Cobo Argus with dApps.',
  connectRabby: 'Connect Rabby Wallet on dApps',
  switchRabby: 'Switch to Cobo Argus on Rabby',
  moduleDescTitle: 'What is a Cobo Safe?',
  moduleDesc:
    'Cobo Safe is an on-chain access control management smart contract. It provides granular risk control ability for web3 protocol interaction.',
  createModuleTip: 'Before starting, please create a Cobo Safe first.',
  createModulePendingTip: 'Your Cobo Safe is being created.',
  createModuleBtn: '+ Create Cobo Safe',
  createModuleTitle: 'Create Cobo Safe',
  createModuleNotice: 'You’ll create a new Cobo Safe under the Safe:',
  enableModuleTitle: 'Enable Cobo Safe',
  enableModuleNotice: 'You’ll enable a new Cobo Safe under the Safe:',
  enableModuleTip: 'The Cobo Safe is disabled, please enable it',
  enableModulePendingTip: 'Your Cobo Safe is being enabled.',
  importSafeTip: `To get started, please import your Safe{Wallet} first.`,
  createPendingBtn: 'Check Progress',
  enablePendingBtn: 'Check Progress',
  enableModuleBtn: 'Enable Cobo Safe',
  needMultiSig: 'Need Multi-sig Confirmation',
  needMultiSigSubtitle:
    'Please notify the Safe Owner to complete multi-signature on ',
  needMultiSigSubBtn: 'Safe{Wallet}',
  createSuccessNotice: 'Your Cobo Safe has been created!',
  multisigFailed: 'Oops, the multi-sig transaction is failed.',
  multisigFailedNotice: 'Please check the transaction detail and try again',
  enableSuccessNotice: 'Your Cobo Safe has been enabled!',
  permissions: 'Permissions',
  safeRoles: 'Safe Roles',
  authorization: 'Authorizations',
  delegatedMembers: 'Delegated Members',
  delegatedMembersHint:
    'Delegated Members are externally owned account (EOA) addresses, which are usually generated by your wallet.',
  settings: 'Settings',
  tokenApproval: 'Token Approvals',
  batchRevokeButton: 'Batch Revoke',
  token: 'Token',
  tokenExposure: 'Token Exposure',
  approvedSpender: 'Approved Spender',
  allowance: 'Allowance',
  revoke: 'Revoke',
  update: 'Update',
  revokeHint1: 'This token approval has been applied to Argus strategies.',
  revokeHint2:
    'Please note that if the approval is revoked, the strategies may not work properly.',
  updateHint1: 'This token approval has been applied to Argus strategies.',
  updateHint2:
    'Please note that if the token allowance is updated, the strategies may not work properly.',
  needsConfirmationTitle: 'Multi-Sig Confirmation Required',
  needsConfirmationContent1: 'Your transaction has been submitted.',
  needsConfirmationContent2:
    'Please inform Safe Owner(s) to complete a multi-sig transaction on ',
  gnosisSafe: 'Safe{Wallet}',
  needsConfirmationContent3: ' platform to complete the Multi-Sig process.',
  backToTheStrategy: 'Back',
  checkTransaction: 'Check the transaction',
  batchRevoke: 'Batch Revoke',
  selectTokens: 'Select tokens whose approvals need to be revoked.',
  batchRevokeHint: 'The approval for the token{{s}} below will be revoked. ',
  batchRevokeHint2:
    'Please note that some strategies may not work properly thereafter.',
  unlimited: 'Unlimited',
  customized: 'Customized',
  allowancePlaceholder: 'Amount > 0',
  safeRoleName: 'Safe Role Name',
  delegatedAddress: 'Number of Delegate Addresses',
  permission: 'Permissions',
  numberOfPermissions: 'Number of Permissions',
  managePermission: 'Manage Permissions',
  manageAddress: 'Manage Addresses',
  viewPermission: 'View Permissions',
  viewAddress: 'View Address',
  addPermission: 'Add Permission',
  delegateAddresses: 'Delegate Addresses',
  delegateAddressBtn: 'Delegate Address',
  delegateMember: 'Member',
  safeRole: 'Safe Role',
  manageSafeRole: 'Manage Safe Roles',
  viewSafeRole: 'View Safe Roles',
  contractInteractionType: 'Function Level Access Control',
  importType: 'Parameter-Level Access Control',
  contractInteraction: 'Contract Interaction',
  parseTxn: 'Parse Transaction',
  permissionName: 'Permission Name',
  permissionNamePlaceholder: 'Please input permission name',
  authorizerAddress: 'Authorizer Contract Address',
  authorizerAddressTip:
    'You can write your own authorizer smart contract to achieve parameter-level and highly customized access controls. For guidance on writing an authorizer contract, please refer to the Argus product manuals.',
  invalidAuthorizer: 'Invalid Authorizer Address',
  duplicatedAuthorizer: 'Duplicated Authorizer Address',
  invalidPermissionName: 'Permission name too long',
  duplicatedPermissionName: 'Duplicated permission name',
  contractAddress: 'Contract Address',
  transactionHash: 'Transaction Hash',
  invalidContract: 'Invalid Contract Address',
  invalidTransaction: 'Invalid Transaction',
  addMoreContracts: 'Add more contracts',
  switchToContractText1: 'You can also ',
  switchToContractText2: 'enter the contract address.',
  switchToParseTxnText1: 'Have difficulty in contract address? You can also ',
  switchToParseTxnText2: 'parse the transaction hash.',
  contractName: 'Contract Name',
  function: 'Function',
  addNewContract: 'Add new contract',
  modificationQueue: 'Modification Queue',
  activity: 'Activity',
  completeMultisig: 'Complete Multisig Confirmation',
  submitModification: 'Submit Modification',
  proceedModification: 'Proceed to multisig',
  undoModification: 'Undo modification',
  undoModificationConfirm: 'Are you sure you want to undo the modification?',
  pendingModification: 'Pending Multi-Sig Transaction',
  refreshByMultisig:
    'Safe{Wallet} multisig status has changed. The page will refresh and then you can continue.',
  pendingModificationMultisig: 'Pending Modification Multisig',
  pendingModificationMultisigText1:
    'There are pending modifications multisig that require confirmation. The modification will not take effect until the multisig is confirmed.',
  pendingModificationMultisigText2:
    'To view and confirm these transactions, please visit ',
  notInOrg: 'Not in this org',
  upgradeFailedMsg: 'Upgrade Cobo Safe failed',
  upgradeSuccessMsg: 'Upgrade Cobo Safe successfully!',
  roleName: 'Role Name',
  safe: `Safe{Wallet}`,
  module: 'Cobo Safe',
  notCreated: `Haven't created yet`,
  createNow: 'Create Now',
  notEnabled: `Cobo Safe not yet enabled`,
  services: 'Services',
  farming: 'Farming',
  trading: 'Trading',
  strategyBots: 'Strategy Bots',
  customizedBots: 'Customized Bots',
  running: 'Running',
};
