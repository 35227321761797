export interface BindSafeInfo {
  name: string;
  address: string;
  chain_id: string;
  id: string;
  default_cobo_module: string;
}

export enum PermissionPage {
  Role,
  ACL,
}

export interface SafeRoleContract {
  address: string;
  funcs: string[];
}

export interface SafeRoleTableItem {
  contracts: SafeRoleContract[];
  role: string;
}

export interface SafeRoleItem {
  address: string;
  email: string[];
  nick_name?: string[];
  label: string | string[];
}

export interface SafeRoles {
  [key: string]: SafeRoleItem[];
}

export interface ProtectorTableItem {
  contract: string;
  contractName: string;
  protector: string;
}

export interface OwnerTableItem {
  nickname: string;
  memberEmail: string;
  address: string;
}

export interface GnosisSafeInfo {
  address: string;
  fallbackHandler: string;
  modules: string[];
  threshold: number;
  version: string;
  owners: {
    address: string;
    label?: string;
  }[];
  nonce: number;
}

export interface SafeListData {
  address: string;
  chain_id: string;
  name: string;
  id: string;
  default_cobo_module: string;
  version: string;
  factory: string;
  isCoboModule: boolean;
  maxVersion: string;
  needUpdate: boolean;
  showDisabled: boolean;
  usd_value: number;
  module_status: ModuleEnabledType;
  default_module_id?: string;
}

export enum ModuleEnabledType {
  hasEnabled,
  empty,
  allDisabled,
}

export interface ImportSafeModalSafeItem {
  chain_id: string;
  safe_address: string;
  imported: boolean;
  safe_name?: string;
}

export interface SafeBalanceItem {
  safe_id: string;
  balance: number;
}

export interface SafeInfo {
  id: string;
  chain_id: string;
  name: string;
  address: string;
  org_id: string;
  default_cobo_module: string;
}

export interface RolePermissionInfo {
  authorizer: string;
  label: string;
  name: string;
  related_id: string;
  related_type: string;
  // 如果是 undefined 代表是用户自己创建的
  related_safe_id?: string;
  tag: string;
  variants: {
    // contract -> signatures[]
    [key: string]: string[];
  };
}

export interface DelegateInfo {
  address: string;
  is_bot: boolean;
  label: string;
}

export interface SafeRoleDetail {
  delegate_to_roles: {
    [key: string]: {
      info: DelegateInfo;
      roles: string[];
    };
  };
  role_to_delegate: {
    [key: string]: DelegateInfo[];
  };
  role_to_permission: {
    [key: string]: RolePermissionInfo[];
  };
}
