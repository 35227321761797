import abiCoder from 'web3-eth-abi';
// @ts-ignore
import { BN, sha3 } from 'web3-utils';

const state: {
  savedABIs: any[];
  methodIDs: any;
} = {
  savedABIs: [],
  methodIDs: {},
};

function getABIs() {
  return state.savedABIs;
}

function typeToString(input: any) {
  if (input.type === 'tuple') {
    return `(${input.components.map(typeToString).join(',')})`;
  }
  if (input.type === 'tuple[]') {
    return `(${input.components.map(typeToString).join(',')})[]`;
  }
  return input.type;
}

function addABI(abiArray: any) {
  if (Array.isArray(abiArray)) {
    // Iterate new abi to generate method id"s
    abiArray.forEach(abi => {
      if (abi.name) {
        const signature = sha3(
          `${abi.name}(${abi.inputs.map(typeToString).join(',')})`,
        );
        if (signature) {
          if (abi.type === 'event') {
            state.methodIDs[signature.slice(2)] = abi;
          } else {
            state.methodIDs[signature.slice(2, 10)] = abi;
          }
        }
      }
    });

    state.savedABIs = state.savedABIs.concat(abiArray);
  } else {
    throw new Error(`Expected ABI array, got ${typeof abiArray}`);
  }
}

function removeABI(abiArray: any) {
  if (Array.isArray(abiArray)) {
    // Iterate new abi to generate method id"s
    abiArray.forEach(abi => {
      if (abi.name) {
        const signature = sha3(
          `${abi.name}(${abi.inputs
            .map((input: any) => {
              return input.type;
            })
            .join(',')})`,
        );
        if (signature) {
          if (abi.type === 'event') {
            if (state.methodIDs[signature.slice(2)]) {
              delete state.methodIDs[signature.slice(2)];
            }
          } else if (state.methodIDs[signature.slice(2, 10)]) {
            delete state.methodIDs[signature.slice(2, 10)];
          }
        }
      }
    });
  } else {
    throw new Error(`Expected ABI array, got ${typeof abiArray}`);
  }
}

function getMethodIDs() {
  return state.methodIDs;
}

function decodeMethod(data: any): any {
  const methodID = data.slice(2, 10);
  const abiItem = state.methodIDs[methodID];
  if (abiItem) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const decoded = abiCoder.decodeParameters(abiItem.inputs, data.slice(10));

    const retData: {
      name: string;
      params: any[];
    } = {
      name: abiItem.name,
      params: [],
    };

    // eslint-disable-next-line no-underscore-dangle
    for (let i = 0; i < decoded.__length__; i++) {
      const param = decoded[i];
      let parsedParam = param;
      const isUint = abiItem.inputs[i].type.indexOf('uint') === 0;
      const isInt = abiItem.inputs[i].type.indexOf('int') === 0;
      const isAddress = abiItem.inputs[i].type.indexOf('address') === 0;

      if (isUint || isInt) {
        const isArray = Array.isArray(param);

        if (isArray) {
          parsedParam = param.map((val: any) => new BN(val).toString());
        } else {
          parsedParam = new BN(param).toString();
        }
      }

      // Addresses returned by web3 are randomly cased so we need to standardize and lowercase all
      if (isAddress) {
        const isArray = Array.isArray(param);

        if (isArray) {
          parsedParam = param.map((_: any) => _.toLowerCase());
        } else {
          parsedParam = param.toLowerCase();
        }
      }

      retData.params.push({
        name: abiItem.inputs[i].name,
        value: parsedParam,
        type: abiItem.inputs[i].type,
      });
    }

    return retData;
  }
  return undefined;
}

function decodeLogs(logs: any) {
  return logs
    .filter((log: any) => log.topics.length > 0)
    .map((logItem: any) => {
      const methodID = logItem.topics[0].slice(2);
      const method = state.methodIDs[methodID];
      if (method) {
        const logData = logItem.data;
        const decodedParams: any[] = [];
        let dataIndex = 0;
        let topicsIndex = 1;

        const dataTypes: any[] = [];
        method.inputs.forEach((input: any) => {
          if (!input.indexed) {
            dataTypes.push(input.type);
          }
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const decodedData = abiCoder.decodeParameters(
          dataTypes,
          logData.slice(2),
        );

        // Loop topic and data to get the params
        method.inputs.forEach((param: any) => {
          const decodedP: {
            name: string;
            type: string;
            value?: any;
          } = {
            name: param.name,
            type: param.type,
          };

          if (param.indexed) {
            decodedP.value = logItem.topics[topicsIndex];
            topicsIndex++;
          } else {
            decodedP.value = decodedData[dataIndex];
            dataIndex++;
          }

          if (param.type === 'address') {
            decodedP.value = decodedP.value.toLowerCase();
            // 42 because len(0x) + 40
            if (decodedP.value.length > 42) {
              const toRemove = decodedP.value.length - 42;
              const temp = decodedP.value.split('');
              temp.splice(2, toRemove);
              decodedP.value = temp.join('');
            }
          }

          if (
            param.type === 'uint256' ||
            param.type === 'uint8' ||
            param.type === 'int'
          ) {
            // ensure to remove leading 0x for hex numbers
            if (
              typeof decodedP.value === 'string' &&
              decodedP.value.startsWith('0x')
            ) {
              decodedP.value = new BN(decodedP.value.slice(2), 16).toString(10);
            } else {
              decodedP.value = new BN(decodedP.value).toString(10);
            }
          }

          decodedParams.push(decodedP);
        });

        return {
          name: method.name,
          events: decodedParams,
          address: logItem.address,
        };
      }
      return undefined;
    });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getABIs,
  addABI,
  getMethodIDs,
  decodeMethod,
  decodeLogs,
  removeABI,
};
