import React, { useMemo, useState } from 'react';
import { useInterval } from 'ahooks';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.less';
import {
  executeBot,
  getBotExecutingRecord,
  pauseGenericBot,
  resumeGenericBot,
} from '@/api';
import { BotIcon, CoboImage, Modal, ServiceIcon } from '@/components';
import { NameLogo } from '@/components/Layout/NameLogo';
import { LeverageBotInfo } from '@/components/StrategyBots/LeverageBotInfo';
import {
  AvailableGeneralBotsListItem,
  ServiceDetailType,
  statusColorMap,
} from '@/interfaces';
import { timestampToStr } from '@/utils/utils';

interface Props {
  canEdit: boolean;
  bot: AvailableGeneralBotsListItem;
  safeName?: string;
  safeAddress?: string;
  safeId?: string;
  onViewDetail: (executeRecord?: boolean) => void;
  onRefresh: () => Promise<AvailableGeneralBotsListItem[] | undefined>;
}

export function CustomizeBotItem({
  bot,
  safeName,
  safeId,
  safeAddress,
  onViewDetail,
  onRefresh,
  canEdit,
}: Props) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [actionLoading, setActionLoading] = useState(false);
  const [executing, setExecuting] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const onBotAction = async (action: 'pause' | 'resume') => {
    if (!bot || actionLoading || !canEdit) return;
    setActionLoading(true);
    try {
      if (action === 'pause') {
        await pauseGenericBot(bot.safe_id, bot.bot_instance_id);
      } else {
        await resumeGenericBot(bot.safe_id, bot.bot_instance_id);
      }
      await onRefresh();
    } finally {
      setActionLoading(false);
    }
  };

  const onCloseModal = () => {
    setVisible(false);
    onRefresh();
  };

  useInterval(async () => {
    if (!executing || !visible) return;
    const { status } = await getBotExecutingRecord(
      bot.safe_id,
      bot.bot_instance_id,
    );
    const currentExecuting = !!(status && status === 'PENDING');
    setExecuting(currentExecuting);
    if (!currentExecuting) {
      onCloseModal();
      onViewDetail(true);
    }
  }, 5000);

  const executeNow = async () => {
    if (!canEdit) return;
    if (!bot.executable) return;
    setVisible(true);
    try {
      setLoading(true);
      const { status } = await getBotExecutingRecord(
        bot.safe_id,
        bot.bot_instance_id,
      );
      const currentExecuting = !!(status && status === 'PENDING');
      setExecuting(currentExecuting);
    } finally {
      setLoading(false);
    }
  };
  const serviceDetailType = useMemo(() => {
    if (bot.bot_type === 3) {
      return ServiceDetailType.CUSTOMIZED_BOT;
    } else if (bot.bot_type === 4) {
      return ServiceDetailType.CLAIM;
    } else if (bot.bot_type === 5) {
      return ServiceDetailType.WITHDRAW;
    } else if (bot.bot_type === 7) {
      return ServiceDetailType.LEVERAGE;
    } else {
      return ServiceDetailType.TRADING;
    }
  }, [bot.bot_type]);
  return (
    <div className={cx('bot-item')} key={bot.bot_instance_id}>
      {bot.bot_type !== 3 && (
        <div className={cx('bot-item-header')}>
          <div className={cx('bot-item-header-top')}>
            <div className={cx('bot-item-header-tokens')}>
              {bot.tokens?.slice(0, 4).map(token => {
                return (
                  <CoboImage
                    className={cx('bot-item-header-token')}
                    url={token.icon_url}
                    key={token.symbol}
                  />
                );
              })}
            </div>
            <div className="flex flex-nowrap item-center">
              {bot.pool_id ? (
                <div className={cx('pool-id', 'mr-2')}>{bot.pool_id}</div>
              ) : null}
              <div
                className={cx('bot-item-header-icon')}
                onClick={() => {
                  navigate('/strategy-info/farming/' + bot.strategy_id);
                }}
              />
            </div>
          </div>

          <div className={cx('bot-header-name')}>{bot.bot_name}</div>
          <div className={cx('bot-name-box')}>
            {bot.bot_type === 3 ? (
              <BotIcon
                type={bot.bot_type}
                width={24}
                height={24}
                scene="default"
                className={cx('bot-item-icon')}
              />
            ) : (
              <ServiceIcon
                className="!w-[24px] !h-[24px] mr-[8px]"
                name={serviceDetailType}
              />
            )}

            <div className={cx('bot-type')}>{serviceDetailType}</div>
          </div>
        </div>
      )}

      <div className={cx('bot-item-content')}>
        {safeName && safeAddress && (
          <div className={cx('bot-item-safe')}>
            <div className={cx('bot-item-safe-info-wrapper')}>
              <div className={styles['name-logo']}>
                <NameLogo label={safeName || ''} radius={'6px'} />
              </div>
              <div className={cx('bot-item-safe-info')}>
                <div className={cx('bot-item-safe-name')}>{safeName}</div>
              </div>
            </div>
            {canEdit && bot.executable ? (
              <div className={cx('bot-item-run')} onClick={executeNow} />
            ) : null}
          </div>
        )}
        {bot.bot_type === 3 && (
          <div className={cx('bot-item-title')}>
            <div className={cx('bot-item-title-inner')}>
              <div className={cx('bot-item-customize-icon')} />
              <span className={cx('bot-item-name')}>
                {bot.bot_instance_name}
              </span>
            </div>
            {canEdit && bot.executable ? (
              <div className={cx('bot-item-run')} onClick={executeNow} />
            ) : null}
          </div>
        )}
        <div className={cx('bot-status', 'bot-data-item')}>
          <div className={cx('bot-item-data-label')}>{t('bots.status')}</div>
          <div
            className={cx('status', 'bot-data-content')}
            style={{ color: statusColorMap[bot?.status] }}
          >
            {bot?.status
              ? bot?.status[0].toUpperCase() + bot?.status.substr(1)
              : null}
          </div>
        </div>
        <div className={cx('bot-data-item')}>
          <div className={cx('bot-item-data-label', 'last-triggered')}>
            {t('bots.lastTriggered')}
          </div>
          {bot.last_execute && Object.keys(bot.last_execute).length ? (
            <div className={cx('bot-data-contents')}>
              {timestampToStr(bot.last_execute.time)
                .split(' ')
                .map(item => (
                  <div
                    className={cx(
                      'bot-data-content',
                      bot.last_execute?.status === 'success'
                        ? 'success-content'
                        : 'fail-content',
                    )}
                  >
                    {item}
                  </div>
                ))}
            </div>
          ) : (
            <div className={cx('bot-data-content')}>-</div>
          )}
        </div>
        {bot?.bot_type === 7 && safeId && (
          <div className={cx('leverage-info')}>
            <LeverageBotInfo bot={bot} safeId={safeId} />
          </div>
        )}
      </div>
      <div className={cx('bot-item-buttons')}>
        <div
          className={cx('bot-item-button')}
          onClick={() => {
            onBotAction(bot.status === 'paused' ? 'resume' : 'pause');
          }}
        >
          {bot.status === 'paused' ? t('bots.resume') : t('bots.pause')}
        </div>

        <span className={cx('bot-item-button-split')} />
        <div
          className={cx('bot-item-button')}
          onClick={() => {
            onViewDetail();
          }}
        >
          {t('bots.viewAll')}
        </div>
      </div>
      <Modal
        title={t('common.note')}
        visible={visible}
        onCloseBtnClick={() => onCloseModal()}
        btnProps={[
          {
            text: t('common.cancel'),
            onClick: () => {
              onCloseModal();
            },
          },
          {
            text: executing ? t('common.waiting') : t('common.continue'),
            primary: true,
            disabled: loading || executing,
            onClick: async () => {
              setExecuting(true);
              await executeBot(bot.safe_id, bot.bot_instance_id);
            },
          },
        ]}
        zIndex={1010}
      >
        {t('bots.executeNowNote')}
      </Modal>
    </div>
  );
}
