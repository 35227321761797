import React, { useEffect, useMemo, useState } from 'react';
import { useInterval } from 'ahooks';
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.less';
import { getGasAccount } from '@/api';
import { getShortNameByCoboName, getShowNumber } from '@/chains/utils';
import { ChainIcon, GasAccountQrCodeModal } from '@/components';
import {
  gasDataAtom,
  qrCodeModalDataAtom,
  qrCodeModalOpenAtom,
} from '@/components/Layout/GasSelector/atom';
import { orgIdAtom } from '@/globalAtom';
import { ChainShortNameType } from '@/interfaces';

export function GasSelector() {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const [gasData, setGasData] = useAtom(gasDataAtom);
  const [qrCodeModalData, setQrCodeModalData] = useAtom(qrCodeModalDataAtom);
  const [qrCodeModalOpen, setQrCodeModalOpen] = useAtom(qrCodeModalOpenAtom);
  const [orgId] = useAtom(orgIdAtom);

  useEffect(() => {
    const firstLoadGas = async () => {
      if (!orgId) return;
      const data = await getGasAccount(orgId, false);
      setGasData(data);
    };
    firstLoadGas();
    // useInterval 首次拿不到orgId
  }, [orgId, setGasData]);

  useInterval(async () => {
    if (!orgId) return;
    const data = await getGasAccount(orgId);
    setGasData(data);
  }, 1000 * 30);

  const lowGasNumber = useMemo(() => {
    return (
      gasData.filter(gas => gas?.balance < gas?.recommended_min_balance)
        .length || 0
    );
  }, [gasData]);

  let content = (
    <div
      className={cx('gas-wrapper', {
        'low-gas-wrapper': lowGasNumber > 0,
      })}
      onClick={() => {
        setDropdownVisible(!dropdownVisible);
      }}
    >
      <div className={cx('gas-icon')}></div>
      <span className={cx('gas-text')}>{t('bots.gasAccount')}</span>
      {lowGasNumber > 1 && (
        <span className={cx('gas-number')}>{lowGasNumber}</span>
      )}
      <div className={cx('arrow', dropdownVisible ? 'arrow-down' : '')} />
    </div>
  );

  const dropdownMenu: MenuProps['items'] = useMemo(() => {
    let data =
      gasData.map(gas => {
        return {
          key: gas.chain,
          label: (
            <div className={cx('gas-item')}>
              <div className={cx('chain')}>
                <ChainIcon
                  shortName={
                    getShortNameByCoboName(
                      gas.chain || 'ETH',
                    ) as ChainShortNameType
                  }
                  className={cx('chain-icon')}
                />
                <span
                  className={cx('balance-number', {
                    'low-gas-number':
                      gas?.balance < gas?.recommended_min_balance,
                  })}
                >
                  {getShowNumber(gas.balance, gas.gas_token_decimals, 4)}
                  &nbsp;
                  {gas.gas_token}
                </span>
              </div>

              {gas?.balance < gas?.recommended_min_balance && (
                <span
                  className={cx('deposit')}
                  onClick={() => {
                    setQrCodeModalData(gas);
                    setQrCodeModalOpen(true);
                    setDropdownVisible(false);
                  }}
                >
                  {t('bots.deposit')}
                </span>
              )}
            </div>
          ),
        };
      }) || [];
    if (data.length === 0) {
      data.push({
        key: 'detail',
        label: (
          <div
            className={cx('detail-btn', {
              'no-gas-btn': gasData.length === 0,
            })}
            onClick={() => {
              setDropdownVisible(false);
              navigate('/my-portfolio/farming');
            }}
          >
            {t('bots.viewDetail')}
          </div>
        ),
      });
    }
    return data;
  }, [gasData, cx, t, navigate, setQrCodeModalData, setQrCodeModalOpen]);
  if (gasData.length === 0) return null;

  return (
    <>
      <div className={cx('gas-container')}>
        <Dropdown
          open={dropdownVisible}
          onOpenChange={visible => {
            setDropdownVisible(visible);
          }}
          menu={{
            items: dropdownMenu,
          }}
          trigger={['click']}
          overlayClassName={cx('gas-dropdown')}
        >
          {content}
        </Dropdown>
      </div>
      <GasAccountQrCodeModal
        gasData={qrCodeModalData || null}
        show={qrCodeModalOpen}
        onClose={() => {
          setQrCodeModalOpen(false);
        }}
      />
    </>
  );
}
