import { useState } from 'react';
import { useMount } from 'ahooks';
import { Select } from 'antd';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { getSupportedProtocols, getSupportedToken } from '@/api/logic';
import { SvgIcon } from '@/components';
import { ServiceSearch } from '@/components/Investment/ServiceSearch';
import { ProtocolItem } from '@/interfaces';
import { uniqueArrayByKey } from '@/utils/utils';

interface StrategySearchProps {
  onSearch: (
    chain: string,
    protocol: string,
    token: string,
    service_type: string,
  ) => void;
  chain: string;
  protocol: string;
  token: string;
  service_type: string;
  strategy_type: '2' | '5';
}
export function StrategySearch({
  chain,
  protocol,
  token,
  service_type,
  onSearch,
  strategy_type,
}: StrategySearchProps) {
  const { t } = useTranslation();
  const { Option } = Select;
  const cx = classNames.bind(styles);
  const handleProtocolChange = (value: string) => {
    onSearch(chain, value, token, service_type);
  };
  const handleTokenChange = (value: string) => {
    onSearch(chain, protocol, value, service_type);
  };
  const handleServiceChange = (service_type: string) => {
    onSearch(chain, protocol, token, service_type);
  };

  const [protocols, setProtocols] = useState<ProtocolItem[]>([]);

  const [tokens, setTokens] = useState<{ name: string; value: string }[]>([]);

  useMount(async () => {
    const asyncProtocols = await getSupportedProtocols(strategy_type);
    const protocols = uniqueArrayByKey(asyncProtocols, 'project_id');
    protocols.unshift({
      chain_id: '',
      icon_url: '',
      name: 'All',
      project_id: '',
    });
    setProtocols(protocols);
    let asyncTokens = await getSupportedToken(strategy_type);
    let tokens = asyncTokens.map((item: string) => {
      return {
        name: item,
        value: item,
      };
    });
    tokens.unshift({ name: 'All', value: '' });
    setTokens(tokens);
  });

  return (
    <div className={styles['strategy-search']}>
      {Number(strategy_type) === 2 && (
        <div className={cx('strategy-networks')}>
          <ServiceSearch onChange={handleServiceChange} />
        </div>
      )}

      <div className={cx('strategy-select-warp')}>
        {Number(strategy_type) === 2 && (
          <div className={cx('strategy-select-item')}>
            <label htmlFor="token" className={cx('strategy-select-label')}>
              {t('investment.strategyMart.token')}
            </label>
            <Select
              getPopupContainer={triggerNode => triggerNode.parentElement}
              defaultValue=""
              style={{ width: 200 }}
              onChange={handleTokenChange}
              className={cx('strategy-select')}
              value={token}
              suffixIcon={
                <SvgIcon name="arrow_down" className={styles['arrow_icon']} />
              }
            >
              {tokens.map(item => (
                <Option value={item.value} key={item.value}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        )}

        <div className={cx('strategy-select-item')}>
          <label htmlFor="token" className={cx('strategy-select-label')}>
            {t('investment.strategyMart.protocol')}
          </label>
          <Select
            getPopupContainer={triggerNode => triggerNode.parentElement}
            defaultValue=""
            style={{ width: 200 }}
            value={protocol}
            onChange={handleProtocolChange}
            className={cx('strategy-select')}
            suffixIcon={
              <SvgIcon name="arrow_down" className={styles['arrow_icon']} />
            }
          >
            {protocols.map(item => (
              <Option value={item.project_id} key={item.project_id}>
                {item.chain_id ? `${item.name}(${item.chain_id})` : item.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}
