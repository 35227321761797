import { useEffect, useMemo, useRef, useState } from 'react';
import { useInterval } from 'ahooks';
import { message } from 'antd';
import classNames from 'classnames/bind';
import { BigNumber, ethers } from 'ethers';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './index.module.less';
import {
  estimateWithdrawGas,
  getWithdrawGasStatus,
  loadGnosisSafe,
  withdrawGas,
} from '@/api';
import Chain from '@/chains';
import {
  ChainsFilter,
  getChainIdByCoboName,
  getChainIdByShortName,
  getShortNameByCoboName,
  getShowNumber,
} from '@/chains/utils';
import {
  BrowserIcon,
  ChainIcon,
  GasAccountPopover,
  Modal,
  SafeSelector,
} from '@/components';
import Box from '@/components/Common/Box';
import {
  gasDataAtom,
  qrCodeModalDataAtom,
  qrCodeModalOpenAtom,
} from '@/components/Layout/GasSelector/atom';
import { globalSafeInfoAtom, orgIdAtom } from '@/globalAtom';
import { BindSafeInfo, ChainShortNameType, GasData } from '@/interfaces';
import { copyToClipboard } from '@/utils/clipboard';
import { truncateAddress } from '@/utils/utils';

export function GasAccount() {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const [allowSlidePrev, setAllowSlidePrev] = useState<boolean>(false);
  const [allowSlideNext, setAllowSlideNext] = useState<boolean>(true);
  const [gasAccountData] = useAtom(gasDataAtom);
  const [, setQrCodeModalData] = useAtom(qrCodeModalDataAtom);
  const [, setQrCodeModalOpen] = useAtom(qrCodeModalOpenAtom);
  const [withdrawOpen, setWithdrawOpen] = useState(false);
  const [withdrawChain, setWithdrawChain] = useState<string>();
  const [gasFee, setGasFee] = useState<string>();
  const [withdrawing, setWithdrawing] = useState(false);
  const [requestingWithdraw, setRequestingWithdraw] = useState(false);
  const [selectedSafe, setSelectedSafe] = useState<BindSafeInfo | null>(null);
  const [safeList, setSafeList] = useState<BindSafeInfo[] | null>(null);
  const [orgId] = useAtom(orgIdAtom);
  const [safeInfo] = useAtom(globalSafeInfoAtom);

  const childRefs = useRef<any>([]);
  // Admin或者Operator才有操作权限
  const formattedGas = useMemo(() => {
    if (!gasFee) return '';
    const gas = ethers.utils.formatEther(BigNumber.from(gasFee));
    const gasNumber = parseFloat(gas);
    if (gasNumber < 0.0001) {
      return '< 0.0001';
    }
    return gasNumber.toFixed(4);
  }, [gasFee]);

  useEffect(() => {
    const loadSafes = async () => {
      if (!orgId || !withdrawChain) {
        setSafeList(null);
        return;
      }
      const list = await loadGnosisSafe(orgId, [withdrawChain]);
      if (list && list.length > 0) {
        setSafeList(list);
        setSelectedSafe(list[0]);
        const { gas_fee } = await estimateWithdrawGas(list[0].id);
        setGasFee(gas_fee);
      }
    };
    loadSafes();
  }, [orgId, setSafeList, withdrawChain]);

  useEffect(() => {
    return () => {
      setSafeList(null);
    };
  }, [setSafeList]);

  useInterval(async () => {
    if (
      !withdrawOpen ||
      !withdrawing ||
      !orgId ||
      !selectedSafe ||
      requestingWithdraw
    )
      return;
    const { status } = await getWithdrawGasStatus(orgId, selectedSafe.chain_id);
    const pending = status === 'PENDING';
    const success = status === 'SUCCESS';
    if (!pending) {
      setWithdrawing(false);
      setWithdrawOpen(false);
      if (success) {
        message.success(t('bots.withdrawSuccess'));
      } else {
        message.error(t('bots.withdrawFailed'));
      }
    }
  }, 5000);

  const chain = useMemo(
    () =>
      selectedSafe
        ? new Chain(getChainIdByShortName(selectedSafe.chain_id))
        : null,
    [selectedSafe],
  );

  const baseToken = useMemo(() => chain?.getBaseToken() || '', [chain]);

  const sourceData = useMemo(() => {
    return gasAccountData.filter(
      item => getShortNameByCoboName(item.chain) === safeInfo?.chain_id,
    );
  }, [gasAccountData, safeInfo?.chain_id]);

  return sourceData?.length > 0 ? (
    <div className={cx('gas-account-warp')}>
      <Swiper
        className={cx('gas-account')}
        spaceBetween={24}
        slidesPerView={'auto'}
        navigation={{
          nextEl: '.' + cx('gas-account-next-btn'),
          prevEl: '.' + cx('gas-account-prev-btn'),
        }}
        onSlideChange={swiper => {
          setAllowSlidePrev(!swiper.isBeginning);
          setAllowSlideNext(!swiper.isEnd);
        }}
        modules={[Navigation]}
        scrollbar={false}
        pagination={false}
      >
        {sourceData.map((gas: GasData) => {
          return (
            <SwiperSlide
              key={gas.chain}
              className={cx('gas-account-item', {
                'low-gas-item': gas?.balance < gas?.recommended_min_balance,
              })}
              id={'gas-account-warp'}
            >
              <div className={cx('gas-account-top')}>
                <div className={cx('gas-account-item-top')}>
                  <div className={cx('gas-account-item-top-left')}>
                    <ChainIcon
                      shortName={
                        getShortNameByCoboName(
                          gas.chain || 'ETH',
                        ) as ChainShortNameType
                      }
                      className={cx('chain-icon')}
                    />
                    <span
                      className={cx('balance-number', {
                        'low-gas-number':
                          gas?.balance < gas?.recommended_min_balance,
                      })}
                    >
                      {getShowNumber(gas.balance, gas.gas_token_decimals, 4)}
                      &nbsp;
                    </span>
                  </div>
                  <div className={cx('gas-account-item-top-right')}>
                    {gas?.balance < gas?.recommended_min_balance && (
                      <GasAccountPopover
                        gas={gas}
                        ref={ref => {
                          childRefs.current[gas.chain] = ref;
                        }}
                        handleNewOpen={() => {
                          for (const key in childRefs.current) {
                            if (
                              Object.prototype.hasOwnProperty.call(
                                childRefs.current,
                                key,
                              )
                            ) {
                              const element = childRefs.current[key];
                              element?.hide();
                            }
                          }
                        }}
                      />
                    )}
                    <span className={cx('token-name')}>{gas.gas_token}</span>
                  </div>
                </div>
                <span className={cx('chain-name')}>
                  {
                    ChainsFilter.find(item => item.coboName === gas.chain)
                      ?.chainName
                  }
                  :
                </span>
                <div className={cx('gas-account-item-content')}>
                  <span className={cx('safe-address')}>
                    {truncateAddress(gas.address)}
                  </span>

                  <div className={cx('gas-account-item-content-right')}>
                    <span
                      className={cx('copy-btn')}
                      onClick={async () => {
                        try {
                          await copyToClipboard({ value: gas.address });
                          message.success(t('safeManagement.copy'));
                        } catch (e: any) {
                          message.error(
                            `${t('common.copyFailed')} ${e.message}`,
                          );
                        }
                      }}
                    />
                    <span
                      className={cx('es-icon')}
                      onClick={() => {
                        const chain = new Chain(
                          getChainIdByCoboName(gas.chain),
                        );
                        window.open(chain.addressUrl(gas.address));
                      }}
                    >
                      <BrowserIcon
                        shortName={
                          (getShortNameByCoboName(
                            gas.chain,
                          ) as ChainShortNameType) || 'rin'
                        }
                        chainName={getShortNameByCoboName(gas.chain)}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className={cx('gas-account-item-bottom')}>
                <div
                  className={cx('action-btn')}
                  onClick={() => {
                    setQrCodeModalData(gas);
                    setQrCodeModalOpen(true);
                  }}
                >
                  {t('bots.deposit')}
                </div>
                {gas.balance > 0 && gas.enable_withdraw ? (
                  <div
                    className={cx('action-btn')}
                    onClick={async () => {
                      if (!orgId) return;
                      const { status } = await getWithdrawGasStatus(
                        orgId,
                        gas.chain,
                      );
                      const pending = status === 'PENDING';
                      if (pending) {
                        setWithdrawing(true);
                      } else {
                        setWithdrawing(false);
                      }
                      setWithdrawOpen(true);
                      setWithdrawChain(gas.chain);
                    }}
                  >
                    {t('bots.withdraw')}
                  </div>
                ) : null}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div
        className={cx('gas-account-prev-btn', {
          'hide-button': !allowSlidePrev,
        })}
      />
      <div
        className={cx('gas-account-next-btn', {
          'hide-button': !allowSlideNext,
        })}
      />
      <Modal
        width={680}
        visible={withdrawOpen}
        title={t('bots.withdrawal')}
        destroyOnClose={true}
        zIndex={1001}
        primaryText={t('common.continue')}
        onPrimaryClick={async () => {
          if (!selectedSafe) return;
          try {
            setWithdrawing(true);
            setRequestingWithdraw(true);
            await withdrawGas(selectedSafe.id);
          } catch (e) {
            setWithdrawing(false);
          } finally {
            setRequestingWithdraw(false);
          }
        }}
        primaryLoading={withdrawing}
        primaryDisabled={!selectedSafe}
        secondaryText={t('common.cancel')}
        onSecondaryClick={() => {
          setWithdrawOpen(false);
          setWithdrawChain(undefined);
          setSelectedSafe(null);
          setGasFee(undefined);
        }}
        onCloseBtnClick={() => {
          setWithdrawOpen(false);
          setWithdrawChain(undefined);
          setSelectedSafe(null);
          setGasFee(undefined);
        }}
      >
        <div className={cx('withdraw-modal-inner')}>
          <div className={cx('note')}>{t('bots.withdrawNote')}</div>
          <div className={cx('note')}>{t('bots.withdrawNote2')}</div>
          <Box height={24} />
          <div className={cx('gnosis-safe-title')}>{t('bots.gnosisSafe')}</div>
          <Box height={8} />
          <SafeSelector
            safes={safeList}
            selectedSafe={selectedSafe}
            onChange={async safe => {
              setSelectedSafe(safe);
              const { gas_fee } = await estimateWithdrawGas(safe.id);
              setGasFee(gas_fee);
            }}
            showChainIcon={true}
            truncate
            maxNameLength={20}
          />
          {gasFee ? <Box height={4} /> : null}
          {gasFee ? (
            <div className={cx('gas')}>{`${t(
              'bots.gasFee',
            )}: ${formattedGas} ${baseToken}`}</div>
          ) : null}
        </div>
      </Modal>
    </div>
  ) : (
    <div className={cx('empty-box')}>{t('bots.noGasAccount')}</div>
  );
}
