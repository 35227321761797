import { BaseChain } from './base';

export default class Bsc extends BaseChain {
  name = 'Binance Smart Chain';
  shortName = 'bnb';

  private providerUrl = 'https://bsc-dataseed.binance.org';
  private bscScanBaseUrl = 'https://bscscan.com';
  public safeWebBaseUrl = 'https://app.safe.global';
  private safeApiBaseUrl = 'https://safe-transaction-bsc.safe.global';
  subsafeFactory = '0x0c331B57AF29A0196A30Ec53c0B46db81E080C4a';
  baseToken = 'BNB';
  baseTokenName = 'BNB';

  public safeUrl(address: string): string {
    return `${this.safeWebBaseUrl}/home?safe=${this.shortName}:${address}`;
  }

  public addressUrl(address: string): string {
    return `${this.bscScanBaseUrl}/address/${address}`;
  }

  public tokenUrl(address: string): string {
    return `${this.bscScanBaseUrl}/token/${address}`;
  }

  public rpcUrl(): string {
    return this.providerUrl;
  }

  public safeApiUrl(): string {
    return this.safeApiBaseUrl;
  }

  public txDetailUrl(hash: string): string {
    return `${this.bscScanBaseUrl}/tx/${hash}`;
  }
}
