import { useCallback, useEffect, useState } from 'react';
import { Core } from '@walletconnect/core';
import { SessionTypes, SignClientTypes } from '@walletconnect/types';
import Web3WalletType, { Web3Wallet } from '@walletconnect/web3wallet';
import { message } from 'antd';
import { useAtom } from 'jotai';

import { getCoboNameByChainId } from '@/chains/utils';
import { globalSafeInfoAtom } from '@/globalAtom';
import useRequestTxflow from '@/walletconnect/hooks/useRequestTxflow';
import {
  ARGUS_METADATA,
  WALLETCONNECT_V2_PROJECT_ID,
} from '@/walletconnect/utils/constants';

const EVMBasedNamespaces = 'eip155';

// see full list here: https://github.com/safe-global/safe-apps-sdk/blob/main/packages/safe-apps-provider/src/provider.ts#L35
export const compatibleSafeMethods: string[] = [
  'eth_sendTransaction',
  'eth_signTransaction',
  'personal_sign',
  'eth_sign',
  'eth_signTypedData',
  'eth_signTypedData_v3',
  'eth_signTypedData_v4',
];

// see https://docs.walletconnect.com/2.0/specs/sign/error-codes
const UNSUPPORTED_CHAIN_ERROR_CODE = 5100;
const INVALID_METHOD_ERROR_CODE = 1001;
const USER_REJECTED_REQUEST_CODE = 4001;
const USER_DISCONNECTED_CODE = 6000;

export const errorLabel =
  'We were unable to create a connection due to compatibility issues with the latest WalletConnect v2 upgrade. We are actively working with the WalletConnect team and the dApps to get these issues resolved.';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type wcConnectType = (uri: string) => Promise<void>;
// eslint-disable-next-line @typescript-eslint/naming-convention
export type wcDisconnectType = () => Promise<void>;

// eslint-disable-next-line @typescript-eslint/naming-convention
type useWalletConnectType = {
  wcClientData: SignClientTypes.Metadata | undefined;
  wcConnect: wcConnectType;
  wcDisconnect: wcDisconnectType;
  isWalletConnectInitialized: boolean;
};

const rejectResponse = (id: number, code: number, message: string) => {
  return {
    id,
    jsonrpc: '2.0',
    error: {
      code,
      message,
    },
  };
};

const useWalletConnectV2 = (
  chainId: number,
  safeAddress: string,
): useWalletConnectType => {
  const requestTxflow = useRequestTxflow(chainId);
  const [web3wallet, setWeb3wallet] = useState<Web3WalletType>();
  const [safeInfo] = useAtom(globalSafeInfoAtom);
  const [wcSession, setWcSession] = useState<SessionTypes.Struct>();
  const [isWalletConnectInitialized, setIsWalletConnectInitialized] =
    useState<boolean>(false);
  // Initializing v2, see https://docs.walletconnect.com/2.0/javascript/web3wallet/wallet-usage
  useEffect(() => {
    const initializeWalletConnectV2Client = async () => {
      const core = new Core({
        projectId: WALLETCONNECT_V2_PROJECT_ID,
      });

      const web3Wallet = await Web3Wallet.init({
        core,
        metadata: ARGUS_METADATA,
      });

      setWeb3wallet(web3Wallet);
    };

    try {
      if (!safeInfo?.id) return;
      initializeWalletConnectV2Client();
    } catch (e) {
      console.log('Error on walletconnect version 2 initialization: ', e);
      setIsWalletConnectInitialized(true);
    }
    return () => {
      setWeb3wallet(undefined);
      setWcSession(undefined);
    };
  }, [safeInfo?.id]);

  const onSessionRequest = useCallback(
    async (event: {
      topic: string;
      id: number;
      params: { request: { method: string; params: any[] }; chainId: string };
    }) => {
      if (!web3wallet) return;
      const { topic, id } = event;
      const { request, chainId: transactionChainId } = event.params;
      const { method, params } = request;

      const isSafeChainId =
        transactionChainId === `${EVMBasedNamespaces}:${chainId}`;

      // we only accept transactions from the Safe chain
      if (!isSafeChainId) {
        const chainName = getCoboNameByChainId(chainId);
        const errorMessage = `Transaction rejected: the connected Dapp is not set to the correct chain. Make sure the Dapp only uses ${chainName} to interact with this Safe.`;
        message.error(errorMessage);
        await web3wallet.respondSessionRequest({
          topic,
          response: rejectResponse(
            id,
            UNSUPPORTED_CHAIN_ERROR_CODE,
            errorMessage,
          ),
        });
        return;
      }

      try {
        if (!compatibleSafeMethods.includes(method)) {
          throw new Error(`We don't support ${method} for now.`);
        }
        if (!['eth_sendTransaction', 'eth_signTransaction'].includes(method)) {
          console.log('Approving transaction...', event);
          await web3wallet.respondSessionRequest({
            topic,
            response: {
              id,
              jsonrpc: '2.0',
              result: '0x',
            },
          });
        } else {
          const eventSafeAddress =
            params.length > 0 ? params[0].from || '' : '';
          if (
            safeInfo?.address.toLowerCase() !==
              eventSafeAddress.toLowerCase() ||
            eventSafeAddress.toLowerCase() !== safeAddress.toLowerCase()
          ) {
            console.error('Invalid Params', event);
            throw new Error('Invalid params');
          }
          const result = await requestTxflow(method, params);
          await web3wallet.respondSessionRequest({
            topic,
            response: {
              id,
              jsonrpc: '2.0',
              result,
            },
          });
        }
      } catch (e: any) {
        const isUserRejection = e?.message?.includes?.(
          'Transaction was rejected',
        );
        console.error(e);
        const errorMessage = e?.message || e?.reason || 'unknown error';
        message.error(errorMessage);
        const code = isUserRejection
          ? USER_REJECTED_REQUEST_CODE
          : INVALID_METHOD_ERROR_CODE;
        await web3wallet.respondSessionRequest({
          topic,
          response: rejectResponse(id, code, errorMessage),
        });
      }
    },
    [chainId, requestTxflow, safeAddress, safeInfo?.address, web3wallet],
  );

  const onSessionProposal = useCallback(
    async proposal => {
      if (!web3wallet) return;
      const { id, params } = proposal;
      const { requiredNamespaces } = params;

      console.log('Session proposal: ', proposal);

      const safeAccount = `${EVMBasedNamespaces}:${chainId}:${safeAddress}`;
      const safeChain = `${EVMBasedNamespaces}:${chainId}`;
      // we accept all events like chainChanged & accountsChanged
      // (even if they are not compatible with the Safe)
      const safeEvents = requiredNamespaces[EVMBasedNamespaces]?.events || [];

      try {
        const namespaces = {
          eip155: {
            accounts: [safeAccount], // only the Safe account
            chains: [safeChain], // only the Safe chain
            methods: compatibleSafeMethods, // only the Safe methods
            events: safeEvents,
          },
        };
        const session = await web3wallet.approveSession({
          id,
          namespaces,
        });

        setWcSession(session);
      } catch (e: any) {
        console.log('error: ', e);

        // human readable error
        message.error(errorLabel);
        const chainName = getCoboNameByChainId(chainId);
        const errorMessage = `Connection refused: This Safe Account is in ${chainName} but the Wallet Connect session proposal is not valid because it contains: 1) A required chain different than ${chainName} 2) Does not include ${chainName} between the optional chains 3) No EVM compatible chain is included`;
        console.log(errorMessage);
        await web3wallet.rejectSession({
          id: proposal.id,
          reason: {
            code: UNSUPPORTED_CHAIN_ERROR_CODE,
            message: errorMessage,
          },
        });
      }
    },
    [chainId, safeAddress, web3wallet],
  );

  const onSessionDelete = useCallback(() => {
    setWcSession(undefined);
  }, []);

  // session_request needs to be a separate Effect because a valid wcSession should be present
  useEffect(() => {
    if (isWalletConnectInitialized && web3wallet && wcSession) {
      console.log('listen session request');
      web3wallet.on('session_request', onSessionRequest);
    }
    return () => {
      if (isWalletConnectInitialized && web3wallet && wcSession) {
        console.log('off session request');
        web3wallet.off('session_request', onSessionRequest);
      }
    };
  }, [isWalletConnectInitialized, onSessionRequest, wcSession, web3wallet]);

  // we set here the events & restore an active previous session
  useEffect(() => {
    if (!isWalletConnectInitialized && web3wallet) {
      // we try to find a compatible active session
      const activeSessions = web3wallet.getActiveSessions();
      const compatibleSession = Object.keys(activeSessions)
        .map(topic => activeSessions[topic])
        .find(
          session =>
            session.namespaces[EVMBasedNamespaces].accounts[0] ===
            `${EVMBasedNamespaces}:${chainId}:${safeAddress}`, // Safe Account
        );

      if (compatibleSession) {
        setWcSession(compatibleSession);
      }
      console.log('listen session proposal');
      // events
      web3wallet.on('session_proposal', onSessionProposal);

      web3wallet.on('session_delete', onSessionDelete);

      setIsWalletConnectInitialized(true);
    }
    return () => {
      if (isWalletConnectInitialized && web3wallet) {
        console.log('off session proposal');
        web3wallet.off('session_proposal', onSessionProposal);
        web3wallet.off('session_delete', onSessionDelete);
        setIsWalletConnectInitialized(false);
      }
    };
  }, [
    chainId,
    isWalletConnectInitialized,
    onSessionDelete,
    onSessionProposal,
    onSessionRequest,
    safeAddress,
    web3wallet,
  ]);

  const wcConnect = useCallback<wcConnectType>(
    async (uri: string) => {
      const isValidWalletConnectUri = uri && uri.startsWith('wc');

      if (isValidWalletConnectUri && web3wallet) {
        await web3wallet.core.pairing.pair({ uri });
      }
    },
    [web3wallet],
  );

  const wcDisconnect = useCallback<wcDisconnectType>(async () => {
    if (wcSession && web3wallet) {
      await web3wallet.disconnectSession({
        topic: wcSession.topic,
        reason: {
          code: USER_DISCONNECTED_CODE,
          message: 'User disconnected. Safe Wallet Session ended by the user',
        },
      });
      setWcSession(undefined);
    }
  }, [web3wallet, wcSession]);

  const wcClientData = wcSession?.peer.metadata;

  return {
    wcConnect,
    wcClientData,
    wcDisconnect,
    isWalletConnectInitialized,
  };
};

export default useWalletConnectV2;
