import { CSSProperties, ReactElement, ReactNode } from 'react';
import { Divider, Select } from 'antd';
import classNames from 'classnames/bind';

import styles from './index.module.less';
import { SvgIcon } from '@/components';

export interface OptionItem {
  label: string | ReactNode;
  value: any;
  icon?: string | ReactNode;
  longLabel?: string | ReactNode;
}

interface OnChangeFunc {
  (value: any): void;
}
interface SelectProps {
  /**选项 */
  notFoundContent?: string | ReactNode;
  options: OptionItem[] | string[];
  /**change事件，暴漏当前value作为参数 */
  onChange?: OnChangeFunc;
  onSelect?: OnChangeFunc;
  onSearch?: (value: string) => void;
  mode?: 'multiple' | 'tags';
  value?: number | string | string[] | number[];
  /**默认值 */
  defaultValue?: number | string;
  /**类名 */
  className?: string;
  /**无选择时展示文案 */
  placeholder?: string;
  /**是否被禁用 */
  disabled?: boolean;
  /** 是否开启箭头旋转动画 */
  openArrowAnimation?: boolean;
  optionLabelProp?: string;
  bordered?: boolean;
  loading?: boolean;
  suffixIcon?: any;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  maxTagCount?: number | 'responsive';
  renderOption?: (value: string) => ReactNode;
  tagRender?: (props: any) => ReactElement;
  undeleteValue?: string[];
  popupMatchSelectWidth?: boolean | number;
  menuItemSelectedIcon?: ReactNode;
  filterOption?: boolean | any;
  gray?: boolean;
  style?: any;
  dropdownRender?: ReactNode;
  open?: boolean;
  onDropdownVisibleChange?: (open: boolean) => void;
  dropdownStyle?: CSSProperties;
}

export function CoboSelect(props: SelectProps) {
  const {
    options,
    onChange,
    onSelect,
    onSearch,
    value,
    defaultValue,
    className,
    placeholder,
    disabled,
    openArrowAnimation = true,
    optionLabelProp,
    bordered,
    suffixIcon,
    loading,
    getPopupContainer,
    mode,
    maxTagCount,
    renderOption,
    tagRender,
    dropdownStyle,
    undeleteValue,
    gray,
    notFoundContent,
    filterOption,
    style,
    dropdownRender,
    open,
    onDropdownVisibleChange,
    ...restProps
  } = props;
  const cx = classNames.bind(styles);
  return (
    <div
      className={cx(
        styles['cobo-select'],
        className,
        gray ? 'gray-cobo-select' : '',
      )}
      style={style}
    >
      <Select
        dropdownStyle={dropdownStyle}
        open={open}
        mode={mode}
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onSelect={onSelect}
        onSearch={onSearch}
        bordered={bordered}
        loading={loading}
        notFoundContent={notFoundContent}
        filterOption={filterOption}
        suffixIcon={
          disabled ? null : suffixIcon ? (
            suffixIcon
          ) : (
            <SvgIcon
              name="arrow_down"
              className={cx(
                styles['arrow_icon'],
                openArrowAnimation ? '.arrow-animation' : '',
              )}
            />
          )
        }
        disabled={disabled}
        optionLabelProp={optionLabelProp}
        getPopupContainer={
          getPopupContainer || (triggerNode => triggerNode.parentNode)
        }
        placement={'bottomLeft'}
        maxTagCount={maxTagCount}
        tagRender={props => {
          if (tagRender) return tagRender(props);
          return (
            <div className={cx('tag-wrapper', gray ? 'gray' : '')}>
              <div className={cx('label')}>{props.label}</div>
              {disabled || undeleteValue?.includes(props.value) ? null : (
                <div
                  className={cx('delete-wrapper')}
                  onMouseDown={event => {
                    if (props.closable) {
                      props.onClose(event);
                      event.stopPropagation();
                      event.preventDefault();
                    }
                  }}
                >
                  <div className={cx('line')} />
                  <div className={cx('delete-view')}>
                    <SvgIcon name="ic_deleted" className={cx('delete-icon')} />
                  </div>
                </div>
              )}
            </div>
          );
        }}
        dropdownRender={menu => (
          <>
            {menu}
            {dropdownRender ? (
              <>
                <Divider style={{ margin: '12px 0' }} />
                {dropdownRender}
              </>
            ) : null}
          </>
        )}
        onDropdownVisibleChange={onDropdownVisibleChange}
        {...restProps}
      >
        {options.map(item => {
          if (typeof item === 'string') {
            return (
              <Select.Option key={item} value={item} title={item} label={item}>
                {item}
              </Select.Option>
            );
          }
          if (renderOption) {
            return (
              <Select.Option
                key={item.value}
                value={item.value}
                label={item.label}
              >
                {renderOption(item.value)}
              </Select.Option>
            );
          }
          return (
            <Select.Option
              key={item.value}
              value={item.value}
              label={item.label}
            >
              {item?.icon}
              <span className={cx('label')}>
                {item.longLabel || item.label}
              </span>
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}
