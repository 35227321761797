import { useMemo } from 'react';
import { Spin } from 'antd';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { CoboSelect } from '@/components';
import { truncateAddress } from '@/utils/utils';

interface Props {
  members: string[];
  delegates: {
    address: string;
    customizeLabel?: string;
  }[];
  undeleteValue?: string[];
  disabled?: boolean;
  onChange: (member: string | string[]) => void;
  className?: string;
  showCustomizeLabel?: boolean;
  gray?: boolean;
  loading?: boolean;
  dropdownRender?: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function MemberSelect({
  open,
  setOpen,
  members,
  delegates,
  onChange,
  disabled,
  undeleteValue,
  className,
  showCustomizeLabel,
  gray,
  loading,
  dropdownRender,
}: Props) {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const addressMap: {
    [key: string]: {
      address: string;
      customizeLabel?: string;
    }[];
  } = useMemo(() => {
    const _addressMap: {
      [key: string]: {
        address: string;
        customizeLabel?: string;
      }[];
    } = {};
    delegates.forEach(delegate => {
      const currentAddress = _addressMap[delegate.address] || [];
      currentAddress.push(delegate);
      _addressMap[delegate.address] = currentAddress;
    });
    return _addressMap;
  }, [delegates]);

  const options: {
    label: string;
    value: string;
    title: string;
    customizeLabel?: string;
  }[] = useMemo(() => {
    const _options: {
      label: string;
      value: string;
      title: string;
      customizeLabel?: string;
    }[] = [];
    Object.entries(addressMap).forEach(([address, currentDelegates]) => {
      const label = truncateAddress(address);
      const customizeLabel =
        currentDelegates.find(o => o.customizeLabel)?.customizeLabel || '';
      const formattedLabel =
        customizeLabel.length > 12
          ? customizeLabel.slice(0, 12) + '...'
          : customizeLabel;
      _options.push({
        label: label,
        value: address,
        title: label,
        customizeLabel: formattedLabel || '',
      });
    });
    return _options;
  }, [addressMap]);

  const renderOption = (address: string) => {
    const option = options.find(item => item.value === address);
    if (!option) return '-';
    const selected = members.includes(address);
    return (
      <div className={cx('member-option-wrapper')}>
        <div className={cx('option-item')}>
          <div className={cx('content')}>
            <div className={cx('title')}>{option.title || '--'}</div>
            <div className={cx('value')}>{option.value}</div>
          </div>
          {showCustomizeLabel && option.customizeLabel && (
            <div className={cx('customize-label')}>{option.customizeLabel}</div>
          )}
        </div>
        {selected ? <div className={cx('icon-selected')} /> : null}
      </div>
    );
  };
  return (
    <div className={className}>
      <CoboSelect
        open={open}
        onDropdownVisibleChange={setOpen}
        mode="multiple"
        value={options.length > 0 ? members : []}
        disabled={disabled}
        onChange={member => {
          if (undeleteValue && undeleteValue.length > 0) {
            const intersection = members.filter((v: any) =>
              undeleteValue.includes(v),
            );
            member = intersection.concat(
              member.filter((v: any) => !intersection.includes(v)),
            );
          }
          onChange(member);
        }}
        loading={loading}
        notFoundContent={loading ? <Spin size="small" /> : t('common.notFound')}
        placeholder={t('authorization.chooseMembersTip')}
        className={cx('member-select')}
        options={options}
        optionLabelProp="label"
        maxTagCount={'responsive'}
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        renderOption={renderOption}
        menuItemSelectedIcon={null}
        undeleteValue={undeleteValue}
        gray={gray}
        dropdownRender={dropdownRender ? dropdownRender : null}
      />
    </div>
  );
}
