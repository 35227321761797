import { BaseChain } from './base';

export default class Arbitrum extends BaseChain {
  name = 'Arbitrum';
  shortName = 'arb1';

  private providerUrl = 'https://arb1.arbitrum.io/rpc';
  private scanBaseUrl = 'https://arbiscan.io';
  public safeWebBaseUrl = 'https://app.safe.global';
  private safeApiBaseUrl = 'https://safe-transaction-arbitrum.safe.global';
  subsafeFactory = '0x9d32d826E5Ef81Bf3d5054B1035afaD8570d69cd';
  baseToken = 'AETH';
  baseTokenName = 'AETH';

  public safeUrl(address: string): string {
    return `${this.safeWebBaseUrl}/home?safe=${this.shortName}:${address}`;
  }

  public addressUrl(address: string): string {
    return `${this.scanBaseUrl}/address/${address}`;
  }

  public tokenUrl(address: string): string {
    return `${this.scanBaseUrl}/token/${address}`;
  }

  public rpcUrl(): string {
    return this.providerUrl;
  }

  public safeApiUrl(): string {
    return this.safeApiBaseUrl;
  }

  public txDetailUrl(hash: string): string {
    return `${this.scanBaseUrl}/tx/${hash}`;
  }
}
