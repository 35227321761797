import { useMemo } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { Steps } from '@/components/Common/Steps';
import { StrategyListItem } from '@/interfaces';

interface Props {
  strategyInfo?: StrategyListItem;
  txHash?: string;
  page?: '' | 'setup' | 'tokenApproval' | 'preview' | 'pending' | 'finish';
}

export function BotSteps({ strategyInfo, txHash, page }: Props) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();

  const genericSteps = useMemo(() => {
    return [
      { name: t('investment.genericBot.setup') },
      { name: t('investment.genericBot.tokenApproval') },
      { name: t('investment.genericBot.preview') },
    ];
  }, [t]);

  const strategySteps: { name: string }[] = useMemo(() => [], []);
  const txSteps = useMemo(
    () => [
      { name: t('investment.strategyBot.multiSign') },
      { name: t('investment.strategyBot.finished') },
    ],
    [t],
  );

  const step = useMemo(() => {
    switch (page) {
      case '':
        return '';
      case 'setup':
        return t('investment.genericBot.setup');
      case 'tokenApproval':
        return t('investment.genericBot.tokenApproval');
      case 'preview':
        return t('investment.genericBot.preview');
      case 'pending':
        return t('investment.strategyBot.multiSign');
      case 'finish':
        return t('investment.strategyBot.finished');
      default:
        return '';
    }
  }, [page, t]);

  const steps = useMemo(() => {
    if (txHash) {
      return txSteps;
    } else if (strategyInfo) {
      return strategySteps;
    } else if (page === 'finish') {
      return [];
    } else {
      return genericSteps;
    }
  }, [page, genericSteps, strategyInfo, strategySteps, txHash, txSteps]);

  if (!steps || steps.length === 0) return <div />;
  return (
    <div className={cx('step-wrapper')}>
      <Steps
        items={steps}
        currentStep={steps.map(item => item.name).indexOf(step || '')}
      />
    </div>
  );
}
