import { AbiItem } from 'web3-utils';

import { TokenItem } from '@/interfaces';

export interface AvailableGeneralBotInstanceItem {
  status: string;
  bot_definition_id: string;
  bot_type: number;
  bot_instance_id: string;
  bot_instance_name: string;
  strategy_id: string;
  last_execute?: { time: string; status: string };
  updated_time?: number;
  total_executed?: number;
  failed_executed?: number;
  success_executed?: number;
  safe_address: string;
  safe_id: string;
  safe_module_address: string;
  safe_module_id: string;
  instance_config: any;
  chain: string;
  safe_name: string;
  executable: boolean;
}

export interface BotSummaryData {
  bots: AvailableGeneralBotItem[];
  customized_bot_running: number;
  customized_bot_all: number;
  strategy_bot_running: number;
  strategy_bot_all: number;
}

export interface AvailableGeneralBotItem {
  bot_definition_id: string;
  bot_type: number;
  bot_version?: string;
  bot_name: string;
  description: string;
  icon: string;
  bot_definition_config: any;
  bot_instances?: AvailableGeneralBotInstanceItem[];
  chain: string;
  protocols: ProtocolInfo[];
  tokens: TokenInfo[];
  pool_id?: string;
}

export interface ProtocolInfo {
  chain_id: string;
  icon_url: string;
  name: string;
  project_id: string;
  project_type: string[];
  protocol_url: string;
}
export interface AvailableGeneralBotsListItem {
  bot_definition_id: string;
  bot_type: number;
  bot_name: string;
  description: string;
  icon: string;
  bot_definition_config: any;
  status: string;
  bot_instance_id: string;
  bot_instance_name: string;
  updated_time?: number;
  last_execute?: { status: string; time: string };
  total_executed?: number;
  failed_executed?: number;
  success_executed?: number;
  safe_address: string;
  safe_id: string;
  safe_module_address: string;
  safe_module_id: string;
  instance_config: any;
  chain: string;
  safe_name: string;
  tokens: TokenInfo[];
  protocols: ProtocolInfo[];
  pool_name?: string;
  pool_id?: string;
  executable: boolean;
  strategy_id: string;
}

export interface GasData {
  address: string;
  balance: number;
  chain: string;
  gas_token: string;
  gas_token_decimals: number;
  recommended_min_balance: number;
  enable_withdraw: boolean;
}

export interface TokenInfo {
  chain_id: string;
  address: string;
  symbol: string;
  decimals: number;
  icon_url: string;
}

export interface TokenTransferItem {
  token_info: TokenInfo;
  receiver_address: string;
}

export interface BotConfigTrigger {
  type:
    | 'time'
    | 'token_amount'
    | 'lp_token_ratio'
    | 'value_in_contract'
    | 'gas_price'
    | 'collateral_ratio'
    | 'trigger_component';
  name: string;
  tokens?: TokenInfo[];
  interval_unit_options?: { label: string; value: number }[];
  op_options?: string[];
  // time trigger
  interval?: string;
  interval_unit?: number;
  // token_amount trigger
  specified_token?: string;
  specified_amount?: number | string;
  // lp_token_ratio trigger
  // specified_token?: string;
  specified_op?: string;
  specified_ratio?: number | string;
  // value in contract trigger
  contract_detail?: ContractInteractionDetail | null;
  specified_value?: string | number | null;
  // specified_op: string;
  specified_return_param?: string;
  options?: { [key: string]: string[] };
  interactions?: any;
  // gas trigger
  gas_gwei?: number | string;
  // collateral_ratio
  eval_detail?: any;
  // specified_value?: string | number | null;
  // specified_op: string;
  // common trigger
  components?: (
    | CommonTriggerSelectComponent
    | CommonTriggerInputComponent
    | CommonTriggerContentComponent
  )[];
}

export interface TimeTrigger extends BotConfigTrigger {
  type: 'time';
  name: string;
  interval?: string;
  interval_unit?: number;
  interval_unit_options: { label: string; value: number }[];
}

export interface GasTrigger extends BotConfigTrigger {
  type: 'gas_price';
  name: string;
  gas_gwei: number | string;
}

export interface TokenAmountTrigger extends BotConfigTrigger {
  type: 'token_amount';
  name: string;
  tokens: TokenInfo[];
  specified_token: string;
  specified_amount: number | string;
  interactions?: any;
}

export interface LpTokenRatioTrigger extends BotConfigTrigger {
  type: 'lp_token_ratio';
  name: string;
  tokens: TokenInfo[];
  op_options: string[];
  specified_token: string;
  specified_op: string;
  specified_ratio: number | string;
}

export type ContractDetailParamType =
  | 'all_balance'
  | 'change_balance'
  | 'custom';

export interface ContractInteractionDetail {
  chain: string;
  contract: string;
  params: {
    type: ContractDetailParamType;
    value: any;
  }[];
  abi?: AbiItem;
  value: string | null;
  function?: string;
  result_index?: any;
}

export interface ValueInContractTrigger extends BotConfigTrigger {
  type: 'value_in_contract';
  name: string;
  contract_detail: ContractInteractionDetail | null;
  specified_value: string | number | null;
  specified_op: string;
  specified_return_param: string;
  options: { [key: string]: string[] };
}

export interface CollateralRatioTrigger extends BotConfigTrigger {
  type: 'collateral_ratio';
  name: string;
  eval_detail: any;
  op_options?: string[];
  specified_value: string | number | null;
  specified_op: string;
}

export interface CommonTriggerSelectComponent {
  type: 'select';
  options: { key: string; value: string; render: string }[];
  key: string;
  placeholder: string;
  value: string;
}
export interface CommonTriggerInputComponent {
  type: 'input';
  key: string;
  input_type: 'number' | 'text';
  min?: number;
  max?: number;
  placeholder: string;
  value: string;
  unit?: string;
}

export interface CommonTriggerContentComponent {
  type: 'content';
  value: string;
  key: string;
}

export interface CommonComponentTrigger extends BotConfigTrigger {
  type: 'trigger_component';
  name: string;
  tip?: string;
  components: (
    | CommonTriggerSelectComponent
    | CommonTriggerInputComponent
    | CommonTriggerContentComponent
  )[];
}

export interface GenericBotDex {
  type: string;
  chain: string;
  token_to_sell: string;
  token_to_buy: string | null;
  token_to_sell_info?: TokenInfo;
  slippage: string | number;
  amount: string | number;
  value?: { type: ContractDetailParamType; value?: string | number };
  path?: string[];
  loading?: boolean;
  error?: string;
}

export interface GenericBotOperation {
  type:
    | 'token_transfer'
    | 'contract_interaction'
    | 'parse_transaction'
    | 'sell_token'
    | 'collateral_ratio_payback';
  chain: string;
  editable: boolean;
  required: boolean;
  // token transfer operation
  tokens?: string[];
  receiver?: string | null;
  // token transfer value, select different type value
  value?: { type: ContractDetailParamType; value?: string };
  percent?: number;
  // parse transaction operation
  transaction_hash?: string;
  contract_detail?: ContractInteractionDetail;
  // contract interaction operation
  // contract_detail?: ContractInteractionDetail;
  // sell token
  recommended_slippage?: number[];
  swap?: GenericBotDex;
  dex_info?: {
    [key: string]: {
      spender: { [key: string]: string };
      max_slippage: number;
    };
  };
  available_dex?: string[];
  token_infos?: TokenInfo[];
  transfer_to_safe?: boolean;
  for_changes?: boolean;
  // collateral_ratio_payback
  configs?: any[];
  collateral_token_info?: TokenInfo;
  specified_ratio?: number | string;
  show_expert?: boolean;
  dex?: string;
  slippage?: string | number;
}

export interface GenericBotRewardsManageData extends GenericBotOperation {
  token: string;
  action?: 'sell' | 'receive';
  receive_address?: string;
  swap_address?: string; // swap address
}

export interface LeverageTokenOptions {
  [key: string]: {
    token_info: TokenInfo;
    spenders: string[];
  };
}

export interface GenericBotTask {
  type:
    | 'notification_task'
    | 'defi_withdraw'
    | 'manage_rewards'
    | 'claim_and_vest'
    | 'increase_leverage'
    | 'decrease_leverage'
    | 'defi_operation'
    | 'defi_add_leverage'
    | 'defi_deleverage';
  // notification_task
  name: string;
  content?: string;
  need?: boolean;
  // defi_operation
  available_operations?: GenericBotOperation[];
  description?: string;
  operations?: GenericBotOperation[];
  // withdraw
  withdraw_options?: {
    [key: string]: {
      slippage: string | number;
      recommend_slippage: number[];
      tokens: TokenInfo[];
      content: string;
      token_approvals: {
        allowance: string;
        spender_address: string;
        token_info: TokenInfo;
      }[];
    };
  };
  withdraw_value?: string;
  min_ratio?: number;
  min_specific_ratio?: number; // target最小杠杆率
  current_ratio?: number; // 当前抵押率 Current
  trigger_ratio?: number; // 触发抵押率 Trigger
  specific_ratio?: number; // 目标抵押率 Target
  available_modes?: string[];
  selected_mode?: string;
  selected_borrow_option?: string;
  selected_against_option?: string;
  borrow_optionals?: LeverageTokenOptions;
  against_optionals?: LeverageTokenOptions;
  selected_withdraw_option?: string;
  selected_payback_option?: string;
  withdraw_optionals?: LeverageTokenOptions;
  payback_optionals?: LeverageTokenOptions;
}

export interface AddLeverageTaskConfig {
  trigger_ratio?: number; // 触发抵押率 Trigger
  specific_ratio: number; // 目标抵押率 Target
  selected_mode?: string;
  selected_borrow_option: string;
  selected_against_option?: string;
  current_ratio?: number; // 当前抵押率 Current
  min_specific_ratio?: number; // target最小杠杆率
}

export interface DeleverageTaskConfig {
  trigger_ratio?: number; // 触发抵押率 Trigger
  specific_ratio: number; // 目标抵押率 Target
  selected_mode?: string;
  selected_withdraw_option: string;
  selected_payback_option?: string;
  current_ratio?: number; // 当前抵押率 Current
  min_specific_ratio?: number; // target最小杠杆率
}

export interface ManageCollateralRatioData {
  min_ratio: number;
  current_ratio?: number; // 当前抵押率 Current
  trigger_ratio?: number; // 触发抵押率 Trigger
  specific_ratio?: number; // 目标抵押率 Target
  min_specific_ratio?: number; // target最小杠杆率
}

export interface BotConfigSection {
  type:
    | 'trigger'
    | 'token_receiving'
    | 'withdraw'
    | 'notification'
    | 'generic_setup'
    | 'generic_task'
    | 'token_approval';

  triggers?: BotConfigTrigger[];
  tokens?: {
    token: TokenInfo;
    editable: boolean;
    options: string[];
  }[];
  supported_tokens?: TokenInfo[][];
  recommended_slippage?: number[];
  slippage?: number | string;
  withdraw_tokens?: TokenInfo[];
  content?: string;
  receivers?: { [key: string]: string };
  // generic setup section
  safe?: string;
  safe_module?: string;
  bot_name?: string;
  // trigger section
  mode?: 'all' | 'any';
  note?: string;
  selected_triggers?: string[];
  // generic task section
  task_type?: 'common' | 'claim' | 'farming';
  available_tasks?: string[];
  tasks?: GenericBotTask[];
  selected_tasks?: string[];
  require_tasks?: string[];
  // token approval
  approvals?: GenericBotTokenItem[];
  selected?: boolean;
}

export interface BotConfig {
  sections: BotConfigSection[];
}

export interface BotDetails {
  bot_definition_id: string;
  pool_id?: string;
  bot_type: number;
  bot_name: string;
  icon: string;
  description: string;
  pool_name: string;
  bot_definition_config: BotConfig;
  protocols: ProtocolInfo[];
  tokens: TokenInfo[];
  earns: TokenInfo[];
  chain: string;
  min_version: string;
  apy: 0;
  tvl: 0;
  bot_instances?: AvailableGeneralBotInstanceItem[];
}

export interface BotInfo {
  bot_definition_id: string;
  bot_type: number;
  bot_name: string;
  description: string;
  icon: string;
  bot_instance_id: string | null;
  config: BotConfig;
  extra?: { tokens_ratio?: { [key: string]: number } };
}

export interface GenericBotInfo {
  bot_definition_id: string;
  chain?: string;
  bot_version?: string;
  bot_type: number;
  bot_name: string;
  description: string;
  icon: string;
  bot_definition_config: BotConfig;
  // instance info
  bot_instance_id: string | null;
  update_time?: number;
  status?: 'running' | 'paused' | 'terminated';
  bot_instance_name?: string;
  total_executed?: number;
  failed_executed?: number;
  success_executed?: number;
  safe_address?: string;
  safe_id?: string;
  safe_module_address?: string;
  safe_module_id?: string;
  instance_config?: BotConfig;
  protocols?: {
    chain_id: string;
    icon_url: string;
    name: string;
    project_id: string;
    project_type: string[];
    protocol_url: string;
  }[];
  webhook?: WebhookInfo;
  executable?: boolean;
}

export const statusColorMap: { [string: string]: string } = {
  running: '#65C97A',
  paused: '#E86E2C',
  closed: '#E74E5A',
};

export interface GenericBotTokenItem {
  address: string;
  spender_address: string;
  allowance: string | number;
  canEdit?: boolean;
  token_info?: TokenInfo;
  from_db?: boolean;
  init?: boolean;
  disable_remove?: boolean;
  disable_change_token?: boolean;
  disable_change_spender?: boolean;
}

export interface GenericBotTableTokenItem {
  allowance: string;
  spender_address: string;
  token_info: TokenItem;
  canEdit?: boolean;
}

export const unlimitedTokenValue =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935';

export const unlimitedTokenValueNumber = 115792089237316195423570985008687907853269984665640564039457584007913129639935;

export interface BotStrategySearchCondition {
  type: number;
  chain: -1 | string;
  protocol: -1 | string;
  token: -1 | string;
}

export interface BotTxLogOperationValue {
  from_address: string;
  to_address: string;
  amount: string;
  token_address: string;
  token_info: TokenInfo;
}

export interface BotTxLogOperation {
  status: string;
  type: string;
  details: {
    tx_url?: string;
    safe_tx_url?: string;
    error?: string;
  };
  value: BotTxLogOperationValue[];
  time: string;
}

export interface BotTxLog {
  safe_id: string;
  safe_name: string;
  safe_address: string;
  updated_time: string;
  operations: BotTxLogOperation[];
  total_count: number;
  total_exec_count: number;
  success_exec_count: number;
  fail_exec_count: number;
  total_gas_cost: number;
  avg_gas_cost: number;
}

export const BotTypeMap: {
  [key: number]: string;
} = {
  3: 'Customized',
  4: 'Farming',
  5: 'Withdrawal',
  6: 'Deleverage',
  7: 'Leverage',
};

export const defaultSliderError = {
  addLeverage: {
    trigger: '',
    target: '',
  },
  deLeverage: {
    trigger: '',
    target: '',
  },
};
export interface SliderError {
  addLeverage: {
    trigger: string;
    target: string;
  };
  deLeverage: {
    trigger: string;
    target: string;
  };
}

export interface PermissionConfig {
  role_name: string;
  delegates: string[];
  authorizers: {
    type: string;
    implementation: string;
    permissions?: {
      contract_address: string;
      contract_name: string;
      signatures: string[];
    }[];
  }[];
  token_approvals: {
    allowance: string;
    spender_address: string;
    token_info: TokenInfo;
  }[];
}

export interface SubmitBotParams {
  safe_id: string;
  safe_module_id: string;
  bot_definition_id: string;
  generic_setup: {
    safe: string;
    safe_module: string;
    bot_name: string;
  };
  triggers: {
    [key: string]: any;
  };
  tasks: {
    [key: string]: any;
  };
  token_approvals?: {
    spender_address: string;
    allowance: string;
    token_info?: TokenInfo;
  }[];
  add_to_txs_bundle?: boolean;
  webhook_id?: string;
}

export interface WebhookInfo {
  url: string;
  basic_auth: string;
  webhook_id: string;
  status: string;
}
