import { memo, useMemo } from 'react';
import { SyncOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import styles from './index.module.less';
import { useImage } from '@/hooks';
import { ChainShortNameType } from '@/interfaces';

interface ChainIconProps {
  url?: string;
  shortName?: ChainShortNameType;
  chainName?: string;
  className?: string;
}

const ChainIconGrey = (props: ChainIconProps) => {
  const { url, className, shortName, chainName } = props;
  const reqSvgs = require.context('@/assets/images/chainsGery', true, /\.png$/);

  const allSvgFilepaths = reqSvgs.keys();
  const imgSrc = useMemo(() => {
    if (!shortName) {
      if (!url) {
        reqSvgs('./link_unknown.png');
      }
      return url;
    }
    const path = `./link_${shortName.toLowerCase()}.png`;

    const index = allSvgFilepaths.findIndex(val => val === path);
    const imagePath = allSvgFilepaths[index];
    if (!imagePath) return reqSvgs('./link_unknown.png');
    return reqSvgs(imagePath);
  }, [shortName, allSvgFilepaths, reqSvgs, url]);
  const { hasLoaded, hasError } = useImage(imgSrc);
  return (
    <>
      {!hasLoaded && !hasError && (
        <SyncOutlined
          className={classNames(styles['chain-icon'], className)}
          spin
        />
      )}
      {hasLoaded && (
        <img
          src={imgSrc}
          alt={chainName || shortName || url}
          className={classNames(styles['chain-icon'], className)}
        />
      )}
      {hasError && (
        <img
          src={require('@/assets/images/chains/link_unknown.png')}
          alt={chainName || shortName || url}
          className={classNames(styles['chain-icon'], className)}
        />
      )}
    </>
  );
};

export default memo(ChainIconGrey);
