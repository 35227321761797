import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Header from '../Header';
import styles from '../index.module.less';
import Expand from '@/routes/SafeDetail/components/WalletConnectTab/Expand';

interface Props {
  peerMeta: any;
  connected: boolean;
  killSession: () => void;
}

export default function WaitingTx({ peerMeta, connected, killSession }: Props) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  return connected ? (
    <div className={cx('wallet-connect-container', 'waiting-container')}>
      <Header killSession={killSession} peerMeta={peerMeta} />
      <div className={cx('empty-tx')}>
        <div className={cx('tx-img')} />
        <div className={cx('empty-label')}>{t('walletconnect.txEmpty')}</div>
      </div>
      <Expand connected={true} />
    </div>
  ) : null;
}
