import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import styles from './index.module.less';

interface CodeBoxProps {
  value: string;
  /**
   * @description 验证码的位数
   * @default 6
   */
  len?: number;
  /**
   * @description 验证码改变时的callback
   */
  onChange?: (code: string) => void;

  /**
   * @description 自定义类名
   *
   */
  className?: string;

  /**
   * @description 是否自动获取焦点
   * @default true
   */
  autoFocus?: boolean;
}

export function CodeBox(props: CodeBoxProps) {
  // 配置相关默认值
  const { value, len = 6, onChange, className = '', autoFocus = true } = props;

  // 输入框数组
  const inputArr = new Array(len).fill('');

  // 输入框ref
  const inputRefs = useRef<any>([]);

  /**
   * @method getRef
   * @description 获取input的ref
   */
  const getRef = (dom: any) => {
    if (inputRefs?.current?.length === len) {
      return;
    }
    inputRefs.current.push(dom);
  };

  /**
   * @method onInputKeyDown
   * @method 处理input的删除事件
   * @param e 事件
   * @param index number input输入框对应的索引
   */
  const onInputKeyDown = (e: any, index: number) => {
    switch (e.key) {
      case 'Backspace':
        if (index > 0 && !e.target.value) {
          const currentInputRef = inputRefs.current[index];
          currentInputRef.value = '';
          const prevInputRef = inputRefs.current[index - 1];
          prevInputRef.focus();
          // prevInputRef.select();
          e.preventDefault();
        }
        break;
    }
  };

  /**
   * @method onInputValueChange
   * @description 当输入的验证码发生变化时
   * @param index number input输入框对应的索引
   * @param target string input 输入的字符
   */
  const onInputValueChange = (index: number, target?: string) => {
    let code = '';
    let isNumber = true;
    inputRefs.current?.forEach((ref: any) => {
      if (ref?.value) {
        if (isNumber && isNaN(parseInt(ref.value))) {
          isNumber = false;
        }
        code += ref?.value;
      } else {
        code += ' ';
      }
    });
    if (!isNumber) return;

    // 判断是删除操作
    if (index > 0 && !target) {
      const prevInputRef = inputRefs.current[index - 1];
      prevInputRef.focus();
    }

    // 判断是写入操作
    if (index < len - 1 && target) {
      const nextInputRef = inputRefs.current[index + 1];
      nextInputRef.focus();
    }

    onChange && onChange(code);
  };

  /**
   * @method getInputClassName
   * @description 动态获取每个验证码输入框的类名，增加已输入码的输入框样式
   * @param index number 索引
   * @returns string
   */
  const getInputClassName = (index: number) => {
    const currentInputRef = inputRefs.current[index];
    const value = currentInputRef?.value;
    return value
      ? classNames(
          styles['code-box-input-has-string'],
          styles['code-box-input'],
        )
      : styles['code-box-input'];
  };

  useEffect(() => {
    // 自动获取焦点的处理
    if (autoFocus) {
      inputRefs?.current[0].focus();
    }
  }, [autoFocus]);

  return (
    <div className={className || classNames(styles['code-box'])}>
      {/* 渲染每一个验证码输入框 */}
      {inputArr.map((v, index) => {
        return (
          <input
            ref={getRef}
            value={
              value.length > index
                ? value[index] === ' '
                  ? ''
                  : value[index]
                : ''
            }
            autoFocus={autoFocus}
            maxLength={1}
            className={getInputClassName(index)}
            key={index}
            type="text"
            onFocus={() => {
              inputRefs.current[index]?.select();
            }}
            onKeyDown={e => {
              onInputKeyDown(e, index);
            }}
            onChange={e => {
              onInputValueChange(index, e.target.value);
            }}
          />
        );
      })}
    </div>
  );
}
