import axios from 'axios';

import coboAtomStore, {
  globalUserInfoAtom,
  pathHistoryAtom,
} from '@/globalAtom';
import { CoboStorage } from '@/utils/storage';

const getCollectUrl = (): string => {
  const isSandbox = process.env.REACT_APP_ENV === 'sandbox';
  if (isSandbox) return 'https://collect.sandbox.cobo.com/img/0/logo.png';
  return 'https://static-img.cobo.com/img/0/logo.png';
};

const collectApi = axios.create({
  baseURL: getCollectUrl(),
  timeout: 60000,
});

collectApi.interceptors.request.use(
  config => config,
  error => Promise.reject(error),
);

/**
 * 埋点
 * @param data 字段如下
 * process Regoist / OrgCreate / Login / SafeCreate / Safemodulecreate / invest / botCreate / addPermission
 * cid 不同行为 cid 不同
 * idx act 为 hb 时必填，0: Page Loaded;
 * act hb: heart beat; ck: client;
 * userId 登录后需要带上 user id
 * extra 可能存在的额外字段，比如 org_id, safe_address, module_address, strategy_id, bot_id, permission_id
 */
async function collect(data: {
  cid?: string;
  idx?: number;
  act?: string;
  userId?: string;
  extra?: { [key: string]: any };
}) {
  const hashCode = function (s: string) {
    let h = 0,
      l = s.length,
      i = 0;
    if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
    return h;
  };
  const collectKey = hashCode('argus-unique-key').toString();
  let sid = CoboStorage.get(collectKey);
  if (!sid) {
    sid = crypto.randomUUID();
    CoboStorage.set(collectKey, sid);
  }
  const uid = coboAtomStore.get(globalUserInfoAtom)?.user_id;
  const { act, userId, cid, extra, idx } = data;
  const history = coboAtomStore.get(pathHistoryAtom);
  // 本地不收集埋点
  if (process.env.NODE_ENV === 'development') return;
  await collectApi.post('', {
    data: {
      type: 'argus_v2',
      act: act || 'ck',
      idx,
      uid: userId || uid || '',
      sid,
      ts: new Date().getTime(),
      ref: history[history.length - 2]
        ? `${window.location.origin}/#${history[history.length - 2]}`
        : '',
      url: window.location.href,
      cid,
      ...(extra || {}),
    },
  });
}

export { collect };
