import { memo, useEffect } from 'react';
import { Tabs, Tooltip } from 'antd';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { CustomizeBotsList, StrategyBotsList } from '@/components';
import { StrategyMart } from '@/components/MyPortfolio/StrategyMart';
import { useQuery } from '@/hooks';
import { AvailableGeneralBotsListItem } from '@/interfaces';

interface Props {
  onRefresh: () => Promise<AvailableGeneralBotsListItem[] | undefined>;
  onViewDetail: (ins: any, executeRecord: any) => void;
  canEdit: boolean;
  botActiveKey: 'customize' | 'strategy';
  tabActiveKey: 'myStrategy' | 'myBot';
  setBotActiveKey: (value: 'customize' | 'strategy') => void;
  setTabActiveKey: (value: 'myStrategy' | 'myBot') => void;
}

const Tab = ({
  onRefresh,
  onViewDetail,
  canEdit,
  botActiveKey,
  tabActiveKey,
  setBotActiveKey,
  setTabActiveKey,
}: Props) => {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const query = useQuery();
  useEffect(() => {
    if (!query.get('tab')) {
      return;
    }
    const tab = query.get('tab');
    if (tab === 'farmingBot') {
      setTabActiveKey('myBot');
      setBotActiveKey('strategy');
    }
    if (tab === 'customizedBot') {
      setTabActiveKey('myBot');
      setBotActiveKey('customize');
    }
  }, [query, setBotActiveKey, setTabActiveKey]);
  return (
    <Tabs
      defaultActiveKey={tabActiveKey}
      activeKey={tabActiveKey}
      onChange={value => setTabActiveKey(value as 'myStrategy' | 'myBot')}
      className={cx('my-bots-tabs')}
      items={[
        {
          key: 'myStrategy',
          label: (
            <>
              {t('investment.strategyDetail.service')}
              <Tooltip title={t('bots.myStrategyToolTip')} placement="bottom">
                <div className={cx('tip-icon')} />
              </Tooltip>
            </>
          ),
          children: <StrategyMart />,
        },
        {
          key: 'myBot',
          label: (
            <>
              {t('bots.myBots')}
              <Tooltip title={t('bots.customizedBotsTip')} placement="bottom">
                <div className={cx('tip-icon')} />
              </Tooltip>
            </>
          ),
          children: (
            <Tabs
              defaultActiveKey={botActiveKey}
              activeKey={botActiveKey}
              onChange={value =>
                setBotActiveKey(value as 'customize' | 'strategy')
              }
              type="card"
              className={cx('tabs-box')}
              items={[
                {
                  key: 'customize',
                  label: t('bots.customizedBots'),
                  children: (
                    <CustomizeBotsList
                      onRefresh={onRefresh}
                      onViewDetail={onViewDetail}
                      canEdit={canEdit}
                    />
                  ),
                },
                {
                  key: 'strategy',
                  label: t('bots.strategyBots'),
                  children: (
                    <StrategyBotsList
                      onRefresh={onRefresh}
                      onViewDetail={onViewDetail}
                      canEdit={canEdit}
                    />
                  ),
                },
              ]}
            />
          ),
        },
      ]}
    ></Tabs>
  );
};

export default memo(Tab);
