import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.less';
import { useTransactionBundle } from '@/hooks';

export function TransactionBundle() {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const { getBundleInfo, bundleInfo } = useTransactionBundle();
  useEffect(() => {
    getBundleInfo();
  }, [getBundleInfo]);

  const bundleNumber = useMemo(() => {
    return bundleInfo?.bundle_items?.length || 0;
  }, [bundleInfo?.bundle_items?.length]);

  return (
    <>
      {!!bundleNumber ? (
        <div
          className={cx('transaction-wrapper')}
          onClick={() => {
            navigate('/transaction', {
              state: {
                tab: 'bundle',
              },
            });
          }}
        >
          <div className={cx('transaction-icon')}></div>
          <span className={cx('transaction-text')}>
            {t('authorization.transactionBundle')}
          </span>
          <span className={cx('transaction-number')}>{bundleNumber}</span>
        </div>
      ) : null}
    </>
  );
}
