import { BaseChain } from './base';

export default class Mode extends BaseChain {
  name = 'Mode';
  shortName = 'mode';

  private providerUrl = 'https://mainnet.mode.network';
  private scanBaseUrl = 'https://explorer.mode.network';
  public safeWebBaseUrl = 'https://safe.optimism.io';
  private safeApiBaseUrl = 'https://transaction-mode.safe.optimism.io';
  subsafeFactory = '';
  baseToken = 'ETH';
  baseTokenName = 'ETH';

  public safeUrl(address: string): string {
    return `${this.safeWebBaseUrl}/home?safe=${this.shortName}:${address}`;
  }

  public addressUrl(address: string): string {
    return `${this.scanBaseUrl}/address/${address}`;
  }

  public tokenUrl(address: string): string {
    return `${this.scanBaseUrl}/token/${address}`;
  }

  public rpcUrl(): string {
    return this.providerUrl;
  }

  public safeApiUrl(): string {
    return this.safeApiBaseUrl;
  }

  public txDetailUrl(hash: string): string {
    return `${this.scanBaseUrl}/tx/${hash}`;
  }
}
