import { atom } from 'jotai';

import { EditType } from '../interfaces';
import {
  BotDetails,
  BundleItem,
  defaultSliderError,
  GenericBotInfo,
  ServiceType,
  StrategyAuthorizationInfoItem,
  StrategyListItem,
  StrategyPermissionRole,
} from '@/interfaces';
import { ExtraConfigType } from '@/routes/ServiceConfig/hooks/useExtraConfig';

export const serviceIdAtom = atom<string>('');
export const serviceTypeAtom = atom<ServiceType | undefined>(undefined);

export const serviceMapAtom = atom<
  | {
      [key: string]: BotDetails | StrategyPermissionRole | undefined;
    }
  | undefined
>(undefined);

export const strategyDetailAtom = atom<StrategyListItem | undefined>(undefined);
export const editingTypeAtom = atom<EditType | undefined>(undefined);

export const editingBundleIdAtom = atom<string | undefined>(undefined);

export const editingBundleItemAtom = atom<BundleItem | undefined>(undefined);

export const editingBundleInitAtom = atom(false);

export const configsAtom = atom<
  | {
      [key: string]: GenericBotInfo | StrategyAuthorizationInfoItem | undefined;
    }
  | undefined
>(undefined);

export const initExtraConfig = {
  originalBotInfo: undefined,
  leverageSliderError: defaultSliderError,
  safeRoleNames: undefined,
  transferSelectedMap: undefined,
  dexAclTokens: undefined,
  tokenSettingType: undefined,
  tokenApprovalList: undefined,
  tradeTokenOptions: undefined,
  receiverOptions: undefined,
  tokenOptions: undefined,
  permissionConfig: undefined,
  page: undefined,
  hasDexAcl: undefined,
  mode: undefined,
  txHash: undefined,
  safeTxRecord: undefined,
  hasWebhook: undefined,
};

export const extraConfigsAtom = atom<{
  [key: string]: ExtraConfigType;
}>({});
