import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInterval } from 'ahooks';
import { useAtom, useAtomValue } from 'jotai';
import { cloneDeep, isEqual } from 'lodash-es';

import {
  getBotDetailsForSafe,
  getStrategyAuthorizedInfoForSafe,
  loadGnosisSafe,
} from '@/api';
import { getChainIdByShortName } from '@/chains/utils';
import {
  globalSafeInfoAtom,
  orgIdAtom,
  safeListAtom,
  safeModuleAtom,
} from '@/globalAtom';
import { useConnectWallet } from '@/hooks/useConnectWallet';
import {
  BotDetails,
  ServiceType,
  StrategyPermissionRole,
  StrategyService,
} from '@/interfaces';
import { serviceMapAtom } from '@/routes/ServiceConfig/atoms';
import { getOwners } from '@/utils/multisign';

export const useLoadServices = (services?: StrategyService[]) => {
  const [serviceMap, setServiceMap] = useAtom(serviceMapAtom);

  const [safeInfo] = useAtom(globalSafeInfoAtom);

  const botServices = useMemo(
    () =>
      services
        ? services.filter(service => service.service_type === ServiceType.BOT)
        : [],
    [services],
  );
  const botServicesData = useMemo(
    () =>
      botServices.map(service =>
        serviceMap ? (serviceMap[service.service_id] as BotDetails) : undefined,
      ),
    [botServices, serviceMap],
  );
  const singleSigService = useMemo(
    () =>
      services
        ? services.find(
            service => service.service_type === ServiceType.SINGLE_SIGN,
          )
        : undefined,
    [services],
  );
  const singleSigServiceData = useMemo(
    () =>
      singleSigService && serviceMap
        ? (serviceMap[singleSigService.service_id] as StrategyPermissionRole)
        : undefined,
    [serviceMap, singleSigService],
  );

  const needSetupService = useMemo(() => {
    if (!serviceMap) return [];
    const result: StrategyService[] = [];
    botServices.forEach(service => {
      const serviceData = serviceMap[service.service_id] as BotDetails;
      if (!serviceData || serviceData.bot_instances?.length === 0) {
        result.push(service);
      }
    });
    if (singleSigService && !singleSigServiceData) {
      result.push(singleSigService);
    }
    return result;
  }, [botServices, serviceMap, singleSigService, singleSigServiceData]);

  const refresh = useCallback(async () => {
    const loadService = async (service: StrategyService) => {
      if (!safeInfo) return;
      let data: BotDetails | StrategyPermissionRole | undefined;
      if (service.service_type === ServiceType.BOT) {
        data = await getBotDetailsForSafe(safeInfo.id, service.service_id);
      } else if (service.service_type === ServiceType.SINGLE_SIGN) {
        data = await getStrategyAuthorizedInfoForSafe(
          safeInfo.id,
          service.service_id,
        );
        const hasPerm = !!Object.values(data).find(
          item => item.roles_config.length > 0,
        );
        if (!hasPerm) {
          return undefined;
        }
      }
      return data;
    };
    if (!safeInfo || !services || services.length === 0) return;
    const promises: Promise<BotDetails | StrategyPermissionRole | undefined>[] =
      [];
    services.forEach(service => promises.push(loadService(service)));
    const results = await Promise.all(promises);
    const newMap: {
      [key: string]: BotDetails | StrategyPermissionRole | undefined;
    } = {};
    services.forEach((service, index) => {
      newMap[service.service_id] = results[index];
    });
    setServiceMap(newMap);
    return newMap;
  }, [safeInfo, services, setServiceMap]);

  useEffect(() => {
    if (
      !safeInfo ||
      !services ||
      services.length === 0 ||
      serviceMap !== undefined
    )
      return;
    refresh();
  }, [refresh, safeInfo, serviceMap, services]);

  const configuredServiceIds = useMemo(() => {
    if (!services) return [];
    const needSetupServiceIds = needSetupService.map(
      service => service.service_id,
    );
    return services
      .filter(service => !needSetupServiceIds.includes(service.service_id))
      .map(service => service.service_id);
  }, [needSetupService, services]);

  return {
    botServices,
    botServicesData,
    singleSigService,
    singleSigServiceData,
    needSetupService,
    configuredServiceIds,
    refresh,
  };
};

export const useIntervalRefreshSingleSigService = (
  services?: StrategyService[],
) => {
  const [serviceMap, setServiceMap] = useAtom(serviceMapAtom);

  const safeInfo = useAtomValue(globalSafeInfoAtom);

  const singleSigService = useMemo(
    () =>
      services
        ? services.find(
            service => service.service_type === ServiceType.SINGLE_SIGN,
          )
        : undefined,
    [services],
  );
  const singleSigServiceData = useMemo(
    () =>
      singleSigService && serviceMap
        ? (serviceMap[singleSigService.service_id] as StrategyPermissionRole)
        : undefined,
    [serviceMap, singleSigService],
  );

  const refreshSingleSign = useCallback(async () => {
    if (!singleSigService || !singleSigServiceData || !safeInfo || !serviceMap)
      return;
    let data: StrategyPermissionRole | undefined =
      await getStrategyAuthorizedInfoForSafe(
        safeInfo.id,
        singleSigService.service_id,
      );
    const hasPerm = !!Object.values(data).find(
      item => item.roles_config.length > 0,
    );
    if (!hasPerm) {
      data = undefined;
    }
    const newMap: {
      [key: string]: BotDetails | StrategyPermissionRole | undefined;
    } = cloneDeep(serviceMap);
    newMap[singleSigService.service_id] = data;
    if (data === undefined) {
      if (serviceMap[singleSigService.service_id]) {
        setServiceMap(newMap);
      }
    } else {
      if (!serviceMap[singleSigService.service_id]) {
        setServiceMap(newMap);
      } else {
        // todo 排序后检查是否数据相同，后端每次返回的数据顺序会不一样，所以会导致每 10s 查询一次 tx hash
        if (!isEqual(data, serviceMap[singleSigService.service_id])) {
          setServiceMap(newMap);
        }
      }
    }
  }, [
    safeInfo,
    serviceMap,
    setServiceMap,
    singleSigService,
    singleSigServiceData,
  ]);

  useInterval(() => {
    refreshSingleSign();
  }, 10000);
};

export const useConsoleStatus = (chainId?: number) => {
  const [safeInfo] = useAtom(globalSafeInfoAtom);
  const [moduleInfo] = useAtom(safeModuleAtom);
  const [safeList, setSafeList] = useAtom(safeListAtom);
  const [orgId] = useAtom(orgIdAtom);
  const { isConnected, address, connect } = useConnectWallet();
  const [owners, setOwners] = useState<string[]>([]);
  const needConnect = useMemo(() => !isConnected, [isConnected]);

  const needSwitchSafe = useMemo(() => {
    if (!safeInfo || !chainId) return false;
    const safeChainId = getChainIdByShortName(safeInfo.chain_id);
    return safeChainId !== chainId;
  }, [chainId, safeInfo]);

  const needImportSafe = useMemo(() => {
    if (!safeList) return false;
    if (!chainId) return safeList.length === 0;
    return !safeList.find(
      safe => getChainIdByShortName(safe.chain_id) === chainId,
    );
  }, [chainId, safeList]);

  const isOwner = useMemo(
    () =>
      owners.length > 0 && address ? owners.indexOf(address) !== -1 : false,
    [address, owners],
  );

  const needCreateCoboSafe = useMemo(() => !moduleInfo, [moduleInfo]);

  const needEnableCoboSafe = useMemo(
    () => (moduleInfo ? !moduleInfo.enabled : true),
    [moduleInfo],
  );

  useEffect(() => {
    const loadSafes = async () => {
      if (!needSwitchSafe || !orgId) return;
      const list = await loadGnosisSafe(orgId);
      setSafeList(list);
    };
    loadSafes();
  }, [needSwitchSafe, orgId, setSafeList]);

  useEffect(() => {
    const loadOwners = async () => {
      if (!safeInfo || !orgId) return;
      const owners = await getOwners(safeInfo.address, safeInfo.chain_id);
      setOwners(owners);
    };
    loadOwners();
  }, [orgId, safeInfo]);
  useEffect(() => {
    setOwners([]);
  }, []);
  return {
    needConnect,
    needImportSafe,
    needSwitchSafe,
    needCreateCoboSafe,
    needEnableCoboSafe,
    isOwner,
    connect,
  };
};
