import React, { ReactNode } from 'react';
import { Segmented as AntSegmented } from 'antd';
import { SegmentedProps } from 'antd/lib/segmented';
import classNames from 'classnames/bind';

import styles from './index.module.less';

interface OptionItem {
  label: string | ReactNode;
  value: string | number;
  disabled?: boolean;
}
interface OnChangeFunc {
  (value: any): void;
}
interface CoboSegmentedProps extends SegmentedProps {
  /**选项 */
  options: OptionItem[] | string[];
  /**change事件，暴漏当前value作为参数 */
  onChange?: OnChangeFunc;
  wrapperCls?: string;
}
export function CoboSegmented(props: CoboSegmentedProps) {
  const { options, onChange, wrapperCls, ...restProps } = props;
  const cx = classNames.bind(styles);
  return (
    <div className={cx('cobo-segmented', wrapperCls || '')}>
      <AntSegmented
        options={options}
        onChange={onChange}
        size={restProps.size}
        disabled={restProps.disabled}
        value={restProps.value}
        defaultValue={restProps.defaultValue}
      ></AntSegmented>
    </div>
  );
}
