import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from '../../index.module.less';
import { Button } from '@/components';

interface Props {
  onImport: () => void;
}

export const ImportSafeView = ({ onImport }: Props) => {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  return (
    <div className={cx('tab-content-container')}>
      <div className={cx('module-img')} />
      <div className={cx('label')}>{t('safeInfo.importSafeTip')}</div>
      <Button
        onClick={() => {
          onImport();
        }}
      >
        {`+ ${t('safeManagement.addBtn')}`}
      </Button>
      <div className="mt-[24px] text-greyFont leading-[24px] text-[16px]">
        {t('safeManagement.emptySafe')}{' '}
        <a
          href="https://app.safe.global/welcome"
          className="text-[500] text-blue"
          target="_blank"
          rel="noreferrer"
        >
          {t('safeManagement.emptyToCreateSafe')}
        </a>
      </div>
    </div>
  );
};
