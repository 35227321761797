import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, SettingItem } from '../components';
import styles from './index.module.less';
import { BrowserIcon } from '@/components/Common/BrowserIcon';
import { useGlobalSafe } from '@/hooks';
import { ChainShortNameType } from '@/interfaces';
import { copyStr, getMaxVersion } from '@/utils/utils';

// interface Props {
//   onVisibleChange: (visible: boolean) => void;
// }

export function SafeModule() {
  const { t } = useTranslation();

  const { safeModule, chain, loadSafeChain } = useGlobalSafe();

  useEffect(() => {
    loadSafeChain();
  }, [loadSafeChain]);

  const showUpgrade = useMemo(() => {
    if (!safeModule) return false;
    const maxVersion = getMaxVersion([safeModule]);
    const maxVersionSafeModule = [safeModule].find(
      item => item.version === maxVersion,
    );
    if (!maxVersionSafeModule) return false;
    return !maxVersionSafeModule.address;
  }, [safeModule]);

  return (
    <>
      {safeModule && (
        <SettingItem
          title={'Cobo Safe'}
          tooltip={t('safe.safeModuleTip')}
          buttons={
            showUpgrade
              ? [
                  {
                    name: 'Upgrade',
                    url: '',
                    classname: styles['color-button'],
                    goSafeApp: true,
                  },
                ]
              : []
          }
        >
          <div className={styles['settings-content']}>
            <div>
              <div className={styles['settings-content-str']}>
                {safeModule.address}
              </div>
              <div
                className={styles['copy-btn']}
                onClick={() => {
                  copyStr(safeModule.address || '');
                }}
              />
              {chain?.config.shortName && (
                <div
                  className={styles['es-icon']}
                  onClick={() => {
                    window.open(chain?.addressUrl(safeModule?.address || ''));
                  }}
                >
                  <BrowserIcon
                    shortName={chain?.config.shortName as ChainShortNameType}
                    chainName={chain?.config.chainName}
                  />
                </div>
              )}
            </div>
          </div>
        </SettingItem>
      )}
      <Divider />
    </>
  );
}
