import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, message } from 'antd';
import type { MenuProps } from 'antd';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import styles from './index.module.less';
import {
  CreateOrganizationModal,
  InvitationModal,
  SvgIcon,
} from '@/components';
import { NameLogo } from '@/components/Layout/NameLogo';
import { globalUserInfoAtom } from '@/globalAtom';
import { useGlobalSafe } from '@/hooks';
import { useGlobalOrgs } from '@/hooks/useGlobalOrg';
import { RoleMap } from '@/interfaces';
import { hideConfirmModal, showConfirmModal } from '@/utils/utils';

export function OrgSelector() {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [userInfo] = useAtom(globalUserInfoAtom);
  const [selectOpen, setSelectOpen] = useState(false);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [hasShownInviteModal, setHasShownInviteModal] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const { showOrganizations, onOrgChange, orgInfo, loadOrgs } = useGlobalOrgs();

  const { clearSafe } = useGlobalSafe();
  useEffect(() => {
    if (!userInfo || !userInfo.user_id || userInfo.org_count === 0) return;
    loadOrgs();
  }, [userInfo, loadOrgs]);

  useEffect(() => {
    if (hasShownInviteModal) return;
    if (
      userInfo &&
      // userInfo.org_count > 0 &&
      userInfo.invite_record_count > 0
    ) {
      setInviteModalVisible(true);
      setHasShownInviteModal(true);
    }
  }, [hasShownInviteModal, userInfo]);

  const changeOrg = useCallback(
    async (id: string) => {
      const pages = [
        '/service-config',
        '/service-config/farming/:id',
        '/service-config/trading/:id',
      ];
      const needConfirm = pages.some(path => {
        return matchPath(path, pathname);
      });
      if (!needConfirm) {
        await onOrgChange(id);
        clearSafe(true);
        return;
      }
      setSelectOpen(false);
      showConfirmModal({
        confirmModalVisible: true,
        confirmModalTitle: t('authorization.reminder'),
        confirmModalElement: t('safe.changeOrgTip'),
        cancelText: t('safe.stayOnThisPage'),
        okText: t('safe.switchOrg'),
        onCancel: () => {
          hideConfirmModal();
        },
        onConfirm: async () => {
          await onOrgChange(id);
          clearSafe(true);
          navigate('/home');
        },
      });
    },
    [t, navigate, pathname, onOrgChange, clearSafe],
  );

  const items: MenuProps['items'] = useMemo(() => {
    const _menus: MenuProps['items'] = showOrganizations?.map(item => {
      return {
        key: item.id,
        label: (
          <div
            className={cx('select-option')}
            onClick={() => {
              changeOrg(item.id);
            }}
          >
            <div className={cx('current-org-content')}>
              <NameLogo
                label={item ? `${item?.name}#${item?.display_id}` : ''}
              />
              <div className={cx('org-info')}>
                <div
                  className={cx('org-name')}
                >{`${item?.name}#${item?.display_id}`}</div>
                <div className={cx('role')}>
                  {item ? RoleMap[item?.role as number] : '-'}
                </div>
              </div>
            </div>
            {orgInfo?.id === item.id ? (
              <div className={styles['icon-selected']} />
            ) : null}
          </div>
        ),
      };
    });
    _menus?.unshift({
      key: 'title',
      label: <div className={cx('drop-down-title')}>{t('safe.chooseOrg')}</div>,
    });
    return _menus || [];
  }, [showOrganizations, orgInfo?.id, cx, changeOrg, t]);

  useEffect(() => {
    return () => {
      setSelectOpen(false);
    };
  }, []);

  const onSuccess = async (label: string) => {
    await loadOrgs();
    message.success(label);
  };
  return (
    <div className={styles['userinfo-container']}>
      {items && (
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          open={selectOpen}
          onOpenChange={setSelectOpen}
          placement="bottomLeft"
          dropdownRender={menu => (
            <div className={cx('content')}>
              {menu}
              <div className={cx('divider')}></div>
              <div className={cx('footer')}>
                <div
                  className={cx('view-all')}
                  onClick={() => {
                    setSelectOpen(false);
                    window.open(
                      `${window.location.origin}/#/organization`,
                      '_blank',
                    );
                  }}
                >
                  <div className={cx('view-all-icon')}></div>
                  {t('bots.viewAll')}
                </div>
              </div>
            </div>
          )}
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        >
          <div className={cx('current-org')}>
            <div className={cx('current-org-content')}>
              <NameLogo
                label={
                  orgInfo ? `${orgInfo?.name}#${orgInfo?.display_id}` : '--'
                }
                logoClassName={cx('org-name-logo')}
              />
              <div className={cx('org-info')}>
                <div className={cx('org-name')}>
                  {orgInfo ? `${orgInfo?.name}#${orgInfo?.display_id}` : '--'}
                </div>
                <div className={cx('role')}>
                  {orgInfo ? RoleMap[orgInfo?.role as number] : '--'}
                </div>
              </div>
            </div>
            <SvgIcon
              color="rgba(133, 144, 166, 1)"
              name="dropdown_arrow"
              className={`${styles['selector-icon']} ${
                selectOpen ? styles['open'] : ''
              }`}
            />
          </div>
        </Dropdown>
      )}

      <InvitationModal
        onSuccess={onSuccess}
        onSetCreateModalVisible={() => setCreateModalVisible(true)}
        closeModal={() => setInviteModalVisible(false)}
        visible={inviteModalVisible}
      />
      <CreateOrganizationModal
        onSuccess={onSuccess}
        createModalVisible={createModalVisible}
        closeModal={() => {
          setCreateModalVisible(false);
          setInviteModalVisible(false);
        }}
      />
    </div>
  );
}
