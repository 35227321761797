import { Decimal } from 'decimal.js';
import { isNumber } from 'lodash-es';

import Arbitrum from './arbitrum';
import Avax from './avax';
import { IBaseChain } from './base';
import Bsc from './bsc';
import Mainnet from './mainnet';
import Optimism from './optimism';
import Xdai from './xdai';
import Base from '@/chains/basechain';
import Goerli from '@/chains/goerli';
import Manta from '@/chains/manta';
import Mantle from '@/chains/mantle';
import Mode from '@/chains/mode';
import Polygon from '@/chains/polygon';
import { numWithCommas } from '@/utils/format';

export interface ChainConfig {
  chainName: string;
  shortName: string;
  coboName: string;
  debankName: string;
  network: () => IBaseChain | null;
}

enum SUPPORTED_CHAINS {
  MAINNET = 1,
  OPTIMISM = 10,
  BSC = 56,
  XDAI = 100,
  POLYGON = 137,
  ARB1 = 42161,
  AVAX = 43114,
  GOERLI = 5,
  BASE = 8453,
  // BASEGoerli = 84531,
  MANTLE = 5000,
  MANTA = 169,
  MODE = 34443,
}

const mainnetConf: ChainConfig = {
  chainName: 'Ethereum',
  shortName: 'eth',
  coboName: 'ETH',
  debankName: 'eth',
  network: () => new Mainnet(),
};

const bscConf: ChainConfig = {
  chainName: 'BNB Smart Chain',
  shortName: 'bnb',
  coboName: 'BSC',
  debankName: 'bsc',
  network: () => new Bsc(),
};

const polygonConf: ChainConfig = {
  chainName: 'Polygon',
  shortName: 'matic',
  coboName: 'POLYGON',
  debankName: 'matic',
  network: () => new Polygon(),
};

const avaxConf: ChainConfig = {
  chainName: 'Avalanche',
  shortName: 'avax',
  coboName: 'AVAX',
  debankName: 'avax',
  network: () => new Avax(),
};

const arbiConf: ChainConfig = {
  chainName: 'Arbitrum',
  shortName: 'arb1',
  coboName: 'ARBI',
  debankName: 'arb',
  network: () => new Arbitrum(),
};

const optConf: ChainConfig = {
  chainName: 'Optimism',
  shortName: 'oeth',
  coboName: 'OPT',
  debankName: 'op',
  network: () => new Optimism(),
};

const xdaiConf: ChainConfig = {
  chainName: 'Gnosis Chain',
  shortName: 'gno',
  coboName: 'XDAI',
  debankName: 'xdai',
  network: () => new Xdai(),
};

const goerliConf: ChainConfig = {
  chainName: 'Goerli',
  shortName: 'gor',
  coboName: 'GTH',
  debankName: '',
  network: () => new Goerli(),
};

const baseConf: ChainConfig = {
  chainName: 'Base',
  shortName: 'base',
  coboName: 'BASE',
  debankName: '',
  network: () => new Base(),
};

const mantleConf: ChainConfig = {
  chainName: 'Mantle',
  shortName: 'mantle',
  coboName: 'MANTLE',
  debankName: '',
  network: () => new Mantle(),
};

const mantaConf: ChainConfig = {
  chainName: 'Manta',
  shortName: 'manta',
  coboName: 'MANTA',
  debankName: '',
  network: () => new Manta(),
};

const modeConf: ChainConfig = {
  chainName: 'Mode',
  shortName: 'mode',
  coboName: 'MODE',
  debankName: '',
  network: () => new Mode(),
};

const Chains = new Map<number, ChainConfig>([
  [SUPPORTED_CHAINS.MAINNET, mainnetConf],
  [SUPPORTED_CHAINS.BSC, bscConf],
  [SUPPORTED_CHAINS.POLYGON, polygonConf],
  [SUPPORTED_CHAINS.AVAX, avaxConf],
  [SUPPORTED_CHAINS.ARB1, arbiConf],
  [SUPPORTED_CHAINS.OPTIMISM, optConf],
  [SUPPORTED_CHAINS.XDAI, xdaiConf],
  [SUPPORTED_CHAINS.GOERLI, goerliConf],
  [SUPPORTED_CHAINS.BASE, baseConf],
  [SUPPORTED_CHAINS.MANTLE, mantleConf],
  [SUPPORTED_CHAINS.MANTA, mantaConf],
  [SUPPORTED_CHAINS.MODE, modeConf],
]);

export interface ChainFilterItem {
  chainName: string;
  shortName: string;
  coboName: string;
}

export const ChainsFilter: ChainFilterItem[] = [
  {
    chainName: 'All',
    shortName: '',
    coboName: 'All',
  },
  {
    chainName: 'Ethereum',
    shortName: 'eth',
    coboName: 'ETH',
  },
  {
    chainName: 'BNB Smart Chain',
    shortName: 'bnb',
    coboName: 'BSC',
  },
  {
    chainName: 'Polygon',
    shortName: 'matic',
    coboName: 'POLYGON',
  },
  {
    chainName: 'Avalanche',
    shortName: 'avax',
    coboName: 'AVAX',
  },

  {
    chainName: 'Arbitrum',
    shortName: 'arb1',
    coboName: 'ARBI',
  },
  {
    chainName: 'Optimism',
    shortName: 'oeth',
    coboName: 'OPT',
  },
  {
    chainName: 'Gnosis Chain',
    shortName: 'gno',
    coboName: 'XDAI',
  },
  {
    chainName: 'Goerli',
    shortName: 'gor',
    coboName: 'GTH',
  },
  {
    chainName: 'Base',
    shortName: 'base',
    coboName: 'BASE',
  },
  {
    chainName: 'Mantle',
    shortName: 'mantle',
    coboName: 'MANTLE',
  },
  {
    chainName: 'Manta',
    shortName: 'manta',
    coboName: 'MANTA',
  },
  {
    chainName: 'Mode',
    shortName: 'mode',
    coboName: 'MODE',
  },
];

const getChainIdByShortName = (shortName: string): number => {
  let result = SUPPORTED_CHAINS.MAINNET;
  Array.from(Chains.entries()).forEach(([chainId, config]) => {
    if (config.shortName === shortName || config.coboName === shortName) {
      result = chainId;
    }
  });
  return result;
};

const getShortNameByChainId = (chainId: number): string => {
  return Chains.get(chainId)?.shortName || '';
};

const getCoboNameByShortName = (shortName: string): string | null => {
  let result = null;
  Array.from(Chains.entries()).forEach(([chainId, config]) => {
    if (config.shortName === shortName || config.coboName === shortName) {
      result = config.coboName;
    }
  });
  return result;
};

const getCoboNameByChainId = (chainId: number): string => {
  const chain = Chains.get(chainId);
  return chain?.coboName || '';
};

const getShortNameByCoboName = (coboName: string): string => {
  let result = '';
  Array.from(Chains.entries()).forEach(([chainId, config]) => {
    if (config.coboName.toLowerCase() === coboName.toLowerCase()) {
      result = config.shortName;
    }
  });
  return result;
};

const getChainNameByCoboName = (coboName: string): string => {
  const chainId = getChainIdByCoboName(coboName);
  const chain = Chains.get(chainId);
  return chain?.chainName || '';
};

const getChainIdByCoboName = (coboName: string): number => {
  const shortName = getShortNameByCoboName(coboName);
  return getChainIdByShortName(shortName);
};

const getDebankNameByShortName = (shortName: string): string => {
  let result = null;
  Array.from(Chains.entries()).forEach(([chainId, config]) => {
    if (config.shortName.toLowerCase() === shortName.toLowerCase()) {
      result = config.debankName;
    }
  });
  return result || '';
};

const getNumberFormDecimal = (target: string | number, digits: number = 2) => {
  if (!isNumber(target) && isNaN(parseInt(target))) {
    return target;
  }
  const num = new Decimal(target);
  const numWithoutComma = num.toFixed(digits, Decimal.ROUND_DOWN);
  return numWithCommas(numWithoutComma);
};

const getTokenAndAllowanceNumber = (
  target: string | number,
  needConverter = true,
) => {
  if (!isNumber(target) && isNaN(parseInt(target))) {
    return target;
  }
  const num = new Decimal(target);
  const toFixedNum = parseFloat(num.toFixed());
  if (toFixedNum === 0) {
    return '0';
  }
  if (Math.abs(toFixedNum) >= 1) {
    const decimalNumber = num.toFixed(2, Decimal.ROUND_DOWN);
    const unitConverterNumber = needConverter
      ? unitConverter(parseFloat(decimalNumber))
      : parseFloat(decimalNumber);
    return numWithCommas(unitConverterNumber);
  } else {
    if (
      num.toFixed().split('.') &&
      num.toFixed().split('.').length > 1 &&
      num.toFixed().split('.')[1].length <= 6
    ) {
      return num.toFixed();
    } else {
      return num.toFixed(6, Decimal.ROUND_DOWN);
    }
  }
};

const unitConverter = (num: number) => {
  if (!num || isNaN(num)) {
    return num;
  }
  // 此处为防止字符串形式的数值进来，因为toFixed方法只能用于数值型数
  num = Number(num);
  if (Math.abs(num) > 100000000) {
    return (num / 1000000000).toFixed(2) + 'B';
  } else if (Math.abs(num) > 1000000) {
    return (num / 1000000).toFixed(2) + 'M';
  } else {
    return num.toFixed(2);
  }
};

const getShowNumber = (
  amount: number,
  decimals: number,
  digits: number = 6,
) => {
  const num = new Decimal(amount / 10 ** decimals);
  const toFixedNum = parseFloat(num.toFixed());
  if (toFixedNum === 0) {
    return '0';
  }
  let result;
  // 不四舍五入，直接截断
  if (Math.abs(toFixedNum) >= 1) {
    result = num.toFixed(2, Decimal.ROUND_DOWN);
  } else {
    result = num.toFixed(digits, Decimal.ROUND_DOWN);
  }
  if (result.indexOf('.') !== -1) {
    const values = result.split('.');
    const decimalPos = values[1].replace(/(0+)$/g, '');
    if (decimalPos) {
      return values[0] + '.' + decimalPos;
    } else {
      return values[0];
    }
  } else {
    return result;
  }
};

export {
  SUPPORTED_CHAINS,
  Chains,
  getChainIdByShortName,
  getCoboNameByShortName,
  getShortNameByCoboName,
  getChainIdByCoboName,
  getCoboNameByChainId,
  getDebankNameByShortName,
  getChainNameByCoboName,
  getShortNameByChainId,
  getShowNumber,
  getNumberFormDecimal,
  getTokenAndAllowanceNumber,
};
