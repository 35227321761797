import { useCallback, useEffect, useState } from 'react';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';

import { useConnectWallet } from '@/hooks/useConnectWallet';

export const useSwitchChain = () => {
  const { chain } = useNetwork();
  const { isConnected } = useAccount();
  const { switchNetworkAsync } = useSwitchNetwork();

  const [chainId, setChainId] = useState<number>();
  const [start, setStart] = useState(false);
  const [trigger, setTrigger] = useState<{
    reject: (error: any) => void;
    resolve: (value: number) => void;
  }>();

  const reset = useCallback(() => {
    setChainId(undefined);
    setStart(false);
    setTrigger(undefined);
  }, []);

  useEffect(() => {
    const _switch = async () => {
      if (!start || !switchNetworkAsync) return;
      if (chainId && chain?.id === chainId) {
        trigger?.resolve(chainId);
        reset();
        return;
      }
      try {
        const newChain = await switchNetworkAsync(chainId);
        if (newChain.id === chainId) {
          trigger?.resolve(chainId);
          reset();
          return;
        }
        trigger?.reject(new Error('Incorrect chain'));
        reset();
      } catch (e: any) {
        const msg = e.shortMessage || e.reason || e.details || e.message;
        trigger?.reject(msg ? new Error(msg) : e);
        reset();
      }
    };
    _switch();
  }, [chain?.id, chainId, reset, start, trigger, switchNetworkAsync]);

  useEffect(() => {
    return reset;
  }, [reset]);

  const run = useCallback(
    (chainId: number) => {
      return new Promise((resolve, reject) => {
        if (!isConnected) {
          reject('Please connect wallet');
          return;
        }
        if (chain?.id === chainId) {
          resolve(chain.id);
          return;
        }
        setTrigger({
          resolve,
          reject,
        });
        setStart(true);
        setChainId(chainId);
      });
    },
    [chain?.id, isConnected],
  );
  return {
    chain,
    switchChain: run,
  };
};

export const useConnectWithChain = (chainId: number) => {
  const { address, isConnected, connect } = useConnectWallet();
  const { chain, switchChain } = useSwitchChain();
  const [start, setStart] = useState(false);
  const [trigger, setTrigger] = useState<{
    resolve: (value: boolean) => void;
    reject: (error: any) => void;
  }>();

  const reset = useCallback(() => {
    setStart(false);
    setTrigger(undefined);
  }, []);

  useEffect(() => {
    const _connect = async () => {
      if (!start) return;
      if (isConnected && address) return;
      try {
        await connect();
      } catch (e) {
        trigger?.reject(e);
        reset();
      }
    };
    _connect();
  }, [address, connect, isConnected, reset, start, trigger]);

  useEffect(() => {
    const _switch = async () => {
      if (!start || !address || !isConnected) return;
      if (chain?.id === chainId) {
        trigger?.resolve(true);
        reset();
        return;
      }
      try {
        await switchChain(chainId);
        trigger?.resolve(true);
        reset();
      } catch (e) {
        trigger?.reject(e);
        reset();
      }
    };
    _switch();
  }, [
    address,
    chain?.id,
    chainId,
    isConnected,
    reset,
    start,
    switchChain,
    trigger,
  ]);

  return useCallback(async () => {
    return new Promise((resolve, reject) => {
      setStart(true);
      setTrigger({ resolve, reject });
    });
  }, []);
};
