import { atom } from 'jotai';

import { Module, TokenApprovalListItem } from '@/interfaces';

export const allModulesAtom = atom<Module[]>([]);

export const balanceAtom = atom(0);

export const moduleTxAtom = atom<string | null>(null);
export const moduleTxStatusAtom = atom<'pending' | 'success' | 'failed'>(
  'pending',
);

export const modalVisibleAtom = atom(false);

export const moduleMultisigTypeAtom = atom<'create' | 'enable'>('create');

export const tokenApprovalListAtom = atom<TokenApprovalListItem[]>([]);

export const updateModalDataAtom = atom<TokenApprovalListItem | null>(null);

export const updateModalVisibleAtom = atom(false);

export const batchRevokeModalVisibleAtom = atom(false);

export const pendingTxAtom = atom('');

export const upgradeSafeModalVisibleAtom = atom<boolean>(false);

export const upgradeSafeTxAtom = atom<string>('');
