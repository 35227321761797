import { useEffect } from 'react';
import { Space } from 'antd';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { listRoles } from '@/api';
import { CoboTable } from '@/components';
import { orgsAtom } from '@/globalAtom';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { OrganizationItem } from '@/interfaces';
import { updateRoles } from '@/store';
import { timestampToStr } from '@/utils/utils';

interface OrgTableProps {
  locale?: any | undefined;
  acceptInvite: (row: OrganizationItem) => void;
}

export function OrgTable(props: OrgTableProps) {
  const [organizationList] = useAtom(orgsAtom);
  const roles = useAppSelector(state => state.rolePage.roles);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (roles === null) {
      listRoles().then(data => {
        dispatch(updateRoles(data));
      });
    }
  }, [roles, dispatch]);

  const columns = [
    {
      title: t('common.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text: Text, record: OrganizationItem) =>
        `${record.name}#${record.display_id}`,
    },
    {
      title: t('common.role'),
      dataIndex: 'role',
      key: 'role',
      render: (role: number) => {
        const name = roles?.filter(item => item.value === role)[0].name;
        return <span>{name}</span>;
      },
    },
    {
      title: t('common.statusText'),
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => {
        let ele = <></>;
        switch (status) {
          case 0:
            ele = (
              <span className={styles['organization-table-text']}>Joined</span>
            );
            break;
          case 1:
            ele = (
              <span className={styles['organization-table-text-green']}>
                Got invited
              </span>
            );
            break;
          case 2:
            ele = (
              <span className={styles['organization-table-text']}>Created</span>
            );
            break;
        }
        return ele;
      },
    },
    {
      title: t('me.updatedTime'),
      dataIndex: 'update_time',
      key: 'update_time',
      render: (update_time: number) => {
        return timestampToStr(update_time);
      },
    },
    {
      title: t('common.action'),
      dataIndex: 'action',
      key: 'action',
      render: (text: Text, record: OrganizationItem, index: Number) =>
        record.status === 1 ? (
          <Space size="middle">
            <span
              style={{ cursor: 'pointer', color: '#4D84FF' }}
              onClick={() => {
                props.acceptInvite(record);
              }}
            >
              Accept
            </span>
          </Space>
        ) : (
          <span className={styles['org-table-action']}>-</span>
        ),
    },
  ];

  return (
    <CoboTable
      className={styles['organization-table']}
      pagination={false}
      rowKey={'id'}
      dataSource={organizationList || []}
      columns={columns}
      locale={props.locale}
    />
  );
}
