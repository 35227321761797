import { message } from 'antd';
import axios from 'axios';

import { getMyProfile } from '@/api/logic/auth';
import { listOrganizations } from '@/api/logic/organization';
import coboAtomStore, {
  globalUserInfoAtom,
  orgsSetterAtom,
} from '@/globalAtom';
import store, { updateLoadingColor, updateLoadingProgress } from '@/store';
import colors from '@/utils/colors';
import { CoboStorage } from '@/utils/storage';
import { request2faCode, requestSign } from '@/utils/utils';

const coboApi = axios.create({
  baseURL: process.env.REACT_APP_COBO_BASE_API,
  timeout: 60000,
});

const pendingRequest = new Map();
// generateReqKey：用于根据当前请求的信息，生成请求Key存放到map中作为当前请求的键；
// function generateReqKey(config: AxiosRequestConfig) {
//   const { method, url, params, data } = config;
//   return [method, url, JSON.stringify(params), JSON.stringify(data)].join('&');
// }
// addPendingRequest：用于把当前请求信息添加到pendingRequest对象中
// function addPendingRequest(config: AxiosRequestConfig) {
//   const requestKey = generateReqKey(config);
//   /* 这下面的部分不能去除的原因是因为在清空所有请求的时候需要用到cancel令牌,所以必须在添加请求的时候就创建一个CancelToken取消令牌用于之后clearPending函数清空请求 */
//   config.cancelToken = new axios.CancelToken((cancel: Canceler) => {
//     //创建当前请求放到map中
//     if (!pendingRequest.has(requestKey)) {
//       pendingRequest.set(requestKey, cancel);
//     }
//   });
// }

// function removePendingRequest(config: AxiosRequestConfig) {
//   const requestKey = generateReqKey(config);
//   if (pendingRequest.has(requestKey)) {
//     const cancel = pendingRequest.get(requestKey);
//     cancel(requestKey);
//     pendingRequest.delete(requestKey);
//   }
// }

//去除所有列表里的请求（用于跳转路由等需要清空请求的情况）
function clearPending() {
  //遍历map对象，用每个取消令牌逐一取消请求，起到清空所有请求的作用
  Object.entries(pendingRequest).forEach(([requestKey, cancelToken]) => {
    cancelToken(requestKey);
  });
  //清空map对象
  pendingRequest.clear();
}

//请求拦截器（发送之前）
coboApi.interceptors.request.use(
  config => {
    const sessionCode =
      CoboStorage.get<string>('SESSION-CODE') ||
      CoboStorage.get<string>('PREPARE-SESSION-CODE') ||
      null;
    if (sessionCode && config.headers) {
      config.headers['SESSION-CODE'] = sessionCode;
    }
    // config.headers['Authorization'] =
    //   `Basic ` + btoa(`668244659433079167:dtsSMTi3VU`);
    if (
      !config.params?.silence &&
      store.getState().global.loadingProgress === 0
    ) {
      store.dispatch(updateLoadingColor(colors.primary));
      store.dispatch(updateLoadingProgress(25));
      setTimeout(() => {
        if (
          store.getState().global.loadingProgress !== 0 &&
          store.getState().global.loadingProgress !== 100
        ) {
          store.dispatch(updateLoadingProgress(50));
        }
      }, 2000);
    }
    // todo cancel request 会导致部分功能卡死，暂时移除
    // if (!!config.params?.silence) {
    //   return config;
    // }
    // removePendingRequest(config); // 检查是否存在重复请求，若存在则取消已发的请求
    // addPendingRequest(config); // 把当前请求添加到pendingRequest对象中
    return config;
  },
  error => {
    // removePendingRequest(error.config || {}); // 从pendingRequest对象中移除请求
    return Promise.reject(error);
  },
);

//响应拦截器（发送之后）
coboApi.interceptors.response.use(
  response => {
    if (
      !response.config?.params?.silence &&
      store.getState().global.loadingProgress > 0
    ) {
      store.dispatch(updateLoadingProgress(100));
    }
    // removePendingRequest(response.config); // 从pendingRequest对象中移除请求
    return response.data.result;
  },
  async error => {
    if (
      error?.response?.status === 400 &&
      error?.response?.data?.extra?.need_otp
    ) {
      const cfg = error.response.config;
      const data = JSON.parse(cfg.data);
      if (!error?.response?.config?.params?.silence) {
        store.dispatch(updateLoadingProgress(100));
      }
      data.otp_code = await request2faCode();
      cfg.data = JSON.stringify(data);
      return coboApi.request(cfg);
    }
    // __CANCEL__的  不改loadingBar的颜色
    if (!error?.response?.config?.params?.silence && !error.__CANCEL__) {
      store.dispatch(updateLoadingColor(colors.error));
      store.dispatch(updateLoadingProgress(100));
    }
    if (
      error?.response?.status === 400 &&
      error?.response?.data?.extra?.need_signature
    ) {
      const cfg = error.response.config;
      if (cfg.coboRetry) {
        return Promise.reject(error);
      }
      if (!error?.response?.config?.params?.silence) {
        store.dispatch(updateLoadingProgress(100));
      }
      try {
        const { message, signature } = await requestSign();
        cfg.headers['COBO-SIWE-MESSAGE'] = JSON.stringify(message);
        cfg.headers['COBO-SIWE-SIGNATURE'] = signature;
        cfg.coboRetry = true;
        return coboApi.request(cfg);
      } catch (e) {
        return Promise.reject(e);
      }
    }
    if (!error?.response?.status) return Promise.reject(error);
    if ([401].includes(error.response.status)) {
      CoboStorage.remove('SESSION-CODE');
      coboAtomStore.set(globalUserInfoAtom, null);
    }
    if ([403].includes(error.response.status)) {
      getMyProfile().then(userInfo => {
        coboAtomStore.set(globalUserInfoAtom, userInfo);
      });
      if (error.response.config.url !== '/argus/orgs/list_organizations/') {
        listOrganizations().then(orgList => {
          coboAtomStore.set(orgsSetterAtom, orgList);
        });
      }
    }
    if ([400, 403, 500].includes(error.response.status)) {
      if (!error.response.config.params?.hideError) {
        const errorMsg = error.response.data.error_message || error.message;
        message.error(errorMsg);
      }
    }
    // removePendingRequest(error.config);
    return Promise.reject(error);
  },
);

export { coboApi, clearPending };
