import { coboApi } from '@/api/axios';
import { MemberInfo } from '@/interfaces';

export const inviteMember = async (
  email: string,
  org_id: string,
  role: number,
): Promise<void> => {
  await coboApi.post('/argus/orgs/invite_member/', {
    email,
    org_id,
    role,
  });
};

export const inviteMemberByAddress = async (
  address: string,
  org_id: string,
  role: number,
): Promise<void> => {
  await coboApi.post('/argus/orgs/invite_member_by_address/', {
    address,
    org_id,
    role,
  });
};

export const updateMember = async (
  org_id: string,
  user_id: string,
  new_role: number,
): Promise<void> => {
  await coboApi.post('/argus/orgs/update_member/', {
    org_id,
    user_id,
    new_role,
  });
};

export const removeMember = async (
  org_id: string,
  user_id: string,
): Promise<void> => {
  await coboApi.post('/argus/orgs/remove_member/', { org_id, user_id });
};

export const getMembersByAddress = async (
  org_id: string,
  address_list: string[],
): Promise<any> => {
  return await coboApi.post('/argus/orgs/get_members_by_address/', {
    org_id,
    address_list,
  });
};

export const acceptInvitation = async (org_id: string): Promise<void> => {
  await coboApi.post('/argus/users/accept_invitation/', { org_id });
};

export const batchAcceptInvitations = async (
  org_ids: string[],
): Promise<void> => {
  await coboApi.post('/argus/users/batch_accept_invitations/', { org_ids });
};

export const listRoles = async (): Promise<
  { value: number; name: string }[]
> => {
  return await coboApi.post('/argus/users/list_roles/');
};

export const listTeamAddresses = async (org_id: string): Promise<any[]> => {
  return await coboApi.post('/argus/orgs/org_address/', { org_id });
};

export const listTeamMembers = async (
  org_id: string,
  with_address = true,
): Promise<MemberInfo[]> => {
  const list: MemberInfo[] = await coboApi.post('/argus/orgs/org_member/', {
    org_id,
    with_address,
  });
  return list.map((item: MemberInfo) => {
    return {
      ...item,
      // addresses: item.addresses?.filter(
      //   (address: {
      //     address: string;
      //     in_use: boolean;
      //     label: string;
      //     status: 'active' | 'inactive';
      //   }) => address.status === 'active',
      // ),
    };
  });
};

export const addDelegateAddressForMember = async (
  org_id: string,
  address: string,
  user_id: string,
  label?: string,
): Promise<void> => {
  await coboApi.post('/argus/users/add_delegate_address_for_member/', {
    org_id,
    label,
    address,
    user_id,
  });
};

export const editDelegateAddressForOrg = async (
  org_id: string,
  address: string,
  user_id: string,
  label?: string,
  enabled?: boolean,
): Promise<void> => {
  await coboApi.post('/argus/orgs/edit_org_address/', {
    org_id,
    label,
    address_id: address,
    user_id,
    enabled,
  });
};
