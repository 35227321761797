export interface IBaseChain {
  name: string;
  rpcUrl: () => string;
  txDetailUrl: (hash: string) => string;
  addressUrl: (address: string) => string;
  tokenUrl: (address: string) => string;
  safeUrl: (address: string) => string;
  safeApiUrl: () => string;
  baseToken: string;
  baseTokenName: string;
  subsafeFactory?: string;
  safeWebBaseUrl: string;
  multiSendCallOnlyAddress: string;
}

export abstract class BaseChain implements IBaseChain {
  abstract name: string;
  abstract rpcUrl(): string;
  abstract txDetailUrl(hash: string): string;
  abstract addressUrl(address: string): string;
  abstract tokenUrl(address: string): string;
  abstract safeUrl(address: string): string;
  abstract safeApiUrl(): string;
  abstract baseToken: string;
  abstract baseTokenName: string;
  abstract subsafeFactory?: string;
  abstract safeWebBaseUrl: string;
  multiSendCallOnlyAddress: string =
    '0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761';
}
