import { useEffect, useState } from 'react';
import { usePublicClient } from 'wagmi';

export const useEstimateGas = (chainId: number, config: any) => {
  const publicClient = usePublicClient({ chainId });
  const [gas, setGas] = useState<bigint>();
  useEffect(() => {
    return () => setGas(undefined);
  }, []);
  useEffect(() => {
    const loadGas = async () => {
      if (!publicClient) return;
      try {
        // @ts-ignore
        const gasLimit = await publicClient.estimateContractGas({
          ...config,
        });
        setGas(gasLimit);
      } catch (e) {
        setGas(1_000_000n);
      }
    };
    loadGas();
  }, [config, publicClient]);
  return gas;
};
