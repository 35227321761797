import { useState } from 'react';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { SvgIcon } from '@/components';

const CONTENT = [
  {
    text: 'aboutArgus',
    link: 'https://www.cobo.com/web3/argus',
  },
  {
    text: 'guide',
    link: 'https://docs.cobo.com/cobo-argus/v/cobo-argus-v2-en-documentation/',
  },
  {
    text: 'techDoc',
    link: 'https://www.cobo.com/developers/overview/smart-contract-wallet/coboargus',
  },
];

const ICONS = [
  {
    img: require('@/assets/images/png-discord.png'),
    link: 'https://discord.com/invite/FaZwQ9WYpj',
    alt: 'discord',
  },
  {
    img: require('@/assets/images/png-Twitter.png'),
    link: 'https://twitter.com/Cobo_Global',
    alt: 'X',
  },
  {
    img: require('@/assets/images/png-github.png'),
    link: 'https://github.com/CoboGlobal/cobosafe',
    alt: 'github',
  },
  {
    img: require('@/assets/images/png-YouTube.png'),
    link: 'https://www.youtube.com/@coboglobal',
    alt: 'youtube',
  },
];

export const Support = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const content = (
    <div className={styles['support-popover']}>
      <div className={styles['support-popover-title']}>
        <h2>{t('common.support')}</h2>
        <SvgIcon
          name="ic_closed"
          className={styles['support-icon']}
          onClick={hide}
        />
      </div>
      <div className={styles['support-popover-content']}>
        <p>
          {t('common.contactUsAt')}
          <span className={styles['color-blue']}>argussupport@cobo.com</span>
        </p>
        <p> {t('common.helpText')}</p>
      </div>
    </div>
  );

  return (
    <div className={styles['support-container']} id="support-container">
      <div className={styles['support-icons']}>
        {ICONS.map(({ img, link, alt }) => {
          return (
            <img
              key={img}
              src={img}
              alt={alt}
              onClick={() => window.open(link)}
            />
          );
        })}
      </div>

      <div className={styles['style-text']}>
        {CONTENT.map(({ text, link }) => {
          return (
            <p
              className={styles['cursor']}
              key={text}
              onClick={() => window.open(link)}
            >
              {t(`auth.${text}`)}
            </p>
          );
        })}
        <Popover
          trigger={'click'}
          content={content}
          placement="top"
          arrow={{ pointAtCenter: true }}
          open={open}
          onOpenChange={handleOpenChange}
          getPopupContainer={() =>
            document.getElementById('support-container') as HTMLElement
          }
        >
          <p className={styles['cursor']}>{t('common.support')}</p>
        </Popover>
      </div>

      <p className={styles['copyright']}>
        © {new Date().getFullYear()} Cobo Argus, Inc. All rights reserved.
      </p>
    </div>
  );
};
