import React, { memo, useState } from 'react';
import classNames from 'classnames/bind';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { Modal } from '@/components';
import { importSafeModalVisibleAtom } from '@/components/Safe/ImportSafe/ImportSafeAtoms';
import { useGlobalOrgs, useGlobalSafe } from '@/hooks';
import {
  modalVisibleAtom,
  moduleMultisigTypeAtom,
} from '@/routes/SafeDetail/atoms';
import { CreateModuleTab } from '@/routes/SafeDetail/components/CreateModuleTab';
import { EnableModuleTab } from '@/routes/SafeDetail/components/EnableModuleTab';
import { ImportSafeView } from '@/routes/SafeDetail/components/ImportSafeView';
import { WalletConnectTab } from '@/routes/SafeDetail/components/WalletConnectTab';

const WalletConnectModal: React.FC = () => {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const [visible, setVisible] = useState(false);
  const { safeInfo, safeModule } = useGlobalSafe();
  const { orgId } = useGlobalOrgs();
  const setImportSafeModalVisible = useSetAtom(importSafeModalVisibleAtom);
  const setModuleVisible = useSetAtom(modalVisibleAtom);
  const setMultisigType = useSetAtom(moduleMultisigTypeAtom);
  const handleClose = () => {
    setVisible(false);
  };
  return (
    <>
      <div
        className={cx('walletConnect-wrapper')}
        onClick={() => {
          setVisible(true);
        }}
      >
        <span className={cx('walletConnect-icon')}></span>
        {t('safeInfo.walletConnect')}
      </div>

      <Modal
        title={t('safeInfo.walletConnect')}
        visible={visible && !!orgId}
        width={1120}
        footer={null}
        className={cx('walletConnect-modal')}
        onCloseBtnClick={handleClose}
        destroyOnClose={false}
      >
        {!safeInfo ? (
          <ImportSafeView
            onImport={() => {
              setVisible(false);
              setImportSafeModalVisible(true);
            }}
          />
        ) : null}
        {safeInfo && orgId && !safeModule ? (
          <CreateModuleTab
            safeInfo={{
              ...safeInfo,
              org_id: orgId,
            }}
            onVisibleChange={async (visible, status) => {
              setMultisigType('create');
              setVisible(false);
              setModuleVisible(visible);
            }}
          />
        ) : null}
        {safeInfo && safeModule && orgId && !safeModule.enabled ? (
          <EnableModuleTab
            safeInfo={{
              ...safeInfo,
              org_id: orgId,
            }}
            moduleInfo={safeModule}
            onVisibleChange={async (visible, status) => {
              setMultisigType('enable');
              setVisible(false);
              setModuleVisible(visible);
            }}
          />
        ) : null}
        {safeInfo && safeModule && safeModule.enabled ? (
          <WalletConnectTab />
        ) : null}
      </Modal>
    </>
  );
};

export default memo(WalletConnectModal);
