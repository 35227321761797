import { createSlice, PayloadAction } from '@reduxjs/toolkit';

let initialState: {
  roles: { value: number; name: string }[] | null;
} = {
  roles: null,
};

const slice = createSlice({
  name: 'rolePage',
  initialState,
  reducers: {
    updateRoles: (
      state,
      action: PayloadAction<{ value: number; name: string }[]>,
    ) => {
      state.roles = action.payload;
    },
    clearRole: state => {
      state.roles = null;
    },
  },
});
export const rolePageReducer = slice.reducer;
export const updateRoles = slice.actions.updateRoles;
export const clearRole = slice.actions.clearRole;
