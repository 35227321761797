import React, { useEffect, useMemo } from 'react';
import { useInterval } from 'ahooks';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  modalVisibleAtom,
  moduleTxAtom,
  moduleTxStatusAtom,
} from '../../atoms';
import commonStyles from '../common.module.less';
import styles from './index.module.less';
import { getEnableSafeModuleStatus, getSafeTxRecord } from '@/api';
import { getCoboNameByShortName } from '@/chains/utils';
import { Button } from '@/components';
import { useQuery } from '@/hooks';
import { Module } from '@/interfaces';

interface Props {
  safeInfo: {
    id: string;
    chain_id: string;
    name: string;
    address: string;
    org_id: string;
    default_cobo_module: string;
  };
  moduleInfo: Module;
  onVisibleChange: (visible: boolean, status?: string) => void;
}

export function EnableModuleTab({
  safeInfo,
  moduleInfo,
  onVisibleChange,
}: Props) {
  const query = useQuery();
  const commonCx = classNames.bind(commonStyles);
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const [enableModuleTx, setEnableModuleTx] = useAtom(moduleTxAtom);
  const [enableTxStatus, setEnableTxStatus] = useAtom(moduleTxStatusAtom);
  const [visible] = useAtom(modalVisibleAtom);
  useEffect(() => {
    if (!query.has('status')) {
      return;
    }
    const status = query.get('status');
    if (status === 'noEnabled') {
      onVisibleChange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const loadTx = async () => {
      const { pending_enable_safe_module_tx_hash } =
        await getEnableSafeModuleStatus(safeInfo.id, moduleInfo.id);
      setEnableModuleTx(pending_enable_safe_module_tx_hash);
    };
    loadTx();
  }, [moduleInfo.id, safeInfo.id, setEnableModuleTx]);
  useEffect(() => {
    if (enableTxStatus === 'pending' || !enableModuleTx || visible) return;
    onVisibleChange(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableModuleTx, enableTxStatus, visible]);
  useInterval(async () => {
    if (!safeInfo.chain_id || !enableModuleTx) return;
    const coboName = getCoboNameByShortName(safeInfo.chain_id);
    if (!coboName) return;
    const record = await getSafeTxRecord(coboName, enableModuleTx);
    const { status } = record;
    if (status === 50) {
      setEnableTxStatus('success');
      onVisibleChange(false, 'success');
      // failed; invalid; cancel;
    } else if ([40, 60, 70].includes(status)) {
      setEnableTxStatus('failed');
    }
  }, 5000);
  const txPending = useMemo(() => {
    return !!enableModuleTx;
  }, [enableModuleTx]);
  return (
    <div className={commonCx('tab-content-container')}>
      <div className={cx('label')}>
        {txPending
          ? t('safeInfo.enableModulePendingTip')
          : t('safeInfo.enableModuleTip')}
      </div>
      <div className={cx('module-wrapper')}>
        <div className={cx('module-title')}>{t('safeInfo.coboSafeModule')}</div>
        <div className={cx('module-content')}>
          <div className={cx('module-version')}>
            Version {moduleInfo.version}
          </div>
          <div className={cx('module-address')}>{moduleInfo.address}</div>
        </div>
      </div>
      <Button onClick={() => onVisibleChange(true)}>
        {txPending
          ? t('safeInfo.enablePendingBtn')
          : t('safeInfo.enableModuleBtn')}
      </Button>
    </div>
  );
}
