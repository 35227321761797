import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  chains: string[];
  activeTab: string;
} = {
  chains: [],
  activeTab: '1',
};

const slice = createSlice({
  name: 'strategies',
  initialState,
  reducers: {
    updateChains: (state, action: PayloadAction<string[]>) => {
      state.chains = action.payload;
    },
    updateStrategyActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
  },
});
export const strategiesReducer = slice.reducer;
export const updateChains = slice.actions.updateChains;
export const updateStrategyActiveTab = slice.actions.updateStrategyActiveTab;
