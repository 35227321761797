import { Signer } from '@ethersproject/abstract-signer';
import { Provider } from '@ethersproject/providers';
import { message } from 'antd';

import { getGnosisSafeInfo } from '@/api';
import { SafeTx } from '@/interfaces';
import { getSafeInfoFromGnosisSafe } from '@/sdk/safe';
import { getSafeSdk } from '@/utils/gnosis-safe';

export const getSafeInfo = async (chainId: number, safeAddress: string) => {
  return await getSafeInfoFromGnosisSafe(chainId, safeAddress);
};

export const getOwners = async (safeAddress: string, chainId: string) => {
  const safeInfo = await getGnosisSafeInfo(safeAddress, chainId);
  return safeInfo.owners.map(owner => owner.address);
};

export const getIsOwner = async (
  singerAddress: string,
  chainId: string,
  safeAddress: string,
): Promise<boolean> => {
  const safeInfo = await getGnosisSafeInfo(safeAddress, chainId);
  return safeInfo.owners
    .map(owner => owner.address.toLowerCase())
    .includes(singerAddress.toLowerCase());
};

export const signSafeTx = async (
  signer: Signer | Provider,
  senderAddress: string,
  safeAddress: string,
  chainId: number,
  safeTxData: SafeTx,
): Promise<string> => {
  try {
    const safeSdk = await getSafeSdk(signer, safeAddress);
    const safeTransactionData = {
      data: safeTxData.data,
      to: safeTxData.to,
      value: safeTxData.value,
      operation: safeTxData.operation,
      safeTxGas: safeTxData.safe_tx_gas,
      baseGas: safeTxData.base_gas,
      gasPrice: safeTxData.gas_price,
      gasToken: safeTxData.gas_token,
      refundReceiver: safeTxData.refund_receiver,
      nonce: safeTxData.safe_nonce,
    };
    const safeTransaction = await safeSdk.createTransaction({
      safeTransactionData,
    });
    const signedSafeTransaction = await safeSdk.signTransaction(
      safeTransaction,
      'eth_signTypedData',
    );
    return signedSafeTransaction.encodedSignatures();
  } catch (e: any) {
    message.error(e.reason || e.message || e.data?.message);
    throw e;
  }
};
