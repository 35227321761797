import { useCallback, useState } from 'react';
import { useAtom } from 'jotai';

import {
  batchGetModuleInfoV2,
  getBalanceList,
  getGnosisSafeInfo,
  getSafeInfo,
  getSafeRolesDetails,
  loadGnosisSafe,
} from '@/api';
import Chain from '@/chains';
import { getChainIdByShortName, getCoboNameByShortName } from '@/chains/utils';
import {
  globalGnosisSafeInfoAtom,
  globalLoadingAtom,
  globalSafeIdAtom,
  globalSafeInfoAtom,
  safeBalanceMapAtom,
  safeListAtom,
  safeModuleAtom,
  safeModuleMapAtom,
} from '@/globalAtom';
import { useGlobalOrgs } from '@/hooks/useGlobalOrg';
import { BindSafeInfo, Module } from '@/interfaces';
import {
  loadedSafeRolesAtom,
  safeRolesAtom,
} from '@/routes/SafeDetail/components/SafeRoleTab/atoms';

export const useGlobalSafe = () => {
  const { orgId, orgInfo } = useGlobalOrgs();
  const [chain, setChain] = useState<Chain | null>(null);
  const [safeInfo, setSafeInfo] = useAtom(globalSafeInfoAtom);
  const [safeId, setSafeId] = useAtom(globalSafeIdAtom);
  const [safeList, setSafeList] = useAtom(safeListAtom);
  const [safeBalanceMap, setSafeBalanceMap] = useAtom(safeBalanceMapAtom);
  const [coboModuleMap, setCoboModuleMap] = useAtom(safeModuleMapAtom);
  const [gnosisSafeInfo, setGnosisSafeInfo] = useAtom(globalGnosisSafeInfoAtom);
  const [safeModule, setSafeModule] = useAtom(safeModuleAtom);
  const [, setSafeLoading] = useAtom(globalLoadingAtom);
  const [, setLoadedSafeRoles] = useAtom(loadedSafeRolesAtom);
  const [, setSafeRoles] = useAtom(safeRolesAtom);

  const loadSafeChain = useCallback(() => {
    const chainId = getChainIdByShortName(safeInfo?.chain_id || '');
    if (!chainId) return;
    const chain = new Chain(chainId);
    setChain(chain);
  }, [safeInfo?.chain_id]);

  const loadGnosisSafeInfo = useCallback(async () => {
    if (!orgId || !safeInfo?.address || !safeInfo?.chain_id) return;
    try {
      setSafeLoading(true);
      const info = await getGnosisSafeInfo(safeInfo.address, safeInfo.chain_id);
      setGnosisSafeInfo(info);
    } finally {
      setSafeLoading(false);
    }
  }, [
    orgId,
    safeInfo?.address,
    safeInfo?.chain_id,
    setGnosisSafeInfo,
    setSafeLoading,
  ]);

  const loadModuleInfo = useCallback(
    async (address: string, chain_id: string) => {
      if (!address || !chain_id) return;
      const coboName = getCoboNameByShortName(chain_id);
      if (!coboName) throw new Error('incorrect chain');
      try {
        setSafeLoading(true);
        const modules = await batchGetModuleInfoV2([
          { chain: coboName, address: address },
        ]);
        if (modules.length === 0 || modules[0].modules.length === 0) {
          setSafeModule({
            id: '',
            version: '',
            factory: '',
            address: '',
            enabled: false,
          });
          return;
        }
        const currentModule = modules[0].modules[0];
        return currentModule;
      } finally {
        setSafeLoading(false);
      }
    },
    [setSafeModule, setSafeLoading],
  );

  const loadSafeInfo = useCallback(
    async () => {
      if (!orgId || !orgInfo?.default_safe_id) return;
      try {
        setSafeLoading(true);
        const info = await getSafeInfo(orgInfo.default_safe_id);
        if (!info) return;
        setSafeId(orgInfo.default_safe_id);
        setSafeInfo(info);
        setSafeModule(undefined);
        const moduleInfo = await loadModuleInfo(info.address, info.chain_id);
        setSafeModule(moduleInfo);
      } finally {
        setSafeLoading(false);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgId, orgInfo?.default_safe_id, loadModuleInfo],
  );

  const loadSafes = useCallback(async (): Promise<
    BindSafeInfo[] | undefined
  > => {
    if (!orgId) return;
    if (safeList !== undefined) return;
    try {
      setSafeLoading(true);
      const result = await loadGnosisSafe(orgId);
      setSafeList(result);
      return result;
    } finally {
      setSafeLoading(false);
    }
  }, [safeList, setSafeList, orgId, setSafeLoading]);

  const loadBalance = useCallback(
    async (safeList: BindSafeInfo[]) => {
      if (!orgId) return;
      const safeIds = safeList
        .filter(safe => safe.chain_id !== 'gor')
        .map(item => item.id);
      const balances = await getBalanceList(safeIds, orgId);
      const _safeBalanceMap = new Map<string, number>();
      for (const balance of balances) {
        const currentSafe = safeList.find(item => item.id === balance.safe_id);
        if (currentSafe)
          _safeBalanceMap.set(
            `${currentSafe.chain_id}:${currentSafe.address}`,
            balance.balance || 0,
          );
      }
      setSafeBalanceMap(_safeBalanceMap);
    },
    [setSafeBalanceMap, orgId],
  );

  // 获取modules接口
  const batchGetModuleInfos = useCallback(async () => {
    if (!safeList) return;
    const _coboModuleMap = new Map<string, Module[]>();
    safeList.forEach(item =>
      _coboModuleMap.set(
        `${getCoboNameByShortName(item.chain_id)}:${item.address}`,
        [],
      ),
    );
    batchGetModuleInfoV2(
      Array.from(_coboModuleMap.keys()).map(item => {
        const [chainStr, addressStr] = item.split(':');
        return {
          chain: chainStr || '',
          address: addressStr,
        };
      }),
    ).then(infos => {
      infos.forEach(item => {
        _coboModuleMap.set(`${item.chain}:${item.address}`, item.modules);
      });
      setCoboModuleMap(_coboModuleMap);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(safeList)]);

  const clearSafe = useCallback((clearSafeList = false) => {
    setSafeInfo(undefined);
    setSafeModule(undefined);
    setGnosisSafeInfo(undefined);
    setChain(null);
    setSafeId('');
    clearSafeList && setSafeList(undefined);
    clearSafeList && setSafeBalanceMap(new Map());
    clearSafeList && setCoboModuleMap(new Map());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSafeRolesDetail = useCallback(async () => {
    const moduleId = safeModule?.id;
    const safeId = safeInfo?.id;
    if (!safeId || !moduleId) return;
    const resp = await getSafeRolesDetails(safeId, moduleId);
    setLoadedSafeRoles(resp);
    setSafeRoles(resp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoadedSafeRoles, setSafeRoles, safeModule?.id]);

  return {
    safeId,
    safeInfo,
    safeList,
    safeBalanceMap,
    coboModuleMap,
    gnosisSafeInfo,
    safeModule,
    setSafeInfo,
    setSafeId,
    setSafeModule,
    chain,
    loadGnosisSafeInfo,
    batchGetModuleInfos,
    loadBalance,
    loadSafes,
    setSafeList,
    loadSafeInfo,
    clearSafe,
    loadSafeChain,
    loadSafeRolesDetail,
  };
};
