import { useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';

import { Strategies } from '../Strategies';
import styles from './index.module.less';
import { getSupportedChains } from '@/api';
import { useAppDispatch } from '@/hooks';
import { updateChains } from '@/store';

export function StrategyMart() {
  const cx = classNames.bind(styles);
  const { pathname } = useLocation();
  const strategyType = useMemo(
    () => (pathname === '/my-portfolio/farming' ? '2' : '5'),
    [pathname],
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    const loadChains = async () => {
      const chains = await getSupportedChains(strategyType);
      dispatch(updateChains(chains));
    };
    loadChains();
  }, [dispatch, strategyType]);

  return (
    <div className={styles['strategy-mart']}>
      <div className={cx('strategy-tabs')}>
        <Strategies strategyType={strategyType} />
      </div>
    </div>
  );
}
