import classNames from 'classnames/bind';

import styles from './index.module.less';

export function UpgradeNotice() {
  const cx = classNames.bind(styles);
  const isSandbox = process.env.REACT_APP_ENV === 'sandbox';
  if (!isSandbox) return null;
  return (
    <div className={cx('notice')}>
      Note: You're currently using Argus Sandbox environment.
    </div>
  );
  // const { t } = useTranslation();
  // const cx = classNames.bind(styles);
  // const [showNotice, setShowNotice] = useAtom(showNoticeAtom);
  // const [orgId] = useAtom(orgIdAtom);
  //
  // useShowUpgradeNotice(orgId || null);
  //
  // const hideNotice = () => {
  //   if (!orgId) return;
  //   CoboStorage.set(getUpgradeKey(orgId), true);
  //   setShowNotice(false);
  // };
  //
  // return showNotice ? (
  //   <div className={cx('notice')}>
  //     <img
  //       className="w-7 invisible"
  //       alt="notice"
  //       src={require('@/assets/icons/ic-delete-yellow.png')}
  //     />
  //     <div className="flex flex-row items-center">
  //       <img
  //         className="w-6 cursor-pointer h-6 mr-2.5"
  //         alt="notice"
  //         src={require('@/assets/icons/ic-notice-2.png')}
  //       />
  //       <span className="mr-2.5">{t('common.upgradeNotice')}</span>
  //       <a
  //         className="text-blue underline font-ubBold cursor-pointer"
  //         href="https://www.cobo.com/post/cobo-argus-new-design-is-officially-live"
  //         target="_blank"
  //         rel="noreferrer"
  //       >
  //         {t('common.learnMore')}
  //       </a>
  //     </div>
  //     <img
  //       className="cursor-pointer w-7"
  //       alt="notice"
  //       src={require('@/assets/icons/ic-delete-yellow.png')}
  //       onClick={() => {
  //         hideNotice();
  //       }}
  //     />
  //   </div>
  // ) : null;
}
