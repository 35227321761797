import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { Button } from '@/components';
import Box from '@/components/Common/Box';

interface Props {
  buttons: {
    type: 'primary' | 'white';
    onClick: () => void;
    label: string;
    btnClassName?: string;
    className?: string;
    text?: string;
  }[];
  title: string;
  message: string;
  address: string;
}

export default function SuccessContent({
  buttons,
  address,
  title,
  message,
}: Props) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  return (
    <div className={cx('content')}>
      <div className={cx('success-img')} />
      <Box height={16} />
      <div className={cx('title')}>
        {title || t('auth.successVerification')}
      </div>
      <Box height={12} />
      <div className={cx('subtitle')}>
        {message || t('auth.successVerifyNotice')}
      </div>
      <Box height={20} />
      <div className={cx('address')}>{address}</div>
      {buttons.length > 0 ? <Box height={32} /> : null}
      {buttons.map((button, index) => {
        const isLast = index === buttons.length - 1;
        const current = (
          <>
            {button.text && (
              <span className={cx('btn-text')}>{button.text}</span>
            )}
            <Button
              onClick={() => button.onClick()}
              type={button.type}
              className={cx('btn-wrapper', button.className)}
              btnClassName={button.btnClassName}
            >
              {button.label}
            </Button>
          </>
        );
        if (isLast) {
          return current;
        }
        return [current, <Box height={16} />];
      })}
      <Box height={32} />
    </div>
  );
}
