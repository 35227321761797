import { message } from 'antd';
import BigNumber from 'bignumber.js';
import i18n from 'i18next';
import { debounce } from 'lodash-es';
import { SHA3 } from 'sha3';
import Web3 from 'web3';
import { AbiInput } from 'web3-utils';

import coboAtomStore, { globalSignPromise } from '@/globalAtom';
import {
  ConfirmModal,
  Module,
  ServiceDetailType,
  ServiceType,
  SignMessage,
  StrategyService,
} from '@/interfaces';
import store, {
  updateCoboModalRejection,
  updateCoboModalResolver,
  updateCodeModalVisible,
  updateConfirmModal,
} from '@/store';
import { copyToClipboard } from '@/utils/clipboard';

export const objectToString = (obj: any) => {
  let res: string[] = [];
  // eslint-disable-next-line array-callback-return
  Object.keys(obj).map(key => {
    res.push(`${key}: ${obj[key]}`);
  });
  return res.join('\n');
};

export const encodePassword = (value: string): string => {
  let hash = new SHA3(256);
  hash.update('cobo-argus');
  hash.update(value);
  return hash.digest('hex');
};

export const filterRoutes = (routes: any[], orgInfo: any): any[] => {
  return routes.filter(item => {
    if (!item.permRequired) return true;
    if (!orgInfo) return true;
    return item.permRequired.includes(orgInfo.role);
  });
};

export const isRole = (orgInfo: any, role: 0 | 1 | 2): boolean => {
  // 0 admin 1 operator 2 viewer
  return orgInfo?.role === role;
};

export const request2faCode = async (): Promise<string> => {
  store.dispatch(updateCodeModalVisible(true));
  return new Promise((resolve, reject) => {
    store.dispatch(updateCoboModalResolver(resolve));
    store.dispatch(updateCoboModalRejection(reject));
  });
};

export const requestSign = async (): Promise<{
  message: SignMessage;
  signature: string;
}> => {
  if (coboAtomStore.get(globalSignPromise))
    throw new Error(
      'You have pending sign request in your wallet. Please cancel the request and try again.',
    );
  return new Promise((resolve, reject) => {
    coboAtomStore.set(globalSignPromise, { resolve, reject });
  });
};

export const timestampToStr = (timestamp: number | string): string => {
  const timestampStr = `${timestamp}`;
  timestamp = Number(timestamp);
  if (isNaN(timestamp)) {
    return timestampStr;
  }
  const date = new Date(timestamp); //如果date为10位需要乘1000
  const Y = date.getFullYear() + '-';
  const M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-';
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
  const h =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  const m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    ':';
  const s =
    date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
};

export const showConfirmModal = (props: ConfirmModal): void => {
  store.dispatch(
    updateConfirmModal({
      ...props,
    }),
  );
};

export const hideConfirmModal = (): void => {
  store.dispatch(
    updateConfirmModal({
      confirmModalVisible: false,
      confirmModalTitle: '',
      confirmModalElement: '',
      onConfirm: () => {},
      onCancel: () => {},
    }),
  );
};

export const isZeroAddress = (address: string): boolean => {
  return address === '0x0000000000000000000000000000000000000000';
};

export const convertVersionToNumber = (v: string) => {
  try {
    const arr = v.split('.');
    return Number(arr[0]) * 100 + Number(arr[1]) * 10 + Number(arr[2]);
  } catch {
    return 0;
  }
};

export const getMaxVersion = (modules: Module[]) => {
  let max_version = '0.0.0';
  modules.forEach(item => {
    if (
      convertVersionToNumber(item.version) > convertVersionToNumber(max_version)
    ) {
      max_version = item.version;
    }
  });
  return max_version;
};

export const truncateAddress = (address: string) => {
  /*
     input: 0x2CEF12345678912345678912345678915C5
     return: 0x2CEF...15C5
  */
  if (address.length < 10) {
    return address;
  }
  let length = address.length;
  return (
    address.substring(0, 6) + '...' + address.substring(length - 4, length)
  );
};

export const truncateString = (
  str: string,
  maxLength: number,
  leftLength?: number,
  rightLength?: number,
) => {
  if (str.length > maxLength) {
    return (
      str.substring(0, leftLength || 4) +
      '...' +
      str.substring(str.length - (rightLength || 4), str.length)
    );
  }
  return str;
};

export const duplicatesArr = (arr: string[]) => {
  let result: string[] = [];
  arr.forEach(function (elem) {
    if (
      arr.indexOf(elem) !== arr.lastIndexOf(elem) &&
      result.indexOf(elem) === -1
    ) {
      result.push(elem);
    }
  });
  return result;
};

// 对象数组根据key去重
export const uniqueArrayByKey = (arr: any[], uniId: string) => {
  const res = new Map();
  return arr.filter(item => !res.has(item[uniId]) && res.set(item[uniId], 1));
};

// 取交集
export function intersect(list: string[][]): string[] {
  if (list.length === 0) {
    return [];
  }
  if (list.length === 1) {
    return list[0];
  }
  return list.reduce((prev, next) => {
    return prev.filter(i => next.indexOf(i) > -1);
  });
}

// 取差集
export function diff(a: string[], b: string[]): string[] {
  return a.concat(b).filter(v => !a.includes(v) || !b.includes(v));
}

export function parseSolidityArgs(inputs: AbiInput[], eventArgs: any): any {
  const parsedObject: any = {};
  for (const input of inputs) {
    const { name } = input;
    parsedObject[name] = parseSolidityValue(input, eventArgs[name]);
  }
  return parsedObject;
}

export function parseSolidityValue(input: AbiInput, argValue: any): any {
  if (input.type === 'address') {
    return argValue;
  }
  if (input.type === 'bool') {
    return argValue;
  }
  if (input.type.includes('bytes')) {
    return argValue;
  }
  if (input.type.includes('uint')) {
    return new BigNumber(argValue);
  }
  if (input.type === 'tuple') {
    return parseSolidityTuple(input, argValue);
  }
  throw new Error(`Unknown arg type ${input.type}`);
}

export function parseSolidityTuple(input: any, argValue: any): any {
  return parseSolidityArgs(input.components, argValue);
}

export function validateTxHash(address: string): boolean {
  return /^0x([A-Fa-f0-9]{64})$/.test(address);
}

export async function isContract(
  web3: Web3,
  address: string,
): Promise<boolean> {
  // await web3.eth.getCode(address) will return 0x randomly
  return Web3.utils.isAddress(address);
}

export function asyncDebounce<T>(func: any, wait: number): () => Promise<T> {
  const debounced = debounce((resolve, reject, args) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, wait);
  return (...args: any) =>
    new Promise<T>((resolve, reject) => {
      debounced(resolve, reject, args);
    });
}

export function removeTrailingZeros(value: string): string {
  return value.replace(/(\.\d*?[1-9])0+$/, '$1').replace(/\.0+$/, '');
}

export function parseNumberString(value: string): string {
  if (!value) return '';
  const splits = value.split('.');
  let [split1, split2] = splits;
  const newSplit1 = new BigNumber(split1).toFixed();
  const newSplits = split2
    ? [`${newSplit1}`, `${split2}`]
    : value.includes('.')
    ? [`${newSplit1}`, '']
    : [`${newSplit1}`];
  return newSplits.join('.');
}

export const copyStr = (value: string) => {
  copyToClipboard({ value })
    .then(res => {
      message.success(i18n.t('common.copySuccess'));
    })
    .catch(e => {
      message.error(`${i18n.t('common.copyFailed')} ${e.message}`);
    });
};

export function getQueryVariable(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

export const serviceSorts = (a: StrategyService, b: StrategyService) => {
  const sorts = [
    ServiceDetailType.CLAIM,
    ServiceDetailType.FARMING,
    ServiceDetailType.WITHDRAW,
    ServiceDetailType.LEVERAGE,
    ServiceDetailType.TRADING,
    ServiceType.SINGLE_SIGN,
  ];
  return (
    sorts.indexOf(
      a.service_type === ServiceType.SINGLE_SIGN
        ? ServiceType.SINGLE_SIGN
        : a.detail_type,
    ) -
    sorts.indexOf(
      b.service_type === ServiceType.SINGLE_SIGN
        ? ServiceType.SINGLE_SIGN
        : b.detail_type,
    )
  );
};

export const getAppVersion = () => {
  // todo get app version from package.json. remember to change here and package.json version after new release.
  return '1.0.7';
};

export const getUpgradeKey = (orgId: string) => {
  const version = getAppVersion();
  return `HIDE_UPGRADE_NOTICE-${version}-${orgId}`;
};
