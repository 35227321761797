import { useEffect, useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { globalUserInfoAtom, orgIdAtom } from '@/globalAtom';

const useSetAttr = (key: string, value?: string | null) => {
  useEffect(() => {
    const body = document.body;
    if (value) {
      const valueAttr =
        body.getAttributeNode(key) || document.createAttribute(key);
      valueAttr.value = btoa(value);
      body.setAttributeNode(valueAttr);
    } else {
      const valueAttr = body.getAttributeNode(key);
      if (valueAttr) {
        body.removeAttributeNode(valueAttr);
      }
    }
  }, [key, value]);
};

export const useDataScript = () => {
  const userInfo = useAtomValue(globalUserInfoAtom);
  const uid = useMemo(() => userInfo?.user_id, [userInfo?.user_id]);
  const orgId = useAtomValue(orgIdAtom);
  useSetAttr('uid', uid);
  useSetAttr('oid', orgId);
  useEffect(() => {
    const isSandbox = process.env.REACT_APP_ENV === 'sandbox';
    const script = document.createElement('script');
    script.src = isSandbox
      ? 'https://collect.sandbox.cobo.com/sdk/202309217382.js'
      : 'https://static-img.cobo.com/sdk/202309214825.js';
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
};
