import React, { useEffect, useMemo } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { collect } from '@/api/axios/collect';
import {
  createOrganization,
  preCreateOrganization,
} from '@/api/logic/organization';
import { CoboSelect, Modal } from '@/components';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { updateOrgSizes, updateOrgTypes } from '@/store';

interface Props {
  createModalVisible: boolean;
  onSuccess: (label: string) => void;
  closeModal: () => void;
}

export function CreateOrganizationModal({
  createModalVisible,
  onSuccess,
  closeModal,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const orgSizes = useAppSelector(state => state.orgPage.orgSizes);
  const orgTypes = useAppSelector(state => state.orgPage.orgTypes);

  const [form] = Form.useForm();

  const name = Form.useWatch('name', form);
  const size = Form.useWatch('size', form);
  const type = Form.useWatch('type', form);

  const fieldsValid = useMemo(() => {
    return name && size && type;
  }, [name, size, type]);

  useEffect(() => {
    if (!createModalVisible) return;
    if (orgSizes.length === 0 || orgTypes.length === 0) {
      preCreateOrganization().then(({ size_choice, type_choice }) => {
        dispatch(updateOrgSizes(size_choice));
        dispatch(updateOrgTypes(type_choice));
      });
    }
  }, [createModalVisible, dispatch, orgSizes, orgTypes]);

  useEffect(() => {
    if (orgSizes.length > 0 && orgTypes.length > 0) {
      form.resetFields();
    }
  }, [form, orgSizes.length, orgTypes.length]);

  const onClose = () => {
    form.resetFields();
    closeModal();
  };

  return (
    <Modal
      className={styles['create-org-modal']}
      title={t('guidance.createOrgTitle')}
      visible={createModalVisible}
      onCloseBtnClick={onClose}
      zIndex={1100}
      btnProps={[
        {
          text: t('common.submit'),
          primary: true,
          onClick: async () => {
            try {
              const org = await createOrganization(name, type, size);
              onSuccess(
                t('guidance.createOrgSuccess', {
                  orgName: `${org.name}#${org.display_id}`,
                }),
              );
              onClose();
            } finally {
              collect({
                cid: 'Click_OrgCreate_Submit',
              });
            }
          },
          disabled: !fieldsValid,
        },
      ]}
      width="680px"
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{
          name: '',
          size: orgSizes && orgSizes.length > 0 ? orgSizes[0] : '',
          type: orgTypes && orgTypes.length > 0 ? orgTypes[0] : '',
        }}
        autoComplete="off"
        layout={'vertical'}
        form={form}
        validateTrigger={'onChange'}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: t('guidance.orgNameError'),
            },
          ]}
        >
          <Input allowClear={true} name="name" maxLength={50} showCount />
        </Form.Item>

        <Form.Item
          label="Size"
          name="size"
          rules={[{ required: true, message: t('guidance.orgSizeError') }]}
        >
          <CoboSelect
            options={orgSizes}
            defaultValue={orgSizes && orgSizes.length > 0 ? orgSizes[0] : ''}
            onChange={e => {}}
          />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true, message: t('guidance.orgTypeError') }]}
        >
          <CoboSelect
            defaultValue={orgTypes && orgTypes.length > 0 ? orgTypes[0] : ''}
            options={orgTypes}
            onChange={e => {}}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
