import { Signer } from '@ethersproject/abstract-signer';
import { Provider } from '@ethersproject/providers';
import Safe from '@safe-global/safe-core-sdk';
import EthersAdapter from '@safe-global/safe-ethers-lib';
import { ethers } from 'ethers';

export const getSafeSdk = async (
  signer: Provider | Signer,
  safeAddress: string,
): Promise<Safe> => {
  const ethAdapter = new EthersAdapter({
    ethers,
    signerOrProvider: signer,
  });
  return await Safe.create({
    ethAdapter: ethAdapter,
    safeAddress: safeAddress,
  });
};
