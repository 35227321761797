import { useCallback, useMemo } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { listOrganizations, setDefaultSafe, switchOrg } from '@/api';
import {
  globalLoadingAtom,
  orgIdAtom,
  orgsAtom,
  orgsSetterAtom,
  safeListAtom,
} from '@/globalAtom';
import { MyOrganizationStatus } from '@/utils/constants';
import { isRole } from '@/utils/utils';

export const useGlobalOrgs = () => {
  const navigate = useNavigate();
  const [orgId, setOrgId] = useAtom(orgIdAtom);
  const [, setSafeList] = useAtom(safeListAtom);
  const setOrgs = useSetAtom(orgsSetterAtom);
  const [organizations] = useAtom(orgsAtom);
  const [, setGlobalLoading] = useAtom(globalLoadingAtom);
  const loadOrgs = useCallback(async () => {
    const orgList = await listOrganizations();
    setOrgs(orgList);
  }, [setOrgs]);

  const setDefaultSafeId = useCallback(
    async (safeId: string, orgId: string, cb?: () => void) => {
      try {
        setGlobalLoading(true);
        await setDefaultSafe(safeId, orgId);
        // 重新获取orgs
        await loadOrgs();
        cb && cb();
      } finally {
        setGlobalLoading(false);
      }
    },
    [loadOrgs, setGlobalLoading],
  );

  const onOrgChange = useCallback(
    async (newOrgId: string) => {
      if (newOrgId !== orgId) {
        await switchOrg(newOrgId);
        setOrgId(newOrgId);
        navigate('/');
        setSafeList(undefined);
      }
    },
    [orgId, setOrgId, navigate, setSafeList],
  );

  const showOrganizations = useMemo(() => {
    if (!organizations) return [];
    return organizations.filter(o =>
      [MyOrganizationStatus.JOINED, MyOrganizationStatus.CREATED].includes(
        o.status,
      ),
    );
  }, [organizations]);

  const orgInfo = useMemo(() => {
    return showOrganizations && orgId
      ? showOrganizations.find(item => item.id === orgId)
      : null;
  }, [showOrganizations, orgId]);

  const canEdit = useMemo(() => orgInfo && !isRole(orgInfo, 2), [orgInfo]);

  const clearOrg = useCallback(() => {
    setOrgId('');
    setOrgs(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    organizations,
    showOrganizations,
    orgInfo,
    onOrgChange,
    setDefaultSafeId,
    orgId,
    loadOrgs,
    clearOrg,
    canEdit,
  };
};
