import { account } from './account';
import { auth } from './auth';
import { authorization } from './authorization';
import { bots } from './bots';
import { common } from './common';
import { guidance } from './guidance';
import { investment } from './investment';
import { me } from './me';
import { orgSettings } from './orgSettings';
import { portfolio } from './portfolio';
import { safe } from './safe';
import { safeInfo } from './safeInfo';
import { safeManagement } from './safeManagement';
import { teamManagement } from './teamManagement';
import { transaction } from './transaction';
import { walletconnect } from './walletconnect';

const en = {
  common: common,
  account: account,
  auth: auth,
  authorization: authorization,
  bots: bots,
  portfolio: portfolio,
  investment: investment,
  safe: safe,
  safeManagement: safeManagement,
  safeInfo: safeInfo,
  teamManagement: teamManagement,
  orgSettings: orgSettings,
  guidance: guidance,
  walletconnect: walletconnect,
  me: me,
  transaction: transaction,
};
export default en;
