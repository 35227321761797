import { Tooltip } from 'antd';
import classNames from 'classnames/bind';

import styles from './index.module.less';
import tips from '@/assets/icons/ic_tips.png';
import { Button } from '@/components';
import { useAppSelector } from '@/hooks';
import { GoSafeAppBtn } from '@/routes/SafeDetail/goSafeApp';

interface SettingsButton {
  name: string;
  url?: string;
  urlType?: 'safeApp' | 'normal';
  classname?: string;
  action?: () => void;
  goSafeApp?: boolean;
}
interface Props {
  title: string;
  buttons: SettingsButton[];
  children: React.ReactNode;
  tooltip?: string;
  contentClassName?: string;
}
export function SettingItem({
  title,
  buttons,
  children,
  tooltip,
  contentClassName,
}: Props) {
  const cx = classNames.bind(styles);
  const safeInfo = useAppSelector(state => state.safeDetail.safeInfo);
  return (
    <div className={styles['settings-item']}>
      <div className={styles['settings-header']}>
        <div className={styles['settings-header-left']}>
          <h1 className={styles['settings-title']}>{title}</h1>
          {tooltip ? (
            <Tooltip placement={'bottom'} title={tooltip}>
              <img
                className={styles['settings-header-tips']}
                src={tips}
                alt="tips"
              />
            </Tooltip>
          ) : null}
        </div>
        <div className={styles['button-box']}>
          {buttons.map((button, index) => {
            return button.goSafeApp ? (
              <GoSafeAppBtn
                url={button.url || ''}
                urlType={button.urlType || 'safeApp'}
                key={index}
                className={cx(
                  button.classname,
                  styles['settings-header-button'],
                )}
                btnType="button"
                chainId={safeInfo?.chain_id || ''}
                address={safeInfo?.address || ''}
                moduleAddress={'upgrade'}
              >
                {button.name}
              </GoSafeAppBtn>
            ) : (
              <Button
                onClick={button.action || (() => {})}
                key={index}
                btnClassName={cx(
                  button.classname,
                  styles['settings-header-button'],
                )}
              >
                {button.name}
              </Button>
            );
          })}
        </div>
      </div>
      <div className={cx('settings-content', contentClassName)}>{children}</div>
    </div>
  );
}
