import React from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { rabbyImportVisible } from './atoms';
import { getChainIdByShortName } from '@/chains/utils';
import { Button, Modal } from '@/components';
import { globalSafeInfoAtom, safeModuleAtom } from '@/globalAtom';

export const RabbyImport = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useAtom(rabbyImportVisible);
  const safeInfo = useAtomValue(globalSafeInfoAtom);
  const moduleInfo = useAtomValue(safeModuleAtom);
  // @ts-ignore
  const installedRabby = !!window.rabby;
  const content = (
    <div className="flex flex-col pb-[32px]">
      <div className="mb-[24px] text-darkFont font-ubMd font-medium text-[16px] leading-[24px]">
        {t('safeInfo.importToRabbyNotice')}
      </div>
      <div className="flex flex-row mb-[32px]">
        <div className="flex-1 flex flex-col bg-blueBg py-[16px] px-[24px] rounded-[8px]">
          <div className="text-orange font-ubBold font-bold text-[14px] leading-[20px] mb-[8px]">
            Step 1:
          </div>
          <div className="text-darkFont text-[16px] leading-[24px] font-ubBold font-bold">
            {t('safeInfo.connectRabby')}
          </div>
          <div className="w-full flex items-center justify-center mt-[40px]">
            <div className="bg-[url('~@/assets/images/import_rabby_step1.png')] bg-cover bg-no-repeat w-[252px] h-[166px] " />
          </div>
        </div>
        <div className="flex-1 flex flex-col bg-blueBg py-[16px] px-[24px] rounded-[8px] ml-[16px]">
          <div className="text-orange font-ubBold font-bold text-[14px] leading-[20px] mb-[8px]">
            Step 2:
          </div>
          <div className="text-darkFont text-[16px] leading-[24px] font-ubBold font-bold">
            {t('safeInfo.switchRabby')}
          </div>
          <div className="w-full flex items-center justify-center mt-[12px]">
            <div className="bg-[url('~@/assets/images/import_rabby_step2.png')] bg-cover bg-no-repeat w-[188px] h-[282px] " />
          </div>
        </div>
      </div>
      <div className="flex flex-row">
        <Button
          type="text"
          className="flex-1"
          onClick={() => setVisible(false)}
        >
          {t('common.cancel')}
        </Button>
        <Button
          type="primary"
          className="flex-1 ml-[16px]"
          onClick={() => {
            // @ts-ignore
            window.rabby.request({
              method: 'wallet_importAddress',
              params: [
                {
                  type: 'Cobo Argus',
                  address: moduleInfo?.address,
                  chainId: getChainIdByShortName(safeInfo?.chain_id || ''),
                },
              ],
            });
          }}
        >
          {t('safeManagement.import')}
        </Button>
      </div>
    </div>
  );
  const installRabbyContent = (
    <div className="flex flex-col pb-[32px]">
      <div className="mb-[24px] text-darkFont font-ubMd font-medium text-[16px] leading-[24px]">
        {t('safeInfo.downloadRabbyNotice')}
      </div>
      <div className="flex flex-row items-center justify-between px-[24px] py-[14px] rounded-[8px] bg-blueBg mb-[16px]">
        <div className="flex flex-col justify-between">
          <div className="text-orange font-ubBold font-bold text-[14px] leading-[20px] mb-[8px]">
            Step 1:
          </div>
          <div className="text-darkFont text-[16px] leading-[24px] font-ubBold font-bold">
            {t('safeInfo.downloadRabby')}
          </div>
        </div>
        <div className="bg-[url('~@/assets/icons/ic_rabby_bg.png')] bg-cover bg-no-repeat w-[56px] h-[56px]" />
      </div>
      <div className="flex flex-col px-[24px] py-[14px] rounded-[8px] bg-blueBg mb-[32px]">
        <div className="text-orange font-ubBold font-bold text-[14px] leading-[20px] mb-[8px]">
          Step 2:
        </div>
        <div className="text-darkFont text-[16px] leading-[24px] font-ubBold font-bold mb-[16px]">
          {t('safeInfo.importRabbyAddress')}
        </div>
        <div className="flex flex-row items-center justify-around">
          <div className="bg-[url('~@/assets/images/rabby_import_private_key.png')] bg-cover bg-no-repeat w-[200px] h-[206px] " />
          <div className="bg-[url('~@/assets/images/rabby_import_cobo_safe.png')] bg-cover bg-no-repeat w-[200px] h-[206px] " />
        </div>
      </div>
      <div className="flex flex-row">
        <Button
          type="text"
          className="flex-1"
          onClick={() => setVisible(false)}
        >
          {t('common.cancel')}
        </Button>
        <Button
          type="primary"
          className="flex-1 ml-[16px]"
          onClick={() => {
            window.open(
              'https://chrome.google.com/webstore/detail/rabby-wallet/acmacodkjbdgmoleebolmdjonilkdbch',
              '_blank',
            );
          }}
        >
          {t('common.download')}
        </Button>
      </div>
    </div>
  );
  return (
    <div>
      <div
        className="px-[40px] h-[44px] flex flex-row items-center text-greyFont text-[14px] leading-[20px] cursor-pointer hover:text-blue"
        onClick={() => {
          setVisible(true);
        }}
      >
        <span className="bg-[url('~@/assets/icons/png-Rabby.png')] bg-cover bg-no-repeat w-[20px] h-[20px] block mr-[16px]" />
        Rabby
      </div>
      <Modal
        title={
          installedRabby
            ? t('safeInfo.importToRabby')
            : t('safeInfo.rabbyNotInstall')
        }
        visible={visible && !!safeInfo && !!moduleInfo}
        footer={null}
        onCloseBtnClick={() => setVisible(false)}
        destroyOnClose={false}
      >
        {installedRabby ? content : installRabbyContent}
      </Modal>
    </div>
  );
};
