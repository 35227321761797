import { useEffect, useMemo, useState } from 'react';
import Safe from '@safe-global/safe-core-sdk';
import EthersAdapter from '@safe-global/safe-ethers-lib';
import { ethers } from 'ethers';
import { useNetwork } from 'wagmi';

import { useEthersSigner } from '@/hooks/useEthersSigner';

export const useSafeSdk = (safeAddress: string, chainId?: number) => {
  const { chain } = useNetwork();
  const signer = useEthersSigner({ chainId });
  const [sdk, setSdk] = useState<Safe>();
  const ethAdapter = useMemo(
    () =>
      signer && chain && chain.id === chainId
        ? new EthersAdapter({
            ethers,
            signerOrProvider: signer,
          })
        : null,
    [chain, chainId, signer],
  );
  useEffect(() => {
    return () => setSdk(undefined);
  }, []);
  useEffect(() => {
    const load = async () => {
      if (!ethAdapter) {
        setSdk(undefined);
        return;
      }
      const sdk = await Safe.create({
        ethAdapter: ethAdapter,
        safeAddress: safeAddress,
        contractNetworks: {
          '169': {
            safeProxyFactoryAddress:
              '0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2',
            safeMasterCopyAddress: '0x3E5c63644E683549055b9Be8653de26E0B4CD36E',
            multiSendAddress: '0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761',
            multiSendCallOnlyAddress:
              '0x40A2aCCbd92BCA938b02010E17A5b8929b49130D',
            fallbackHandlerAddress:
              '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
            signMessageLibAddress: '0xA65387F16B013cf2Af4605Ad8aA5ec25a2cbA3a2',
            createCallAddress: '0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4',
          },
          '34443': {
            safeProxyFactoryAddress:
              '0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2',
            safeMasterCopyAddress: '0x3E5c63644E683549055b9Be8653de26E0B4CD36E',
            multiSendAddress: '0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761',
            multiSendCallOnlyAddress:
              '0x40A2aCCbd92BCA938b02010E17A5b8929b49130D',
            fallbackHandlerAddress:
              '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
            signMessageLibAddress: '0xA65387F16B013cf2Af4605Ad8aA5ec25a2cbA3a2',
            createCallAddress: '0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4',
          },
        },
      });
      setSdk(sdk);
    };
    load();
  }, [ethAdapter, safeAddress]);
  return sdk;
};
