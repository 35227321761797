import { useEffect, useState } from 'react';

export const useImage = (src: string) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasStartedInitialFetch, setHasStartedInitialFetch] = useState(false);

  useEffect(() => {
    setHasStartedInitialFetch(true);
    setHasLoaded(false);
    setHasError(false);

    // Here's where the magic happens.
    const image = new Image();
    image.src = src;

    const handleError = () => {
      setHasError(true);
    };

    const handleLoad = () => {
      setHasLoaded(true);
      setHasError(false);
    };

    image.onerror = handleError;
    image.onload = handleLoad;

    return () => {
      image.removeEventListener('error', handleError);
      image.removeEventListener('load', handleLoad);
    };
  }, [src]);

  useEffect(() => {
    return () => {
      setHasLoaded(false);
      setHasError(false);
      setHasStartedInitialFetch(false);
    };
  }, []);

  return { hasLoaded, hasError, hasStartedInitialFetch };
};
