import React, { ReactNode } from 'react';
import { Button as AntdButton, ButtonProps } from 'antd';
import classNames from 'classnames/bind';

import styles from './index.module.less';

// @ts-ignore
interface coboButtonProps extends ButtonProps {
  block?: boolean;
  danger?: boolean;
  disabled?: boolean;
  ghost?: boolean;
  href?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  icon?: ReactNode;
  loading?: boolean;
  shape?: 'default' | 'circle' | 'round';
  size?: 'large' | 'middle' | 'small';
  target?: string;
  className?: string;
  btnClassName?: string;
  type?:
    | 'primary'
    | 'dashed'
    | 'link'
    | 'text'
    | 'default'
    | 'white'
    | 'primary-light';
  onClick: (event: any) => void;
}

const cx = classNames.bind(styles);
export const Button: React.FunctionComponent<coboButtonProps> = props => {
  const {
    block,
    danger,
    disabled,
    ghost,
    href,
    htmlType,
    icon,
    loading,
    shape,
    size,
    target,
    className,
    btnClassName,
    type,
    onClick,
    children,
    ...restProps
  } = props;
  const whiteBtnCls = type === 'white' ? 'white-button' : '';
  const primaryLightCls =
    type === 'primary-light' ? '!bg-blue/10 !text-blue !font-ubMd' : '';
  const coboBtnCls = [whiteBtnCls, primaryLightCls].join(' ');
  return (
    <div
      className={
        className
          ? `${styles['cobo-button-wrapper']} ${className}`
          : styles['cobo-button-wrapper']
      }
    >
      <AntdButton
        block={block}
        danger={danger}
        disabled={disabled}
        ghost={ghost}
        href={href}
        htmlType={htmlType}
        icon={icon}
        loading={loading}
        shape={shape}
        size={size}
        target={target}
        type={type === 'white' || type === 'primary-light' ? 'default' : type}
        className={
          btnClassName
            ? cx('cobo-button', coboBtnCls, btnClassName)
            : cx('cobo-button', coboBtnCls)
        }
        onClick={disabled ? () => {} : onClick}
        {...restProps}
      >
        {children}
      </AntdButton>
    </div>
  );
};

Button.defaultProps = {
  type: 'primary',
  disabled: false,
};
