export const guidance = {
  title: 'Guidance',
  welcomeTitle: 'Welcome to Cobo Argus!',
  welcomeSubtitle:
    'It’s great to have you here! You’re almost ready to start your DeFi journey. To get started, please complete this checklist.',
  step1Title: 'Set up username',
  step2Title: 'Create/Join Organization',
  gaStep1: 'Step 1. ',
  gaStep2: 'Step 2. ',
  gaStep3: 'Step 3. ',
  gaStep1Title: 'Download and install the Google Authenticator App',
  gaStep2Title: 'Scan QR code & back up your key',
  gaStep2Subtitle:
    'Scan this QR code via the Google Authenticator App or manually enter the key into the app.',
  gaStep3Title: 'Enter Google verification code',
  gaStep3Subtitle: 'Enter the 6-digit code from Google Authenticator.',
  joinOrgDesc:
    'To unlock the full power of Cobo Argus, you need to create an organization or accept an invite from an organization.',
  createAnOrganization: 'Create an organization',
  checkTheInvitation: 'Check my invitations',
  createOrgTitle: 'Create Organization',
  orgNameError: 'Please input the name of the organization.',
  orgSizeError: 'Please select the organization size.',
  orgTypeError: 'Please select the organization type.',
  createOrgSuccess: 'The "{{orgName}}" has been created successfully!',
  joinOrgSuccess: "You've joined {{orgs}} successfully",
  chooseJoinOrgs: 'Choose the organization(s) you want to join:',
  bindSuccess: 'Bound successfully',
  unbindSuccess: 'Authenticator switched off',
  createNewOrg: `Accept later and create a new org first`,
};
