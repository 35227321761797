import axios from 'axios';

export const gnosisApi = axios.create({
  timeout: 60000,
});

gnosisApi.interceptors.request.use(
  config => {
    return config;
  },
  error => Promise.reject(error),
);

gnosisApi.interceptors.response.use(
  response => response.data,
  error => {
    return Promise.reject(error);
  },
);
