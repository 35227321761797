import { IJsonRpcRequest } from '@walletconnect/legacy-types';
import { ethers } from 'ethers';
import { differenceWith } from 'lodash-es';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import { getContractAbi } from '@/api';
import Chain from '@/chains';
import { getShortNameByChainId, SUPPORTED_CHAINS } from '@/chains/utils';
import { AbiFunc } from '@/interfaces';
import detectProxyTarget from '@/utils/proxyDetection';
import abiDecoder from '@/walletconnect/utils/abiDecoder';
import { MultiCallFunc } from '@/walletconnect/utils/interface';

const SUPPORTED_MULTI_CALLS = new Map<number, Map<string, string[]>>([
  [
    SUPPORTED_CHAINS.MAINNET,
    new Map<string, string[]>([
      [
        '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
        ['0x1f0464d1', '0x5ae401dc', '0xac9650d8'],
      ],
      ['0xC36442b4a4522E871399CD717aBDD847Ab11FE88', ['0xac9650d8']],
      ['0xE592427A0AEce92De3Edee1F18E0157C05861564', ['0xac9650d8']],
    ]),
  ],
  [
    SUPPORTED_CHAINS.BSC,
    new Map<string, string[]>([
      ['0x9Bf8399c9f5b777cbA2052F83E213ff59e51612B', ['0xac9650d8']],
      ['0xBd3bd95529e0784aD973FD14928eEDF3678cfad8', ['0xac9650d8']],
      ['0x93C22Fbeff4448F2fb6e432579b0638838Ff9581', ['0xac9650d8']],
      [
        '0x13f4EA83D0bd40E75C8222255bc855a974568Dd4',
        ['0x1f0464d1', '0x5ae401dc', '0xac9650d8'],
      ],
    ]),
  ],
  [
    SUPPORTED_CHAINS.GOERLI,
    new Map<string, string[]>([
      [
        '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
        ['0x1f0464d1', '0x5ae401dc', '0xac9650d8'],
      ],
      ['0xC36442b4a4522E871399CD717aBDD847Ab11FE88', ['0xac9650d8']],
      ['0xE592427A0AEce92De3Edee1F18E0157C05861564', ['0xac9650d8']],
    ]),
  ],
  [
    SUPPORTED_CHAINS.ARB1,
    new Map<string, string[]>([
      [
        '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
        ['0x1f0464d1', '0x5ae401dc', '0xac9650d8'],
      ],
      ['0xC36442b4a4522E871399CD717aBDD847Ab11FE88', ['0xac9650d8']],
      ['0xE592427A0AEce92De3Edee1F18E0157C05861564', ['0xac9650d8']],
      // pancake
      [
        '0x32226588378236Fd0c7c4053999F88aC0e5cAc77',
        ['0x1f0464d1', '0x5ae401dc', '0xac9650d8'],
      ],
    ]),
  ],
  [
    SUPPORTED_CHAINS.POLYGON,
    new Map<string, string[]>([
      [
        '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
        ['0x1f0464d1', '0x5ae401dc', '0xac9650d8'],
      ],
      ['0xC36442b4a4522E871399CD717aBDD847Ab11FE88', ['0xac9650d8']],
      ['0xE592427A0AEce92De3Edee1F18E0157C05861564', ['0xac9650d8']],
    ]),
  ],
  [
    SUPPORTED_CHAINS.OPTIMISM,
    new Map<string, string[]>([
      [
        '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
        ['0x1f0464d1', '0x5ae401dc', '0xac9650d8'],
      ],
      ['0xC36442b4a4522E871399CD717aBDD847Ab11FE88', ['0xac9650d8']],
      ['0xE592427A0AEce92De3Edee1F18E0157C05861564', ['0xac9650d8']],
    ]),
  ],
]);

const loadAbi = async (address: string, chainId: number, web3: Web3) => {
  const info: { contract_name: string; abi: AbiItem[] } = await getContractAbi(
    getShortNameByChainId(chainId),
    address,
    false,
  );
  const abi = info.abi;
  const funcs: any[] = [];
  abi.forEach(item => {
    if (item.type !== 'function') {
      return;
    }
    const signature = web3.utils._jsonInterfaceMethodToString(item);
    funcs.push({
      abiItem: item,
      signature,
      selector: web3.eth.abi.encodeFunctionSignature(item),
    });
  });
  const abiItems = funcs.map(abiItem => abiItem.abiItem);
  return abiItems
    .filter((e: any) => {
      if (['pure', 'view'].includes(e.stateMutability)) {
        return false;
      }

      if (e?.type.toLowerCase() === 'event') {
        return false;
      }

      return !e.constant;
    })
    .filter((m: any) => m.type !== 'constructor')
    .map((m: any) => ({
      inputs: m.inputs || [],
      name: m.name || (m.type === 'fallback' ? 'fallback' : 'receive'),
      payable: m.payable || m.stateMutability === 'payable',
    }));
};

const getAbiParamFromEncodedData = async (
  chainId: number,
  address: string,
  paramData: string,
): Promise<any> => {
  try {
    const c = new Chain(chainId);
    const web3Instance = new Web3(c.rpcUrl());

    const abiList = await loadAbi(address, chainId, web3Instance);

    abiDecoder.addABI(abiList);
    const decodedData = abiDecoder.decodeMethod(paramData) || {};

    const interactedAbi = abiList.find(abiItem => {
      if (
        abiItem.name === decodedData.name &&
        abiItem.inputs.length === decodedData.params.length
      ) {
        const difference = differenceWith(
          abiItem.inputs,
          decodedData.params,
          (arrVal: any, othVal: any) =>
            arrVal.name === othVal.name && arrVal.type === othVal.type,
        );
        if (difference.length === 0) return true;
      }
      return false;
    });

    decodedData.formattedParams = interactedAbi?.inputs.map((input: any) => {
      if (input.type === 'tuple') {
        return input.components.reduce(
          (data: any, component: any) => {
            data.types.push(component.type);
            data.paramTypeMap[component.name] = component.type;
            return data;
          },
          { types: [], paramTypeMap: {} },
        );
      }
      return {
        types: [input.type],
        paramTypeMap: {
          [input.name]: input.type,
        },
      };
    });

    return decodedData;
  } catch (e) {
    console.error(e);
    return {};
  }
};

const checkIsMultiCall = (
  chainId: number,
  contract: string,
  selector: string,
): boolean => {
  return (SUPPORTED_MULTI_CALLS.get(chainId)?.get(contract) || []).includes(
    selector,
  );
};

export const formatPayload = async (
  payload: {
    method: string;
    params: any[];
  },
  chainId: number,
  multiCallFuncs?: MultiCallFunc[] | undefined,
): Promise<{ label: string; value: string }[]> => {
  if (
    !payload ||
    !payload.method ||
    !payload.params ||
    !payload.params.length
  ) {
    return [];
  }
  const requestParams = payload.params[0];
  if (requestParams) {
    requestParams.from = ethers.utils.getAddress(requestParams.from);
    requestParams.to = ethers.utils.getAddress(requestParams.to);

    requestParams.decodedData = await getAbiParamFromEncodedData(
      chainId,
      requestParams.to,
      requestParams.data,
    );
  }
  payload.params[0] = requestParams;
  let params = [{ label: 'WalletConnect Method', value: payload.method }];
  const { data, decodedData } = payload.params[0];
  const methodSelector = data.slice(0, 10);
  const { to } = payload.params[0];
  const isMultiCall = checkIsMultiCall(chainId, to, methodSelector);
  if (multiCallFuncs === undefined) {
    multiCallFuncs = await parseMultiCallFuncs(payload, chainId);
  }
  const decodedDataParams = (decodedData.params || []).map(
    (param: any, index: number) => {
      const formattedParam = decodedData.formattedParams[index];
      const paramValues = Object.keys(param.value || {})
        .filter(value => Number.isNaN(Number(value)))
        .map(key => ({
          label: `${key}(${formattedParam.paramTypeMap[key]})`,
          value: param.value[key],
        }));
      let value: any = '';
      if (Array.isArray(param.value)) {
        value = param.value.join(',');
      } else if (paramValues.length > 0) {
        value = paramValues;
      } else {
        value = param.value;
      }
      return {
        label: `${param.name}(${formattedParam.types.join(',')})`,
        value: value?.toString() || '',
        useless: isMultiCall && param.name !== 'data',
      };
    },
  );

  switch (payload.method) {
    case 'eth_sendTransaction':
      params = [
        ...params,
        { label: 'From', value: payload.params[0].from },
        { label: 'To', value: payload.params[0].to },
        {
          label: 'Value',
          value: payload.params[0].value
            ? Web3.utils.hexToNumberString(payload.params[0].value)
            : '0',
        },
        { label: 'Data', value: payload.params[0].data },
        { label: 'Contract Interaction', value: decodedData.name },
        ...decodedDataParams,
      ];
      break;
    default:
      params = [
        ...params,
        {
          label: 'params',
          value: JSON.stringify(payload.params, null, '\t'),
        },
      ];
      break;
  }
  multiCallFuncs.forEach((item, index) => {
    const { abi, calldata } = item;
    params.push({
      label: `MultiCall Method ${index + 1}`,
      value: abi.signature,
    });
    params.push({
      label: 'calldata',
      value: calldata || 'empty',
    });
  });
  return params;
};

export const parseMultiCallFuncs = async (
  payload: { params: any[] },
  chainId: number,
): Promise<MultiCallFunc[]> => {
  if (!payload) {
    return [];
  }
  const c = new Chain(chainId);
  const w3 = new Web3(c.rpcUrl());
  const { data, decodedData } = payload.params[0];
  const methodSelector = data.slice(0, 10);
  const { to } = payload.params[0];
  const isMultiCall = checkIsMultiCall(chainId, to, methodSelector);
  if (isMultiCall) {
    const currentData = decodedData.params.find(
      (item: { name: string }) => item.name === 'data',
    );
    const multiCallFuncs: MultiCallFunc[] = [];
    if (currentData) {
      for (const functionData of currentData.value) {
        const funcSelector = functionData.slice(0, 10);
        const calldata = functionData.slice(10)
          ? `0x${functionData.slice(10)}`
          : '';
        const logicAddress = await detectProxyTarget(to, w3);
        const info: { contract_name: string; abi: AbiItem[] } =
          await getContractAbi(
            getShortNameByChainId(chainId),
            logicAddress || to,
            true,
          );
        let { abi } = info;
        const abiItem = abi.find(
          item =>
            item.type === 'function' &&
            w3.eth.abi.encodeFunctionSignature(item) === funcSelector,
        );
        const abiFunc: AbiFunc | undefined = abiItem
          ? {
              abiItem: abiItem,
              signature: w3.utils._jsonInterfaceMethodToString(abiItem),
              selector: w3.eth.abi.encodeFunctionSignature(abiItem),
            }
          : undefined;
        if (abiFunc) {
          const multiCallFunc: MultiCallFunc = {
            abi: abiFunc,
            calldata,
            data: functionData,
          };
          multiCallFuncs.push(multiCallFunc);
        }
      }
    }
    return multiCallFuncs;
  }
  return [];
};

const parseCallRequestParam = async (param: any, chainId: number) => {
  const from = ethers.utils.getAddress(param.from);
  const to = ethers.utils.getAddress(param.to);
  return {
    ...param,
    from,
    to,
    decodedData: await getAbiParamFromEncodedData(chainId, to, param.data),
  };
};

export const parseLegacyCallRequest = async (
  payload: IJsonRpcRequest,
  chainId: number,
): Promise<IJsonRpcRequest> => {
  payload.params[0] = await parseCallRequestParam(payload.params[0], chainId);
  return payload;
};

export const parseCallRequest = async (requestEvent: any, chainId: number) => {
  requestEvent.params.request.params[0] = await parseCallRequestParam(
    requestEvent.params.request.params[0],
    chainId,
  );
  return requestEvent;
};
