import { atom } from 'jotai';

export const methodAtom = atom<string | undefined>(undefined);

export const paramsAtom = atom<any | undefined>(undefined);

export const formattedParamsAtom = atom<
  { label: string; value: any; useless?: boolean }[] | undefined
>(undefined);

export const promiseTriggerAtom = atom<
  | {
      resolve: (txHash: string) => void;
      reject: (e: any) => void;
    }
  | undefined
>(undefined);
