import {
  createRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames/bind';

import styles from './index.module.less';

interface StepItem {
  name: string;
}

interface Props {
  items: StepItem[];
  currentStep: number;
}

interface ItemRect {
  nodeLeft: number;
  nodeTop: number;
  bottom: number;
  width: number;
  height: number;
  left: number;
  right: number;
  top: number;
  x: number;
  y: number;
}

export function Steps({ items, currentStep }: Props) {
  const cx = classNames.bind(styles);
  const [itemsRect, setItemsRect] = useState<ItemRect[]>([]);
  const stepRefs = useRef<any>([]);
  stepRefs.current = Array.from(items).map(
    (_, i) => stepRefs.current[i] || createRef(),
  );
  const defaultNodeInfo = useMemo(
    () => ({
      nodeLeft: -1000,
      nodeTop: -1000,
      bottom: 0,
      width: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      x: 0,
      y: 0,
    }),
    [],
  );
  const getNodeInfo = useCallback(
    (idx: number) => {
      const currentNode = stepRefs.current[idx]?.current;
      if (currentNode) {
        const rect = currentNode.getBoundingClientRect();
        const { bottom, height, left, right, top, width, x, y } = rect;
        return {
          nodeLeft: width / 2 - 16,
          nodeTop: -40,
          bottom,
          width,
          height,
          left,
          right,
          top,
          x,
          y,
        };
      }
      return defaultNodeInfo;
    },
    [defaultNodeInfo],
  );
  useLayoutEffect(() => {
    const initItemRect = () =>
      setTimeout(() => {
        const data: ItemRect[] = [];
        items.forEach((item, idx) => {
          const rect = getNodeInfo(idx);
          data.push(rect);
        });
        setItemsRect(data);
      }, 20);
    window.addEventListener('resize', initItemRect);
    initItemRect();
    return () => {
      window.removeEventListener('resize', initItemRect);
    };
  }, [getNodeInfo, items]);
  useEffect(() => {
    return () => {
      setItemsRect([]);
      stepRefs.current = [];
    };
  }, []);
  const wrapperWidth =
    items.length === 2 ? '50%' : items.length === 3 ? '75%' : '100%';
  return (
    <div className={cx('steps-container')}>
      <div className={cx('steps-wrapper')} style={{ width: wrapperWidth }}>
        {items.map((item, idx) => {
          const rect = itemsRect[idx] || defaultNodeInfo;
          const nextNodeRect =
            idx === items.length - 1
              ? null
              : itemsRect[idx + 1] || defaultNodeInfo;
          const done = currentStep > idx;
          const current = currentStep === idx;
          const progressWidth = nextNodeRect
            ? nextNodeRect.right -
              rect.left -
              (rect.width / 2 + 16) -
              (nextNodeRect.width / 2 + 16) -
              16 -
              16
            : 0;
          const getPresent = () => {
            if (done) {
              return 0;
            } else if (current) {
              return '50%';
            } else {
              return '100%';
            }
          };
          const getStepNodeClass = () => {
            if (done) {
              return 'steps-node-done';
            } else if (current) {
              return '';
            } else {
              return 'steps-node-undone';
            }
          };
          return (
            <div
              ref={stepRefs.current[idx]}
              onClick={() => getNodeInfo(idx)}
              key={idx}
              className={cx(
                'steps-node-container',
                done ? 'done' : '',
                current ? 'current' : '',
              )}
            >
              {item.name}
              <div
                className={cx('steps-node-wrapper')}
                key={idx}
                style={{ top: rect.nodeTop, left: rect.nodeLeft }}
              >
                <div className={cx('steps-node', getStepNodeClass())}>
                  {done ? '' : idx + 1}
                </div>
                <div
                  className={cx('steps-progressbar')}
                  style={{ width: progressWidth }}
                >
                  <div
                    className={cx('progress')}
                    style={{ right: getPresent() }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
