import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Web3 from 'web3';

import styles from './index.module.less';
import { getTokenInfo } from '@/api';
import { getChainNameByCoboName } from '@/chains/utils';
import { ChainIcon, Input, Modal } from '@/components';
import { EmptyView } from '@/components/Common/EmptyView';
import { TokenInfo } from '@/interfaces';

interface Props {
  chainId: string;
  show: boolean;
  onDismiss: () => void;
  title: string;
  placeholder?: string;
  onSelectToken: (token: TokenInfo) => void;
  checkToken?: (token: TokenInfo) => Promise<boolean>;
  existTokens?: string[];
  canSearchMainToken?: boolean;
}

export function SearchTokenModal({
  chainId,
  show,
  title,
  onDismiss,
  placeholder,
  onSelectToken,
  checkToken,
  existTokens,
  canSearchMainToken = true,
}: Props) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [checking, setChecking] = useState(false);
  const [token, setToken] = useState<TokenInfo | null>(null);
  useEffect(() => {
    const loadToken = async () => {
      try {
        setLoading(true);
        const tokenInfo = await getTokenInfo(chainId, input);
        setToken(tokenInfo);
      } catch (e) {
        setToken(null);
      } finally {
        setLoading(false);
      }
    };
    if (loading) return;
    if (
      input === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' &&
      !canSearchMainToken
    ) {
      setToken(null);
      return;
    }
    if (Web3.utils.isAddress(input)) {
      loadToken();
    } else {
      setToken(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, input]);
  return (
    <Modal
      width={680}
      className={cx('search-token-modal')}
      visible={show}
      onCloseBtnClick={() => {
        onDismiss();
      }}
      title={title}
      destroyOnClose={true}
      zIndex={1001}
    >
      <div className={cx('search-input-wrapper')}>
        <Input
          value={input}
          prefix={<div className={cx('search-icon')} />}
          className={cx('search-input')}
          onChange={e => {
            if (!loading) {
              setInput(e.target.value);
            }
          }}
          maxLength={42}
          placeholder={placeholder || 'Please input token address'}
          allowClear
        />
      </div>
      <div className={cx('content')}>
        {input && token && (
          <div className={cx('token-item')}>
            <div className={cx('token-info-wrapper')}>
              <ChainIcon
                url={token.icon_url || ''}
                className={cx('token-icon')}
              />
              <div className={cx('token-name-wrapper')}>
                <div className={cx('token-name')}>{token.symbol}</div>
                <div className={cx('token-chain')}>
                  {getChainNameByCoboName(token.chain_id)}
                </div>
              </div>
            </div>
            <div
              className={cx('add-btn')}
              onClick={async () => {
                if (checkToken) {
                  if (checking) return;
                  try {
                    setChecking(true);
                    const success = await checkToken(token);
                    if (!success) return;
                  } finally {
                    setChecking(false);
                  }
                }
                onSelectToken(token);
                setInput('');
              }}
            >
              {!existTokens || !existTokens.includes(token?.address)
                ? t('common.add')
                : t('common.select')}
            </div>
          </div>
        )}
        {input && !token && (
          <EmptyView
            title={
              loading ? t('common.fetchingToken') : t('common.tokenNotFound')
            }
            subtitle={loading ? '' : t('common.invalidToken')}
          />
        )}
      </div>
    </Modal>
  );
}
