// top level data
import { atom } from 'jotai';
import { cloneDeep } from 'lodash-es';

import {
  BindSafeInfo,
  defaultSliderError,
  GenericBotInfo,
  Module,
  SliderError,
  WebhookInfo,
} from '@/interfaces';

export const botInfoAtom = atom<GenericBotInfo | undefined>(undefined);
export const originBotInfoAtom = atom<GenericBotInfo | undefined>(undefined);

export const originReceiversAtom = atom<string[]>(get => {
  const botInfo = get(originBotInfoAtom);
  const instance = botInfo?.instance_config;
  if (!instance) return [];
  const receivers: string[] = [];
  instance.sections.forEach(section => {
    if (section.type !== 'generic_task') return;
    section.tasks?.forEach(task => {
      task.operations?.forEach(op =>
        op.receiver && !receivers.includes(op.receiver)
          ? receivers.push(op.receiver)
          : null,
      );
    });
  });
  return receivers;
});

export const safesAtom = atom<BindSafeInfo[] | undefined>(undefined);
export const modulesAtom = atom<Module[] | undefined>(undefined);
export const txHashAtom = atom('');
export const pageAtom = atom<
  'setup' | 'tokenApproval' | 'preview' | 'pending' | 'success' | 'failed'
>('setup');

export const botVersionAtom = atom(get => get(botInfoAtom)?.bot_version || '1');
export const botDefinitionAtom = atom(
  get => get(botInfoAtom)?.bot_definition_config,
);
export const botInstanceAtom = atom(get => {
  const instanceConfig = cloneDeep(get(botInfoAtom)?.instance_config);
  if (!instanceConfig || !instanceConfig.sections) return instanceConfig;
  const sections = instanceConfig.sections;
  const triggerSection = sections.find(section => section.type === 'trigger');
  const taskSection = sections.find(section => section.type === 'generic_task');
  if (triggerSection) {
    const selectedTriggers = triggerSection.selected_triggers;
    triggerSection.triggers = triggerSection.triggers?.filter(
      trigger => selectedTriggers?.includes(trigger.name),
    );
  }
  if (taskSection) {
    const selectedTasks = taskSection.selected_tasks;
    taskSection.tasks = taskSection.tasks?.filter(
      task => selectedTasks?.includes(task.name),
    );
  }
  instanceConfig.sections = instanceConfig.sections.map(section => {
    if (section.type === 'trigger') {
      return triggerSection || section;
    }
    if (section.type === 'generic_task') {
      return taskSection || section;
    }
    return section;
  });
  return instanceConfig;
});

export const leverageSliderErrorAtom = atom<SliderError>(defaultSliderError);

export const withdrawBotSlippageAtom = atom<string>('');

export const hasWebhookAtom = atom<boolean>(false);
export const webhookInfoAtom = atom(
  get => {
    const botInfo = get(botInfoAtom);
    return botInfo?.webhook;
  },
  (get, set, webhook?: WebhookInfo) => {
    const botInfo = get(botInfoAtom);
    if (!botInfo) return;
    if (botInfo.instance_config) {
      botInfo.webhook = webhook;
    }
    set(botInfoAtom, { ...botInfo });
  },
);
