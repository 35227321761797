import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Delegate, MyPortfolioTab, WithdrawData } from '@/interfaces';

const initialState: {
  myPortfolioTab: MyPortfolioTab;
  delegateMember: Delegate | null;
  withdrawData: WithdrawData | null;
} = {
  myPortfolioTab: MyPortfolioTab.MyAuthorization,
  delegateMember: null,
  withdrawData: null,
};

const slice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    updateShowMyPortfolioTab: (
      state,
      action: PayloadAction<MyPortfolioTab>,
    ) => {
      state.myPortfolioTab = action.payload;
    },
    updateInvestmentDelegateMember: (
      state,
      action: PayloadAction<Delegate | null>,
    ) => {
      state.delegateMember = action.payload;
    },
    updateWithdrawData: (state, action: PayloadAction<WithdrawData | null>) => {
      state.withdrawData = action.payload;
    },
  },
});
export const portfolioReducer = slice.reducer;
export const updateShowMyPortfolioTab = slice.actions.updateShowMyPortfolioTab;
export const updateInvestmentDelegateMember =
  slice.actions.updateInvestmentDelegateMember;
export const updateWithdrawData = slice.actions.updateWithdrawData;
