import { coboApi } from '@/api/axios';
import {
  BotDetails,
  InvestmentPositionItem,
  InvestmentTransactions,
  MyPortfolioSafe,
  MyPortfolioTabData,
  PermissionConfig,
  ProtocolItem,
  SafeTx,
  SafeTxRecord,
  StrategyACL,
  StrategyAuthorizationInfoItem,
  StrategyListItem,
  StrategyListProps,
  StrategyPermissionRole,
  StrategyServiceTypes,
  SubmitAuthorizeParams,
  TokenItem,
} from '@/interfaces';

export const getSupportedChains = async (
  strategy_type?: '2' | '5',
): Promise<any> => {
  return await coboApi.post('/argus/strategy/get_supported_chains/', {
    strategy_type,
  });
};

export const getSupportedToken = async (
  strategy_type?: '2' | '5',
): Promise<string[]> => {
  return await coboApi.post('/argus/strategy/get_supported_coins/', {
    strategy_type,
  });
};

export const getSupportedProtocols = async (
  strategy_type?: '2' | '5',
): Promise<ProtocolItem[]> => {
  return await coboApi.post('/argus/strategy/get_supported_protocols/', {
    strategy_type,
  });
};

export const getStrategies = async (props: StrategyListProps): Promise<any> => {
  return await coboApi.post('/argus/strategy/get_strategies/', props);
};

export const getPortfolioForSafe = async ({
  safe_id,
  strategy_type,
  current_page,
  page_size,
  invest_coin,
  protocol_id,
  service_type,
}: {
  safe_id: string;
  strategy_type?: number | string;
  current_page: number;
  page_size: number;
  invest_coin?: string;
  protocol_id?: string;
  service_type?: string;
}): Promise<MyPortfolioSafe> => {
  return await coboApi.post(`/argus/strategy/get_portfolio_for_safe/`, {
    safe_id,
    strategy_type,
    current_page,
    page_size,
    invest_coin,
    protocol_id,
    service_type,
  });
};

export const getPortfolioList = async ({
  org_id,
  chain_id,
  current_page,
  page_size,
  url,
  safe_address,
}: {
  org_id: string;
  chain_id: string;
  current_page: number;
  page_size: number;
  url: string;
  safe_address?: string;
}): Promise<MyPortfolioTabData> => {
  return await coboApi.post(`/argus/strategy/${url}/`, {
    org_id,
    chain_id,
    current_page,
    page_size,
    safe_address,
  });
};

export const getStrategyDetail = async (
  id: string,
  safe_id?: string,
  safe_module_address?: string,
): Promise<StrategyListItem> => {
  return await coboApi.post('/argus/strategy/get_strategy_detail/', {
    strategy_id: id,
    safe_id,
    module_address: safe_module_address,
  });
};

export const getBotDetailsForSafe = async (
  safe_id: string,
  bot_definition_id: string,
): Promise<BotDetails> => {
  return await coboApi.post('/argus/generic_bot/get_bot_details_for_safe/', {
    safe_id,
    bot_definition_id,
  });
};

export const getStrategyAuthorizedInfoForSafe = async (
  safe_id: string,
  strategy_id: string,
): Promise<StrategyPermissionRole> => {
  return await coboApi.post(
    '/argus/strategy/get_strategy_authorized_info_for_safe/',
    {
      safe_id,
      strategy_id,
    },
  );
};

export const getAuthorizedInfos = async (
  org_id: string,
  strategy_id: string,
  safe_address: string,
  module_address: string,
  silence = false,
): Promise<StrategyAuthorizationInfoItem | null> => {
  const resp: any =
    (await coboApi.post(
      '/argus/strategy/get_authorized_info/',
      {
        org_id,
        strategy_id,
        safe_address,
        module_address,
      },
      {
        params: { silence: silence },
      },
    )) || null;
  if (resp) {
    return {
      permission_roles: resp,
    };
  } else {
    return null;
  }
};

export const getStrategyPermission = async (
  params: SubmitAuthorizeParams,
): Promise<PermissionConfig[]> => {
  return await coboApi.post('/argus/strategy/get_strategy_permission/', params);
};

export const authorizeStrategy = async (
  params: SubmitAuthorizeParams,
): Promise<SafeTx | { bundle_id: string }> => {
  return await coboApi.post('/argus/strategy/authorize_strategy/', params);
};

export const unAuthorizeStrategy = async (params: {
  safe_id: string;
  strategy_id: string;
  cobo_module_id: string;
}): Promise<SafeTx> => {
  return await coboApi.post('/argus/strategy/unauthorize_strategy/', params);
};

export const getUnAuthorizeStrategyStatus = async (params: {
  safe_id: string;
  strategy_id: string;
  cobo_module_id: string;
}): Promise<{
  pending_unauthorize_tx_hash: string;
}> => {
  return await coboApi.post(
    '/argus/strategy/get_strategy_unauthorize_status/',
    params,
  );
};

export const activateStrategy = async (
  safe_id: string,
  strategy_id: string,
  cobo_module_id: string,
  role_delegates: { [key: string]: string[] },
  nonce: number,
  reward_token_receiver?: { [key: string]: string },
): Promise<SafeTx> => {
  const newRoleDelegates: { [key: string]: string[] } = {};
  Object.entries(role_delegates).forEach(([role, addressList]) => {
    if (addressList.length > 0) {
      newRoleDelegates[role] = addressList;
    }
  });
  const role_delegates_str = JSON.stringify(newRoleDelegates);
  let data: {
    safe_id: string;
    strategy_id: string;
    cobo_module_id: string;
    role_delegates: any;
    reward_token_receiver?: string;
    nonce: number;
  } = {
    safe_id,
    strategy_id,
    cobo_module_id,
    role_delegates: role_delegates_str,
    nonce,
  };
  if (reward_token_receiver) {
    data = {
      ...data,
      reward_token_receiver: JSON.stringify(reward_token_receiver),
    };
  }
  return await coboApi.post('/argus/strategy/activate_strategy/', data);
};

export const submitSafeTx = async (
  chain_id: string,
  safe_tx_hash: string,
  signature: string,
  signer_address: string,
  org_id: string,
) => {
  await coboApi.post('/argus/strategy/submit_safe_tx/', {
    chain_id,
    safe_tx_hash,
    signature,
    signer_address,
    org_id,
  });
};

export const getSafeTxRecord = async (
  chain_id: string,
  safe_tx_hash: string,
): Promise<SafeTxRecord> => {
  return await coboApi.post(
    '/argus/strategy/get_safe_tx/',
    {
      chain_id,
      safe_tx_hash,
    },
    {
      params: { silence: true },
    },
  );
};

export const getStrategyAuthorizeStatus = async (
  strategy_id: string,
  safe_id: string,
): Promise<{ pending_authorize_tx_hash: string | null }> => {
  return await coboApi.post('/argus/strategy/get_strategy_authorize_status/', {
    strategy_id,
    safe_id,
  });
};

export const getStrategyActivateStatus = async (
  strategy_id: string,
  safe_id: string,
): Promise<{
  subsafe_address: string | null;
  pending_tx_hash: string | null;
}> => {
  return await coboApi.post('/argus/strategy/get_strategy_activate_status/', {
    strategy_id,
    safe_id,
  });
};

export const multisigDeposit = async (
  strategy_id: string,
  safe_id: string,
  safe_module_id: string,
  amounts: { [key: string]: string },
): Promise<SafeTx> => {
  return await coboApi.post('/argus/strategy/multisig_deposit/', {
    strategy_id,
    safe_id,
    safe_module_id,
    amounts: JSON.stringify(amounts),
  });
};

export const singleSigAction = async (
  strategy_id: string,
  safe_id: string,
  module_id: string,
  signer_address: string,
  action: string,
): Promise<{
  from: string;
  to: string;
  value: string;
  data: string;
  nonce: string;
}> => {
  return await coboApi.post('/argus/strategy/single_sig_action/', {
    strategy_id,
    safe_id,
    module_id,
    signer_address,
    action,
  });
};

export const getWithdrawPosition = async (
  strategy_id: string,
  safe_id: string,
): Promise<any> => {
  return await coboApi.post('/argus/strategy/get_strategy_position/', {
    strategy_id,
    safe_id,
  });
};

export const getStrategyRewardReceiver = async (
  strategy_id: string,
  safe_id: string,
): Promise<{ receiver_address: string; token_info: TokenItem }[]> => {
  return await coboApi.post('/argus/strategy/get_strategy_reward_receiver/', {
    strategy_id,
    safe_id,
  });
};

export const getInvestmentPositionList = async (
  strategy_id: string,
  org_id: string,
): Promise<InvestmentPositionItem[]> => {
  return await coboApi.post('/argus/strategy/get_investment_position/', {
    strategy_id,
    org_id,
  });
};

export const getInvestmentTransactions = async (
  strategy_id: string,
  org_id: string,
  current_page: number,
  page_size: number,
): Promise<InvestmentTransactions> => {
  return await coboApi.post('/argus/strategy/get_investment_transactions/', {
    strategy_id,
    org_id,
    current_page,
    page_size,
  });
};

export const getOnChainAclConfig = async (
  module_address: string,
  strategy_id: string,
): Promise<StrategyACL> => {
  return await coboApi.post('/argus/strategy/get_onchain_acl_config/', {
    module_address,
    strategy_id,
  });
};

export const getStrategyServiceTypes = async (
  strategy_type: string,
): Promise<StrategyServiceTypes> => {
  return await coboApi.post('/argus/strategy/get_strategy_service_types/', {
    strategy_type,
  });
};
