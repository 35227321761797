import { coboApi } from '@/api/axios';
import {
  BundleInfo,
  BundleItem,
  SafeTx,
  TransactionsQueueInfo,
} from '@/interfaces';

export const getSafeTxBundle = async (safe_id: string): Promise<BundleInfo> => {
  return await coboApi.post('/argus/tx_bundle/get_safe_tx_bundle/', {
    safe_id,
  });
};

export const getSafeTxFormBundle = async (
  safe_id: string,
  safe_tx_bundle_id: string,
  nonce: number,
): Promise<SafeTx> => {
  return await coboApi.post('/argus/tx_bundle/finalize_safe_tx_bundle/', {
    safe_id,
    safe_tx_bundle_id,
    nonce,
  });
};

// getTransaction
export const getTransactionsQueue = async (
  safe_id: string,
  page: number,
  page_size: number,
  silence: boolean,
): Promise<TransactionsQueueInfo> => {
  return await coboApi.post(
    '/argus/tx_bundle/get_transactions_queue/',
    {
      safe_id,
      page,
      page_size,
    },
    {
      params: { silence },
    },
  );
};

export const getTransactionsHistory = async (
  safe_id: string,
  page: number,
  page_size: number,
  silence: boolean,
): Promise<TransactionsQueueInfo> => {
  return await coboApi.post(
    '/argus/tx_bundle/get_transactions_history/',
    {
      safe_id,
      page,
      page_size,
    },
    {
      params: { silence },
    },
  );
};

export const sendSafeTxConfirmationEmail = async (
  safe_id: string,
  receivers: string[],
  safe_tx_hash?: string,
): Promise<{ transactions: any[] }> => {
  return await coboApi.post(
    '/argus/tx_bundle/send_safe_tx_confirmation_email/',
    {
      safe_id,

      receivers,
      safe_tx_hash,
    },
  );
};

export const removeBundleItem = async (
  safe_id: string,
  tx_bundle_item_id: string,
): Promise<void> => {
  return await coboApi.post('/argus/tx_bundle/remove_tx_bundle_item/', {
    safe_id,
    tx_bundle_item_id,
  });
};

export const clearBundle = async (safe_id: string): Promise<void> => {
  return await coboApi.post('/argus/tx_bundle/clear_tx_bundle/', {
    safe_id,
  });
};

export const submitBundle = async (
  safe_id: string,
  safe_tx_bundle_id: string,
  nonce: number,
  ignore_nonce_check: boolean,
): Promise<SafeTx> => {
  return await coboApi.post('/argus/tx_bundle/finalize_safe_tx_bundle/', {
    safe_id,
    safe_tx_bundle_id,
    nonce,
    ignore_nonce_check, // 检查是否有相同的bundle
  });
};

export const getTxBundleItem = async (
  safe_id: string,
  tx_bundle_item_id: string,
): Promise<BundleItem> => {
  return await coboApi.post('/argus/tx_bundle/get_tx_bundle_item/', {
    safe_id,
    tx_bundle_item_id,
  });
};
