export const me = {
  member: 'Member',
  title: 'Me',
  myAddress: 'My Addresses',
  safeRoles: 'My Safe Roles',
  organizationRoles: 'My Organization Roles',
  organizationRole: 'My Organization Role',
  email: 'Email',
  addAddress: 'Add Address',
  address: 'Address',
  label: 'Label',
  removeTip:
    'This address is currently delegated with on-chain permissions. Removing it will take it off the list but will not revoke its on-chain permissions.',
  removeConfirmTip: 'Are you sure you want to remove this address?',
  safeDelegateStatus: 'Safe Delegate Status',
  delegated: 'Delegated',
  active: 'Active',
  inactive: 'Inactive',
  AddressStatus: 'Address Status',
  updatedTime: 'Updated Time',
  optional: '(Optional)',
  addressTip: `Note: Once submitted,only admin can edit the address. If you can't switch address, please try switching account in your wallet manually.`,
  continueAndVerify: 'Continue to Verify',
  delegatedAddress: 'Delegate Address',
  safe: 'Safe',
  addEmail: 'Add Email',
  organization: 'Organization',
  addressStatus: 'Address Status',
  interactWithDapp: 'Interact with Dapp',
  disable: 'Disable',
  enable: 'Enable',
  inputEmail: 'Please input email',
  selectRole: 'Please select role',
  sendEmail: 'Send email',
  inviteByAddress: 'Invite by address',
  walletAddress: 'Wallet Address',
  generateLink: 'Generate Link',
  invitationLink: 'Invitation Link',
  copy: 'Copy',
};
