import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import colors from '@/utils/colors';

const initialState: {
  loadingProgress: number;
  loadingColor: string;
  pageLoading: boolean;
  codeModalVisible: boolean;
  coboModalResolver: CallableFunction | null;
  coboModalRejection: CallableFunction | null;
  confirmModalVisible: boolean;
  confirmModalTitle: string;
  confirmModalElement: any;
  connectWalletModalVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  okText: string;
  cancelText: string;
  showConfirmModalClose?: () => void;
} = {
  connectWalletModalVisible: false,
  // loading progress related
  loadingProgress: 0,
  loadingColor: colors.primary,
  pageLoading: false,
  // code modal related
  codeModalVisible: false,
  coboModalResolver: null,
  coboModalRejection: null,
  // confirm modal
  confirmModalVisible: false,
  confirmModalTitle: '',
  confirmModalElement: '',
  onConfirm: () => {},
  onCancel: () => {},
  showConfirmModalClose: () => {},
  okText: 'ok',
  cancelText: 'cancel',
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    updatePageLoading: (state, action: PayloadAction<boolean>) => {
      state.pageLoading = action.payload;
    },
    updateLoadingProgress: (state, action: PayloadAction<number>) => {
      state.loadingProgress = action.payload;
    },
    updateLoadingColor: (state, action: PayloadAction<string>) => {
      state.loadingColor = action.payload;
    },
    updateCodeModalVisible: (state, action: PayloadAction<boolean>) => {
      state.codeModalVisible = action.payload;
    },
    updateCoboModalResolver: (
      state,
      action: PayloadAction<CallableFunction | null>,
    ) => {
      state.coboModalResolver = action.payload;
    },
    updateCoboModalRejection: (
      state,
      action: PayloadAction<CallableFunction | null>,
    ) => {
      state.coboModalRejection = action.payload;
    },
    updateConfirmModal: (
      state,
      action: PayloadAction<{
        confirmModalVisible: boolean;
        confirmModalTitle: string;
        confirmModalElement: any;
        onConfirm: () => void;
        onCancel?: () => void;
        okText?: string;
        cancelText?: string;
        showConfirmModalClose?: () => void;
      }>,
    ) => {
      const {
        confirmModalVisible,
        confirmModalTitle,
        confirmModalElement,
        onConfirm,
        onCancel,
        okText,
        cancelText,
        showConfirmModalClose,
      } = action.payload;
      state.confirmModalVisible = confirmModalVisible;
      state.confirmModalTitle = confirmModalTitle;
      state.confirmModalElement = confirmModalElement;
      state.onConfirm = onConfirm;
      state.onCancel = onCancel || (() => {});
      state.okText = okText || 'OK';
      state.cancelText = cancelText === undefined ? 'Cancel' : cancelText;
      state.showConfirmModalClose = showConfirmModalClose;
    },
  },
});
export const globalReducer = slice.reducer;
export const updateLoadingProgress = slice.actions.updateLoadingProgress;
export const updateLoadingColor = slice.actions.updateLoadingColor;
export const updateCodeModalVisible = slice.actions.updateCodeModalVisible;
export const updateCoboModalResolver = slice.actions.updateCoboModalResolver;
export const updateCoboModalRejection = slice.actions.updateCoboModalRejection;
export const updateConfirmModal = slice.actions.updateConfirmModal;
export const updatePageLoading = slice.actions.updatePageLoading;
