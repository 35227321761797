import { Input as AntdInput } from 'antd';
import { InputProps } from 'antd/lib/input';
import classNames from 'classnames/bind';

import styles from './index.module.less';

interface coboInputProps extends InputProps {
  size?: 'large' | 'middle' | 'small';
}

const cx = classNames.bind(styles);
export const Password: React.FunctionComponent<coboInputProps> = props => {
  const { size, className, ...restProps } = props;
  return (
    <div className={styles['cobo-input-box']}>
      <AntdInput.Password
        {...restProps}
        className={cx({
          'large-input': size === 'large',
          'middle-input': size === 'large',
          'small-input': size === 'large',
        })}
      />
    </div>
  );
};

Password.defaultProps = {
  size: 'large',
};
