import { useEffect } from 'react';
import { useAtom } from 'jotai';

import { collect } from '@/api/axios/collect';
import { globalUserInfoAtom } from '@/globalAtom';
import { useCountDown } from '@/hooks/useCountdown';

export const useHeartbeat = (cid: string, start: boolean, extra?: any) => {
  const [userInfo] = useAtom(globalUserInfoAtom);
  const [leftSeconds] = useCountDown(start ? 120 : -1);

  useEffect(() => {
    if (!start) return;
    let idx = -1;
    const seconds = 120 - leftSeconds;
    switch (seconds) {
      case 0:
        idx = 0;
        break;
      case 1:
        idx = 1;
        break;
      case 3:
        idx = 2;
        break;
      case 5:
        idx = 3;
        break;
      case 10:
        idx = 4;
        break;
      case 15:
        idx = 5;
        break;
      case 30:
        idx = 6;
        break;
      case 45:
        idx = 7;
        break;
      case 60:
        idx = 8;
        break;
      case 120:
        idx = 9;
        break;
      default:
        break;
    }
    if (idx !== -1) {
      collect({
        idx,
        userId: userInfo?.user_id || undefined,
        act: 'hb',
        cid,
        extra: extra || {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftSeconds]);
};
