import classNames from 'classnames/bind';

import styles from './index.module.less';

interface Props {
  error: string;
}

export function ErrorTip({ error }: Props) {
  const cx = classNames.bind(styles);
  return (
    <div className={cx('error-msg')}>
      <div className={cx('error-icon')} />
      {error}
    </div>
  );
}
