import { useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { getBotSafeDatas } from '@/api';
import { LoadingIcon } from '@/components/Common/LoadingIcon';
import { orgIdAtom } from '@/globalAtom';
import {
  AvailableGeneralBotsListItem,
  unlimitedTokenValue,
  unlimitedTokenValueNumber,
} from '@/interfaces';

interface Props {
  bot: AvailableGeneralBotsListItem;
  safeId?: string;
  mode?: 'manage' | 'detail';
}

export function LeverageBotInfo({ bot, safeId, mode }: Props) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const [currentRatioLoading, setCurrentRatioLoading] = useState(false);
  const [currentRatio, setCurrentRatio] = useState<number | undefined>(
    undefined,
  );
  const [minRatio, setMinRatio] = useState<number | undefined>(undefined);
  const [orgId] = useAtom(orgIdAtom);

  const bot_definition_id = useMemo(() => {
    return bot.bot_definition_id;
  }, [bot]);

  useEffect(() => {
    return () => {
      setCurrentRatioLoading(false);
      setCurrentRatio(undefined);
    };
  }, []);

  useEffect(() => {
    // 当前抵押率每个Safe都不一样，所以需要每次切换Safe的时候，更新一下
    const getCurrentRatio = async () => {
      if (!safeId || !orgId || !bot_definition_id) return;
      setCurrentRatioLoading(true);
      try {
        const value = await getBotSafeDatas(orgId, bot_definition_id, safeId);
        const ratio = value.current_ratio;
        const min_ratio = value.min_ratio;
        if (
          ratio !== unlimitedTokenValue &&
          ratio !== unlimitedTokenValueNumber
        ) {
          let current_ratio: number | undefined =
            typeof ratio === 'string' ? parseFloat(ratio) : ratio;
          if (!current_ratio || current_ratio === 0) {
            current_ratio = undefined;
          }
          setCurrentRatio(current_ratio);
        }

        let min: number | undefined =
          typeof min_ratio === 'string' ? parseFloat(min_ratio) : min_ratio;
        setMinRatio(min);
      } finally {
        setCurrentRatioLoading(false);
      }
    };
    getCurrentRatio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [safeId, bot_definition_id, orgId]);
  const loadingIcon = <LoadingIcon dark={true} />;
  return (
    <div
      className={cx('leverage-bot-info', { 'manage-mode': mode === 'manage' })}
    >
      <div className={cx('item', {})}>
        <span className={cx('label')}>{t('bots.collateralRatio')}:</span>
        <span className={cx('data')}>
          {currentRatioLoading ? (
            <Spin indicator={loadingIcon} />
          ) : currentRatio !== undefined ? (
            currentRatio + '%'
          ) : (
            '-'
          )}
        </span>
      </div>
      <div className={cx('item')}>
        <span className={cx('label')}>
          {t('bots.liquidationCollateralRatio')}:
        </span>
        <span className={cx('data', 'red')}>
          {currentRatioLoading ? (
            <Spin indicator={loadingIcon} />
          ) : minRatio !== undefined ? (
            minRatio + '%'
          ) : (
            '-'
          )}
        </span>
      </div>
    </div>
  );
}
