import React, { useEffect, useMemo, useState } from 'react';
import { useInterval } from 'ahooks';
import { message, Tooltip } from 'antd';
import BigNumber from 'bignumber.js';
import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.less';
import {
  getBotOperationLog,
  getGenericBotTerminateStatus,
  getSafeTxRecord,
  terminateGenericBot,
} from '@/api';
import { getChainIdByCoboName, getShortNameByCoboName } from '@/chains/utils';
import {
  BrowserIcon,
  Button,
  ChainIcon,
  CoboTable,
  Modal,
  Pagination,
} from '@/components';
import { NameLogo } from '@/components/Layout/NameLogo';
import { checkMultiSignTransaction } from '@/components/StrategyBots/terminate';
import { orgIdAtom, orgsAtom } from '@/globalAtom';
import { useSignAndSubmit } from '@/hooks/useSignAndSubmit';
import {
  BotTxLog,
  BotTxLogOperation,
  BotTxLogOperationValue,
  ChainShortNameType,
  InvestmentTransaction,
} from '@/interfaces';
import {
  hideConfirmModal,
  isRole,
  showConfirmModal,
  timestampToStr,
  truncateAddress,
} from '@/utils/utils';

interface TxListProps {
  operations: BotTxLogOperation[];
  chainShortName: string;
  executeRecord: boolean;
  safeAddress?: string;
}

function BotTransactionList({
  operations,
  chainShortName,
  executeRecord,
  safeAddress,
}: TxListProps) {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const dataSource = useMemo(() => {
    return operations?.map((item, index) => {
      return {
        ...item,
        keyId: index,
      };
    });
  }, [operations]);
  const columns = [
    {
      title: t('investment.genericBot.txTable.status'),
      dataIndex: 'status',
      key: 'status',
      render: (value: string) => (
        <div
          className={cx('status', value === 'Success' ? 'success' : 'failed')}
        >
          {value}
        </div>
      ),
    },
    {
      title: t('investment.genericBot.txTable.type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('investment.genericBot.txTable.details'),
      dataIndex: 'details',
      key: 'details',
      render: ({
        tx_url,
        safe_tx_url,
        error,
      }: {
        tx_url?: string;
        safe_tx_url?: string;
        error?: string;
      }) => {
        if (error) {
          return error;
        }
        if (tx_url || safe_tx_url) {
          return (
            <div className={cx('details')}>
              {tx_url && (
                <div
                  className={cx('link-icon-wrapper')}
                  onClick={() => {
                    if (!tx_url) return;
                    window.open(tx_url);
                  }}
                >
                  <BrowserIcon
                    shortName={chainShortName as ChainShortNameType}
                  />
                </div>
              )}
              {safe_tx_url && (
                <div
                  className={cx('link-icon-wrapper')}
                  onClick={() => {
                    if (!safe_tx_url) return;
                    window.open(safe_tx_url);
                  }}
                >
                  <div className={cx('gnosis-icon')} />
                </div>
              )}
            </div>
          );
        }
        return '—';
      },
    },
    {
      title: t('investment.genericBot.txTable.value'),
      dataIndex: 'value',
      key: 'value',
      render: (valueList: BotTxLogOperationValue[]) => {
        if (!valueList || valueList.length === 0) {
          return '—';
        }
        return (
          <div className={cx('value-wrapper')}>
            {valueList.map(value => {
              const {
                from_address,
                to_address,
                token_info,
                token_address,
                amount,
              } = value;
              const isIn =
                safeAddress &&
                to_address?.toLowerCase() === safeAddress?.toLowerCase();
              const isOut =
                safeAddress &&
                from_address?.toLowerCase() === safeAddress?.toLowerCase();
              const amountValue = new BigNumber(amount)
                .div(10 ** token_info.decimals)
                .toFixed();
              return (
                <div className={cx('value-item')}>
                  <ChainIcon
                    className={cx('token-icon')}
                    url={token_info.icon_url}
                    shortName={
                      !token_info.icon_url &&
                      token_address ===
                        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
                        ? (chainShortName as ChainShortNameType)
                        : undefined
                    }
                  />
                  <div className={cx('value-text', isIn ? 'value-in' : '')}>
                    <span>{isIn ? '+' : isOut ? '-' : ''}</span>
                    {`${amountValue} ${token_info.symbol}`}
                  </div>
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      title: t('investment.genericBot.txTable.time'),
      dataIndex: 'time',
      key: 'time',
      render: (text: string, record: InvestmentTransaction) => (
        <div className={cx('time')}>{timestampToStr(record.time)}</div>
      ),
    },
  ];
  return (
    <CoboTable
      pagination={false}
      rowKey="keyId"
      dataSource={dataSource || []}
      columns={columns}
      rowClassName={(record, index) => {
        if (!executeRecord) return '';
        const manuallyExecute = 'Manually Execute';
        if (record.type !== manuallyExecute) return '';
        const prevIdx = operations.findIndex(op => op.type === manuallyExecute);
        return prevIdx === index ? cx('manually-exe-item') : '';
      }}
      locale={{
        emptyText: (
          <div style={{ padding: '40px 0' }}>
            <img
              alt=""
              style={{ width: '160px' }}
              src={require('@/assets/images/empty.png')}
            />
            <div style={{ padding: '20px 0 0 0' }}>
              {t('common.emptyTable')}
            </div>
          </div>
        ),
      }}
    />
  );
}

interface Props {
  instance?: {
    bot_definition_id: string;
    bot_type: number;
    bot_instance_id: string;
    chain: string;
    safe_id: string;
    safe_address: string;
    safe_module_id: string;
    safe_module_address: string;
    status: string;
    bot_instance_name: string;
    strategy_id: string;
  };
  onClose: () => void;
  onRefresh: () => Promise<void>;
  executeRecord: boolean;
}

export function BotDetailModal({
  instance,
  onClose,
  onRefresh,
  executeRecord,
}: Props) {
  const cx = classNames.bind(styles);
  const [log, setLog] = useState<BotTxLog>();
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [terminateLoading, setTerminateLoading] = useState(false);
  const [terminateTx, setTerminateTx] = useState<string>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [orgId] = useAtom(orgIdAtom);
  const [organizations] = useAtom(orgsAtom);
  const orgInfo = useMemo(
    () =>
      organizations ? organizations.find(item => item.id === orgId) : undefined,
    [orgId, organizations],
  );
  const signAndSubmit = useSignAndSubmit(
    instance?.safe_address || '',
    getChainIdByCoboName(instance?.chain || ''),
  );
  const canEdit = useMemo(() => orgInfo && !isRole(orgInfo, 2), [orgInfo]);
  const close = () => {
    setLog(undefined);
    setPage(1);
    setTotal(0);
    setLoading(false);
    setTerminateLoading(false);
    setTerminateTx('');
    onClose();
  };

  const confirmTerminate = () => {
    showConfirmModal({
      confirmModalVisible: true,
      confirmModalTitle: t('bots.note'),
      confirmModalElement: t('bots.confirmTerminateContent', {
        title: instance?.bot_instance_name,
      }),
      onConfirm: () => {
        onTerminate();
      },
      onCancel: () => {
        hideConfirmModal();
      },
      okText: t('common.yes'),
      cancelText: t('common.no'),
      showConfirmModalClose: () => {
        hideConfirmModal();
      },
    });
  };

  const onTerminate = async () => {
    if (!instance || terminateLoading || !canEdit) return;
    try {
      setTerminateLoading(true);
      const safeTx = await terminateGenericBot(
        instance.safe_id,
        instance.bot_instance_id,
      );
      if (!safeTx) {
        setTerminateLoading(false);
        close();
        await onRefresh();
        return;
      }
      await signAndSubmit(safeTx);
      setTerminateTx(safeTx.safe_tx_hash);
      checkMultiSignTransaction(
        instance.chain,
        instance.safe_address,
        true,
        safeTx.safe_tx_hash,
      );
    } finally {
      setTerminateLoading(false);
    }
  };
  const loadLogs = async () => {
    if (!instance || loading) return;
    setLoading(true);
    try {
      const resp = await getBotOperationLog(
        instance.bot_instance_id,
        instance.safe_id,
        page,
        pageSize,
      );
      setLog(resp);
      setTotal(resp.total_count);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!instance) {
      setLog(undefined);
    } else {
      loadLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, page, pageSize]);

  useEffect(() => {
    // check terminate status
    if (!instance || terminateTx) return;
    const load = async () => {
      const safeTx = await getGenericBotTerminateStatus(
        instance.safe_id,
        instance.safe_module_id,
        instance.bot_instance_id,
        false,
      );
      setTerminateTx(safeTx?.pending_bot_terminate_tx_hash || '');
    };
    load();
  }, [instance, terminateTx]);

  useInterval(async () => {
    if (!instance || !terminateTx || !canEdit) return;
    const record = await getSafeTxRecord(instance.chain, terminateTx);
    const { status } = record;
    if ([40, 50, 60, 70].includes(status)) {
      if ([40, 60, 70].includes(status)) {
        message.error(t('bots.terminateFailedMsg'));
      } else {
        message.success(t('bots.terminateSuccessMsg'));
      }
      setTerminateTx('');
      close();
      await onRefresh();
    }
  }, 1000 * 5);

  const title = (
    <div className={cx('bot-detail-modal-title')}>
      <div className={cx('bot-icon')} />
      <div className={cx('bot-name')}>{instance?.bot_instance_name || ''}</div>
      <div
        className={cx(
          'bot-status',
          instance?.status === 'running'
            ? 'running'
            : instance?.status === 'paused'
            ? 'paused'
            : 'terminated',
        )}
      >
        {instance?.status && instance.status.length > 0
          ? `${instance.status.charAt(0).toUpperCase()}${instance.status.slice(
              1,
            )}`
          : ''}
      </div>
    </div>
  );

  const successRate: number = useMemo(() => {
    if (!log || !log.total_exec_count || !log.success_exec_count) return 0;
    return log.success_exec_count / log.total_exec_count || 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [log?.success_exec_count, log?.total_exec_count]);

  return (
    <Modal
      className={cx('bot-detail-modal')}
      visible={!!instance}
      width={1000}
      title={title}
      onCloseBtnClick={() => close()}
    >
      <div className={cx('content-wrapper')}>
        <div className={cx('safe-info')}>
          {log?.safe_name && <NameLogo label={log.safe_name} radius="5px" />}
          <div className={cx('safe-name')}>{`${log?.safe_name || ''}${
            log?.safe_name && log?.safe_address ? ':' : ''
          }${truncateAddress(log?.safe_address || '')}`}</div>
        </div>
        <div className={cx('info-box')}>
          <div className={cx('info-box-top')}>
            <div className={cx('info-item')}>
              <div className={cx('info-item-title')}>
                {t('bots.successRate')}:&nbsp;
              </div>
              {log?.total_exec_count === 0 ? (
                '-'
              ) : (
                <div
                  className={cx('info-item-value', {
                    'text-success': successRate >= 0.75 && successRate <= 1,
                    'text-warning': successRate >= 0.5 && successRate < 0.75,
                    'text-failed': successRate < 0.5,
                  })}
                >
                  {successRate !== 0 ? (successRate * 100).toFixed(2) : 0}%
                </div>
              )}
            </div>
            <div className={cx('info-item')}>
              <div className={cx('info-item-title')}>
                {t('bots.averageGasCost')}:&nbsp;
              </div>
              <div className={cx('info-item-value')}>
                {log?.avg_gas_cost || 0}
              </div>
            </div>
          </div>
          <div className={cx('info-box-bottom')}>
            <div className={cx('info-item')}>
              <div className={cx('info-label')}>{t('bots.execute')}:&nbsp;</div>
              <div className={cx('info-content')}>
                {log?.total_exec_count}&nbsp;{t('bots.times')}
              </div>
            </div>
            <div className={cx('info-item')}>
              <div className={cx('info-label')}>{t('bots.success')}:&nbsp;</div>
              <div className={cx('info-content')}>
                {log?.success_exec_count}&nbsp;{t('bots.times')}
              </div>
            </div>
            <div className={cx('info-item')}>
              <div className={cx('info-label')}>{t('bots.fail')}:&nbsp;</div>
              <div className={cx('info-content')}>
                {log?.fail_exec_count}&nbsp;{t('bots.times')}
              </div>
            </div>
            <div className={cx('info-item')}>
              <div className={cx('info-label')}>
                {t('bots.totalGasCost')}:&nbsp;
              </div>
              <div className={cx('info-content')}>
                {log?.total_gas_cost || 0}
              </div>
            </div>
          </div>
        </div>
        <BotTransactionList
          operations={log?.operations || []}
          chainShortName={getShortNameByCoboName(instance?.chain || '')}
          safeAddress={log?.safe_address}
          executeRecord={page === 1 && executeRecord}
        />
        <div className={cx('pag-wrapper')}>
          <Pagination
            showQuickJumper
            showSizeChanger={false}
            current={page}
            total={total}
            pageSize={pageSize}
            onChange={value => {
              if (loading) return;
              setPage(value);
            }}
          />
        </div>
        {instance && canEdit && (
          <div className={cx('actions')}>
            <div className={cx('box')} />
            <Button
              className={cx('action-btn')}
              btnClassName={cx('action-btn-inner', 'terminate')}
              onClick={() => {
                if (terminateTx === undefined) return;
                if (terminateTx) return;
                confirmTerminate();
              }}
              loading={terminateLoading || !!terminateTx}
              disabled={terminateTx === undefined}
            >
              {t('bots.terminate')}
            </Button>
            <div className={cx('box')}>
              <Tooltip title={t('bots.terminateTip')} placement="bottom">
                <div className={cx('tip-icon')} />
              </Tooltip>
            </div>
            {instance.bot_type === 3 ||
            (instance.strategy_id && instance.strategy_id !== '0') ? (
              <Button
                className={cx('action-btn')}
                btnClassName={cx('action-btn-inner', 'edit')}
                onClick={() => {
                  if (!instance) return;
                  const isCustomize = instance.bot_type === 3;
                  if (isCustomize) {
                    navigate(`/generic-bot/${instance.bot_instance_id}`, {
                      state: {
                        defaultSafe: instance.safe_address || '',
                        defaultModule: instance.safe_module_address || '',
                      },
                    });
                  } else {
                    navigate(
                      `/service-config/farming/${instance.strategy_id}?service_id=${instance.bot_definition_id}`,
                    );
                  }
                }}
              >
                {t('bots.edit')}
              </Button>
            ) : null}
          </div>
        )}
      </div>
    </Modal>
  );
}
