import i18n from 'i18next';

import { getShortNameByCoboName } from '@/chains/utils';
import { getSafeTxUrl } from '@/sdk/safe';
import { hideConfirmModal, showConfirmModal } from '@/utils/utils';

export const checkMultiSignTransaction = (
  chain_id: string,
  safe_address: string,
  isFromBot: boolean = false,
  safeTx: string,
) => {
  setTimeout(() => {
    showConfirmModal({
      confirmModalVisible: true,
      confirmModalTitle: i18n.t('bots.needsConfirmationTitle'),
      confirmModalElement: (
        <>
          <p>
            {isFromBot
              ? i18n.t('bots.needsConfirmationContent1')
              : i18n.t('safeInfo.needsConfirmationContent1')}
          </p>
          <p>
            {i18n.t('safeInfo.needsConfirmationContent2')}
            <span
              onClick={() => {
                window.open(
                  getSafeTxUrl(
                    getShortNameByCoboName(chain_id),
                    safe_address,
                    safeTx,
                  ),
                  '_blank',
                );
              }}
            >
              {i18n.t('safeInfo.gnosisSafe')}
            </span>
          </p>
        </>
      ),
      onConfirm: () => {
        window.open(
          getSafeTxUrl(getShortNameByCoboName(chain_id), safe_address, safeTx),
          '_blank',
        );
        hideConfirmModal();
      },
      onCancel: () => {
        hideConfirmModal();
      },
      okText: i18n.t('safeInfo.checkTransaction'),
      cancelText: '',
      showConfirmModalClose: () => {
        hideConfirmModal();
      },
    });
  }, 0);
};
