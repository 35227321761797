import { useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';

import {
  formattedParamsAtom,
  methodAtom,
  paramsAtom,
  promiseTriggerAtom,
} from '@/walletconnect/atoms/txflow';
import { formatPayload } from '@/walletconnect/utils/parser';

const useRequestTxflow = (chainId: number) => {
  const [, setMethod] = useAtom(methodAtom);
  const [, setParams] = useAtom(paramsAtom);
  const [, setFormattedParams] = useAtom(formattedParamsAtom);
  const [, setTrigger] = useAtom(promiseTriggerAtom);
  useEffect(() => {
    return () => {
      setMethod(undefined);
      setParams(undefined);
      setFormattedParams(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useCallback(
    async (method: string, params: any): Promise<string> => {
      setMethod(method);
      setParams(params);
      setFormattedParams(
        await formatPayload(
          {
            method,
            params,
          },
          chainId,
        ),
      );
      return new Promise((resolve, reject) => {
        setTrigger({ resolve, reject });
      });
    },
    [chainId, setFormattedParams, setMethod, setParams, setTrigger],
  );
};

export default useRequestTxflow;
