import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.less';
import { Breadcrumb } from '@/components';
import { StrategyListItem } from '@/interfaces';

interface Props {
  strategyInfo?: StrategyListItem;
  editing: boolean;
  isFarming?: boolean;
}

export function BotBreadcrumb({ strategyInfo, editing, isFarming }: Props) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breadcrumbItems = strategyInfo
    ? [
        {
          label: t('investment.strategy'),
          onClick: () => navigate('/investment'),
        },
        {
          label: strategyInfo.strategy || 'Strategy Detail',
          onClick: () =>
            navigate(
              `/strategy-info/${
                strategyInfo.desc.type === 2 ? 'farming' : 'trading'
              }/${strategyInfo.desc.unique_id}`,
            ),
        },
        {
          label: editing
            ? t('investment.strategyBot.editBot')
            : t('investment.strategyBot.setup'),
          onClick: () => {},
        },
      ]
    : [
        {
          label: t('investment.farming'),
          onClick: () => navigate('/investment'),
        },
        editing && {
          label: t('bots.myBots'),
          onClick: () => {
            navigate('/my-portfolio/farming?tab=customizedBot');
          },
        },
        {
          label: editing
            ? t('investment.genericBot.editBot')
            : t('bots.createBots'),
          onClick: () => {},
        },
      ];
  const items = breadcrumbItems.filter(item => !!item);
  return (
    <div className={cx('wrapper')}>
      <Breadcrumb
        items={
          items as {
            label: string;
            onClick?: () => void;
          }[]
        }
      />
    </div>
  );
}
