import { atom, createStore } from 'jotai';

import {
  BindSafeInfo,
  BundleInfo,
  GnosisSafeInfo,
  MeAddressInfo,
  MemberInfo,
  Module,
  OrganizationItem,
  SignMessage,
  TransactionsQueueInfo,
  TransactionsQueueItem,
} from '@/interfaces';
import { MyOrganizationStatus } from '@/utils/constants';

const coboAtomStore = createStore();

export default coboAtomStore;

export const globalLoadingAtom = atom(false);
export const globalTeamMembersAtom = atom<MemberInfo[] | undefined>(undefined);
export const globalTeamMembersWithAddresses = atom<MemberInfo[] | undefined>(
  undefined,
);
export const addressBookAtom = atom<MeAddressInfo[] | undefined>(undefined);
export const orgIdAtom = atom<string | null>(null);
export const orgsAtom = atom<OrganizationItem[] | null>(null);
export const orgsSetterAtom = atom(
  null,
  (get, set, args: OrganizationItem[] | null) => {
    set(orgsAtom, args);
    if (!args) return;
    const validOrganizations = args.filter(item =>
      [MyOrganizationStatus.CREATED, MyOrganizationStatus.JOINED].includes(
        item.status,
      ),
    );
    const orgId = get(orgIdAtom);
    if (!orgId) {
      const defaultOrg = validOrganizations.find(item => item.is_default);
      if (defaultOrg) {
        set(orgIdAtom, defaultOrg.id);
      } else if (args.length > 0) {
        if (validOrganizations.length > 0) {
          set(orgIdAtom, validOrganizations[0].id);
        }
      }
    } else {
      if (!validOrganizations.map(item => item.id).includes(orgId)) {
        set(orgIdAtom, validOrganizations[0].id);
      }
    }
  },
);
export const canEditAtom = atom<boolean>(false);
export const globalTeamMembersLoadingAtom = atom<boolean>(false);
export const pathHistoryAtom = atom<string[]>([]);

/**Safe */
export const globalSafeInfoAtom = atom<BindSafeInfo | undefined>(undefined);
export const globalGnosisSafeInfoAtom = atom<GnosisSafeInfo | undefined>(
  undefined,
);
export const globalSafeIdAtom = atom<string | undefined>(undefined);
export const safeListAtom = atom<BindSafeInfo[] | undefined>(undefined);
export const allModulesAtom = atom<Module[] | undefined>(undefined);
export const latestModuleVersionAtom = atom<string | undefined>(undefined);
export const safeBalanceMapAtom = atom<Map<string, number>>(new Map());
export const safeModuleMapAtom = atom<Map<string, Module[]>>(new Map());
export const safeModuleAtom = atom<Module | undefined>(undefined);

export const safeSelectOpenAtom = atom<boolean>(false);

/**User */
export const globalUserInfoAtom = atom<{
  name: string;
  has_oauth_enable_2fa?: boolean;
  email: string;
  has_2fa: boolean;
  invite_record_count: number;
  org_count: number;
  is_v1_user: boolean;
  user_id: string | null;
  login_address: string;
  can_login_by_email: boolean;
  web3_login_addresses: string[];
  is_new_user?: boolean;
  accept_terms?: boolean;
  google_one_tap_email?: string;
} | null>(null);

/**Transaction */
export const SafeBundleInfoAtom = atom<BundleInfo | undefined>(undefined);
export const SafeTransactionQueueAtom = atom<TransactionsQueueInfo | undefined>(
  undefined,
);
export const SafeTransactionHistoryAtom = atom<
  TransactionsQueueItem[] | undefined
>(undefined);

export const globalTitleAtom = atom<string>('');
export const globalFocusMenuAtom = atom<string>('');
export const globalAccountModalVisibleAtom = atom<boolean>(false);

export const globalSignPromise = atom<
  | {
      resolve: (value: { message: SignMessage; signature: string }) => void;
      reject: (error: Error) => void;
    }
  | undefined
>(undefined);
