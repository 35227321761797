import { message } from 'antd';
import classNames from 'classnames/bind';
import { QRCodeCanvas } from 'qrcode.react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { Modal } from '@/components';
import { GasData } from '@/interfaces';
import { copyToClipboard } from '@/utils/clipboard';

interface GasAccountQrCodeModalProps {
  show: boolean;
  gasData: GasData | null;
  onClose: () => void;
}
export function GasAccountQrCodeModal({
  show,
  gasData,
  onClose,
}: GasAccountQrCodeModalProps) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  return (
    <Modal
      width={680}
      className={styles['qr-code-modal']}
      visible={show}
      onCloseBtnClick={onClose}
      title={t('bots.depositGas', {
        token: gasData?.gas_token || '',
      })}
      destroyOnClose={true}
      zIndex={1001}
    >
      <div className={styles['qr-code']}>
        <QRCodeCanvas
          value={gasData?.address || ''}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
      <div className={styles['qr-code-content']}>
        <div className={styles['qr-code-address']}>
          <span className={styles['qr-code-text']}>{gasData?.address}</span>
          <span
            className={cx('copy-btn')}
            onClick={async () => {
              try {
                await copyToClipboard({ value: gasData?.address });
                message.success(t('safeManagement.copy'));
              } catch (e: any) {
                message.error(`${t('common.copyFailed')} ${e.message}`);
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
