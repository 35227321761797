import { coboApi } from '@/api/axios';
import { SafeTx, TokenApprovalListItem } from '@/interfaces';

export const loadTokenApprovals = async (
  safe_id: string,
  chain_id: string,
  silence: boolean = false,
): Promise<TokenApprovalListItem[]> => {
  return await coboApi.post(
    '/argus/token_approval/get_token_approvals/',
    {
      address: safe_id,
      chain_id,
    },
    {
      params: { silence },
    },
  );
};

export const updateTokenApproval = async (
  safe_id: string,
  chain_id: string,
  token_address: string,
  spender_address: string,
  allowance: string,
): Promise<SafeTx> => {
  return await coboApi.post('/argus/token_approval/update_token_approval/', {
    safe_id,
    chain_id,
    token_address,
    spender_address,
    allowance,
  });
};

export const batchRevokeTokenApproval = async (
  safe_id: string,
  chain_id: string,
  token_approvals: {
    token_address: string;
    spender_address: string;
  }[],
): Promise<SafeTx> => {
  const list = JSON.stringify(token_approvals);
  return await coboApi.post(
    '/argus/token_approval/batch_revoke_token_approval/',
    {
      safe_id,
      chain_id,
      token_approvals: list,
    },
  );
};

export const revokeTokenApproval = async (
  safe_id: string,
  chain_id: string,
  token_address: string,
  spender_address: string,
): Promise<SafeTx> => {
  return await coboApi.post('/argus/token_approval/revoke_token_approval/', {
    safe_id,
    chain_id,
    token_address,
    spender_address,
  });
};
