import { useCallback, useEffect } from 'react';
import { useAtom, useAtomValue } from 'jotai';

import { getMyDelegateAddress } from '@/api';
import { addressBookAtom, globalSafeInfoAtom } from '@/globalAtom';

export const useMyAddressBook = () => {
  const safeInfo = useAtomValue(globalSafeInfoAtom);
  const [addressTable, setAddressTable] = useAtom(addressBookAtom);
  // 获取列表数据
  const getList = useCallback(async () => {
    if (!safeInfo) return;
    const result = await getMyDelegateAddress(safeInfo.id);
    setAddressTable(result);
    return result;
  }, [safeInfo, setAddressTable]);

  useEffect(() => {
    if (safeInfo) setAddressTable(undefined);
  }, [safeInfo, setAddressTable]);

  useEffect(() => {
    if (addressTable === undefined) {
      getList();
    }
  }, [addressTable, getList]);
  return {
    addressBook: addressTable,
    refresh: getList,
  };
};
