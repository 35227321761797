import classNames from 'classnames/bind';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import styles from '../index.module.less';
import { Button } from '@/components';
import Header from '@/routes/SafeDetail/components/WalletConnectTab/Header';
import { formattedParamsAtom } from '@/walletconnect/atoms/txflow';

interface Props {
  peerMeta: any;
  approveRequest: () => void;
  rejectRequest: () => void;
  killSession: () => void;
  loading: boolean;
}

export default function Connected({
  peerMeta,
  approveRequest,
  rejectRequest,
  killSession,
  loading,
}: Props) {
  const [formattedParams] = useAtom(formattedParamsAtom);
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  if (!formattedParams) return null;
  return (
    <div className={cx('wallet-connect-container', 'parse-container')}>
      <Header killSession={killSession} peerMeta={peerMeta} />
      <div className={cx('tx-detail-data')}>
        {formattedParams.map((item, index) => {
          return (
            <div className={cx('tx-data-item')} key={`${item.label}-${index}`}>
              <div className={cx('tx-data-label')}>{item.label}:</div>
              <div className={cx('tx-data-value')}>{item.value}</div>
            </div>
          );
        })}
      </div>
      <div className={cx('footer')}>
        <Button
          onClick={() => {
            rejectRequest();
          }}
          disabled={loading}
          className={cx('reject-btn-wrapper')}
          btnClassName={cx('reject-btn')}
        >
          {t('common.reject')}
        </Button>
        <Button onClick={approveRequest} loading={loading}>
          {t('common.approve')}
        </Button>
      </div>
    </div>
  );
}
