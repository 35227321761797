import { useCallback, useEffect, useRef, useState } from 'react';
import WalletConnect from '@walletconnect/client';
import {
  IClientMeta,
  IWalletConnectSession,
} from '@walletconnect/legacy-types';
import { useAtom } from 'jotai';

import { compatibleSafeMethods } from './useWalletConnectV2';
import { globalSafeInfoAtom } from '@/globalAtom';
import useRequestTxflow from '@/walletconnect/hooks/useRequestTxflow';

const rejectWithMessage = (
  connector: WalletConnect,
  id: number | undefined,
  message: string,
) => {
  connector.rejectRequest({ id, error: { message } });
};

const useWalletConnectV1 = (chainId: number, safeAddress: string) => {
  const requestTxflow = useRequestTxflow(chainId);
  const [wcClientData, setWcClientData] = useState<IClientMeta | null>(null);
  const [connector, setConnector] = useState<WalletConnect | undefined>();
  const [safeInfo] = useAtom(globalSafeInfoAtom);
  const localStorageSessionKey = useRef(`session_${safeAddress}`);

  const wcDisconnect = useCallback(async () => {
    try {
      await connector?.killSession();
      setConnector(undefined);
      setWcClientData(null);
    } catch (error) {
      console.log('Error trying to close WC session: ', error);
    }
  }, [connector]);

  const wcConnect = useCallback(
    async ({
      uri,
      session,
    }: {
      uri?: string;
      session?: IWalletConnectSession;
    }) => {
      const wcConnector = new WalletConnect({
        uri,
        session,
        storageId: localStorageSessionKey.current,
      });
      setConnector(wcConnector);
      setWcClientData(wcConnector.peerMeta);

      wcConnector.on('session_request', (error, payload) => {
        if (error) {
          throw error;
        }

        wcConnector.approveSession({
          accounts: [safeAddress],
          chainId: chainId,
        });

        setWcClientData(payload.params[0].peerMeta);
      });

      wcConnector.on('call_request', async (error, payload) => {
        if (error) {
          throw error;
        }

        try {
          // const result = await web3Provider.send(payload.method, payload.params);
          console.log(payload.method, payload.params);
          if (!compatibleSafeMethods.includes(payload.method)) {
            throw new Error(`We don't support ${payload.method} for now.`);
          }
          if (
            !['eth_sendTransaction', 'eth_signTransaction'].includes(
              payload.method,
            )
          ) {
            await wcConnector.approveRequest({
              id: payload.id,
              result: '0x',
            });
          } else {
            const result = await requestTxflow(payload.method, payload.params);
            wcConnector.approveRequest({
              id: payload.id,
              result,
            });
          }
        } catch (err) {
          rejectWithMessage(wcConnector, payload.id, (err as Error).message);
        }
      });

      wcConnector.on('disconnect', error => {
        if (error) {
          throw error;
        }
        wcDisconnect();
      });
    },
    [chainId, requestTxflow, safeAddress, wcDisconnect],
  );

  useEffect(() => {
    if (!safeInfo?.id) return;
    if (!connector) {
      const session = localStorage.getItem(localStorageSessionKey.current);
      if (session) {
        wcConnect({ session: JSON.parse(session) });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connector, wcConnect, safeInfo?.id]);

  return { wcClientData, wcConnect, wcDisconnect };
};

export default useWalletConnectV1;
