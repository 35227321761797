import { BaseChain } from './base';

export default class Avax extends BaseChain {
  name = 'Avalanche';
  shortName = 'avax';

  private providerUrl = 'https://api.avax.network/ext/bc/C/rpc';
  private scanBaseUrl = 'https://snowtrace.io';
  public safeWebBaseUrl = 'https://app.safe.global';
  private safeApiBaseUrl = 'https://safe-transaction-avalanche.safe.global';
  subsafeFactory = '0x09Ca9a159845D40ab2a5Cf42079a3672c5E33035';
  baseToken = 'AVAX';
  baseTokenName = 'Avalanche';
  multiSendCallOnlyAddress = '0x998739BFdAAdde7C933B942a68053933098f9EDa';

  public safeUrl(address: string): string {
    return `${this.safeWebBaseUrl}/home?safe=${this.shortName}:${address}`;
  }

  public addressUrl(address: string): string {
    return `${this.scanBaseUrl}/address/${address}`;
  }

  public tokenUrl(address: string): string {
    return `${this.scanBaseUrl}/token/${address}`;
  }

  public rpcUrl(): string {
    return this.providerUrl;
  }

  public safeApiUrl(): string {
    return this.safeApiBaseUrl;
  }

  public txDetailUrl(hash: string): string {
    return `${this.scanBaseUrl}/tx/${hash}`;
  }
}
