import { useCallback, useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { isNaN } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { CodeBox, Modal } from '@/components';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { updateCoboModalResolver, updateCodeModalVisible } from '@/store';
import { readClipboard } from '@/utils/clipboard';

export function CodeModal() {
  const { t } = useTranslation();
  const show = useAppSelector(state => state.global.codeModalVisible);
  const resolver = useAppSelector(state => state.global.coboModalResolver);
  const rejection = useAppSelector(state => state.global.coboModalRejection);
  const dispatch = useAppDispatch();
  const [code, setCode] = useState<string>('');
  const onCodeChange = (code: string) => {
    setCode(code);
  };
  const onCancel = useCallback(() => {
    dispatch(updateCodeModalVisible(false));
    dispatch(updateCoboModalResolver(null));
    if (rejection) {
      rejection('code modal dismiss');
    }
  }, [dispatch, rejection]);

  useEffect(() => {
    if (!show) {
      setCode('');
      return;
    }
    const currentCode = code.replace(/\s/g, '');
    if (currentCode.toString().length === 6) {
      if (resolver) {
        resolver(currentCode);
        onCancel();
      }
    }
  }, [code, onCancel, resolver, show]);

  const onPaste = async () => {
    const pasteValue = await readClipboard();
    const codeArray = Array.from(pasteValue);
    const result: string[] = [];
    codeArray.forEach(c => {
      const num = parseInt(c);
      if (isNaN(num)) {
        result.push(' ');
      } else {
        result.push(num + '');
      }
    });
    setCode(result.join(''));
  };

  return (
    <Modal
      className={styles['code-modal']}
      visible={show}
      onCloseBtnClick={onCancel}
      title={t('auth.verifyGaCode')}
      destroyOnClose={true}
      zIndex={10001}
    >
      <p className={styles['code-modal-text']}>{t('auth.pleaseEnter2fa')}</p>
      <div className={styles['code-modal-body']} id="code-modal">
        <div className={styles['code-modal-code-box']}>
          <CodeBox value={code} onChange={onCodeChange} />
        </div>
        <Button
          type="text"
          className={styles['parse-button']}
          onClick={onPaste}
        >
          {t('common.paste')}
        </Button>
      </div>
      <Tooltip
        placement="bottomLeft"
        title={t('auth.gaHint')}
        getPopupContainer={() =>
          document.getElementById('code-modal') as HTMLElement
        }
      >
        <span className={styles['code-modal-tooltip']}>
          {t('auth.unavailableHint')}
        </span>
      </Tooltip>
    </Modal>
  );
}
