import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { CoboSelect } from '@/components';
import { Module } from '@/interfaces';
import { copyStr, truncateAddress } from '@/utils/utils';

interface Props {
  name?: string;
  selectedModule: Module | null;
  modules: Module[] | null;
  disabled?: boolean;
  defaultModule: string;
  defaultInLabel?: boolean;
  defaultInOption?: boolean;
  truncate?: boolean;
  onChange: (module: Module) => void;
  optionFormat?: (module: Module) => string;
  nameStyle?: object;
  selectorStyle?: object;
  copyable?: boolean;
}

export function ModuleSelect({
  name,
  selectedModule,
  modules,
  disabled,
  defaultModule,
  defaultInLabel,
  defaultInOption,
  truncate,
  onChange,
  optionFormat,
  nameStyle,
  selectorStyle,
  copyable,
}: Props) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const renderLabel = (module: Module) => {
    return (
      <div className={cx('module-content')}>
        <div className={cx('module-version')}>Version {module.version}</div>
        {defaultInLabel && defaultModule === module.address ? (
          <div className={cx('module-default')}>Default</div>
        ) : null}
        <div className={cx('module-address')}>
          {truncate ? truncateAddress(module.address || '') : module.address}
        </div>
      </div>
    );
  };
  const renderOption = (module: Module) => {
    const selected = selectedModule?.id === module.id;
    return (
      <div className={cx('module-option-content')}>
        <div className={cx('module-info-wrapper')}>
          <div className={cx('module-info')}>
            <div className={cx('module-version')}>Version {module.version}</div>
            {defaultModule === module.address && defaultInOption ? (
              <div className={cx('module-default')}>Default</div>
            ) : null}
          </div>
          <div className={cx('module-address')}>{module.address}</div>
        </div>
        {selected ? <div className={cx('icon-selected')} /> : null}
      </div>
    );
  };

  return (
    <div className={cx('safe-selector-wrapper')}>
      {name && <div className={cx('name')}>{name.trim()}</div>}
      <div className={cx('selector')} id="module-select" style={selectorStyle}>
        <CoboSelect
          value={
            modules && modules.find(module => module.id === selectedModule?.id)
              ? selectedModule?.id
              : undefined
          }
          placeholder={t('safe.chooseModulePlaceholder')}
          disabled={disabled}
          onChange={module => {
            if (!modules) return;
            const currentModule = modules.find(m => m.id === module);
            if (!currentModule) return;
            onChange(currentModule);
          }}
          className={styles['module-selector']}
          // getPopupContainer={() =>
          //   document.getElementById('module-select') as HTMLElement
          // }
          popupMatchSelectWidth={true}
          optionLabelProp="label"
          options={
            modules
              ? modules.map(config => ({
                  label:
                    (optionFormat && optionFormat(config)) ||
                    renderLabel(config),
                  value: config.id,
                }))
              : []
          }
          renderOption={value => {
            const currentModule = modules?.find(m => m.id === value);
            if (!currentModule) return '-';
            return renderOption(currentModule);
          }}
        />
        {copyable && selectedModule?.address ? (
          <div className={cx('copy-btn')}>
            <span onClick={() => copyStr(selectedModule.address || '')}>
              {t('common.copyToClipboard')}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
