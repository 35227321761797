import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BindSafeInfo,
  Module,
  ProtectorTableItem,
  StrategyACL,
  StrategyAuthorization,
  TokenWithBalance,
  WhitelistItem,
} from '@/interfaces';

const initialState: {
  safelist: BindSafeInfo[] | null;
  authorizationInfo: StrategyAuthorization | null;
  selectedSafe: null | BindSafeInfo;
  selectedModule: null | Module;
  modules: null | Module[];
  selectedRoleMember: { [key: string]: string[] };
  authorizationResult: 'success' | 'fail';
  tokenTransferWhitelist: string[] | null;
  rewardTokenReceiver: { [key: string]: string } | null;
  transferWhitelist: WhitelistItem[] | null;
  pendingAuthorizeTxHash: string | null;
  subsafeAddress: string | null;
  // acl config
  aclNonce: number | null;
  installNewAcl: boolean;
  protectors: ProtectorTableItem[] | null;
  factoryAclMap: { [key: string]: string } | null;
  generateAclMap: { [key: string]: string } | null;
  showAclLamp: boolean;
  aclTokenOptions: TokenWithBalance[] | null;
  dexAclConfig: StrategyACL | null;
  sellTokens: string[] | null;
  buyTokens: string[] | null;
  healthFactor: string | null;
} = {
  safelist: null,
  modules: null,
  authorizationInfo: null,
  selectedSafe: null,
  selectedModule: null,
  selectedRoleMember: {},
  authorizationResult: 'success',
  tokenTransferWhitelist: null,
  rewardTokenReceiver: null,
  transferWhitelist: null,
  pendingAuthorizeTxHash: null,
  subsafeAddress: null,
  aclNonce: null,
  installNewAcl: false,
  protectors: null,
  factoryAclMap: null,
  generateAclMap: null,
  showAclLamp: false,
  aclTokenOptions: null,
  dexAclConfig: null,
  sellTokens: null,
  buyTokens: null,
  healthFactor: null,
};

const slice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    updateAuthorizationInfo: (
      state,
      action: PayloadAction<StrategyAuthorization | null>,
    ) => {
      state.authorizationInfo = action.payload;
    },
    updateAuthorizationSafeList: (
      state,
      action: PayloadAction<BindSafeInfo[] | null>,
    ) => {
      state.safelist = action.payload;
    },
    updateAuthorizationModules: (
      state,
      action: PayloadAction<Module[] | null>,
    ) => {
      state.modules = action.payload;
    },
    updateSelectedSafe: (state, action: PayloadAction<BindSafeInfo | null>) => {
      state.selectedSafe = action.payload;
    },
    updateSelectedModule: (state, action: PayloadAction<Module | null>) => {
      state.selectedModule = action.payload;
    },
    updateAuthorizationResult: (
      state,
      action: PayloadAction<'success' | 'fail'>,
    ) => {
      state.authorizationResult = action.payload;
    },
    updateSelectedRoleMember: (
      state,
      action: PayloadAction<{ [key: string]: string[] }>,
    ) => {
      const keys = Object.keys(action.payload);
      const selectedRoleMember: { [key: string]: string[] } = {};
      keys.forEach(key => {
        selectedRoleMember[key] = Array.from(new Set(action.payload[key]));
      });
      state.selectedRoleMember = selectedRoleMember;
    },
    updateTokenTransferWhitelist: (state, action: PayloadAction<string[]>) => {
      state.tokenTransferWhitelist = action.payload;
    },
    updateRewardTokenReceiver: (
      state,
      action: PayloadAction<{ [key: string]: string }>,
    ) => {
      state.rewardTokenReceiver = action.payload;
    },
    updateTransferWhitelist: (
      state,
      action: PayloadAction<WhitelistItem[]>,
    ) => {
      state.transferWhitelist = action.payload;
    },
    updatePendingAuthorizeTxHash: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.pendingAuthorizeTxHash = action.payload;
    },
    updateSubSafeAddress: (state, action: PayloadAction<string>) => {
      state.subsafeAddress = action.payload;
    },
    updateAclNonce: (state, action: PayloadAction<number | null>) => {
      state.aclNonce = action.payload;
    },
    updateInstallNewAcl: (state, action: PayloadAction<boolean>) => {
      state.installNewAcl = action.payload;
    },
    updateAuthorizeProtectors: (
      state,
      action: PayloadAction<ProtectorTableItem[] | null>,
    ) => {
      state.protectors = action.payload;
    },
    updateAuthorizeFactoryAclMap: (
      state,
      action: PayloadAction<{ [key: string]: string } | null>,
    ) => {
      state.factoryAclMap = action.payload;
    },
    updateAuthorizeGenerateAclMap: (
      state,
      action: PayloadAction<{ [key: string]: string } | null>,
    ) => {
      state.generateAclMap = action.payload;
    },
    updateAuthorizeAclLamp: (state, action: PayloadAction<boolean>) => {
      state.showAclLamp = action.payload;
    },
    updateAuthorizeAclTokenOptions: (
      state,
      action: PayloadAction<TokenWithBalance[]>,
    ) => {
      state.aclTokenOptions = action.payload;
    },
    updateAuthorizeSellTokens: (state, action: PayloadAction<string[]>) => {
      state.sellTokens = action.payload;
    },
    updateAuthorizeBuyTokens: (state, action: PayloadAction<string[]>) => {
      state.buyTokens = action.payload;
    },
    updateAuthorizeHealthFactor: (state, action: PayloadAction<string>) => {
      state.healthFactor = action.payload;
    },
    updateAuthorizeDexAclConfig: (
      state,
      action: PayloadAction<StrategyACL>,
    ) => {
      state.dexAclConfig = action.payload;
    },
    resetAuthorize: state => {
      state.safelist = null;
      state.modules = null;
      state.authorizationInfo = null;
      state.selectedRoleMember = {};
      state.authorizationResult = 'success';
      state.tokenTransferWhitelist = null;
      state.rewardTokenReceiver = null;
      state.transferWhitelist = [];
      state.subsafeAddress = '';
      state.aclNonce = null;
      state.installNewAcl = false;
      state.protectors = null;
      state.factoryAclMap = null;
      state.generateAclMap = null;
      state.showAclLamp = false;
      state.aclTokenOptions = null;
      state.sellTokens = null;
      state.buyTokens = null;
      state.healthFactor = null;
      state.dexAclConfig = null;
    },
  },
});

export const authorizationReducer = slice.reducer;
export const updateAuthorizationSafeList =
  slice.actions.updateAuthorizationSafeList;
export const updateAuthorizationModules =
  slice.actions.updateAuthorizationModules;
export const updateAuthorizationInfo = slice.actions.updateAuthorizationInfo;
export const updateSelectedSafe = slice.actions.updateSelectedSafe;
export const updateSelectedModule = slice.actions.updateSelectedModule;
export const updateAuthorizationResult =
  slice.actions.updateAuthorizationResult;
export const updateSelectedRoleMember = slice.actions.updateSelectedRoleMember;
export const updateTokenTransferWhitelist =
  slice.actions.updateTokenTransferWhitelist;
export const updateRewardTokenReceiver =
  slice.actions.updateRewardTokenReceiver;
export const updateTransferWhitelist = slice.actions.updateTransferWhitelist;
export const updatePendingAuthorizeTxHash =
  slice.actions.updatePendingAuthorizeTxHash;
export const updateSubSafeAddress = slice.actions.updateSubSafeAddress;
export const resetAuthorize = slice.actions.resetAuthorize;
export const updateAclNonce = slice.actions.updateAclNonce;
export const updateInstallNewAcl = slice.actions.updateInstallNewAcl;
export const updateAuthorizeProtectors =
  slice.actions.updateAuthorizeProtectors;
export const updateAuthorizeFactoryAclMap =
  slice.actions.updateAuthorizeFactoryAclMap;
export const updateAuthorizeGenerateAclMap =
  slice.actions.updateAuthorizeGenerateAclMap;
export const updateAuthorizeAclLamp = slice.actions.updateAuthorizeAclLamp;
export const updateAuthorizeAclTokenOptions =
  slice.actions.updateAuthorizeAclTokenOptions;
export const updateAuthorizeSellTokens =
  slice.actions.updateAuthorizeSellTokens;
export const updateAuthorizeBuyTokens = slice.actions.updateAuthorizeBuyTokens;
export const updateAuthorizeHealthFactor =
  slice.actions.updateAuthorizeHealthFactor;
export const updateAuthorizeDexAclConfig =
  slice.actions.updateAuthorizeDexAclConfig;
