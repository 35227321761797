import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInterval } from 'ahooks';
import { Tooltip } from 'antd';
import classNames from 'classnames/bind';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { cloneDeep } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './index.module.less';
import Tab from './Tabs';
import { getGenericBots } from '@/api';
import { Breadcrumb, GasAccount } from '@/components';
import { BotDetailModal } from '@/components/Bot/BotDetailModal';
import {
  gasDataAtom,
  qrCodeModalDataAtom,
  qrCodeModalOpenAtom,
} from '@/components/Layout/GasSelector/atom';
import { StrategyMart } from '@/components/MyPortfolio/StrategyMart';
import {
  globalSafeInfoAtom,
  orgIdAtom,
  orgsAtom,
  safeListAtom,
} from '@/globalAtom';
import { useAppDispatch, useQuery } from '@/hooks';
import {
  AvailableGeneralBotItem,
  AvailableGeneralBotsListItem,
} from '@/interfaces';
import { customBotsAtom } from '@/routes/MyPortfolio/atoms';
import { updatePageLoading } from '@/store';
import { isRole } from '@/utils/utils';

export default function MyPortfolio() {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const [data, setData] = useState<AvailableGeneralBotItem[] | undefined>();
  const [bots, setBots] = useAtom(customBotsAtom);
  const dispatch = useAppDispatch();
  const [instance, setInstance] = useState<AvailableGeneralBotsListItem>();
  const [executeRecord, setExecuteRecord] = useState(false);

  const [orgId, setOrgId] = useAtom(orgIdAtom);
  const [organizations] = useAtom(orgsAtom);
  const [initInstance, setInitInstance] =
    useState<AvailableGeneralBotsListItem>();

  const [botActiveKey, setBotActiveKey] = useState<'customize' | 'strategy'>(
    'customize',
  );
  const [tabActiveKey, setTabActiveKey] = useState<'myStrategy' | 'myBot'>(
    'myStrategy',
  );

  const setQrCodeModalData = useSetAtom(qrCodeModalDataAtom);
  const setQrCodeModalOpen = useSetAtom(qrCodeModalOpenAtom);
  const gasData = useAtomValue(gasDataAtom);
  const [safeInfo, setSafeInfo] = useAtom(globalSafeInfoAtom);
  const safeList = useAtomValue(safeListAtom);
  const { pathname } = useLocation();

  const orgInfo = useMemo(() => {
    return organizations
      ? organizations.find(item => item.id === orgId)
      : undefined;
  }, [organizations, orgId]);

  // Admin或者Operator才有操作权限
  const canEdit = orgInfo && !isRole(orgInfo, 2);

  const botFromQuery = useMemo(() => {
    return bots.find(bot => bot.bot_instance_id === query.get('history'));
  }, [bots, query]);

  useEffect(() => {
    const openGasAccountByUrl = async () => {
      if (!orgId || !gasData || gasData.length === 0) return;
      setTimeout(() => {
        // 新开页面的时候initOrgs的时候也会切换orgId，这里需要延迟一下
        if (query.has('chainId') && query.has('orgId')) {
          const orgId = query.get('orgId');
          if (!orgId) return;
          setOrgId(orgId);
          const chainId = query.get('chainId');
          const gas = gasData.find(gas => gas.chain === chainId);
          if (!gas) return;
          setQrCodeModalData(gas);
          setQrCodeModalOpen(true);
        }
      });
    };
    openGasAccountByUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, orgId, query]);

  useEffect(() => {
    const orgId = query.get('orgId');
    const orgInQuery = organizations
      ? organizations.find(item => item.id === orgId)
      : undefined;
    if (!orgInQuery) return;
    const timer = setTimeout(() => setOrgId(orgId));
    return () => clearTimeout(timer);
  }, [organizations, query, setOrgId]);

  useEffect(() => {
    const orgId = query.get('orgId');
    if (!orgId || !orgInfo || orgInfo.id !== orgId) return;
    const safeId = query.get('safeId');
    const safeInQuery = (safeList || []).find(item => item.id === safeId);
    if (!safeInQuery) return;
    const timer = setTimeout(() => {
      if (!safeInQuery) return;
      setSafeInfo(safeInQuery);
      if (
        botFromQuery &&
        (!initInstance ||
          initInstance.bot_instance_id !== botFromQuery.bot_instance_id)
      ) {
        setTabActiveKey('myBot');
        setBotActiveKey(botFromQuery.bot_type === 3 ? 'customize' : 'strategy');
        setInitInstance(botFromQuery);
      }
    });

    return () => clearTimeout(timer);
  }, [botFromQuery, initInstance, orgInfo, query, safeList, setSafeInfo]);

  useEffect(() => {
    if (initInstance) {
      setInstance(initInstance);
    }
  }, [initInstance]);

  useInterval(() => load(false), 15000);

  const load = useCallback(
    async (loading = true) => {
      if (!orgId || !safeInfo?.id) return;
      loading && dispatch(updatePageLoading(true));
      try {
        const res = await getGenericBots(orgId, safeInfo.id, !loading);
        setData(res);

        const bots = res
          .filter(bot => bot.bot_instances && bot.bot_instances.length > 0)
          .flatMap(bot =>
            (bot?.bot_instances ?? []).map(instance => ({
              ...cloneDeep(bot),
              ...instance,
              bot_instances: [],
            })),
          );
        setBots(bots);
        return bots;
      } finally {
        loading && dispatch(updatePageLoading(false));
      }
    },
    [dispatch, orgId, safeInfo?.id, setBots],
  );

  useEffect(() => {
    if (!orgId || data !== undefined || !safeInfo?.id) return;
    load();
  }, [data, orgId, safeInfo?.id, dispatch, safeInfo, load]);

  useEffect(() => {
    return () => {
      setData(undefined);
      setBots([]);
    };
  }, [setBots]);

  const breadcrumbItems = useMemo(() => {
    const investmentType =
      pathname === '/my-portfolio/farming' ? 'farming' : 'trading';
    return [
      {
        label: t(`investment.${investmentType}`),
        onClick: () =>
          navigate(
            `/${investmentType === 'farming' ? 'investment' : 'trading'}`,
          ),
      },
      {
        label: t('investment.myPortfolio'),
        onClick: () => {},
      },
    ];
  }, [navigate, pathname, t]);

  const onRefresh = useCallback(async () => {
    const bots = await load(false);
    if (!bots || !instance) return;
    setInstance(
      bots.find(item => item.bot_instance_id === instance.bot_instance_id),
    );
  }, [instance, load]);

  return (
    <div className={cx('my-bot-container')}>
      <div className={styles['my-bots-header']}>
        <Breadcrumb items={breadcrumbItems} />
      </div>

      {pathname === '/my-portfolio/farming' ? (
        <>
          <div className={cx('section-title', 'first')}>
            {t('bots.gasAccountTitle')}
            <Tooltip title={t('bots.gasAccountTooltip')} placement="bottom">
              <div className={cx('tip-icon')} />
            </Tooltip>
          </div>
          <GasAccount />
          <Tab
            onViewDetail={(ins, executeRecord) => {
              setInstance(ins);
              setExecuteRecord(executeRecord);
            }}
            canEdit={canEdit || false}
            onRefresh={load}
            botActiveKey={botActiveKey}
            tabActiveKey={tabActiveKey}
            setBotActiveKey={setBotActiveKey}
            setTabActiveKey={setTabActiveKey}
          />
        </>
      ) : (
        <StrategyMart />
      )}

      <BotDetailModal
        instance={instance}
        onClose={async () => {
          setInstance(undefined);
          await load(false);
        }}
        onRefresh={onRefresh}
        executeRecord={executeRecord}
      />
    </div>
  );
}
