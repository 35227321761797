import { useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { SearchTokenModal } from '@/components/Common/SearchTokenModal';
import { TokenInfo } from '@/interfaces';

interface Props {
  chain: string;
  existTokens?: string[];
  title?: string;
  checkToken?: (token: TokenInfo) => Promise<boolean>;
  onSelectToken?: (token: TokenInfo) => void;
  wrapperCls?: string;
  canSearchMainToken?: boolean;
  setDropdownVisible?: (visible: boolean) => void;
}

export function SearchAddressLabel({
  chain,
  existTokens,
  title,
  checkToken,
  onSelectToken,
  wrapperCls,
  canSearchMainToken = true,
  setDropdownVisible,
}: Props) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  return (
    <div className={cx('wrapper', wrapperCls)}>
      <div
        className={cx('label-wrapper')}
        onClick={() => {
          setVisible(true);
          setDropdownVisible && setDropdownVisible(false);
        }}
      >
        <div className={cx('search-icon')} />
        <div className={cx('label')}>{t('common.searchByAddress')}</div>
      </div>
      <SearchTokenModal
        chainId={chain}
        show={visible}
        onDismiss={() => {
          setVisible(false);
        }}
        existTokens={existTokens}
        title={title || t('authorization.addNewToken')}
        checkToken={checkToken}
        onSelectToken={token => {
          onSelectToken && onSelectToken(token);
          setVisible(false);
        }}
        canSearchMainToken={canSearchMainToken}
      />
    </div>
  );
}
