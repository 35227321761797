import classNames from 'classnames/bind';

import styles from './index.module.less';
import { SvgIcon } from '@/components';

interface Props {
  items: {
    label: string;
    onClick?: () => void;
  }[];
}

export function Breadcrumb({ items }: Props) {
  const cx = classNames.bind(styles);
  return (
    <div className={cx('cobo-breadcrumb')}>
      {items.map((item, index) => {
        const isCurrent = index === items.length - 1;
        return (
          <div className={cx('breadcrumb-item-wrapper')} key={item.label}>
            <div
              className={cx('breadcrumb-item', isCurrent ? 'current' : '')}
              onClick={() => {
                if (!isCurrent && item.onClick) {
                  item.onClick();
                }
              }}
            >
              {item.label}
            </div>
            {!isCurrent ? (
              <SvgIcon
                name="arrow_down"
                className={styles['breadcrumb-divider']}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
}
