import { useCallback, useMemo, useState } from 'react';
import { message } from 'antd';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './index.module.less';
import { addDelegateAddress } from '@/api';
import { useAppDispatch } from '@/hooks';
import { useMyAddressBook } from '@/hooks/useMyAddressBook';
import { MeAddressInfo } from '@/interfaces';
import { AddAddressModal } from '@/routes/Me/AddAddressModal';
import { updatePageLoading } from '@/store';

interface Props {
  onSuccess?: (infos: MeAddressInfo[]) => void;
  wrapperCls?: string;
  contents?: (onAdd: () => void) => any;
  setSelectOpen?: (open: boolean) => void;
}

export function AddTokenNotice({
  onSuccess,
  wrapperCls,
  contents,
  setSelectOpen,
}: Props) {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);

  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const { refresh } = useMyAddressBook();

  const onAddSuccess = useCallback(async () => {
    const data = await refresh();
    if (onSuccess && data) {
      onSuccess(data);
    }
    setVisible(false);
  }, [refresh, onSuccess]);

  const onAddAddress = useCallback(
    async (address: string, label?: string) => {
      if (!address) return;
      dispatch(updatePageLoading(true));
      try {
        await addDelegateAddress(address, label);
        await onAddSuccess();
      } catch (e: any) {
        message.error(
          e?.response?.data?.error_message ||
            e?.reason ||
            e?.message ||
            'Verify Failed',
        );
        throw e;
      } finally {
        dispatch(updatePageLoading(false));
      }
    },
    [dispatch, onAddSuccess],
  );

  const openAddModal = async () => {
    setVisible(true);
  };

  const renderModal = useMemo(() => {
    return (
      <>
        <AddAddressModal
          visible={visible}
          onClose={() => {
            setVisible(false);
          }}
          onAdd={onAddAddress}
        />
      </>
    );
  }, [visible, onAddAddress]);

  return (
    <div className={cx('add-token-notice-wrapper', wrapperCls)}>
      {contents ? (
        <div className={cx('label')}>
          {contents(() => {
            setSelectOpen && setSelectOpen(false);
            openAddModal();
          })}
        </div>
      ) : (
        <p className={cx('label')}>
          {t('authorization.addAddressText')}
          <span
            className={cx('action')}
            onClick={() => {
              setSelectOpen && setSelectOpen(false);
              openAddModal();
            }}
          >
            {t('authorization.addAddress')}
          </span>
        </p>
      )}
      {renderModal}
    </div>
  );
}
