import { useEffect, useState } from 'react';
import { message } from 'antd';
import classNames from 'classnames/bind';
import { useGlobalSafe } from 'hooks';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { allModulesAtom, moduleTxAtom, moduleTxStatusAtom } from '../../atoms';
import styles from './index.module.less';
import {
  batchGetModuleInfoV2,
  createSafeModule,
  enableSafeModule,
} from '@/api';
import { collect } from '@/api/axios/collect';
import { getChainIdByShortName, getCoboNameByShortName } from '@/chains/utils';
import { Button, ChainIcon, Modal, SvgIcon } from '@/components';
import { useHeartbeat } from '@/hooks/useHeartbeat';
import { useSignAndSubmit } from '@/hooks/useSignAndSubmit';
import { ChainShortNameType } from '@/interfaces';
import { getSafeTxUrl } from '@/sdk/safe';

interface Props {
  type: 'create' | 'enable';
  safeInfo: {
    id: string;
    chain_id: string;
    name: string;
    address: string;
    org_id: string;
    default_cobo_module: string;
  };
  visible: boolean;
  onClose: () => void;
}

export function MultisigModuleModal({
  type,
  safeInfo,
  visible,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const close = () => onClose();
  const cx = classNames.bind(styles);
  const [moduleTx, setModuleTx] = useAtom(moduleTxAtom);
  const [txHash, setTxHash] = useState('');
  const [moduleTxStatus, setModuleTxStatus] = useAtom(moduleTxStatusAtom);
  const { safeModule, setSafeModule } = useGlobalSafe();

  const [allModules, setAllModules] = useAtom(allModulesAtom);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const signAndSubmit = useSignAndSubmit(
    safeInfo.address,
    getChainIdByShortName(safeInfo.chain_id),
  );
  useEffect(() => {
    if (!moduleTx || !visible) return;
    if (['success', 'failed'].includes(moduleTxStatus)) {
      setStatus(moduleTxStatus);
      setModuleTxStatus('pending');
      setTxHash(moduleTx || '');
      setModuleTx(null);
    }
  }, [moduleTx, moduleTxStatus, setModuleTx, setModuleTxStatus, visible]);
  useEffect(() => {
    if (!visible) {
      setStatus('');
      setTxHash('');
    }
  }, [visible]);
  useHeartbeat(
    'View_Page_SafeModuleMultiSig',
    type === 'create' && visible && !!(moduleTx && !status),
    {
      safe_id: safeInfo.id,
    },
  );
  useHeartbeat(
    'View_Page_SafeModuleCreate_CreateSuccess',
    type === 'create' && visible && status === 'success' && !!safeModule,
    {
      safe_id: safeInfo.id,
      module_id: safeModule?.id,
    },
  );
  useEffect(() => {
    if (status !== 'success') return;
    const loadModuleInfo = async () => {
      if (!visible) return;
      const coboName = getCoboNameByShortName(safeInfo.chain_id);
      if (!coboName) throw new Error('incorrect chain');
      const modules = await batchGetModuleInfoV2([
        { chain: coboName, address: safeInfo.address },
      ]);
      if (modules.length === 0) return;
      const currentModules = modules[0].modules;
      const existModules = allModules.map(item => item.address);
      const newModule =
        type === 'create'
          ? currentModules.find(
              module => !existModules.includes(module.address),
            )
          : currentModules.find(
              module => safeModule?.address === module.address,
            );
      if (newModule) {
        setSafeModule(newModule);
      }
      setAllModules(currentModules);
    };
    loadModuleInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    visible,
    safeInfo.address,
    safeInfo.chain_id,
    safeInfo.default_cobo_module,
    safeInfo.id,
    setAllModules,
    setSafeModule,
    status,
    type,
  ]);
  const createModule = async () => {
    const coboName = getCoboNameByShortName(safeInfo.chain_id);
    const chainId = getChainIdByShortName(safeInfo.chain_id);
    if (!chainId || !coboName) return;
    try {
      setLoading(true);
      const safeTx = await createSafeModule(coboName, safeInfo.id);
      await signAndSubmit(safeTx);
      setModuleTx(safeTx.safe_tx_hash);
      setModuleTxStatus('pending');
    } catch (e: any) {
      message.error(
        e?.response?.data?.error_message || e?.reason || e?.message,
      );
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const enableModule = async () => {
    if (!safeModule) return;
    const coboName = getCoboNameByShortName(safeInfo.chain_id);
    if (!coboName) return;
    try {
      setLoading(true);
      const safeTx = await enableSafeModule(
        coboName,
        safeInfo.id,
        safeModule.id,
      );
      await signAndSubmit(safeTx);
      setModuleTx(safeTx.safe_tx_hash);
      setModuleTxStatus('pending');
    } finally {
      setLoading(false);
    }
  };
  const content =
    !!moduleTx || !!status ? null : (
      <div className={cx('content')}>
        <div className={cx('tip')}>
          {type === 'create'
            ? t('safeInfo.createModuleNotice')
            : t('safeInfo.enableModuleNotice')}
        </div>
        <div className={cx('safe-wrapper')}>
          <div className={cx('name-wrapper')}>
            <ChainIcon
              shortName={safeInfo?.chain_id as ChainShortNameType}
              className={cx('safe-chain-icon')}
            />
            <div className={cx('label')}>{safeInfo.name}</div>
          </div>
          <div className={cx('address')}>{safeInfo.address}</div>
        </div>
      </div>
    );
  const pendingContent =
    moduleTx && !status ? (
      <div className={cx('content', 'center')}>
        <div className={cx('multisig-icon')} />
        <div className={cx('multisig-title')}>{t('safeInfo.needMultiSig')}</div>
        <div className={cx('multisig-subtitle')}>
          {t('safeInfo.needMultiSigSubtitle')}
          &nbsp;
          <span
            className={cx('multisig-btn')}
            onClick={() => {
              if (!moduleTx) return;
              window.open(
                getSafeTxUrl(safeInfo.chain_id, safeInfo.address, moduleTx),
                '_blank',
              );
            }}
          >
            {t('safeInfo.needMultiSigSubBtn')}
          </span>
          .&nbsp;
        </div>
      </div>
    ) : null;
  const successContent =
    status === 'success' ? (
      <div className={cx('content', 'center')}>
        <div className={cx('success-image')} />
        <div className={cx('multisig-title')}>
          {t('common.congratulations')}
        </div>
        <div className={cx('multisig-subtitle')}>
          {type === 'create'
            ? t('safeInfo.createSuccessNotice')
            : t('safeInfo.enableSuccessNotice')}
        </div>
        {safeModule && safeModule.version && (
          <div className={cx('success-module-info')}>
            <div className={cx('success-module-title')}>
              {t('safeInfo.coboSafeModule')}
            </div>
            <div className={cx('success-module-content')}>
              <div className={cx('version')}>Version {safeModule.version}</div>
              <div className={cx('address')}>{safeModule.address}</div>
            </div>
          </div>
        )}
        <Button className={cx('ok-btn')} onClick={() => close()}>
          {t('common.ok')}
        </Button>
      </div>
    ) : null;
  const failedContent =
    status === 'failed' ? (
      <div className={cx('content', 'center')}>
        <div className={cx('fail-image')} />
        <div className={cx('multisig-title')}>
          {t('safeInfo.multisigFailed')}
        </div>
        <div className={cx('multisig-subtitle')}>
          {t('safeInfo.multisigFailedNotice')}
        </div>
        <Button
          size="large"
          type="default"
          btnClassName={cx('check-btn')}
          onClick={() => {
            if (!txHash) return;
            window.open(
              getSafeTxUrl(safeInfo.chain_id, safeInfo.address, txHash),
              '_blank',
            );
          }}
        >
          <div className={cx('authorization-btn-context')}>
            {t('authorization.checkBtn')}
            <SvgIcon
              name="ic_browse"
              className={cx('browse-icon')}
              color="#4d84ff"
            />
          </div>
        </Button>
      </div>
    ) : null;
  useHeartbeat('View_Page_CreateSafeModule', type === 'create' && visible, {
    safe_id: safeInfo.id,
  });
  return (
    <Modal
      className={cx('multisig-modal')}
      title={
        type === 'create'
          ? t('safeInfo.createModuleTitle')
          : t('safeInfo.enableModuleTitle')
      }
      visible={visible}
      onCloseBtnClick={close}
      destroyOnClose
      btnProps={
        !status
          ? [
              {
                text: t('common.cancel'),
                primary: false,
                onClick: close,
              },
              {
                text: moduleTx ? t('common.waiting') : t('common.continue'),
                primary: true,
                disabled: !!moduleTx,
                loading: loading,
                onClick: () => {
                  if (moduleTx) return;
                  if (type === 'create') {
                    collect({
                      cid: 'Click_SafeModuleCreate_Create',
                      extra: {
                        safe_id: safeInfo.id,
                      },
                    });
                    createModule();
                  }
                  if (type === 'enable') {
                    enableModule();
                  }
                },
              },
            ]
          : []
      }
    >
      {content}
      {pendingContent}
      {successContent}
      {failedContent}
    </Modal>
  );
}
