import React from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames/bind';

import styles from './index.module.less';

interface Props {
  name: string;
  tip?: string;
  extraCls?: string;
  tipIcon?: string;
}

export function FormName({ name, tip, extraCls, tipIcon }: Props) {
  const cx = classNames.bind(styles);
  return (
    <div className={cx('form-name-wrapper', extraCls)}>
      <div>{name}</div>
      {tip && (
        <Tooltip title={tip} placement="bottom">
          <div className={cx('tip-icon', tipIcon)} />
        </Tooltip>
      )}
    </div>
  );
}
