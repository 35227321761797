import React from 'react';
import { Input as AntdInput } from 'antd';
import { InputProps } from 'antd/lib/input';
import classNames from 'classnames/bind';

import styles from './index.module.less';
import { ErrorTip } from '@/components';

interface coboInputProps extends InputProps {
  size?: 'large' | 'middle' | 'small';
  suffix?: any;
  innerSuffix?: any;
  error?: string;
  note?: string;
  containerCls?: string;
}

const cx = classNames.bind(styles);
export const Input: React.FunctionComponent<coboInputProps> = props => {
  const {
    size,
    className,
    error,
    note,
    suffix,
    innerSuffix,
    prefix,
    containerCls,
    ...restProps
  } = props;
  return (
    <div className={cx('cobo-input-container', containerCls)}>
      <div
        className={cx(
          'cobo-input-box',
          !!innerSuffix || !!prefix ? 'with-suffix' : 'without-suffix',
        )}
      >
        <AntdInput
          {...restProps}
          className={cx(
            {
              'large-input': size === 'large',
              'middle-input': size === 'middle',
              'small-input': size === 'small',
              'error-input': !!error,
            },
            className,
          )}
          prefix={prefix}
          suffix={innerSuffix || ''}
        />
        {suffix}
      </div>
      {error ? <ErrorTip error={error} /> : null}
      {note && !error ? <div className={cx('note')}>{note}</div> : null}
    </div>
  );
};

Input.defaultProps = {
  size: 'large',
};
