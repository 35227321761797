import { atom } from 'jotai';
import { cloneDeep } from 'lodash-es';

import { GenericBotTokenItem } from '@/interfaces';
import { botInfoAtom, botInstanceAtom } from '@/routes/Bot/atoms/bot';

export const tokenApprovalAtom = atom(
  get => {
    const instance = get(botInstanceAtom);
    const tokenApprovalSection = instance?.sections.find(
      section => section.type === 'token_approval',
    );
    if (!tokenApprovalSection) return [];
    return tokenApprovalSection.approvals;
  },
  (get, set, value: GenericBotTokenItem[]) => {
    const info = get(botInfoAtom);
    if (!info || !info.instance_config) return;
    info.instance_config.sections = info.instance_config.sections.map(
      section => {
        if (section.type !== 'token_approval') return section;
        section.approvals = value;
        return section;
      },
    );
    set(botInfoAtom, cloneDeep(info));
  },
);
