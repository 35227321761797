import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from '../index.module.less';
import { CoboImage } from '@/components';

interface IHeaderProps {
  killSession: () => void;
  peerMeta: any;
}

export default function Header({ killSession, peerMeta }: IHeaderProps) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  return (
    <div className={cx('tx-header')}>
      <div className={cx('tx-detail')}>{t('walletconnect.txDetail')}</div>
      <div className={cx('connect-info')}>
        <div
          className={cx('protocol-info')}
          onClick={() => {
            if (peerMeta.url) {
              window.open(peerMeta.url);
            }
          }}
        >
          <div className={cx('link-icon')} />
          {peerMeta.icons && peerMeta.icons.length > 0 ? (
            <CoboImage url={peerMeta.icons[0]} className={cx('protocol-img')} />
          ) : null}
          <div className={cx('protocol-name')}>{peerMeta.name || '-'}</div>
        </div>
        <div
          className={cx('disconnect-btn')}
          onClick={async () => {
            await killSession();
          }}
        >
          {t('common.disconnect')}
        </div>
      </div>
    </div>
  );
}
