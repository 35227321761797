import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.less';
import { collect } from '@/api/axios/collect';
import { getShortNameByCoboName } from '@/chains/utils';
import {
  Button,
  ChainIcon,
  CoboTable,
  IconGroup,
  ServiceIcon,
} from '@/components';
import { ChainShortNameType, portfolioItem, ServiceType } from '@/interfaces';
import { serviceSorts } from '@/utils/utils';

interface Props {
  strategyList: Record<string, any>[];
}

export function TradingList({ strategyList }: Props) {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  const navigateToStrategyInfo = (strategy: portfolioItem) => {
    collect({
      cid: 'Click_Invest_Strategy',
      extra: {
        strategy_id: strategy.strategy_id,
      },
    });
    navigate(`/service-config/trading/${strategy.strategy_id}`);
  };

  const strategyNameEle = (strategy: portfolioItem) => {
    return (
      <div
        className={cx('strategy-name')}
        onClick={() => {
          navigateToStrategyInfo(strategy);
        }}
      >
        <ChainIcon
          isRegIcon
          shortName={
            getShortNameByCoboName(
              strategy.strategy_chain,
            ) as ChainShortNameType
          }
          className={cx('strategy-icon-box')}
        />

        {strategy.strategy_icon || strategy.strategy_invest_tokens?.length ? (
          <div className={cx('iconGroup-box')}>
            <IconGroup
              icons={
                strategy.strategy_icon
                  ? [{ icon_url: strategy.strategy_icon }]
                  : strategy.strategy_invest_tokens ?? []
              }
            />
          </div>
        ) : (
          <div className={cx('protocol-box')}>
            <img
              width="32px"
              height="32px"
              className={cx('protocol')}
              src={require('@/assets/images/chains/protocol.png')}
              alt="none"
            />
          </div>
        )}

        <div className={cx('strategy-name-title')}>
          <h2>{strategy.strategy_name}</h2>
        </div>
      </div>
    );
  };

  const serviceEle = (strategy: portfolioItem) => {
    return (
      <div
        className={cx('strategy-service')}
        onClick={() => {
          navigateToStrategyInfo(strategy);
        }}
      >
        <div className={cx('strategy-service-content')}>
          {strategy.services.sort(serviceSorts).map(el => {
            const sameData = strategy.services.filter(
              _service =>
                _service.detail_type === el.detail_type &&
                _service.service_type === el.service_type,
            );
            const serviceIdx = sameData.findIndex(
              _service => _service.service_id === el.service_id,
            );
            const showIndex = sameData.length > 1 ? `# ${serviceIdx + 1}` : '';
            return (
              <ServiceIcon
                name={
                  el.service_type === ServiceType.SINGLE_SIGN
                    ? el.service_type
                    : el.detail_type
                }
                className="mr-[12px]"
                inactive={el.service_status !== 'In use'}
                showTooltip
                parseServiceTooltip={tooltip =>
                  showIndex ? `${tooltip} ${showIndex}` : tooltip
                }
              />
            );
          })}
        </div>
      </div>
    );
  };

  const btnEle = (strategy: portfolioItem) => {
    return (
      <Button
        onClick={() => {
          navigateToStrategyInfo(strategy);
        }}
        btnClassName={cx('view-button')}
      >
        <div className={cx('button-wrapper')}>
          {t('investment.viewMore')}
          &nbsp;
          <div className={cx('next-icon')} />
        </div>
      </Button>
    );
  };

  const columns = [
    {
      title: t('investment.strategyMart.protocol'),
      dataIndex: 'strategyName',
      key: 'strategyName',
      width: 610,
      render: (text: Text, record: portfolioItem) => strategyNameEle(record),
    },
    {
      title: (
        <span className={cx('table-title')}>
          {t('investment.strategyDetail.service')}
        </span>
      ),
      dataIndex: 'chain_id',
      key: 'chain_id',
      render: (text: Text, record: portfolioItem) => serviceEle(record),
    },
    {
      title: '',
      dataIndex: 'btn',
      width: 184,
      key: 'btn',
      render: (text: Text, record: portfolioItem) => btnEle(record),
    },
  ];

  return (
    <div className={styles['strategy-list']}>
      <CoboTable
        className={cx('strategy-table')}
        pagination={false}
        rowKey={(record: portfolioItem) => `${record.strategy_id}`}
        showSorterTooltip={false}
        dataSource={strategyList || []}
        columns={columns}
        locale={{
          emptyText: (
            <div style={{ padding: '40px 0' }}>
              <img
                alt=""
                style={{ width: '160px' }}
                src={require('@/assets/images/empty.png')}
              />
              <div style={{ padding: '20px 0 0 0' }}>
                {t('common.emptyTable')}
                {` ${t('common.goto')} `}
                <a
                  href={`${window.location.origin}/#/trading`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue"
                >
                  {t('investment.strategyMartTitle')}
                </a>
                {` ${t('investment.strategyDetail.toChooseStrategy')}`}
              </div>
            </div>
          ),
        }}
      />
    </div>
  );
}
