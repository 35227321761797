import { useEffect, useMemo, useState } from 'react';
import { Form, Input } from 'antd';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Web3 from 'web3';

import styles from './index.module.less';
import { Modal } from '@/components';
import { MeAddressInfo } from '@/interfaces';

interface Props {
  visible: boolean;
  onAdd: (address: string, label: string) => Promise<void>;
  onClose: () => void;
  defaultData?: MeAddressInfo;
}
export function AddAddressModal({
  visible,
  onAdd,
  onClose,
  defaultData,
}: Props) {
  const cx = classNames.bind(styles);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const address = Form.useWatch('address', form);
  const label = Form.useWatch('label', form);
  const [setup, setSetup] = useState(false);

  const fieldsValid = useMemo(() => {
    return Web3.utils.isAddress(address || '');
  }, [address]);

  const defaultAddress = useMemo(
    () => defaultData?.address,
    [defaultData?.address],
  );
  const defaultLabel = useMemo(() => defaultData?.label, [defaultData?.label]);
  const delegated = useMemo(
    () => defaultData?.delegated,
    [defaultData?.delegated],
  );

  useEffect(() => {
    if (visible && !address && !setup) {
      form.setFieldValue('address', defaultAddress);
      form.setFieldValue('label', defaultLabel);
      setSetup(true);
    }
  }, [setup, address, defaultAddress, defaultLabel, form, visible]);

  useEffect(() => {
    if (!visible) {
      setSetup(false);
    }
  }, [visible]);

  const [btnLoading, setBtnLoading] = useState(false);

  const onCancel = () => {
    form.setFieldsValue({ address: '', label: '' });
    onClose();
  };

  return (
    <Modal
      className={styles['add-modal']}
      title={
        defaultAddress
          ? t('account.addressTable.editModal.editTitle')
          : t('account.addressTable.editModal.addTitle')
      }
      onCloseBtnClick={() => {
        form.setFieldsValue({ address: '', label: '' });
        onClose();
      }}
      destroyOnClose={true}
      visible={visible}
      btnProps={[
        {
          text: t('common.cancel'),
          primary: false,
          onClick: () => {
            form.setFieldsValue({ address: '', label: '' });
            onClose();
          },
        },
        {
          text: t('common.submit'),
          primary: true,
          disabled: !fieldsValid,
          loading: btnLoading,
          onClick: async () => {
            try {
              setBtnLoading(true);
              await onAdd(address, label);
              onCancel();
            } finally {
              setBtnLoading(false);
            }
          },
        },
      ]}
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{
          address: '',
          label: '',
        }}
        autoComplete="off"
        layout={'vertical'}
        form={form}
        validateTrigger={'onChange'}
      >
        <Form.Item
          label={t('account.addressTable.editModal.address')}
          name="address"
          rules={[
            {
              required: true,
              message: t('account.addressTable.error.address'),
            },
            {
              validator: async (rule, value) => {
                if (!value) return;
                if (!Web3.utils.isAddress(address || '')) {
                  throw new Error(
                    t('account.addressTable.error.invalidAddress'),
                  );
                }
              },
            },
          ]}
        >
          <Input
            allowClear={true}
            name="address"
            disabled={delegated}
            placeholder={t('account.addressTable.placeHolder.address')}
          />
        </Form.Item>
        <Form.Item
          label={
            <>
              {t('me.label')}
              <span className={cx('optional')}>{t('me.optional')}</span>
            </>
          }
          name="label"
        >
          <Input
            allowClear={true}
            name="label"
            maxLength={30}
            placeholder={t('account.addressTable.placeHolder.label')}
          />
        </Form.Item>
      </Form>
      {/*<div className={cx('tip-box')}>*/}
      {/*  <OrangeTipText text={t('me.addressTip')} />*/}
      {/*</div>*/}
    </Modal>
  );
}
