import classNames from 'classnames/bind';

import styles from './index.module.less';

interface Props {
  label: string;
  radius?: string;
  logoClassName?: string;
}

export function NameLogo({ label, radius, logoClassName }: Props) {
  const cx = classNames.bind(styles);
  const colors = [
    '#F2523D',
    '#F56B21',
    '#FFBF2E',
    '#34BE6F',
    '#62D4FA',
    '#2A80EB',
    '#4532CF',
    '#EA517E',
    '#DF62D7',
  ];
  const hashCode = function (s: string) {
    let h = 0,
      l = s.length,
      i = 0;
    if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
    return h;
  };
  let idx = hashCode(label) % colors.length;
  if (idx < 0) {
    idx = -idx;
  }
  return (
    <div
      className={cx('name-logo', logoClassName)}
      style={{ background: colors[idx], borderRadius: radius }}
    >
      {label[0]?.toUpperCase()}
    </div>
  );
}
