import { atom } from 'jotai';

import {
  AuthorizationBundleToken,
  BindSafeInfo,
  Module,
  SafeRole,
  SafeRoles,
  StrategyAuthorizationInfoItem,
  StrategyListItem,
  TokenInfo,
  TokenWithBalance,
  WhitelistItem,
} from '@/interfaces';

export const modeAtom = atom<'create' | 'edit'>('create');
export const strategyInfoAtom = atom<StrategyListItem | null>(null);
export const safeListAtom = atom<BindSafeInfo[] | null>(null);
export const authorizationInfoAtom = atom<StrategyAuthorizationInfoItem | null>(
  null,
);
export const onChainRoleNamesAtom = atom(get => {
  const authorizationInfo = get(authorizationInfoAtom);
  return Object.keys(authorizationInfo?.permission_roles || {}).filter(
    item => item !== 'permission_desc',
  );
});
export const transferAuthorizerMap = atom<{ [key: string]: boolean }>({});
export const selectedSafeAtom = atom<BindSafeInfo | null>(null);
export const selectedModuleAtom = atom<Module | null>(null);
export const moduleListAtom = atom<Module[] | null>(null);
export const authorizationResultAtom = atom<'success' | 'fail'>('success');
export const transferWhitelistAtom = atom<WhitelistItem[] | null>(null);
export const pendingAuthorizeTxHashAtom = atom<string | null>(null);
export const submittingAtom = atom<boolean>(false);
export const newSafeRolesAtom = atom<SafeRole>({});
export const safeRoleNamesAtom = atom<string[]>([]);
export const transferTokensAtom = atom<TokenInfo[]>([]);
export const tokenApprovalListAtom = atom<AuthorizationBundleToken[]>([]);
export const hasDexACLAtom = atom<boolean>(false);
export const transferSelectedMapAtom = atom<{ [key: string]: boolean }>({});
export const dexAclTokensAtom = atom<TokenWithBalance[]>([]);
export const safeRolesAtom = atom<SafeRoles>({});
export const tokenSettingTypeAtom = atom<'general' | 'advance'>('general');
