export const walletconnect = {
  disconnect: 'Disconnect',
  connectWallet: 'Connect Wallet',
  installExtension: 'Install {{extension}}',
  installExtensionContent:
    "Sorry, we haven't detected the {{extension}} Extension from your browser.",
  installExtensionBtn: 'Install {{extension}} Extension',
  uriPlaceholder: 'Paste WalletConnect QR code or connection URI',
  txDetail: 'Transaction Details',
  descTitle: 'How to connect to a Dapp?',
  descStep1: 'Open a Dapp with WalletConnect support.',
  descStep2:
    'Copy the QR code image into clipboard (Command+Control+Shift+4 on Mac, Windows key+PrtScn on  Windows) or copy the link.',
  descStep3:
    'Paste the QR code image or link into the input field (Command+V on Mac, Ctrl+V on Windows).',
  descStep4: 'WalletConnect connection is established automatically.',
  descStep5: 'Now you can trigger transactions via the Dapp to your Safe.',
  txDescTitle: 'How to confirm transactions?',
  txDescStep1: 'Trigger a transaction from the Dapp.',
  txDescStep2:
    'Come back here to confirm the transaction. You will see a popup with transaction details. Review the details and approve the transaction. ',
  txDescStep3:
    'Complete the transaction with a single sig if you are authorized to execute the transaction',
  txEmpty:
    'You must keep the WalletConnect Safe app open for the transaction request to pop up. ',
  txNotice:
    'Please make sure that you and your EOA address have been authorized for this transaction. Otherwise, the transaction will fail. To update your authorization, please contact the Safe{Wallet} owner(s).',
};
