import { useState } from 'react';
import { useMount } from 'ahooks';
import { message } from 'antd';
import { useAtom, useSetAtom } from 'jotai';

import { EmptyOrganization } from './Empty';
import styles from './index.module.less';
import { OrgTable } from './OrgTable';
import { acceptInvitation, listOrganizations } from '@/api';
import { collect } from '@/api/axios/collect';
import { Button, CreateOrganizationModal, SvgIcon } from '@/components';
import { orgsAtom, orgsSetterAtom } from '@/globalAtom';
import { useHeartbeat } from '@/hooks/useHeartbeat';
import { OrganizationItem } from '@/interfaces';

export default function Organization() {
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const [orgs] = useAtom(orgsAtom);
  const setOrgs = useSetAtom(orgsSetterAtom);

  const getOrgList = async () => {
    setCreateModalVisible(false);
    const organizationList = await listOrganizations();
    setOrgs(organizationList);
  };
  useHeartbeat('View_Page_Orglist', true);

  useMount(async () => {
    getOrgList();
  });

  const acceptInvite = async (row: OrganizationItem) => {
    collect({
      cid: 'Click_Orgcreate_Accept',
      extra: {
        org_id: row.id,
      },
    });
    await acceptInvitation(row.id);
    message.success(`You’ve joined the”${row.name}” successfully!`, 10);
    getOrgList();
  };

  useHeartbeat('View_Page_OrgCreate', createModalVisible);
  return (
    <div className={styles['organization-container']}>
      <div className={styles['organization-body']}>
        <div className={styles['organization-header']}>
          <Button
            className={styles['organization-button']}
            onClick={() => {
              collect({
                cid: 'Click_Orgcreate_Create',
              });
              setCreateModalVisible(true);
            }}
          >
            <SvgIcon name="add" className={styles['organization-icon']} />
            &nbsp;&nbsp;Create
          </Button>
        </div>

        {orgs && orgs.length > 0 ? (
          <OrgTable acceptInvite={acceptInvite} />
        ) : (
          <OrgTable
            acceptInvite={acceptInvite}
            locale={{
              emptyText: (
                <EmptyOrganization
                  onOpenCreateModal={() => {
                    setCreateModalVisible(true);
                  }}
                />
              ),
            }}
          />
        )}
      </div>

      <CreateOrganizationModal
        onSuccess={getOrgList}
        createModalVisible={createModalVisible}
        closeModal={() => {
          setCreateModalVisible(false);
        }}
      />
    </div>
  );
}
