import { memo } from 'react';
import classNames from 'classnames/bind';

import styles from './index.module.less';
import { CoboImage } from '@/components/Common';

interface props {
  icons: { icon_url: string; alt?: string }[];
}
export const IconGroup = memo(({ icons }: props) => {
  const cx = classNames.bind(styles);
  const data = icons.slice(0, 4);

  if (data.length === 0) return null;
  return (
    <div className={cx('img-box')}>
      {data.length === 1 && (
        <CoboImage
          className={cx('img-one')}
          key={data[0].icon_url}
          url={data[0].icon_url}
          alt={data[0].alt}
        />
      )}

      {data.length === 2 &&
        data.map(item => {
          return (
            <CoboImage
              className={cx('img-two')}
              key={item.icon_url}
              url={item.icon_url}
              alt={item.alt}
            />
          );
        })}

      {data.length === 3 && (
        <div className={cx('flex')}>
          <div className={cx('row')}>
            {data.slice(0, 2).map(item => {
              return (
                <CoboImage
                  className={cx('img-three')}
                  key={item.icon_url}
                  url={item.icon_url}
                  alt={item.alt}
                />
              );
            })}
          </div>
          <CoboImage
            className={cx('img-three-last')}
            key={data[2].icon_url}
            url={data[2].icon_url}
            alt={data[2].alt}
          />
        </div>
      )}

      {data.length === 4 && (
        <div className={cx('flex')}>
          <div className={cx('row')}>
            {data.slice(0, 2).map(item => {
              return (
                <CoboImage
                  className={cx('img-four')}
                  key={item.icon_url}
                  url={item.icon_url}
                  alt={item.alt}
                />
              );
            })}
          </div>
          <div className={cx('row', 'row-two')}>
            {data.slice(2, 4).map(item => {
              return (
                <CoboImage
                  className={cx('img-four')}
                  key={item.icon_url}
                  url={item.icon_url}
                  alt={item.alt}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
});
