import i18n from '@/i18n';

export interface OrganizationItem {
  id: string;
  name: string;
  display_id: string;
  size: string;
  type: string;
  role: number;
  update_time: number;
  status: number;
  is_default: boolean;
  default_safe_id: string;
}

export const RoleMap: {
  [key: number]: string;
} = {
  0: i18n.t('teamManagement.roleAdmin'),
  1: i18n.t('teamManagement.roleOperator'),
  2: i18n.t('teamManagement.roleViewer'),
};
