import i18n from 'i18next';
import { atom } from 'jotai';

import { ImportSafeModalSafeItem } from '@/interfaces';

export const importSafeModalVisibleAtom = atom(false);

export enum ImportSafeModalStep {
  SelectWallet = 'SelectWallet',
  EmptySafe = 'EmptySafe',
  LoadingSafe = 'LoadingSafe',
  SelectSafe = 'SelectSafe',
  VerifySafe = 'VerifySafe',
  ImportingSafe = 'ImportingSafe',
}
export const importSafeModalStepAtom = atom<ImportSafeModalStep>(
  ImportSafeModalStep.SelectWallet,
);

export const importSafeTitleAtom = atom(get => {
  let title = i18n.t('safeManagement.importSafe.title');
  switch (get(importSafeModalStepAtom)) {
    case ImportSafeModalStep.SelectWallet:
      title = i18n.t('safeManagement.importSafe.selectWallet');
      break;
    case ImportSafeModalStep.ImportingSafe:
      title = i18n.t('safeManagement.importSafe.confirmCoboSafeModule');
      break;
    default:
      break;
  }
  return title;
});

export const importSafeSubTitleAtom = atom(get => {
  let title = i18n.t('safeManagement.importSafe.verifySafeText');
  switch (get(importSafeModalStepAtom)) {
    case ImportSafeModalStep.SelectWallet:
      title = i18n.t('safeManagement.importSafe.selectWalletText');
      break;
    case ImportSafeModalStep.EmptySafe:
      title = i18n.t('safeManagement.importSafe.emptySafeText');
      break;
    case ImportSafeModalStep.LoadingSafe:
      title = i18n.t('safeManagement.importSafe.loadingSafeText');
      break;
    case ImportSafeModalStep.SelectSafe:
      title = i18n.t('safeManagement.importSafe.selectSafeText');
      break;
    default:
      break;
  }
  return title;
});

export const walletConnectWay = atom<'walletConnect' | 'MetaMask'>('MetaMask');

export const ImportSafeModalSafesAtom = atom<ImportSafeModalSafeItem[]>([]);

export const selectedSafesAtom = atom<ImportSafeModalSafeItem[]>([]);
