import i18n from 'i18next';

import { ServiceDetailType, ServiceType, StrategyService } from '@/interfaces';

export const getServiceLabel = (service: StrategyService) => {
  if (service.service_type === ServiceType.SINGLE_SIGN) {
    return i18n.t('bots.manualAuthorize');
  } else if (service.service_type === ServiceType.BOT) {
    if (service.detail_type === ServiceDetailType.CUSTOMIZED_BOT) {
      return service.detail_type;
    } else if (service.detail_type === ServiceDetailType.FARMING) {
      return i18n.t('bots.farmingBot');
    } else if (service.detail_type === ServiceDetailType.WITHDRAW) {
      return i18n.t('bots.withdrawalBot');
    } else if (service.detail_type === ServiceDetailType.LEVERAGE) {
      return i18n.t('bots.leverageBot');
    } else if (service.detail_type === ServiceDetailType.CLAIM) {
      return i18n.t('bots.claimingBot');
    } else if (service.detail_type === ServiceDetailType.TRADING) {
      return service.detail_type;
    }
  }
  return '';
};
